import { IRegisterContextMenu } from '../../../interface/contextmenu/ContextMenu';
import { modKeyText } from '../../../utils/hotkey';
import { Command } from '../../command/Command';

export const globalMenus: IRegisterContextMenu[] = [
  {
    name: '剪切',
    shortCut: `${modKeyText} + X`,
    when: (payload) => {
      return !payload.isReadonly;
    },
    callback: (command: Command) => {
      command.executeCut();
    },
  },
  {
    name: '复制',
    shortCut: `${modKeyText} + C`,
    when: (payload) => {
      return payload.editorHasSelection;
    },
    callback: (command: Command) => {
      command.executeCopy();
    },
  },
  {
    name: '粘贴',
    shortCut: `${modKeyText} + V`,
    when: (payload) => {
      return !payload.isReadonly && payload.editorTextFocus;
    },
    callback: (command: Command) => {
      command.executePaste();
    },
  },
  {
    name: '全选',
    shortCut: `${modKeyText} + A`,
    when: (payload) => {
      return payload.editorTextFocus;
    },
    callback: (command: Command) => {
      command.executeSelectAll();
    },
  },
  {
    isDivider: true,
  },
  {
    icon: 'print',
    name: '打印',
    shortCut: `${modKeyText} + P`,
    when: () => true,
    callback: (command: Command) => {
      command.executePrint();
    },
  },
];
