import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React from 'react';

import Block from '@/module/Block';
import FlexBlock from '@/module/FlexBlock';

import Abnormal from './abnormal';
import Exam from './exam';
import Warning from './warning';

enum OrderTypeEnum {
  MEDICATION = 'MEDICATION',
  HANDLE = 'HANDLE',
  HERBS = 'HERBS',
  EXAMINE = 'EXAMINE',
  ASSAY = 'ASSAY',
  OPERATION = 'OPERATION',
  PATHOLOGY = 'PATHOLOGY',
}

const Index = (props) => {
  const { detail, type } = props;
  const handelType = (type) => {
    return detail?.orderItemDtoList.filter((item) => {
      return item?.orderType === type;
    });
  };

  return (
    <div className="detail-block overview">
      <FlexBlock
        gap={['12px']}
        style={{
          paddingBottom: '12px',
        }}
        colSpan={[1, 1]}
      >
        <Block title="用药记录">
          <VSPromiseTable
            scroll={{
              y: 233,
            }}
            size="small"
            className="aspirin-table"
            id="1"
            onFetch={async (params) => {
              return { data: handelType(OrderTypeEnum.MEDICATION) };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderName']}
              title="医嘱"
              editable={false}
              valueType="digit"
              width={200}
            />

            <VSTableColumn
              dataIndex={['dosageAmount']}
              title="剂量"
              editable={false}
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return (
                  <div>
                    {data?.dosageAmount}&ensp;{data?.dosageUnit}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['way']}
              title="途径"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              editable={false}
              valueType="text"
            />
          </VSPromiseTable>
        </Block>
        <div className="other-block">
          <Block title="检验异常值">
            <Abnormal data={handelType(OrderTypeEnum.ASSAY)} />
          </Block>
          <Block title="检验">
            <Exam type="ASSAY" data={handelType(OrderTypeEnum.ASSAY)} />
          </Block>
        </div>
      </FlexBlock>
      <FlexBlock
        gap={['12px']}
        style={{
          paddingBottom: '12px',
        }}
        colSpan={[1, 1]}
      >
        <Block title="治疗处置">
          <VSPromiseTable
            scroll={{
              y: 233,
            }}
            size="small"
            className="aspirin-table"
            id="1"
            onFetch={async (params) => {
              return { data: handelType(OrderTypeEnum.HANDLE) };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderName']}
              title="医嘱"
              editable={false}
              valueType="digit"
              width={200}
            />

            <VSTableColumn
              dataIndex={['way']}
              title="途径"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              editable={false}
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['dayAmount']}
              title="天数"
              editable={false}
              valueType="text"
            />
          </VSPromiseTable>
        </Block>

        <div className="other-block">
          <Block title="检查异常信息">
            <Warning data={handelType(OrderTypeEnum.EXAMINE)} />
          </Block>
          <Block title="检查">
            <Exam
              type="EXAMINE"
              data={handelType(OrderTypeEnum.EXAMINE)}
              className="exam-block-alone"
            />
          </Block>
        </div>
      </FlexBlock>
      <FlexBlock gap={['12px']}>
        <Block title="手术">
          <VSPromiseTable
            scroll={{
              y: 233,
            }}
            size="small"
            className="aspirin-table"
            id="1"
            onFetch={async (params) => {
              return {
                data: [],
                total: 0,
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['title']}
              title="手术时间"
              editable={false}
              valueType="digit"
            />

            <VSTableColumn
              dataIndex={['quantity']}
              title="手术名称"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['time']}
              title="等级"
              editable={false}
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['time']}
              title="主刀医生"
              editable={false}
              valueType="text"
            />
          </VSPromiseTable>
        </Block>
        <Block title="输血">
          <VSPromiseTable
            scroll={{
              y: 233,
            }}
            size="small"
            className="aspirin-table"
            id="1"
            onFetch={async (params) => {
              return {
                data: [],
                total: 0,
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['title']}
              title="时间"
              editable={false}
              valueType="digit"
            />

            <VSTableColumn
              dataIndex={['quantity']}
              title="输血医嘱"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['time']}
              title="护士"
              editable={false}
              valueType="text"
            />
          </VSPromiseTable>
        </Block>
      </FlexBlock>
    </div>
  );
};

export default Index;
