import './index.less';

import vsf from '@vs/vsf-boot';
import { Popover } from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { async } from 'q';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/Icon';

const arr = [
  {
    status: 'admitted',
    degree: 'common',
    type: [
      'icon-a-Group1292-2',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    degree: 'servious',
    status: 'admitted',
    type: [
      'icon-a-Group1292-1',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'admitted',
    degree: 'risk',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'admitted',
    degree: 'common',
    type: [
      'icon-a-Group1292-2',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    degree: 'servious',
    status: 'admitted',
    type: [
      'icon-a-Group1292-1',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'admitted',
    degree: 'risk',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'occupied',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'occupied',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'occupied',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'occupied',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
  {
    status: 'empty',
    type: [
      'icon-a-Group1292',
      'icon-a-Group1293',
      'icon-a-Group1294',
      'icon-a-Frame1283',
    ],
  },
];

const emptyBedBackground = '#FFFFFF';
const occupyBedBackground = '#F0F0F0';

const Index = (props) => {
  const { list, typeValue, onChange } = props;

  const handelList = () => {
    return arr.map((item, index) => {
      const _res = list?.find((_item) => {
        return _item?.bedNum == index + 1;
      });
      return {
        // ...item,
        degree: index === 0 ? '待出院' : index === 1 ? '待转科' : 'common',
        type: [
          'icon-a-Group1292-2',
          'icon-a-Group1293',
          'icon-a-Group1294',
          'icon-a-Frame1283',
        ],
        status: index === 19 || index === 18 ? 'empty' : '',
        info: _res,
      };
    });
  };

  const handelTime = (time) => {
    const now = new Date();
    return dayjs(now).diff(time, 'day');
  };

  const handleChange = (v) => {
    onChange?.(v);
  };

  const content = <div>暂无</div>;

  const renderCardItem = (item, index) => {
    if (item?.info?.id) {
      return (
        <div
          style={{
            backgroundColor: item?.degree !== 'common' ? '#f0f0f0' : '#fff',
          }}
          key={index}
          className="Inpatient_bed_card_list_content_item"
          onClick={() => {
            handleChange(item);
          }}
        >
          <div className="Inpatient_bed_card_list_content_item_top">
            <div>
              <div className="Inpatient_bed_card_list_content_item_top_index">
                {typeValue === '在科' && (
                  <div
                    style={{
                      backgroundColor:
                        item?.degree === 'common'
                          ? '#FFB93E'
                          : item?.degree === 'risk'
                          ? '#FF7E7E'
                          : item?.degree === 'servious'
                          ? '#FFA57E'
                          : '#F1F6FF',
                    }}
                    className="Inpatient_bed_card_list_content_item_top_index_num"
                  >
                    {index + 1}
                  </div>
                )}
                {typeValue === '在科' && item?.degree !== 'common' && (
                  <div
                    style={{
                      color: '#85898D',
                      marginLeft: '-16px',
                    }}
                  >
                    {item?.degree}
                  </div>
                )}
                {typeValue !== '在科' && (
                  <div className="Inpatient_bed_card_list_content_item_top_index_text">
                    <div className="Inpatient_bed_card_list_content_item_top_index_div"></div>
                    <div>{typeValue}</div>
                  </div>
                )}
                <div className="Inpatient_bed_card_list_content_item_top_index_icon">
                  {item?.type.map((items, _index) => {
                    return (
                      <Popover key={_index} content={content}>
                        <Icon
                          style={{
                            marginLeft: '6px',
                            fontSize: '16px',
                            cursor: 'pointer',
                          }}
                          type={items}
                        />
                      </Popover>
                    );
                  })}
                </div>
              </div>
              <div className="Inpatient_bed_card_list_content_item_top_info">
                <div
                // style={{ color: '#30A313' }}
                >
                  {item?.info?.patient?.name}
                </div>
                <div>{item?.info?.patient?.gender ? '男' : '女'}</div>
              </div>
            </div>
            <div>
              <div className="Inpatient_bed_card_list_content_item_top_card">
                <div> {item?.info?.patient?.id}</div>
                <div> {item?.info?.patient?.age}岁</div>
              </div>
              <div className="Inpatient_bed_card_list_content_item_top_res">
                <div> {item?.info?.nurseName}</div>
                <div> {item?.info?.diagnose?.mainDiagnose}</div>
              </div>
            </div>
          </div>
          <div className="Inpatient_bed_card_list_content_item_bottom">
            <div className="Inpatient_bed_card_list_content_item_bottom_time">
              <div>
                手术：
                <span style={{ color: '#343B42' }}>
                  {dayjs(item?.info?.hospitalTime).format('YYYY-MM-DD HH:mm')}
                </span>
              </div>
              <div>
                术后：
                <span style={{ color: '#343B42' }}>
                  {handelTime(dayjs(item?.info?.hospitalTime))}天
                </span>
              </div>
            </div>
            <div className="Inpatient_bed_card_list_content_item_bottom_time">
              <div>
                入院：
                <span style={{ color: '#343B42' }}>
                  {dayjs(item?.info?.hospitalTime).format('YYYY-MM-DD HH:mm')}
                </span>
              </div>
              <div>
                术后：
                <span style={{ color: '#343B42' }}>
                  {handelTime(dayjs(item?.info?.hospitalTime))}天
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={index}
          className="Inpatient_bed_card_list_content_item Inpatient_bed_card_list_content_item_empty"
          style={{
            backgroundColor:
              item?.status === 'empty'
                ? emptyBedBackground
                : occupyBedBackground,
          }}
        >
          <div className="Inpatient_bed_card_list_content_item_empty_index">
            {index + 1}
          </div>
          <div className="Inpatient_bed_card_list_content_item_empty_content">
            <div className="Inpatient_bed_card_list_content_item_empty_content_img">
              <Icon size={80} type="icon-a-Group1373" />
            </div>
            <div className="Inpatient_bed_card_list_content_item_empty_content_text">
              {item?.status === 'empty' ? '空床位' : '占床'}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="Inpatient_bed_card_list_content">
      {handelList().map((item, index) => {
        return renderCardItem(item, index);
      })}
    </div>
  );
};

export default Index;
