import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Tabs,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

import BgImage from '@/assets/login/bg.png';
import Icon from '@/module/Icon';
import message from '@/module/message';

const { TabPane } = Tabs;

const list = [
  { label: '门诊工作站', value: 'out' },
  { label: '住院工作站', value: 'in' },
];

const Detail = (props) => {
  const { onChange } = props;
  return list?.map((item, index) => (
    <div
      className="item"
      key={index}
      onClick={() => {
        onChange?.(item?.value);
      }}
    >
      {item?.label}
    </div>
  ));
};

const Login = () => {
  const [form] = Form.useForm();

  const [typeOpen, setTypeOpen] = useState(false);
  const [type, setType] = useState('in');

  const onLogin = async (values) => {
    // const res =
    //   await vsf?.services?.AccountController_staffLogin_ed24bb(values);
    // message(res);
    // if (res.code === 200) {
    //   vsf?.history?.push('/index/center');
    // }
    vsf?.stores?.user?.setHospitalType(type);
    vsf?.navigateTo('/index');
  };

  const getApplicationList = async () => {
    // const res =
    //   await vsf?.services?.PrivilegeController_getAuthorizedApplicationList_3e355f(
    //     {
    //       loginId: 'OYO',
    //     },
    //   );
    // if (res?.code === 200) {
    //   setApplication(res?.data);
    // }
  };

  useEffect(() => {
    getApplicationList();
  }, []);

  return (
    <div className="login-container">
      <div className="login-block">
        <div className="login-image-2"></div>
        <div className="title dinpro">Aspirin HIS</div>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onLogin}
        >
          <Form.Item name="username">
            <Input
              allowClear
              size="large"
              prefix={<Icon type="icon-zhanghao" size={25} />}
              placeholder="用户名：admin or user"
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              size="large"
              prefix={<Icon type="icon-mima" size={25} />}
              type="password"
              placeholder="密码：ant.design"
            />
          </Form.Item>
          <Popover
            placement="bottom"
            title=""
            content={
              <Detail
                onChange={(v) => {
                  setTypeOpen(false);
                  setType(v);
                }}
              />
            }
            open={typeOpen}
            trigger="click"
            overlayClassName="login-item-popover"
          >
            <div
              className="medical-type-choose"
              onClick={() => {
                setTypeOpen(true);
              }}
            >
              <Icon type="icon-a-List-viewliebiaoshitu" />
              <span className="word">
                {list?.find((item) => item?.value === type)?.label ??
                  '请选择工作站'}
              </span>
              <Icon type="icon-xia" />
            </div>
          </Popover>
          <div className="extra-oper">
            <Checkbox>记住密码</Checkbox>
            <Button type="link">忘记密码</Button>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
              size="large"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        <div className="other-method">
          <span>还没有账号?</span>
          <Button type="link">立即创建</Button>
        </div>
        <Divider className="login-divider" dashed orientationMargin={25}>
          其他登录方式
        </Divider>
      </div>
    </div>
  );
};

export default definePage(Login);
