import '../index';

import { DoubleRightOutlined } from '@ant-design/icons';
import { Button, Modal, VSTable, VSTableColumn } from '@vs/vsf-kit';
import className from 'classnames';
import React, { useState } from 'react';

import Block from '@/module/Block';
import Cdss from '@/module/Cdss';

import Tendency from './tendency';
const mock = [
  {
    label: '申请项目',
    value: 'applyItemName',
  },
  {
    label: '样本',
    value: 'sample',
  },
  {
    label: '申请医生',
    // orderDoctor.name

    value: 'orderDoctorName',
  },
  {
    label: '检验号',
    value: 'id',
  },
  {
    label: '样本条码',
    value: 'sampleNum',
  },
  {
    label: '诊断',
    // treat.mainDiagnose

    value: 'mainDiagnose',
  },
];

const value = [
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
  {
    a0: '白细胞',
    a1: '15.0',
    a2: '参考范围',
    a3: '单位',
    a4: '异常结果',
    a5: '危急值',
  },
];

const Index = (props) => {
  const { detail } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState(true);

  const handelHearder = (item) => {
    if (item?.value === 'orderDoctorName') {
      return detail?.orderDoctor?.name;
    } else if (item?.value === 'mainDiagnose') {
      return detail?.treat?.mainDiagnose;
    } else {
      return detail?.[item?.value];
    }
  };

  return (
    <div className="timeline-detail">
      <div className="info">
        {mock?.map((item, index) => {
          return (
            <div key={index} className="item">
              <span className="label">{item?.label}</span>
              <span className="value">{handelHearder(item)}</span>
            </div>
          );
        })}
      </div>
      {detail?.applyType === 'ASSAY' && (
        <div className="items">
          <VSTable
            scroll={{
              y: 436,
            }}
            className="aspirin-table aspirin-table-nopadding"
            editable={{
              editType: 'none',
            }}
            value={detail?.applyType === 'ASSAY' && detail?.assayReportDtoList}
            pagination={false}
          >
            <VSTableColumn
              valueType="index"
              fieldProps={{}}
              editable={false}
              width={40}
            />
            <VSTableColumn
              dataIndex={['assayItemName']}
              title="项目"
              valueType="text"
              fieldProps={{}}
              editable={false}
              render={(_, data) => {
                return <div>{data?.reportItem?.assayItemName}</div>;
              }}
            />

            <VSTableColumn
              dataIndex={['result']}
              title="结果"
              valueType="text"
              fieldProps={{}}
              width={250}
            />

            <VSTableColumn
              dataIndex={['limitStart']}
              title="参考范围"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return (
                  <div style={{ color: '#3276E8' }}>
                    {data?.reportItem?.isNumber
                      ? `${data?.reportItem?.limitStart}-${data?.reportItem?.limitEnd}`
                      : data?.reportItem?.descr}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['unit']}
              title="单位"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return data?.reportItem?.unit;
              }}
            />

            <VSTableColumn
              dataIndex={['abnormalResult']}
              title="异常结果"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return (
                  <div style={{ color: '#F54141' }}>
                    {data?.abnormal ? data?.abnormalResult : ''}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['a5']}
              title="危急值"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['a6']}
              title="操作"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return data?.reportItem?.isNumber ? (
                  <Cdss title="智能助手(检测报告)" placement="left" data={data}>
                    <Button
                      type="text"
                      style={{
                        color: '#3276E8',
                      }}
                      // onClick={() => {
                      //   setOpen(true);
                      // }}
                    >
                      分析
                    </Button>
                  </Cdss>
                ) : (
                  ''
                );
              }}
            />
          </VSTable>
        </div>
      )}
      {detail?.applyType === 'EXAMINE' && (
        <div className="timeline-detail_checked_detail">
          <div style={{ position: 'sticky', top: 0 }}>
            <div className="timeline-detail_checked_detail_title">
              {detail?.applyCategory}[{detail?.applyItemName}]
            </div>
            <div className="timeline-detail_checked_detail_doctor_name">
              申请医师：{detail?.orderDoctor?.name}
            </div>
          </div>
          <div
            style={{
              // height: '100%',
              height: '390px',
              overflowY: 'scroll',
            }}
          >
            <div>
              <Block titleBackgroundColor="#DBEDFF" title="申请信息">
                <div className="timeline-detail_checked_detail_content">
                  <div className="timeline-detail_checked_detail_content_label">
                    简要病史:
                  </div>
                  <div className="timeline-detail_checked_detail_content_content">
                    {detail?.patient?.medicalHistory}
                  </div>
                </div>
                <div className="timeline-detail_checked_detail_content">
                  <div className="timeline-detail_checked_detail_content_label">
                    诊断:
                  </div>
                  <div className="timeline-detail_checked_detail_content_content">
                    {detail?.treat?.mainDiagnose}
                  </div>
                </div>
                <div className="timeline-detail_checked_detail_content">
                  <div className="timeline-detail_checked_detail_content_label">
                    执行科室:
                  </div>
                  <div className="timeline-detail_checked_detail_content_content">
                    {detail?.executeDepartment}
                  </div>
                </div>
                <div className="timeline-detail_checked_detail_content">
                  <div className="timeline-detail_checked_detail_content_label">
                    注意事项:
                  </div>
                  <div className="timeline-detail_checked_detail_content_content">
                    {detail?.precaution}
                  </div>
                </div>
              </Block>
            </div>
            {detail?.examineReportDto && (
              <div>
                <Block
                  titleBackgroundColor="#DBEDFF"
                  title="报告1"
                  extra={
                    <div style={{ display: 'flex', paddingRight: '10px' }}>
                      <div className="timeline-detail_checked_detail_view_img">
                        查看图像
                      </div>
                      <DoubleRightOutlined
                        className={className(
                          isShowDetail
                            ? 'timeline-detail_checked_detail_double_up_outlined'
                            : 'timeline-detail_checked_detail_double_down_outlined',
                        )}
                        style={{ color: '#3276E8' }}
                        onClick={() => {
                          setIsShowDetail(!isShowDetail);
                        }}
                      />
                    </div>
                  }
                >
                  <div style={{ display: isShowDetail ? 'block' : 'none' }}>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        检查所见:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {detail?.examineReportDto?.examineSee}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        印象:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {/* 两侧胸廓对称，气管居中，两肺纹理增多，两肺多发散在斑片状高密度影，右上为著，可见空洞形成。气管及主要支气管通畅。纵隔淋巴结肿大。胸腔内未见积液征。心景未见异常。左侧第3、4、6肋骨陈旧性骨折。 */}
                        {detail?.examineReportDto?.impression}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        建议:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {detail?.examineReportDto?.suggest}
                      </div>
                    </div>
                  </div>
                </Block>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
