import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

import Block from '@/module/Block';
import Empty from '@/module/empty';
import FlexBlock from '@/module/FlexBlock';

import Detail from './detail';
import List from './list';
import Search from './search';

const Index = (props) => {
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};

  const [currentReport, setCurrentReport] = useState();
  const [reportList, setReportList] =
    useState<OrderApplyVoDoctorOrderEntranceWebVoDoctorordervo[]>();
  const [reportDetail, setReportDetail] = useState();
  const [applyStatusIs, setApplyStatusIs] = useState<boolean>();
  const [thisVisit, setThisVisit] = useState<boolean>();
  const [applyCategoryIn, setApplyCategoryIn] = useState<string[]>();

  useEffect(() => {
    getExamineList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyStatusIs, thisVisit, applyCategoryIn, initKey, currentPatient]);

  const getExamineList = async () => {
    if (!currentPatient?.patient?.id) {
      setReportList([]);
      getExamineDetail(undefined);
      setCurrentReport(undefined);
      setReportDetail(undefined);
    } else {
      const res =
        await vsf?.services?.OrderApplyController_allOrderApply_ce2c69?.({
          qto: {
            from: 0,
            size: 20,
            applyTypeIs: 'ASSAY',
            applyStatusIs: applyStatusIs ? 'CONFIRM_REPORT' : undefined,
            applyCategoryIn:
              applyCategoryIn?.[0] === '全部' ? undefined : applyCategoryIn,
            patientIdIs: currentPatient?.patient?.id,
            // treatIdIs: thisVisit ? '' : undefined,
          },
        });
      if (res.code === 200) {
        setReportList(res.data);
        if (res?.data?.length > 0) {
          getExamineDetail(res.data?.[0]?.id);
          setCurrentReport(res.data?.[0]?.id);
        } else {
          setReportDetail(undefined);
        }
      }
    }
  };
  const getExamineDetail = async (id) => {
    if (!id) {
      setReportDetail(undefined);
    } else {
      const res =
        await vsf?.services?.OrderApplyController_getOrderApplyDetailById_9708b7(
          {
            id: id,
          },
        );
      if (res.code == 200) {
        setReportDetail(res.data);
      }
    }
  };

  const handelSearch = (v) => {
    setApplyStatusIs(v?.isReportValue);
    setThisVisit(v?.thisVisit);
    setApplyCategoryIn(v?.selectValue);
  };

  return (
    <div className="examine">
      <Search handelConditionQuery={handelSearch} />
      <div className="examine_main">
        {!reportList ||
          (reportList?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <List
                value={currentReport}
                dataSource={reportList}
                onChange={(item) => {
                  setCurrentReport(item?.id);
                  getExamineDetail(item?.id);
                }}
              />
              {reportList && reportList?.length > 0 && (
                <Detail reportDetail={reportDetail} />
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default definePage(Index);
