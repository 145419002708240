import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/Icon';
import { useHorizontalScroll } from '@/utils/hooks';

import Oper from './oper';

type InfoPropsType = {
  data: any;
  color?: string;
  info?: any;
};

const Item = (props: InfoPropsType) => {
  const { data, info } = props;
  return (
    <div className="info-item">
      <div
        className="info-bar"
        style={{
          backgroundColor: data?.color,
        }}
      ></div>
      <div className="info-content">
        {(data?.data || [])?.map((item, index) => {
          const labelChar = (item?.label || '')?.split('');
          return (
            <div key={index} className="item-row">
              <span
                className="label"
                style={{
                  width: data?.labelWidth,
                }}
              >
                {labelChar?.map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
              </span>
              <span className="value">
                {item?.field ? info?.[item?.field] : item?.value}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mockIn = [
  {
    color: '#3276E8',
    labelWidth: 64,
    data: [
      { label: '类型', value: '自费' },
      { label: '床号', value: '感染31床', field: 'bedNum' },
    ],
  },
  {
    color: '#FFA57E',
    labelWidth: 64,
    data: [
      { label: '过敏史', value: '无' },
      { label: '诊断', value: '-', field: 'mainDiagnose' },
    ],
  },
  {
    color: '#69A1FF',
    labelWidth: 80,
    data: [
      { label: '病区', value: '生殖中心' },
      { label: '经治医生', value: '薛照' },
    ],
  },
  {
    color: '#50BC35',
    labelWidth: 64,
    data: [
      { label: '入院', value: '2023-07-17' },
      { label: '已住院', value: '10天' },
    ],
  },
  {
    color: '#FF7E7E',
    labelWidth: 96,
    data: [
      { label: '已出费用', value: '￥10200' },
      { label: '预交金金额', value: '￥31410' },
    ],
  },
];

const mockOut = [
  {
    color: '#3276E8',
    labelWidth: 64,
    data: [
      { label: '费别', value: '自费' },
      { label: '人员类别', value: '自费病员' },
    ],
  },
  {
    color: '#69A1FF',
    labelWidth: 80,
    data: [
      { label: '诊断', value: '-', field: 'mainDiagnose' },
      { label: '过敏史', value: '无' },
    ],
  },
  {
    color: '#50BC35',
    labelWidth: 64,
    data: [
      { label: '患者电话', value: '-', field: 'phone' },
      { label: '地址', value: '-', field: 'address' },
    ],
  },
];

const Index = (props) => {
  const { user } = props?.stores || {};
  const {
    currentPatient,
    hospitalType,
    getPatientList,
    getTreatInfo,
    treatInfo,
  } = user || {};
  const indexCls = getPrefixCls('index-center-info');
  const infoRef = useRef<any>();
  useHorizontalScroll(infoRef);

  useEffect(() => {
    getPatientList();
  }, [getPatientList]);

  useEffect(() => {
    if (currentPatient?.id) {
      getTreatInfo();
    }
  }, [currentPatient, getTreatInfo]);

  const mock = hospitalType === 'out' ? mockOut : mockIn;

  return (
    <div className={indexCls}>
      <Oper />
      {currentPatient?.patient?.id ? (
        <>
          {hospitalType === 'in' && (
            <div className="number">
              <Icon type="icon-Frame2" size={30} />
            </div>
          )}

          <div className="main">
            <div className="first">
              <span className="name">{treatInfo?.patient?.name}</span>
              <span className="gender">
                {treatInfo?.patient?.gender ? (
                  <Icon type="icon-nan" size={20} />
                ) : (
                  <Icon type="icon-nv" size={20} />
                )}
              </span>
              <span className="age">{treatInfo?.patient?.age}岁</span>
            </div>
            <div className="second">
              <span className="label">
                {hospitalType === 'in' ? '住院号' : '患者ID'}
              </span>
              <span className="value">{treatInfo?.patient?.id}</span>
            </div>
          </div>

          {hospitalType === 'in' && (
            <div className="status">
              <Icon type="icon-a-Group1294" size={26} />
              <Icon
                type="icon-a-Frame1283"
                size={26}
                style={{ marginTop: 12 }}
              />
            </div>
          )}

          <div className="info noscroll" ref={infoRef}>
            {mock?.map((item, index) => (
              <Item
                key={index}
                data={item}
                info={{
                  ...cloneDeep(treatInfo),
                  mainDiagnose: treatInfo?.mainDiagnose ?? '无',
                  ...treatInfo?.patient,
                  bedNum: `${currentPatient?.bedNum}号`,
                }}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default definePage(Index);
