import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';
import Icon from '@/module/Icon';

const labelWidth = 100;
const formItemWidth = 194;

const TransplantRecord = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};
  const getValue = (value) => {
    if (typeof value === 'string') {
      try {
        return value && JSON.parse(value || '');
      } catch (error) {
        return [];
      }
    } else {
      return value;
    }
  };
  return (
    <VSForm
      labelAlign="left"
      id="transplantRecord"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.TransplantRecordController_getTransplantRecordByPatientId_5220d0?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
    >
      <FormBlock>
        <div style={{ display: 'flex' }}>
          <VSFormLayout key="0" columnCount={3} labelWidth={labelWidth}>
            <VSFormItem
              name={['badNumber']}
              label="床号"
              valueType="digit"
              fieldProps={{
                width: 180,
              }}
            />
          </VSFormLayout>
          <div className="transplant_record_import">
            <Icon type="icon-tianjiagongneng" />
            导入床号
          </div>
        </div>
        <div
          style={{ borderTop: '1px solid #AEB0B3', marginBottom: '25px' }}
        ></div>
        <VSFormLayout key="1" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['admissionDate']}
            label="入院时间"
            valueType="date"
            fieldProps={{
              width: 180,
            }}
          />
        </VSFormLayout>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormLayout key="2" columnCount={1} labelWidth={labelWidth}>
            <VSFormItem
              name={['admissionDiagnosis']}
              label="入院诊断"
              valueType="text"
              fieldProps={{
                width: 962,
              }}
            />
          </VSFormLayout>
          <div
            style={{ marginTop: '5px', color: '#3276E8', cursor: 'pointer' }}
          >
            <Icon style={{ marginRight: '2px' }} type="icon-Frame-51" />
            刷新评分
          </div>
        </div>
        <VSFormLayout key="3" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['dischargeDate']}
            label="出院时间"
            valueType="date"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['dischargeDiagnosis']}
            label="出院诊断"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <div
          style={{ borderTop: '1px solid #AEB0B3', marginBottom: '25px' }}
        ></div>
        <VSFormLayout key="5" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['complaint']}
            label="主诉"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['admissionCondition']}
            label="入院情况"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['treatment']}
            label="治疗经过"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <div
          style={{ borderTop: '1px solid #AEB0B3', marginBottom: '25px' }}
        ></div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: '100px',
              paddingTop: '5px',
              flexShrink: 0,
            }}
          >
            生命特征
          </div>
          <div style={{ flex: 1 }}>
            <VSFormLayout key="6" columnCount={4} labelWidth={75}>
              <VSFormItem
                addonAfter="℃"
                name={['temperature']}
                label="体温"
                valueType="text"
              />

              <VSFormItem
                addonAfter="次/分"
                name={['breath']}
                label="呼吸"
                valueType="text"
              />

              <VSFormItem
                addonAfter="次/分"
                name={['pulse']}
                label="脉搏"
                valueType="text"
              />

              <VSFormItem
                addonAfter="mmHg"
                name={['bloodPressure']}
                label="血压"
                valueType="text"
              />
            </VSFormLayout>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: '100px',
              paddingTop: '5px',
              flexShrink: 0,
            }}
          >
            试移植
          </div>
          <div style={{ flex: 1 }}>
            <VSFormLayout key="7" columnCount={4} labelWidth={75}>
              <VSFormItem
                name={['cervicalPosition']}
                label="宫颈位置"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.cervical_position}
                fieldProps={{
                  width: 154,
                }}
              />

              <VSFormItem
                name={['depth']}
                label="深度(cm)"
                valueType="digit"
                fieldProps={{
                  width: 136,
                }}
              />

              <VSFormItem
                name={['smoothness']}
                label="顺利程度"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.smoothness}
                fieldProps={{
                  width: 136,
                }}
              />

              <VSFormItem
                name={['cervicalCondition']}
                label="宫颈情况"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.cervical_condition}
                fieldProps={{
                  width: 126,
                }}
              />
            </VSFormLayout>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            // alignItems: 'center',
          }}
        >
          <VSFormLayout key="15" columnCount={1} labelWidth={labelWidth}>
            <VSFormItem
              name={['otherOne']}
              label="其他"
              valueType="text"
              fieldProps={{
                width: 950,
              }}
            />
          </VSFormLayout>
          <div
            style={{ marginTop: '5px', color: '#3276E8', cursor: 'pointer' }}
          >
            <Icon style={{ marginRight: '2px' }} type="icon-Frame-51" />
            刷新试移植
          </div>
        </div>
        <VSFormLayout key="16" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['technologyImplemented']}
            label="已实施实验室技术"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.technology_implemented}
            fieldProps={{}}
          />

          <VSFormItem
            name={['cultureTime']}
            label="胚胎培养时间"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.cultureTimeEnum}
            fieldProps={{}}
          />

          <VSFormItem
            addonAfter="个"
            name={['splitEmbryoCount']}
            label="分裂胚胎"
            valueType="digit"
            fieldProps={{}}
          />
          <VSFormItem
            name={['splitEmbryo']}
            label="分裂胚胎/裹胚内容"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="6" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['subzero']}
            label="冷冻分"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['subzeroDate']}
            label="冻存日期"
            valueType="date"
            fieldProps={{}}
          />

          <VSFormItem
            name={['subzeroContent']}
            label="冷冻胚胎内容"
            valueType="text"
            fieldProps={{
              width: 467,
            }}
          />
        </VSFormLayout>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormLayout key="6" columnCount={4} labelWidth={labelWidth}>
            <VSFormItem
              name={['ispgo']}
              label="是否进行PGO"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.ispgo}
              fieldProps={{}}
            />

            <VSFormItem
              name={['pgoDiagnosis']}
              label="PGO诊断"
              valueType="text"
              fieldProps={{
                width: 467,
              }}
            />
          </VSFormLayout>
          <div
            style={{ marginTop: '5px', color: '#3276E8', cursor: 'pointer' }}
          >
            <Icon style={{ marginRight: '2px' }} type="icon-Frame-51" />
            刷新评分
          </div>
        </div>
        <VSFormLayout key="7" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['transplantDate']}
            label="移植时间"
            valueType="date"
            fieldProps={{
              width: 174,
            }}
          />

          <VSFormItem
            addonAfter="个"
            name={['transplantCount']}
            label="移植胚胎数目"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['transplantContent']}
            label="移植胚胎内容"
            valueType="text"
            fieldProps={{
              width: 467,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['uterinePosition']}
            label="子宫位置"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.uterinePositionEnum}
            fieldProps={{}}
          />

          <VSFormItem
            name={['size']}
            label="大小"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="20" columnCount={1} labelWidth={labelWidth}>
          {/* 操作过程 */}
          <div style={{ display: 'flex' }}>
            <div
              style={{
                flex: 1,
                marginRight: '280px',
              }}
            >
              <VSFormLayout key="20" columnCount={1} labelWidth={labelWidth}>
                <VSFormItem
                  name={['operatProcess']}
                  label="操作过程"
                  valueType="checkbox"
                  fieldProps={{}}
                  dataSource={[
                    { label: '进宫颈内口顺利', value: 12 },
                    { label: '进宫颈内口困难', value: 13 },
                    { label: '反复多次', value: 14 },
                    { label: '使用内芯', value: 15 },
                    { label: '宫颈钳', value: 16 },
                    { label: '探针', value: 17 },
                    { label: '充盈膀胱', value: 18 },
                    { label: '按压腹部', value: 19 },
                    { label: 'B超引导', value: 20 },
                    { label: '进宫腔影象清晰', value: 21 },
                  ]}
                  transform={(v) => {
                    return { operatProcess: JSON.stringify(v) };
                  }}
                  convertValue={(v) => {
                    return getValue(v);
                  }}
                />
              </VSFormLayout>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
              <VSFormLayout key="26" columnCount={1} labelWidth={labelWidth}>
                <VSFormItem
                  name={['other']}
                  label="其他"
                  valueType="text"
                  fieldProps={{
                    width: 334,
                  }}
                />
              </VSFormLayout>
            </div>
          </div>
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['transplantTubeContents']}
            label="移植管内容物"
            valueType="checkbox"
            fieldProps={{}}
            dataSource={[
              { label: '无', value: 1 },
              { label: '反复移植', value: 2 },
              { label: '内管粘液', value: 3 },
              { label: '内管沾血', value: 4 },
              { label: '外管粘液', value: 5 },
              { label: '外管沾血', value: 6 },
              { label: '出血少', value: 7 },
              { label: '出血多', value: 8 },
            ]}
            transform={(v) => {
              return { transplantTubeContents: JSON.stringify(v) };
            }}
            convertValue={(v) => {
              return getValue(v);
            }}
          />
          {/* <VSFormItem
            name={['t']}
            // label="移植管内容物"
            valueType="checkbox"
            fieldProps={{}}
            dataSource={[{ label: '反复移植', value: true }]}
          /> */}
          {/* </div> */}
          {/* </div> */}
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['graftTube']}
            label="所用移植管"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.graft_tube_enum}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['circumstancesHand']}
            label="手中特殊情况"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <div
          style={{ borderTop: '1px solid #AEB0B3', marginBottom: '25px' }}
        ></div>
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{ paddingTop: '5px', marginRight: '20px', flexShrink: 0 }}
          >
            出院医嘱
          </div>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['progesteroneInjection']}
                    // label="黄体酮针剂"
                    valueType="checkbox"
                    dataSource={[{ label: '黄体酮针剂', value: '1' }]}
                    transform={(v) => {
                      if (v.length > 0) {
                        return { progesteroneInjection: true };
                      } else {
                        return { progesteroneInjection: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { progesteroneInjection: v ? '1' : '' };
                    }}
                  />
                </div>
                <VSFormItem
                  addonAfter="mg"
                  name={['progesteroneInjectionIntramuscular']}
                  //   label="黄体酮针剂_肌注"
                  valueType="text"
                  //   style={{ marginLeft: '20px' }}
                  fieldProps={{
                    width: 100,
                  }}
                />

                <VSFormItem
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  name={['progesteroneInjectionIntramuscularCount']}
                  //   label="黄体酮针剂_肌注_数量"
                  valueType="digit"
                  fieldProps={{}}
                />

                {/* </VSFormLayout> */}
              </div>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout columnCount={3} labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['hcg']}
                    // label="HCG"
                    valueType="checkbox"
                    dataSource={[{ label: 'HCG', value: '2' }]}
                    transform={(v) => {
                      if (v.length > 0) {
                        return { hcg: true };
                      } else {
                        return { hcg: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '2' : '' };
                    }}
                    fieldProps={{}}
                  />
                </div>

                <VSFormItem
                  addonAfter="IU"
                  name={['hcgIntramuscular']}
                  // label="HCG_肌注"
                  valueType="text"
                  fieldProps={{
                    width: 100,
                  }}
                />

                <VSFormItem
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  name={['hcgIntramuscularCount']}
                  // label="HCG_肌注__数量"
                  valueType="digit"
                  fieldProps={{}}
                />
                {/* </VSFormLayout> */}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['dafton']}
                    //   label="达芙通"
                    dataSource={[{ label: '达芙通', value: '3' }]}
                    valueType="checkbox"
                    transform={(v) => {
                      if (v.length > 0) {
                        return { dafton: true };
                      } else {
                        return { dafton: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '3' : '' };
                    }}
                    fieldProps={{}}
                  />
                </div>

                <VSFormItem
                  name={['daftonIntramuscular']}
                  addonAfter="mg"
                  //   label="达芙通_肌注"
                  valueType="text"
                  fieldProps={{
                    width: 100,
                  }}
                />

                <VSFormItem
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  name={['daftonIntramuscularCount']}
                  //   label="达芙通_肌注_数量"
                  valueType="digit"
                  fieldProps={{}}
                />
                {/* </VSFormLayout> */}
              </div>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout columnCount={3} labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    dataSource={[{ label: '安琪坦', value: '4' }]}
                    name={['anqitan']}
                    // label="安琪坦"
                    valueType="checkbox"
                    transform={(v) => {
                      if (v.length > 0) {
                        return { anqitan: true };
                      } else {
                        return { anqitan: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '4' : '' };
                    }}
                    fieldProps={{}}
                  />
                </div>

                <VSFormItem
                  addonAfter="#"
                  name={['anqitanIntramuscular']}
                  //   label="安琪坦_肌注"
                  valueType="text"
                  fieldProps={{
                    width: 100,
                  }}
                />
                <VSFormItem
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  name={['anqitanIntramuscularCount']}
                  //   label="安琪坦_肌注_数量"
                  valueType="digit"
                  fieldProps={{}}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['propionate']}
                    // label="补佳乐"
                    dataSource={[{ label: '补佳乐', value: '5' }]}
                    valueType="checkbox"
                    fieldProps={{}}
                    transform={(v) => {
                      if (v.length > 0) {
                        return { propionate: true };
                      } else {
                        return { propionate: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '5' : '' };
                    }}
                  />
                </div>

                <VSFormItem
                  name={['propionateIntramuscular']}
                  //   label="补佳乐_肌注"
                  addonAfter="mg"
                  valueType="text"
                  fieldProps={{
                    width: 100,
                  }}
                />

                <VSFormItem
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  name={['propionateIntramuscularCount']}
                  //   label="补佳乐_肌注_数量"
                  valueType="digit"
                  fieldProps={{}}
                />
                {/* </VSFormLayout> */}
              </div>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout columnCount={3} labelWidth={labelWidth}> */}

                <VSFormItem
                  name={['propionateInfo']}
                  // label="补乐佳信息"
                  valueType="text"
                  fieldProps={{
                    width: 450,
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['aspirin']}
                    // label="阿司匹林"
                    dataSource={[{ label: '阿司匹林', value: '6' }]}
                    valueType="checkbox"
                    transform={(v) => {
                      if (v.length > 0) {
                        return { aspirin: true };
                      } else {
                        return { aspirin: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '6' : '' };
                    }}
                    fieldProps={{}}
                  />
                </div>

                <VSFormItem
                  name={['aspirinIntramuscular']}
                  //   label="阿司匹林_肌注"
                  addonAfter="mg"
                  valueType="text"
                  fieldProps={{
                    width: 100,
                  }}
                />
                <VSFormItem
                  name={['aspirinIntramuscularCount']}
                  //   label="阿司匹林_肌注_数量"
                  addonBefore="肌注 每日"
                  addonAfter="次"
                  valueType="digit"
                  fieldProps={{}}
                />

                {/* </VSFormLayout> */}
              </div>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout columnCount={3} labelWidth={labelWidth}> */}

                <VSFormItem
                  name={['aspirinInfo']}
                  // label="阿司匹林信息"
                  valueType="text"
                  fieldProps={{
                    width: 450,
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout labelWidth={labelWidth}> */}
                <div style={{ width: '150px' }}>
                  <VSFormItem
                    name={['schonordone']}
                    // label="雪诺酮"
                    dataSource={[{ label: '雪诺酮', value: '7' }]}
                    valueType="checkbox"
                    fieldProps={{}}
                    transform={(v) => {
                      if (v.length > 0) {
                        return { schonordone: true };
                      } else {
                        return { schonordone: false };
                      }
                    }}
                    convertValue={(v) => {
                      return { hcg: v ? '7' : '' };
                    }}
                  />
                </div>

                <VSFormItem
                  name={['schonordoneIntramuscular']}
                  //   label="雪诺酮_肌注"
                  valueType="text"
                  addonAfter="mg"
                  fieldProps={{
                    width: 100,
                  }}
                />

                <VSFormItem
                  name={['schonordoneIntramuscularCount']}
                  //   label="雪诺酮_肌注__数量"
                  valueType="digit"
                  fieldProps={{}}
                  addonBefore="肌注 每日"
                  addonAfter="次"
                />

                {/* </VSFormLayout> */}
              </div>
              <div style={{ display: 'flex', flex: '1' }}>
                {/* <VSFormLayout columnCount={3} labelWidth={labelWidth}> */}

                <VSFormItem
                  name={['schonordoneInfo']}
                  // label="雪诺酮信息"
                  valueType="text"
                  fieldProps={{
                    width: 450,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="transplant_record_text">
          如有腹痛，阴道流血，少尿随诊
        </div>
        <div className="transplant_record_text">
          胚胎移植后两周生殖门诊复查HCG
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.transplantRecord?.validateFieldsReturnFormatValue();

              const res =
                await vsf?.services?.TransplantRecordController_createTransplantRecord_7c879c?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(TransplantRecord);
