import { SyncOutlined } from '@vs/vsf-icons';
import {
  Button,
  compose,
  Empty,
  Input,
  Spin,
  Tree,
  TreeProps,
  withRef,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { DomKey } from './constants';
import ContextMenu, { ContextMenuConfigType } from './contextMenu';
import Icon from './icon';
import { portalize } from './portal';
import { buildTree, filterTreeData, highlight } from './utils';

type loadDataType = () => Promise<any>;

type FieldNames = {
  title?: string;
  /** @private Internal usage for `rc-tree-select`, safe to remove if no need */
  _title?: string[];
  key?: string;
  children?: string;
};

export type TreeMenuRef = {
  handleReload: () => void;
};

type TreeMenuProps = Omit<TreeProps, 'treeData' | 'fieldNames' | 'onSelect'> & {
  /**
   * 区域标题
   */
  title?: string | React.ReactNode;
  /**
   * TreeMenu 根节点 classname
   */
  className?: string;
  /**
   * 数据源
   */
  treeData?: any[];
  /**
   * 动态加载数据
   */
  loadData?: loadDataType | loadDataType[];
  /**
   * 是否需要重载
   */
  reload?: boolean;
  /**
   * 是否数据本身为tree (跳过build tree)
   */
  isTree?: boolean;
  /**
   * 自定义节点 title、key、children 的字段
   * @field [title, key, children]
   */
  fieldNames?: FieldNames;
  /**
   * tree 关系映射
   */
  dataRelationFieldNames?: {
    id: string;
    parentId: string;
  };
  /**
   * 选择回调
   */
  onSelect: (node: any) => void;
  /**
   * 行设置
   * @expand
   */
  row: {
    /**
     * 是否行选中时, 非叶子节点的项自动展开
     */
    expand?: boolean;
    /**
     * 右侧额外操作栏
     */
    extra?: (node: any) => React.ReactNode;
    /**
     * 右侧额外操作栏 显示方式
     * 默认为 false
     */
    extraShow?: 'default' | 'select' | 'hover';
    /**
     * 取消选中
     * 默认为 false
     */
    cancelSelect?: boolean;
  };
  /**
   * 搜索
   * @expand
   */
  search: {
    /**
     * 是否需要搜索过滤
     */
    status?: boolean;
    /**
     * 提示语 placeholder
     */
    searchPlaceholder?: string;
    /**
     * 搜索过滤类型, 静态过滤 | 请求接口
     */
    searchType?: 'static' | 'request';
    /**
     * 自定义搜索关键字
     */
    searchKeyWord?: string | string[];
    /**
     * 自定义搜索函数
     */
    searchCallback?: (node: any, keyword: string) => boolean;
  };
  /**
   * 右键菜单
   * @expand
   */
  context?: {
    /**
     * 单行右键菜单
     */
    rowContextMenu: ContextMenuConfigType;
    /**
     * 空白处右键菜单
     */
    defaultContextMenu: ContextMenuConfigType;
  };
  /**
   * tree 组件 props
   * @component Tree
   * @componentProp omit [treeData, fieldNames]
   */
  treeProps?: TreeProps;
} & {
  ref?: React.Ref<TreeMenuRef>;
};

/**
 * 树形菜单
 */
let TreeMenu: any = (props: TreeMenuProps, ref?: React.Ref<TreeMenuRef>) => {
  const {
    treeData,
    loadData,
    reload,
    fieldNames,
    dataRelationFieldNames,
    isTree = false,
    search,
    onSelect,
    context,
    treeProps,
    className,
    title,
    row,
    ...rest
  } = props;
  const { rowContextMenu, defaultContextMenu } = context || {};
  const {
    status,
    searchType = 'static',
    searchKeyWord,
    searchCallback,
    searchPlaceholder = '请输入',
  } = search || {};
  const {
    expand: rowExpand,
    extra,
    extraShow = 'default',
    cancelSelect = false,
  } = row || {};
  const { expandedKeys: treeExpandedKeys, onExpand: treeOnExpand } =
    treeProps || {};
  const portalRef = useRef();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const searchValue = useRef();

  const [adding, setAdding] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<any[]>();
  const [expandedKeys, setExpandedKeys] = useState<any[]>();
  const [selectedParentKeys, setSelectedParentKeys] = useState<number[]>();
  let extraProps = {};

  useImperativeHandle(ref, () => ({
    handleReload,
    buildData,
  }));

  const onRightClick = ({ event, node }) => {
    event.stopPropagation();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={rowContextMenu} record={node} />,
      key: DomKey,
    });
  };

  const onBgRightClick = (event) => {
    event.preventDefault();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={defaultContextMenu} />,
      key: DomKey,
    });
  };

  const handleSearch = (e) => {
    if (searchType === 'static') {
      const target = filterTreeData(dataSource, e?.target?.value, {
        fieldNames: fieldNames,
        keywords: searchKeyWord,
        callback: searchCallback,
      });
      searchValue.current = e?.target?.value;
      setInputValue(e?.target?.value);
      setData(target?.data);
      setExpandedKeys(target?.expandKeys);
    } else {
      // todo request
    }
  };

  const request = async (loadData) => {
    if (!loadData) return;
    if (Array.isArray(loadData)) {
      return await Promise.all(loadData?.map((item) => item?.()));
    }
  };

  const buildData = useCallback(
    async (treeData, loadData, isTree) => {
      let resData = treeData;
      if (loadData) {
        setLoading(true);
        let target: any;
        if (Array.isArray(loadData)) {
          target = await request(loadData);
          resData = target.flatMap((item: any) => item.data);
        } else {
          target = await loadData?.();
          resData = target?.data;
        }
      }
      return !isTree
        ? buildTree(resData ?? [], dataRelationFieldNames)
        : resData;
    },
    [dataRelationFieldNames],
  );

  const getData = useCallback(async () => {
    const targetData = await buildData(treeData, loadData, isTree);
    setDataSource(targetData);
    setData(targetData);
    setLoading(false);
    if (treeExpandedKeys) {
      setExpandedKeys(treeExpandedKeys);
    }
    return targetData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    if (treeExpandedKeys) {
      setExpandedKeys(treeExpandedKeys);
    }
  }, [treeExpandedKeys]);

  const handleExpand = (expandedKeys, other) => {
    setExpandedKeys(expandedKeys);
    treeOnExpand?.(expandedKeys, other);
  };

  const handleSelect = (
    selectedKeys,
    { selected, selectedNodes, node, event },
  ) => {
    if (!node?.leafFlag && rowExpand) {
      if (!expandedKeys?.includes(node?.key)) {
        setExpandedKeys([...new Set([...(expandedKeys ?? []), node?.key])]);
      } else {
        setExpandedKeys(
          [...(expandedKeys ?? [])].filter((v) => v !== node?.key),
        );
      }
    }
    if (selectedKeys?.[0] || cancelSelect) {
      setSelectedKeys(selectedKeys);
    }
    setAdding(false);
    onSelect?.({
      ...(node ?? {}),
      treeLevel: (node?.pos?.split('-')?.length || 0) - 2,
    });
  };

  const renderTitleSign = () => {
    return (
      <div className="title-sign">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const getKey = useCallback(
    (node) => {
      return node?.[fieldNames?.key ?? 'key'];
    },
    [fieldNames],
  );

  const findSelectedNodeParentKeys = useCallback(
    (tree, targetLeafKey) => {
      const parentKeys: any[] = [];

      function traverse(node, path) {
        if (getKey(node) === targetLeafKey) {
          parentKeys.push(...path);
          return;
        }

        if (node.children) {
          for (const child of node.children) {
            traverse(child, [...path, getKey(node)]);
          }
        }
      }

      for (const node of tree) {
        traverse(node, []);
      }

      return parentKeys;
    },
    [getKey],
  );

  const handleReload = () => {
    getData();
    searchValue.current = undefined;
    setInputValue('');
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (selectedKeys && data) {
      const res = findSelectedNodeParentKeys(data, selectedKeys?.[0]);
      setSelectedParentKeys(res);
    }
  }, [selectedKeys, data, findSelectedNodeParentKeys]);

  if (rowContextMenu) {
    extraProps = {
      onRightClick: onRightClick,
    };
  }

  return (
    // @ts-ignore
    <div
      {...rest}
      {...(defaultContextMenu ? { onContextMenu: onBgRightClick } : {})}
      className={`vsf-treemenu-container ${className ?? ''}`}
    >
      <div className="vsf-treemenu-main">
        {title && (
          <div className="vsf-treemenu-header">
            <div className="title">
              {renderTitleSign()}
              <div className="word">{title}</div>
            </div>
            {reload && (
              <div className="extra">
                <Button
                  icon={<SyncOutlined spin={loading} />}
                  onClick={handleReload}
                >
                  刷新
                </Button>
              </div>
            )}
          </div>
        )}
        {status && (
          <Input
            prefix={
              <Icon
                type="icon-a-Searchsousuo"
                size={18}
                style={{
                  color: '#85898D',
                }}
              />
            }
            placeholder={searchPlaceholder}
            value={inputValue}
            className="vsf-treemenu-search"
            onChange={handleSearch}
          />
        )}
        <div
          className={classNames('vsf-treemenu-content', {
            'vsf-treemenu-content-center':
              loading || !data || data.length === 0,
          })}
        >
          {loading ? (
            <Spin></Spin>
          ) : (
            <>
              {data && data?.length > 0 ? (
                <Tree
                  {...treeProps}
                  {...extraProps}
                  rootClassName="vsf-treemenu-tree"
                  treeData={data}
                  fieldNames={fieldNames}
                  onExpand={handleExpand}
                  expandedKeys={expandedKeys}
                  selectedKeys={selectedKeys}
                  onSelect={handleSelect}
                  blockNode={true}
                  switcherIcon={(data) => {
                    return (
                      <Icon
                        type={data?.expanded ? 'icon-shang' : 'icon-xia'}
                        size={22}
                        style={{
                          color:
                            data?.expanded || data?.selected
                              ? '#3276E8'
                              : '#343B42',
                        }}
                      />
                    );
                  }}
                  titleRender={(node) => {
                    return (
                      <div
                        className={classNames('aspirin-treemenu-title-render', {
                          'aspirin-treemenu-title-render-active':
                            selectedParentKeys?.includes(getKey(node)),
                        })}
                      >
                        <div className="label">
                          {highlight(
                            node,
                            searchValue.current,
                            searchKeyWord,
                            fieldNames,
                          )}
                        </div>
                        {extra && (
                          <div className={`extra extra-${extraShow}`}>
                            {extra(node)}
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              ) : (
                <Empty description="暂无数据" />
              )}
            </>
          )}
        </div>
      </div>
      {/* {!!onAdd && (
        <div className="vsf-treemenu-footer">
          <div
            className={classNames('add-btn', {
              adding: adding,
            })}
            onClick={handleAdd}
          >
            <Icon type="icon-tianjia" size={30} />
          </div>
        </div>
      )} */}
    </div>
  );
};

TreeMenu.displayName = 'TreeMenu';

TreeMenu = React.forwardRef(TreeMenu);

export default compose(withRef())(TreeMenu);
