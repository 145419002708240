import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'

import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import './index.less'

const AbortionMethodsMenu = [
  {
    label: '药流',
    value: '药流'
  },
  {
    label: '人流',
    value: '人流'
  },
  {
    label: '药流+清宫',
    value: '药流+清宫'
  },
  {
    label: '引产',
    value: '引产'
  },
  {
    label: '自然流产',
    value: '自然流产'
  },
  {
    label: '生化妊娠',
    value: '生化妊娠'
  },
  {
    label: '难免流产清宫',
    value: '难免流产清宫'
  }
]

const AbortionRecords = props => {

  const { open, abortionRecordsTableValue = [] } = props


  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">流产记录</div>
      </div>
    )
  }

  const handelSubmit = async () => {
    props?.onOk(
      (await vsf.refs?.AbortionRecordsTable?.getValues())?.map(item => ({
        ...item,
        gestationalWeek: item?.gestationalWeek?.filter(v => v)
      }))
    )
  }

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="abortion_records_wapper"
      width={700}
      bodyStyle={{
        height: 350,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.AbortionRecordsTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.AbortionRecordsTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="AbortionRecordsTable"
        scroll={{
          y: 220
        }}
        defaultValue={abortionRecordsTableValue}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={240}
          title='妊娠日期'
          dataIndex={['pregnancyDate']}
          valueType='dateYear'
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择妊娠日期'
              }
            ]
          }}
        />
        <VSTableColumn
          width={240}
          title='孕周'
          dataIndex={['gestationalWeek']}
          valueType='digitRange'
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请输入孕周'
              }
            ]
          }}
        />
        <VSTableColumn
          width={160}
          title='流产方式'
          dataIndex={['allergyReaction']}
          valueType='select'
          dataSource={AbortionMethodsMenu}
          fieldProps={{
            showSearch: true
          }}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择流产方式'
              }
            ]
          }}
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(AbortionRecords)