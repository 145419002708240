import React from 'react';

import Block from '../Block';

const Index = (props) => {
  const { children, title } = props;
  return (
    <Block
      title={title}
      auto
      // mainStyle={{
      //   padding: '0 12px',
      // }}
      style={{
        padding: '0 20px',
      }}
    >
      {children}
    </Block>
  );
};

export default Index;
