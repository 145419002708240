import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Todo, VSTableColumn, VSPromiseTable } from '@vs/vsf-kit';
import React from 'react';

import './index.less';

const OrderItemList = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};

  return (
    <div className="demo-container">
      <VSPromiseTable
        className="aspirin-table"
        editable={{ editType: 'single' }}
        vsid="45305"
        scroll={{
          x: 1200,
          y: 600,
        }}
        onFetch={async (params) => {
          // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
          // Tips: 以下是你选择的请求函数
          const res =
            await vsf?.services?.OrderItemController_allOrderItem_f38b92?.({
              // TODO: 请检查以下这部分传参是否符合需求
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList,
                ...params.search,
                ...params.filters,
                ...(params?.extra ?? {}),
                ...(params ?? {}),
                patientIdIs: currentPatient?.patient?.id,
              },
            }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
      >
        <VSTableColumn
          dataIndex={['orderType']}
          title="医嘱类型"
          valueType="select"
          search
          order={-35}
          columnKey={'orderTypeIs'}
          dataSource={[
            { label: '药疗', value: 'MEDICATION' },
            { label: '处置', value: 'HANDLE' },
            { label: '中草药', value: 'HERBS' },
            { label: '检查', value: 'EXAMINE' },
            { label: '化验', value: 'ASSAY' },
            { label: '手术', value: 'OPERATION' },
            { label: '病理', value: 'PATHOLOGY' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['orderName']}
          title="医嘱名称"
          valueType="text"
          search
          order={-45}
          columnKey={'orderNameLike'}
          formItemProps={{
            rules: [
              {
                message: '医嘱名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['doctor', 'name']}
          title="医生姓名"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '医生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
        <VSTableColumn
          dataIndex={['orderItemStatus']}
          title="医嘱条目状态"
          valueType="select"
          dataSource={[
            { label: '开立', value: 'OPEN' },
            { label: '发送', value: 'SEND' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['orderTime']}
          title="开单时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
        <VSTableColumn
          dataIndex={['orderApplyDto', 'applyStatus']}
          title="申请单状态"
          valueType="select"
          dataSource={[
            { label: '开立', value: 'OPEN' },
            { label: '确认报告', value: 'CONFIRM_REPORT' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['orderApplyDto', 'reportDoctor', 'name']}
          title="医生姓名"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '医生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['orderApplyDto', 'reportTime']}
          title="报告时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSPromiseTable>
    </div>
  );
};
export default definePage(OrderItemList);
