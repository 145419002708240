import { writeElementList } from '../../../utils/clipboard';
import { isImageElement } from '../../../utils/element';
import { CanvasEvent } from '../CanvasEvent';

export function copy(host: CanvasEvent) {
  const draw = host.getDraw();
  const rangeManager = draw.getRange();
  const { startIndex, endIndex } = rangeManager.getRange();
  const elementList = draw.getElementList();
  const options = draw.getOptions();
  if (startIndex !== endIndex) {
    writeElementList(elementList.slice(startIndex + 1, endIndex + 1), options);
  } else {
    const element = elementList[startIndex];
    if (isImageElement(element)) {
      writeElementList([element], options);
    }
  }
}
