import './index.less';

import {
  compose,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import CSS from 'csstype';
import React from 'react';

type scrollType = {
  x?: boolean;
  y?: boolean;
};

export type BlockPropsType = {
  auto?: boolean;
  title?: string | React.ReactNode;
  extra?: string | React.ReactNode;
  desc?: string | React.ReactNode;
  footer?: React.ReactNode;
  scroll?: boolean | scrollType;
  height?: string;
  width?: string;
  className?: string;
  children?: React.ReactNode;
  style?: CSS.Properties;
  mainStyle?: CSS.Properties;
  titleStyle?: CSS.Properties;
  titleBackgroundColor?: string;
};

/**
 * 页面容器
 */
const Block = (props: BlockPropsType) => {
  const {
    auto,
    width = '100%',
    height = '100%',
    title,
    extra,
    desc,
    footer,
    style = {},
    scroll,
    className,
    children,
    mainStyle = {},
    titleStyle = {},
    titleBackgroundColor = '',
    ...rest
  } = props;

  const BlockStyle = {
    width,
    height,
    ...(auto ? { height: 'auto' } : {}),
    ...style,
  };

  const BlockMainStyle: any = {
    overflowX:
      typeof scroll === 'boolean' ? 'auto' : scroll?.x ? 'auto' : 'hidden',
    overflowY:
      typeof scroll === 'boolean' ? 'auto' : scroll?.y ? 'auto' : 'hidden',
  };

  /** 编写组件逻辑 */
  return (
    <>
      {/* 这里必须把rest传递给根节点，不要删除 */}
      <div
        {...rest}
        style={BlockStyle}
        className={`aspirin-block ${className ?? ''} ${
          !children ? 'aspirin-block-nomargin' : ''
        }`}
      >
        {title || extra ? (
          <div
            style={{ backgroundColor: titleBackgroundColor }}
            className="header"
          >
            <div className="left">
              {title && (
                <>
                  <div
                    style={{ ...titleStyle }}
                    className="aspirin-block-title"
                  >
                    {title}
                  </div>
                  {desc && <span className="aspirin-block-desc">{desc}</span>}
                </>
              )}
            </div>
            {extra}
          </div>
        ) : null}
        <div
          className="aspirin-block-main"
          style={{
            ...mainStyle,
            ...BlockMainStyle,
          }}
        >
          {children}
        </div>
        {footer && <div className="aspirin-block-footer">{footer}</div>}
      </div>
    </>
  );
};
Block.displayName = 'Block';

export default compose(
  withField<BlockPropsType>({
    name: 'Block',
  }),
  withPreview<BlockPropsType>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      return <></>;
    },
  }),
)(Block);
