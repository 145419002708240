import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'
import { useEffect, useState } from "react";
import { debounce } from "lodash";

import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import Icon from '@/module/Icon'

import './index.less'


const AllergyHistory = props => {

  const { open, allergyHistoryTableValue = [] } = props

  const [selectList, setSelectList] = useState<any>([])

  useEffect(() => {
    handelSearch()
  }, [])

  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">选择过敏原</div>
      </div>
    )
  }

  const handelSearch = debounce(async (v?) => {
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.AllergyDictionaryController_getAllByAllergyDictionaryQto_6bf106?.({
      // TODO: 请检查以下这部分传参是否符合需求
      qto: {
        allergyNameLike: v ?? undefined
      }
    });
    const data = res?.data?.map(item => ({
      label: item?.allergyName,
      value: item?.id,
      allergyCode: item?.allergyCode,
      allergyName: item?.allergyName
    })) ?? [];
    setSelectList(data)
    return data
  }, 500)

  const handelSubmit = async () => {
    console.log(await vsf.refs?.AllergyHistoryTable?.getValues())
    props?.onOk(await vsf.refs?.AllergyHistoryTable?.getValues())
  }

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="allergy_history_wapper"
      width={1012}
      bodyStyle={{
        height: 559,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.AllergyHistoryTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.AllergyHistoryTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="AllergyHistoryTable"
        defaultValue={allergyHistoryTableValue}
        editable={{
          editType: 'single',
          columnProps: {
            width: 80,
            hideInTable: true
          },
          onCanEdit: () => false,
          deleteText: <Icon type="icon-shanchuwudi" size={18} />,
          onFieldChange: (key, value: any, record, form) => {
            if (value?.allergyName) {
              form.setFieldValue(key, {
                ...record,
                allergyName: selectList?.find(item => item.value === value?.allergyName)?.label,
                allergyId: value?.allergyName,
                allergyCode: selectList?.find(item => item.value === value?.allergyName)?.allergyCode
              })
            }
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={240}
          title='过敏原'
          dataIndex={['allergyName']}
          valueType='select'
          dataSource={selectList}
          fieldProps={{
            showSearch: true,
            onSearch: handelSearch
          }}
        />
        <VSTableColumn
          hideInTable
          dataIndex={['allergyId']}
          valueType='text'
        />
        <VSTableColumn
          width={644}
          title='过敏反应'
          dataIndex={['allergyReaction']}
          valueType='text'
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(AllergyHistory)