import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

const mock = [
  {
    label: '门诊',
    value: 'CLINIC',
  },
  {
    label: '住院',
    value: 'HOSPITALIZED',
  },
  {
    label: '急诊',
    value: 'EMERGENCY',
  },
  {
    label: '体检',
    value: 'PHYSICAL_CHECK',
  },
  {
    label: '本科室',
    value: 5,
  },
];

const Index = (props) => {
  const { style = {}, handelConditionQuery } = props;
  const target = mock;

  const [keys, setKeys] = useState<any[]>([]);
  const [departmentNameIs, setDepartmentNameIs] = useState<string>();

  useEffect(() => {
    setDepartmentNameIs('111');
  }, []);

  const handleSelect = (item: any) => {
    const isSelect = keys?.includes(item?.value);

    if (isSelect) {
      setKeys(cloneDeep(keys)?.filter((v) => v !== item?.value));
      handelConditionQuery({
        keys: cloneDeep(keys)
          ?.filter((v) => v !== item?.value)
          .filter((item) => item !== 5),
        departmentNameIs: cloneDeep(keys)
          ?.filter((v) => v !== item?.value)
          .some((item) => item === 5)
          ? departmentNameIs
          : undefined,
      });
    } else {
      setKeys([...keys, item?.value]);
      handelConditionQuery({
        keys: [...keys, item?.value].filter((item) => item !== 5),
        departmentNameIs: [...keys, item?.value].some((item) => item === 5)
          ? departmentNameIs
          : undefined,
      });
    }
  };

  return (
    <div className="cpoe-check-block" style={style}>
      {target?.map((item, index) => {
        return (
          <div
            className={classnames('item', {
              'check-item': keys?.includes(item?.value),
            })}
            key={index}
            onClick={() => {
              handleSelect(item);
            }}
          >
            {item?.label}
          </div>
        );
      })}
    </div>
  );
};

export default Index;
