import { ControlComponent } from '../../../../dataset/enum/Control';
import { KeyMap } from '../../../../dataset/enum/KeyMap';
import { IControlInstance } from '../../../../interface/Control';
import { IElement } from '../../../../interface/Element';
import { Control } from '../Control';

export class TextControl implements IControlInstance {
  private element: IElement;
  private control: Control;

  constructor(element: IElement, control: Control) {
    this.element = element;
    this.control = control;
  }

  public markRequired(startElement: IElement) {
    const elementList = this.control.getElementList(startElement);
    const startIndex = elementList.indexOf(startElement);
    if (!startElement.control?.required) {
      return;
    }
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (preElement.controlId !== startElement.controlId) {
        break;
      } else if (preElement.controlComponent === ControlComponent.PLACEHOLDER) {
        preElement.markRequired = true;
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (nextElement.controlId !== startElement.controlId) {
        break;
      } else if (
        nextElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        nextElement.markRequired = true;
      }
      nextIndex++;
    }
  }

  public getElement(): IElement {
    return this.element;
  }

  public getValue(): IElement[] {
    const elementList = this.control.getElementList();
    const { startIndex } = this.control.getRange();
    const startElement = elementList[startIndex];
    if (!startElement) {
      return [];
    }
    const data: IElement[] = [];
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (
        preElement.controlId !== startElement.controlId ||
        preElement.controlComponent === ControlComponent.PREFIX
      ) {
        break;
      }
      if (preElement.controlComponent === ControlComponent.VALUE) {
        data.unshift(preElement);
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (
        nextElement.controlId !== startElement.controlId ||
        nextElement.controlComponent === ControlComponent.POSTFIX
      ) {
        break;
      }
      if (nextElement.controlComponent === ControlComponent.VALUE) {
        data.push(nextElement);
      }
      nextIndex++;
    }
    return data;
  }

  public setValue(data: IElement[]): number {
    const elementList = this.control.getElementList();
    const range = this.control.getRange();
    // 收缩边界到Value内
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = range;
    // 移除选区元素
    if (startIndex !== endIndex) {
      elementList.splice(startIndex + 1, endIndex - startIndex);
    } else {
      // 移除空白占位符
      this.control.removePlaceholder(startIndex);
    }
    // 插入
    const startElement = elementList[startIndex];
    const start = range.startIndex + 1;
    for (let i = 0; i < data.length; i++) {
      elementList.splice(start + i, 0, {
        ...startElement,
        ...data[i],
        controlComponent: ControlComponent.VALUE,
      });
    }
    return start + data.length - 1;
  }

  public keydown(evt: KeyboardEvent): number {
    const elementList = this.control.getElementList();
    const range = this.control.getRange();
    // 收缩边界到Value内
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = range;
    const startElement = elementList[startIndex];
    const endElement = elementList[endIndex];
    // backspace
    if (evt.key === KeyMap.Backspace) {
      // 移除选区元素
      if (startIndex !== endIndex) {
        elementList.splice(startIndex + 1, endIndex - startIndex);
        const value = this.getValue();
        if (!value.length) {
          this.control.addPlaceholder(startIndex);
        }
        return startIndex;
      } else {
        if (
          startElement.controlComponent === ControlComponent.PREFIX ||
          endElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 文本
          elementList.splice(startIndex, 1);
          const value = this.getValue();
          if (!value.length) {
            this.control.addPlaceholder(startIndex - 1);
          }
          return startIndex - 1;
        }
      }
    } else if (evt.key === KeyMap.Delete) {
      // 移除选区元素
      if (startIndex !== endIndex) {
        elementList.splice(startIndex + 1, endIndex - startIndex);
        const value = this.getValue();
        if (!value.length) {
          this.control.addPlaceholder(startIndex);
        }
        return startIndex;
      } else {
        const endNextElement = elementList[endIndex + 1];
        if (
          (startElement.controlComponent === ControlComponent.PREFIX &&
            endNextElement.controlComponent === ControlComponent.PLACEHOLDER) ||
          endNextElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 文本
          elementList.splice(startIndex + 1, 1);
          const value = this.getValue();
          if (!value.length) {
            this.control.addPlaceholder(startIndex);
          }
          return startIndex;
        }
      }
    }
    return endIndex;
  }

  public cut(): number {
    const elementList = this.control.getElementList();
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = this.control.getRange();
    if (startIndex === endIndex) {
      return startIndex;
    }
    elementList.splice(startIndex + 1, endIndex - startIndex);
    const value = this.getValue();
    if (!value.length) {
      this.control.addPlaceholder(startIndex);
    }
    return startIndex;
  }
}
