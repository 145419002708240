import './index.less';
import '../index.less';

import { definePage } from '@vs/vsf-boot';
import { Popover } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, { useState } from 'react';

import DemoPath from '@/assets/demo-path-2.png';
import LuJing from '@/assets/imgs/lujing.png';
import ZhouQi from '@/assets/imgs/zhouqi.png';
import Icon from '@/module/Icon';
import Visible from '@/module/Visible';
import DiagnoseInfo from '@/pages/center/left/reproduction/patient_information/diagnose_info';
import FemaleRecordOne from '@/pages/center/left/reproduction/patient_information/female_record_one';
import FemaleRecordTwo from '@/pages/center/left/reproduction/patient_information/female_record_two';
import MaleRecordOne from '@/pages/center/left/reproduction/patient_information/male_record_one';
import MaleRecordTwo from '@/pages/center/left/reproduction/patient_information/male_record_two';
import MargrationRecord from '@/pages/center/left/reproduction/patient_information/migration_record';
import PatientInfo from '@/pages/center/left/reproduction/patient_information/patient_info';

import Patient from './patient';
import Diagnosis from './toggle/Diagnosis';
import Info from './toggle/Info';

const Index = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};
  const [revise, setRevise, getRevise] = useGetState<boolean>(false);

  const [current, setCurrent] = useState('患者信息');

  const onSubmitMedical = () => {};

  const handleRevise = () => {};

  const mockOper = [
    {
      label: '保存',
      icon: 'icon-baocun',
      callback: onSubmitMedical,
    },
    {
      label: '取消修订',
      icon: 'icon-quxiaoxiuding',
      callback: handleRevise,
      render: (v) => {
        return v ? '取消修订' : '修订';
      },
    },
    {
      label: '签名',
      icon: 'icon-Frame3',
    },
    {
      label: '打印',
      icon: 'icon-Frame-16',
    },
    {
      label: '历史病历',
      icon: 'icon-Frame-22',
    },
  ];

  const onChange = (v) => {
    setCurrent(v);
  };

  return (
    <div className="cpoe-reproduction">
      <div className="header">
        <div className="medical-extra">
          <div>
            {mockOper?.map((item, index) => {
              return (
                <div
                  className="item"
                  key={index}
                  onClick={() => {
                    item?.callback?.();
                  }}
                >
                  <Icon type={item?.icon ?? ''} />
                  <span className="label">
                    {item?.render ? item?.render(revise) : item?.label}
                  </span>
                </div>
              );
            })}
          </div>
          <div>
            <Popover
              placement="right"
              title="已入【注射用重组人促卵泡激素】临床试验"
              content={<img style={{ width: '1080px' }} src={DemoPath} />}
              trigger={['click']}
            >
              <div className="item active-item">
                <Icon type="icon-Frame-19" />
                <span className="label">
                  已入【注射用重组人促卵泡激素】临床试验
                </span>
              </div>
            </Popover>
          </div>
        </div>
        <div
          className="toggle"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex' }}>
            <Diagnosis value={current} onChange={onChange} />
            <Info value={current} onChange={onChange} />
          </div>
          <div className="toggle_right">
            <div className="toggle_right_item">
              <Icon type="icon-Frame-23" />
              2023-08-01
            </div>
            <div className="toggle_right_item">
              {/* <Popover
                placement="topLeft"
                title="IVF周期"
                content={
                  <img
                    style={{ width: '442px', height: '1000px' }}
                    src={ZhouQi}
                  />
                }
              > */}
              IVF周期
              {/* </Popover> */}
            </div>
            <div className="toggle_right_item">
              <Popover
                placement="bottom"
                title="路径"
                content={<img style={{ width: '440px' }} src={ZhouQi} />}
                trigger={['click']}
              >
                <Icon type="icon-zhuyuanlinchuanglujing" />
                路径
              </Popover>
            </div>
          </div>
        </div>
      </div>
      {currentPatient?.id && (
        <div className="main">
          {current === '患者信息' && <PatientInfo />}
          {current === '女方病历一' && <FemaleRecordOne />}
          {current === '女方病历二' && <FemaleRecordTwo />}
          {current === '男方病历一' && <MaleRecordOne />}
          {current === '男方病历二' && <MaleRecordTwo />}
          {current === '诊断情况' && <DiagnoseInfo />}
          {current === '移植记录' && <MargrationRecord />}
        </div>
      )}
    </div>
  );
};

export default definePage(Index);
