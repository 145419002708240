import './index.less';

import CSS from 'csstype';
import React, { useMemo } from 'react';

type VpVisibieType = {
  value?: boolean;
  children?: React.ReactNode;
  style?: CSS.Properties;
  className?: string;
  destory?: boolean;
  center?: boolean;
};

const Index = (props: VpVisibieType) => {
  const {
    center,
    children,
    value,
    style,
    className = '',
    destory = false,
  } = props;

  const classNameList: string[] = useMemo(() => {
    const arr: string[] = [];
    if (center) {
      arr.push('vpVisibleCenter');
    }

    if (!value) {
      arr.push('vpVisibleHidden');
    }

    if (className) {
      arr.push(className);
    }
    return arr;
  }, [center, className, value]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        ...style,
      }}
      className={classNameList.join(' ')}
    >
      {value ? children : destory ? null : children}
    </div>
  );
};

export default Index;
