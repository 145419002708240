import './index.less';

import React from 'react';

import Timeline from './timeline';

const Index = (props) => {
  const { prefixCls } = props;
  return (
    <div className={`${prefixCls}-rightContainer`}>
      <Timeline />
    </div>
  );
};

export default Index;
