import vsf, { createService, defineStore } from '@vs/vsf-boot';
import { isEmpty } from 'lodash';

const service = createService({
  url: '/api/db_enum/index',
});
const findChildren = (list = [], parent) => {
  return list
    .filter((item: any) => {
      if (isEmpty(item.parentCode) && isEmpty(parent.code)) return false;
      return item.parentCode === parent.code;
    })
    .map((item: any) => ({
      label: item.cname,
      value: item.code,
      children: findChildren(list, item),
      data: item,
    }));
};

export const loadEnums = async () => {
  if (Object.keys(vsf.stores.dbenums.enums).length !== 0) return;

  const enumNames = vsf.stores.dbenums.enumNames;
  if (enumNames.length <= 0) return;

  const res = await service();
  if (!res?.data) return;

  const fileMap = res.data as any[];
  const fileList = enumNames.map((e) => {
    const emap = fileMap.find((f) => f.enumName === e);
    return emap?.jsonFileUrl ?? '';
  });
  const fileSet = new Set<string>(fileList); // 去重
  const loadFilePromises = Array.from(fileSet)
    .filter((f) => !!f)
    .map((url) => {
      const getFileService = createService({ url: url, raw: true });
      return getFileService();
    });
  const files = await Promise.all(loadFilePromises);
  const data = {};
  files.forEach((f) => {
    Object.keys(f).forEach((fkey) => {
      data[fkey] = f[fkey]
        .filter((d) => !d.dbEnumParent) // 一级
        .map((d) => ({
          label: d.cname,
          value: d.code,
          children: findChildren(f[fkey], d),
          data: d,
        }));
    });
  });
  vsf.stores.dbenums.initEnums(data);
};

const dbenums = defineStore(
  {
    // state
    enums: {},
    enumNames: [
      'skin_mucosa_abnormal',
      'kidney_abnormal',
      'heart_rhythm',
      'nervous_system',
      'vaginal_wall_tension',
      'kidney',
      'back_chest_abnormal',
      'thorax',
      'NATION',
      'cervical_others',
      'COUNTRY',
      'right_uterine_appendage_others',
      'SEX_OTHER',
      'lungs',
      'five_senses',
      'vagina',
      'OCCUPATION_ENUM',
      'vulva_others',
      'liver',
      'spleen',
      'spine_limbs_abnormal',
      'heart',
      'uterine_size_abnormal',
      'lymph_node_abnormal',
      'hair_distribution',
      'five_senses_abnormal',
      'spirit_abnormal',
      'back_chest',
      'secretion',
      'thyroid',
      'left_uterine_appendage',
      'skin_mucosa',
      'uterine_position',
      'NUTRITION',
      'heart_abnormal',
      'lungs_abnormal',
      'EJACULATION',
      'lymph_node',
      'vagina_others',
      'uterine_neck',
      'spine_limbs',
      'acanthosis_nigricans',
      'CARD_TYPE',
      'EDUCATION',
      'INFERTILITY_TYPE',
      'uterine_surface',
      'vulva',
      'uterine_texture',
      'head_abnormal',
      'neck_abnormal',
      'female_illness_history_others',
      'head',
      'FREQUENCY_SEX',
      'hair',
      'spleen_abnormal',
      'noise',
      'hair_distribution_abnormal',
      'SPIRIT',
      'uterine_size',
      'uterine_others',
      'breast_abnormal',
      'uterine_deviation',
      'liver_abnormal',
      'pubic_hair_distribution',
      'left_uterine_appendage_others',
      'thyroid_abnormal',
      'right_uterine_appendage',
      'hair_abnormal',
      'neck',
      'reproductive_system',
      'leucorrhea',
      'uterine_mobility',
      'NUTRITION_ABNORMAL',
      'leucorrhea_smell',
      'CITY',
      'female_physical_others',
      'form',
      'DEVELOP_ABNORMAL',
      'leucorrhea_color',
      'PROVINCE',
      'breast',
      'mucosa',
      'DEVELOP',
    ],

    // action
    initEnums: (data) => (dbenums.enums = data),
  },
  'dbenums',
);

export default dbenums;
