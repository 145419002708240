import './index.less';

import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React from 'react';

import Icon from '@/module/Icon';

const dataDay = Array.from({ length: 4 }).fill({});
const dataWeek = Array.from({ length: 8 }).fill({});

const date = [
  {
    week: '周日',
    day: '04-22',
  },
  {
    week: '周一',
    day: '04-23',
  },
  {
    week: '周二',
    day: '04-24',
  },
  {
    week: '周三',
    day: '04-25',
  },
  {
    week: '周四',
    day: '04-26',
  },
  {
    week: '周五',
    day: '04-27',
  },
  {
    week: '周六',
    day: '04-28',
  },
];

const items = [
  {
    label: '值班',
    time: '6h',
    borderColor: '#3276E8',
    bgColor: '#D8E6FF',
  },
  {
    label: '门诊',
    time: '2h',
    borderColor: '#50BC35',
    bgColor: '#DAEFD5',
  },
  {
    label: '住院',
    time: '1h',
    borderColor: '#FFB93E',
    bgColor: '#FFE3B2',
  },
  {
    label: '手术',
    time: '4h',
    borderColor: '#FF7E7E',
    bgColor: '#FFD2D2',
  },
  {
    label: '会诊',
    time: '1h',
    borderColor: '#26BBEA',
    bgColor: '#BEEFFF',
  },

  {
    label: '其他',
    time: '1h',
    borderColor: '#5D6268',
    bgColor: '#D6D8D9',
  },
];

const Index = () => {
  const renderTh = (j) => {
    if (j > 0) {
      const target = date?.[j - 1];
      return (
        <div className="word">
          <div>{target?.day}</div>
          <div>{target?.week}</div>
        </div>
      );
    }
    return '';
  };

  const renderTd = (i, j) => {
    const target = cloneDeep(items);
    // 生成随机数
    const randomCount = Math.floor(Math.random() * 2) + 1;

    // 随机选择对象
    const randomObjects: any[] = [];
    for (let i = 0; i < randomCount; i++) {
      const randomIndex = Math.floor(Math.random() * target.length);
      randomObjects.push(target?.[randomIndex]);
      target.splice(randomIndex, 1); // 从数组中移除已选择的对象
    }

    if (j === 0) {
      return i === 1 ? '上午' : i === 2 ? '下午' : '晚上';
    } else {
      return (
        <div className="info">
          {randomObjects?.map((item, index) => (
            <div
              className="item"
              key={index}
              style={{
                borderColor: item?.borderColor,
                backgroundColor: item?.bgColor,
              }}
            >
              <span className="label">{item?.label}</span>
              <span className="time">{item?.time}</span>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="cpoe-schedule">
      <div className="header">
        <div className="date">
          <div className="left">
            <Icon type="icon-zuo" />
          </div>
          <div className="main">
            <span className="begin dinpro-medium">2023-04-22</span>
            <span className="sign"></span>
            <span className="end dinpro-medium">2023-04-28</span>
          </div>
          <div className="right">
            <Icon type="icon-you" />
          </div>
          <div className="extra">周</div>
        </div>
        <div className="legend">
          {items?.map((item, index) => (
            <div key={index} className="item">
              <div
                className="sign"
                style={{
                  borderColor: item?.borderColor,
                  backgroundColor: item?.bgColor,
                }}
              ></div>
              <div className="label">{item?.label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="main">
        {dataDay?.map((item, i) => {
          return (
            <div key={i} className="row">
              {dataWeek?.map((item, j) => {
                if (i === 0) {
                  return (
                    <div
                      key={`${i}-${j}`}
                      className={classnames('th', {
                        index: j === 0,
                        'no-right': j === dataWeek?.length - 1,
                      })}
                    >
                      {renderTh(j)}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={`${i}-${j}`}
                      className={classnames('td', {
                        index: j === 0,
                        'no-right': j === dataWeek?.length - 1,
                        'no-bottom': i === dataDay?.length - 1,
                      })}
                    >
                      {renderTd(i, j)}
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
