import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'

import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import './index.less'

const SemenExaminationMenu = [
  {
    label: '正常',
    value: '正常'
  },
  {
    label: '异常',
    value: '异常'
  },
  {
    label: '无',
    value: '无'
  }
]

const AuxiliaryExamination = props => {

  const { open, auxiliaryExaminationTableValue = [] } = props


  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">辅助检查（男性）记录</div>
      </div>
    )
  }

  const handelSubmit = async () => {
    props?.onOk(
      (await vsf.refs?.AuxiliaryExaminationTable?.getValues())
    )
  }

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="auxiliary_examination_wapper"
      width={700}
      bodyStyle={{
        height: 350,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.AuxiliaryExaminationTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.AuxiliaryExaminationTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="AuxiliaryExaminationTable"
        scroll={{
          y: 220
        }}
        defaultValue={auxiliaryExaminationTableValue}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={280}
          title='精液检查'
          dataIndex={['semenExamination']}
          valueType='select'
          dataSource={SemenExaminationMenu}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择精液检查'
              }
            ]
          }}
        />
        <VSTableColumn
          width={280}
          title='其他'
          dataIndex={['other']}
          valueType='text'
          formItemProps={{
            rules: [
              {
                required: true,
                message: '其他'
              }
            ]
          }}
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(AuxiliaryExamination)