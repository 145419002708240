import '../index.less';

import vsf from '@vs/vsf-boot';
import { Popover, Tag } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';

import Icon from '@/module/Icon';

import Detail from './detail';

enum OrderTypeEnum {
  // icon-Frame-9
  MEDICATION = 'MEDICATION',
  // icon-Frame-6
  HANDLE = 'HANDLE',
  HERBS = 'HERBS',
  // icon-Frame-3
  EXAMINE = 'EXAMINE',
  // icon-huayan
  ASSAY = 'ASSAY',
  OPERATION = 'OPERATION',
  PATHOLOGY = 'PATHOLOGY',
}

enum ApplyStatusEnum {
  OPEN = 'OPEN',
  CONFIRM_REPORT = 'CONFIRM_REPORT',
}

const mock = {
  startTime: '2023-08-22 8:22',
  endTime: '2023-08-22 15:22',
  type: '1',
  name: '0.9%氯化钠注射液 (100ml:0.9g (0.9%/1袋)注射用呱拉西林钠 (3g/3瓶)',
  abnormal: 'D-二聚体6769 ng/ml',
  operator: '李华',
  status: '1',
};

const Index = (props) => {
  const { data, currentType } = props;
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState();
  // const data = mock;
  const handleOpenChange = (newOpen: boolean) => {
    if (
      data?.orderType === OrderTypeEnum.ASSAY ||
      data?.orderType === OrderTypeEnum.EXAMINE
    ) {
      setOpen(newOpen);
      if (newOpen) {
        getDetail();
      }
    }
  };
  const getDetail = async () => {
    const res =
      await vsf?.services?.OrderApplyController_getOrderApplyDetailById_9708b7?.(
        { id: data?.orderApplyDto?.id },
      );
    if (res.code === 200) {
      setDetail(res?.data);
    }
  };

  const handelType = (type) => {
    if (type === 'MEDICATION') {
      return {
        icon: 'icon-Frame-9',
        size: 38,
      };
    }
    if (type === 'HANDLE') {
      return {
        icon: 'icon-Frame-6',
        size: 34,
      };
    }
    if (type === 'EXAMINE') {
      return {
        icon: 'icon-Frame-3',
        size: 32,
        abnormalInfo: data?.orderApplyDto?.abnormal
          ? data?.orderApplyDto?.abnormalInfo
          : '',
      };
    }
    if (type === 'ASSAY') {
      return {
        icon: 'icon-huayan',
        size: 24,
        abnormalInfo: data?.orderApplyDto?.abnormal
          ? data?.orderApplyDto?.abnormalInfo
          : '',
      };
    }
    if (type === 'OPERATION') {
      return {
        icon: 'icon-Frame-5',
        size: 24,
      };
    }
  };

  const renderTime = (type) => {
    if (type === OrderTypeEnum.ASSAY || type === OrderTypeEnum.EXAMINE) {
      return (
        <div className="time dinpro-bold">
          <span>{dayjs(data?.createdAt).format('YYYY-MM-DD HH:mm')}</span>
          <span className="line"></span>
          <span>
            {dayjs(data?.orderApplyDto?.reportTime).format('YYYY-MM-DD HH:mm')}
          </span>
        </div>
      );
    }
    if (type === OrderTypeEnum.MEDICATION || type === OrderTypeEnum.HANDLE) {
      return (
        <div className="time dinpro-bold">
          <span>{dayjs(data?.orderTime).format('YYYY-MM-DD HH:mm')}</span>
        </div>
      );
    }
    if (type === OrderTypeEnum.OPERATION) {
      return (
        <div className="time dinpro-bold">
          <span>{dayjs(data?.orderTime).format('YYYY-MM-DD HH:mm')}</span>
        </div>
      );
    }
  };

  const renderStatus = (value) => {
    return (
      <Tag
        shape="fill"
        color={value === ApplyStatusEnum.CONFIRM_REPORT ? 'success' : ''}
      >
        {value === ApplyStatusEnum.CONFIRM_REPORT ? '确认报告' : '其他'}
      </Tag>
    );
  };

  return (
    <Popover
      placement="leftTop"
      title="基本信息"
      content={<Detail detail={detail} />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName="timeline-item-popover"
    >
      <div className="timeline-item">
        <div className="first">
          {renderTime(data?.orderType)}
          <div
            style={{
              visibility:
                data?.orderType === 'EXAMINE' || data?.orderType === 'ASSAY'
                  ? 'visible'
                  : 'hidden',
            }}
            className="status"
          >
            {renderStatus(data?.orderApplyDto?.applyStatus)}
          </div>
        </div>
        <div className="second">
          <div className="left">
            <div className="icon-container">
              <Icon
                type={handelType(data?.orderType)?.icon as string}
                size={handelType(data?.orderType)?.size}
              />
            </div>
            <div className="content">
              <div>
                <span className="label">名称</span>
                <span className="value"> {data?.orderName}</span>
              </div>
              <div>
                <span className="label">异常</span>
                {(data?.orderType === OrderTypeEnum.ASSAY ||
                  data?.orderType === OrderTypeEnum.EXAMINE) &&
                  handelType(data?.orderType)?.abnormalInfo && (
                    <span className="value abnormal">
                      <Tag color="error">
                        {handelType(data?.orderType)?.abnormalInfo}
                      </Tag>
                    </span>
                  )}
              </div>
            </div>
          </div>
          <div className="right">{data?.doctor?.name}</div>
        </div>
      </div>
    </Popover>
  );
};

export default Index;
