import React, { useEffect } from 'react';

export const useHorizontalScroll = (ref) => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (ref) {
        ref.current.scrollLeft += event.deltaY;
      }
    };

    ref?.current?.addEventListener('wheel', handleWheel);
  }, [ref]);
};
