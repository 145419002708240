import './index.less';

import React from 'react';

import BgImg from '@/assets/empty.png';

const Index = (props) => {
  const { desc = '暂无内容' } = props;
  return (
    <div className="cpoe-empty-container">
      <div
        className="center"
        style={{
          background: `url(${BgImg})`,
        }}
      >
        <div className="desc">{desc}</div>
      </div>
    </div>
  );
};

export default Index;
