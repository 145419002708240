import FormBlock from '@/module/FormBlock';
import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  message,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

const operationList = [
  '人工授精',
  '体外受精-胚胎移植',
  '配子或合子输卵管内移植',
  '卵胞浆内单精子显微注射',
  '胚胎冻融',
  '植入前胚胎遗传学诊断',
  '腹腔镜下输卵管通液术',
  '腹腔镜下输卵管造口术',
  '腹腔镜下输卵管吻合术',
  '腹腔镜下输卵管结扎术',
  '腹腔镜下输卵管切除术',
  '腹腔镜下卵巢囊肿剥除术',
  '腹腔镜下卵巢切除术',
  '腹腔镜下附件切除术',
  '腹腔镜下子宫肌瘤剥除术',
  '腹腔镜辅助下阴式子宫全切术',
  '腹腔镜下子宫切除术',
  '阴式子宫全切术',
  '全子宫切除术',
  '次全子宫切除术',
  '子宫根治术',
  '盆腔淋巴结清除术',
  '腹主动脉旁淋巴结清除术',
  '宫颈息肉电切术',
  '宫颈锥形切除术',
  'LEEP术：环电极切除术',
  '刮宫术：扩张与刮取术',
  '前庭大腺囊肿造口术',
  '阴道成形术',
  '阴道紧缩术',
  '阴道囊肿切除术',
  '阴道瘘修补术',
  '阴道前壁修补术',
  '阴道后壁修补术',
  '阴道顶悬吊手术',
  '尿道下裂修复手术',
  '尿道瘘修复手术',
  '尿道球囊扩张手术',
  '尿道口成形手术',
  '尿道口狭窄切开手术',
  '尿道口移位手术',
  '尿道口松弛手术',
  '尿道口缩小手术',
  '尿道口扩大手术',
  '尿道口闭合手术',
  '尿道口重建手术',
  '外阴白斑切除手术',
  '外阴癌根治手术',
  '外阴整形手术',
];

const Index = (props) => {
  const { data, onClose } = props;

  const handleFetch = async () => {
    // Tips: 函数入参包含通过表单params属性传入的额外参数(平铺)
    // Tips: 以下是你选择的请求函数
    if (!data) {
      return null;
    }
    const res =
      await vsf?.services?.DoctorOrderOperationController_getById_ea493a?.({
        // TODO: 请检查以下这部分传参是否符合需求
        id: data?.id,
      }); // Tips: 需要返回内容数据，结构和表单RO一致
    return res?.data;
  };

  const handleSubmit = async () => {
    const values =
      await vsf.refs.orderOperationForm?.validateFieldsReturnFormatValue();

    props?.onSubmit({
      ...values,
      id: new Date().valueOf(),
    });
  };

  const [showSome, setShowSome] = useState<boolean>(false);

  const getFlag = async () => {
    const res = await vsf?.services?.TreatInfoController_getTreatFlag_771723?.({
      // TODO: 请检查以下这部分传参是否符合需求
    }); // TODO: 你可能需要整理返回数据格式
    if (res?.data === 2) {
      setShowSome(true);
    }
  };

  useEffect(() => {
    getFlag();
  }, []);

  const labelWidth = 100;

  return (
    <div className="cpoe-operation">
      <div className="main">
        <VSForm id="orderOperationForm" onFetch={handleFetch}>
          <FormBlock title="手术室信息">
            <VSFormLayout key="0" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationRoom']}
                label="手术室"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_room}
                fieldProps={{}}
              />

              <VSFormItem
                name={['anesthesiaConsultation']}
                label="麻醉会诊"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['anesthesiaEvaluate']}
                label="麻醉评估"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationDoctor']}
                label="手术医师"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_doctor}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationHelpOne']}
                label="助手1"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_doctor}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationHelpTwo']}
                label="助手2"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_doctor}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="2" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationHelpThree']}
                label="助手3"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_doctor}
                fieldProps={{}}
              />

              <VSFormItem
                name={['outHandle']}
                label="外来器械"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />
            </VSFormLayout>
          </FormBlock>
          <FormBlock title="手术信息">
            <VSFormLayout key="3" columnCount={1} labelWidth={labelWidth}>
              <VSFormItem
                name={['operation']}
                label="手术"
                valueType="select"
                dataSource={operationList?.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </VSFormLayout>
            <VSFormLayout key="4" columnCount={1} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationDiagnose']}
                label="术前诊断"
                valueType="text"
                rules={[
                  {
                    message: '术前诊断长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="5" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationLevel']}
                label="手术等级"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['planOperation']}
                label="非计划二次手术"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="6" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationDepartment']}
                label="手术科室"
                valueType="text"
                rules={[
                  {
                    message: '手术科室长度不合法',
                    type: 'string',
                    min: 0,
                    max: 32,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationTime']}
                label="手术日期"
                valueType="date"
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationLength']}
                label="手术时长"
                valueType="text"
                rules={[
                  {
                    message: '手术时长长度不合法',
                    type: 'string',
                    min: 0,
                    max: 32,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="7" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['bloodType']}
                label="血型"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.blood_type}
                fieldProps={{}}
              />

              <VSFormItem
                name={['quickPathology']}
                label="快速病理"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['outsideTag']}
                label="外请标识"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="8" columnCount={1} labelWidth={labelWidth}>
              <VSFormItem
                name={['operationToGo']}
                label="术后去向"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_go}
                fieldProps={{}}
              />
            </VSFormLayout>
          </FormBlock>
          <FormBlock title="其他">
            <VSFormLayout key="9" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['outsideCycle']}
                label="体外循环"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['selfCycle']}
                label="字体回循环"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationBeforeMedication']}
                label="术前抗菌药物"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="10" columnCount={3} labelWidth={labelWidth}>
              <VSFormItem
                name={['specialInfectious']}
                label="特殊感染"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_yes_no}
                fieldProps={{}}
              />

              <VSFormItem
                name={['infectiousDisease']}
                label="急性传染病"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_infectious}
                fieldProps={{}}
              />

              <VSFormItem
                name={['infectiousDiseaseName']}
                label="急性感染病名称"
                valueType="text"
                rules={[
                  {
                    message: '急性感染病名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 32,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="11" columnCount={4} labelWidth={labelWidth}>
              <VSFormItem
                name={['hbsag']}
                label="Hbsag"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_disease}
                fieldProps={{}}
              />

              <VSFormItem
                name={['syphilis']}
                label="梅毒"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_disease}
                fieldProps={{}}
              />

              <VSFormItem
                name={['aids']}
                label="艾滋"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_disease}
                fieldProps={{}}
              />

              <VSFormItem
                name={['bingan']}
                label="丙肝"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_disease}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="12" columnCount={1} labelWidth={labelWidth}>
              <VSFormItem
                name={['comment']}
                label="备注"
                valueType="text"
                rules={[
                  {
                    message: '备注长度不合法',
                    type: 'string',
                    min: 0,
                    max: 64,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="14" columnCount={1} labelWidth={labelWidth}>
              <VSFormItem
                name={['description']}
                label="说明"
                valueType="text"
                rules={[
                  {
                    message: '说明长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            {showSome ? (
              <VSFormItem
                name={['operationType']}
                label="手术类型"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.operation_type}
                fieldProps={{}}
              />
            ) : null}
          </FormBlock>
        </VSForm>
      </div>
      <div
        className="footer"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0 18px',
        }}
      >
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{
            marginLeft: 12,
          }}
        >
          确定
        </Button>
      </div>
    </div>
  );
};

export default Index;
