import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'

import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import './index.less'

const TreatmentMethodsMenu = [
  {
    label: '患侧输卵管切除术',
    value: '患侧输卵管切除术'
  },
  {
    label: '患侧输卵管切开取胚术',
    value: '患侧输卵管切开取胚术'
  },
  {
    label: '化学药物治疗',
    value: '化学药物治疗'
  },
  {
    label: '患侧附件切除术',
    value: '患侧附件切除术'
  },
  {
    label: '中医治疗',
    value: '中医治疗'
  },
]


const EctopicPregnancyRecords = props => {

  const { open, ectopicPregnancyRecordsTableValue = [] } = props

  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">异位妊娠记录</div>
      </div>
    )
  }

  const handelSubmit = async () => {
    console.log((await vsf.refs?.EctopicPregnancyRecordsTable?.getValues()))
    props?.onOk(
      (await vsf.refs?.EctopicPregnancyRecordsTable?.getValues())
    )
  }

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="ectopic_pregnancy_records_wapper"
      width={700}
      bodyStyle={{
        height: 350,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.EctopicPregnancyRecordsTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.EctopicPregnancyRecordsTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="EctopicPregnancyRecordsTable"
        scroll={{
          y: 220
        }}
        defaultValue={ectopicPregnancyRecordsTableValue}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={240}
          title='妊娠日期'
          dataIndex={['pregnancyDate']}
          valueType='dateYear'
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择妊娠日期'
              }
            ]
          }}
        />
        <VSTableColumn
          width={180}
          title='妊娠部位'
          dataIndex={['pregnancySite']}
          valueType='doubleSelect'
        // formItemProps={{
        //   rules: [
        //     {
        //       required: true,
        //       message: '请选择妊娠部位'
        //     }
        //   ]
        // }}
        />
        <VSTableColumn
          width={180}
          title='治疗方式'
          dataIndex={['treatmentMethods']}
          valueType='select'
          dataSource={TreatmentMethodsMenu}
          fieldProps={{
            showSearch: true
          }}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择治疗方式'
              }
            ]
          }}
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(EctopicPregnancyRecords)