import './dialog.css';

import { EDITOR_COMPONENT, EditorComponent } from '../../editor';
import { KeyMap } from '../../editor/dataset/enum/KeyMap';
import { createImageInput, getImageValue } from './Image';
import { createValueSetInput, getValueSetValue } from './ValueSet';

export interface IDialogData {
  type:
    | 'text'
    | 'select'
    | 'checkbox'
    | 'color'
    | 'number'
    | 'textarea'
    | 'value-set'
    | 'datetime-local'
    | 'image';
  label?: string;
  name: string;
  value?: string;
  checked?: boolean;
  options?: { label: string; value: string }[];
  placeholder?: string;
  width?: number;
  height?: number;
  required?: boolean;
  core?: boolean;
}

export interface IDialogConfirm {
  name: string;
  value: string;
  checked?: boolean;
}

export interface IDialogOptions {
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: (payload: IDialogConfirm[]) => void;
  title: string;
  data: IDialogData[];
  coreOnly?: boolean;
}

export class Dialog {
  private options: IDialogOptions;
  private mask: HTMLDivElement | null;
  private container: HTMLDivElement | null;
  private inputList: (
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLDivElement
    | HTMLSelectElement
  )[];
  private root: HTMLElement;

  constructor(options: IDialogOptions, root: HTMLElement) {
    this.options = options;
    this.mask = null;
    this.container = null;
    this.inputList = [];
    this.root = root;
    this._render();
  }

  private _render() {
    const { title, data, coreOnly, onClose, onCancel, onConfirm } =
      this.options;
    // 渲染遮罩层
    const mask = document.createElement('div');
    mask.classList.add('dialog-mask');
    mask.setAttribute(EDITOR_COMPONENT, EditorComponent.COMPONENT);
    this.root.append(mask);
    // 渲染容器
    const container = document.createElement('div');
    container.classList.add('dialog-container');
    container.setAttribute(EDITOR_COMPONENT, EditorComponent.COMPONENT);
    // 弹窗
    const dialogContainer = document.createElement('div');
    dialogContainer.classList.add('dialog');
    container.append(dialogContainer);
    // 标题容器
    const titleContainer = document.createElement('div');
    titleContainer.classList.add('dialog-title');
    // 标题&关闭按钮
    const titleSpan = document.createElement('span');
    titleSpan.append(document.createTextNode(title));
    const titleClose = document.createElement('i');
    titleClose.onclick = () => {
      if (onClose) {
        onClose();
      }
      this._dispose();
    };
    titleContainer.append(titleSpan);
    titleContainer.append(titleClose);
    dialogContainer.append(titleContainer);
    // 选项容器
    const optionContainer = document.createElement('div');
    optionContainer.classList.add('dialog-option');
    // 选项
    for (let i = 0; i < data.length; i++) {
      const option = data[i];
      const optionItemContainer = document.createElement('div');
      optionItemContainer.classList.add('dialog-option__item');
      // 选项名称
      if (option.label) {
        const optionName = document.createElement('span');
        optionName.append(document.createTextNode(option.label));
        optionItemContainer.append(optionName);
        if (option.required) {
          optionName.classList.add('dialog-option__item--require');
        }
      }
      // 选项输入框
      if (option.type === 'value-set') {
        const valueSetsDiv = createValueSetInput(option);
        optionItemContainer.append(valueSetsDiv);
        optionContainer.append(optionItemContainer);
        this.inputList.push(valueSetsDiv);
      } else if (option.type === 'image') {
        const imageDiv = createImageInput(option);
        optionItemContainer.append(imageDiv);
        optionContainer.append(optionItemContainer);
        this.inputList.push(imageDiv);
      } else {
        let optionInput:
          | HTMLInputElement
          | HTMLTextAreaElement
          | HTMLSelectElement;
        if (option.type === 'select') {
          optionInput = document.createElement('select');
          option.options?.forEach((item) => {
            const optionItem = document.createElement('option');
            optionItem.value = item.value;
            optionItem.label = item.label;
            optionInput.append(optionItem);
          });
        } else if (option.type === 'textarea') {
          optionInput = document.createElement('textarea');
        } else {
          optionInput = document.createElement('input');
          optionInput.type = option.type;
        }
        if (option.width) {
          optionInput.style.width = `${option.width}px`;
        }
        if (option.height) {
          optionInput.style.height = `${option.height}px`;
        }
        if (optionInput instanceof HTMLInputElement) {
          optionInput.checked = option.checked ?? false;
        }
        optionInput.name = option.name;
        optionInput.value = option.value || '';
        if (!(optionInput instanceof HTMLSelectElement)) {
          optionInput.placeholder = option.placeholder || '';
        }
        if (coreOnly && !option.core) {
          optionItemContainer.style.display = 'none';
        }
        optionItemContainer.append(optionInput);
        optionContainer.append(optionItemContainer);
        this.inputList.push(optionInput);
      }
    }
    dialogContainer.append(optionContainer);
    // 按钮容器
    const menuContainer = document.createElement('div');
    menuContainer.classList.add('dialog-menu');
    // 取消按钮
    const cancelBtn = document.createElement('button');
    cancelBtn.classList.add('dialog-menu__cancel');
    cancelBtn.append(document.createTextNode('取消'));
    cancelBtn.type = 'default';
    cancelBtn.onclick = () => {
      if (onCancel) {
        onCancel();
      }
      this._dispose();
    };
    menuContainer.append(cancelBtn);
    // 确认按钮
    const confirmBtn = document.createElement('button');
    confirmBtn.append(document.createTextNode('确定'));
    confirmBtn.type = 'primary';
    const onclick = () => {
      if (onConfirm) {
        const payload = this.inputList.map<IDialogConfirm>((input) => {
          if (input instanceof HTMLDivElement) {
            if (input.classList.contains('value-sets')) {
              return getValueSetValue(input);
            } else if (input.classList.contains('image')) {
              return getImageValue(input);
            }
            return {
              name: input.id,
              value: '',
            };
          }
          if (input instanceof HTMLInputElement && input.type === 'checkbox') {
            return {
              name: input.name,
              value: input.value,
              checked: input.checked,
            };
          }
          return {
            name: input.name,
            value: input.value,
          };
        });
        onConfirm(payload);
      }
      this._dispose();
    };
    confirmBtn.onclick = onclick;
    menuContainer.append(confirmBtn);
    dialogContainer.append(menuContainer);
    container.onkeydown = (evt) => {
      if (evt.key === KeyMap.ESC) {
        this._dispose();
      } else if (evt.key === KeyMap.Enter) {
        onclick();
      }
    };
    // 渲染
    this.root.append(container);
    setTimeout(() => {
      this.inputList[0].focus();
    });
    this.container = container;
    this.mask = mask;
  }

  private _dispose() {
    this.mask?.remove();
    this.container?.remove();
  }
}
