import './index.less';

import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Layout, Menu } from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/Icon';

import Header from './header';

const { Sider } = Layout;

type ContainerProps = {
  route: RouteConfig;
  location: Location;
};

const mock = [
  {
    label: '首页',
    key: 'index',
    path: '/index',
    icon: <Icon type="icon-a-Frame456" size={30} />,
  },
  {
    label: '接诊',
    key: 'index2',
    path: '/center',
    icon: <Icon type="icon-a-Frame456-1" size={30} />,
  },
  {
    label: '手术相关',
    key: 'index3',
    icon: <Icon type="icon-a-Plus-crossjiahao" size={30} />,
    path: '/operation',
  },
  // {
  //   label: '诊间预约',
  //   key: 'index4',
  //   icon: <Icon type="icon-Frame-12" size={30} />,
  // },
  {
    label: '会诊信息',
    key: 'index55',
    icon: <Icon type="icon-Frame-13" size={30} />,
    path: '2',
  },
  // {
  //   label: '住院申请',
  //   key: 'index6',
  //   icon: <Icon type="icon-a-Toolgongju" size={30} />,
  // },
];

type SiderMenuPropsType = {
  collapsed?: boolean;
};

const LeftSider = (props: SiderMenuPropsType) => {
  const { collapsed } = props;
  const [active, setActive] = useState<any>(window.location.pathname);

  const onClick = async (v) => {
    if (v?.key === 'index3') {
      const res =
        await vsf?.services?.TreatInfoController_getTreatFlag_771723?.({
          // TODO: 请检查以下这部分传参是否符合需求
        }); // TODO: 你可能需要整理返回数据格式
      if (res?.data > 1) {
        if (v?.path && v?.path?.indexOf('/') === 0) {
          vsf?.navigateTo(v?.path);
        }
      }
    } else {
      if (v?.path && v?.path?.indexOf('/') === 0) {
        vsf?.navigateTo(v?.path);
      }
    }
  };

  return (
    <div className="sider-menu">
      {mock?.map((item) => (
        <div
          className={classnames('item', {
            'active-item': active === item?.path,
          })}
          key={item?.key}
          onClick={() => {
            setActive(item?.path);
            onClick(item);
          }}
        >
          <div className="bg"></div>
          <span className="icon">{item?.icon}</span>
          <span className="label">{item?.label}</span>
        </div>
      ))}
    </div>
  );
};

const Container: React.FC<any> = (props) => {
  const { route, stores } = props;
  const { user } = stores || {};
  const { hospitalType } = user || {};
  const [collapsed, setCollapsed] = useState(false);

  const items = [
    {
      label: `${hospitalType === 'in' ? '住院' : '门诊'}接诊`,
      key: 'mail',
    },
    {
      label: '辅助诊疗',
      key: 'mail2',
    },
    {
      label: '会诊管理',
      key: 'mail3',
    },
    {
      label: '手术管理',
      key: 'mail7',
    },
    {
      label: '医保相关',
      key: 'mail4',
    },
    {
      label: '统计查询',
      key: 'mail5',
    },
    {
      label: '系统维护',
      key: 'mail6',
    },
  ];

  const layoutCls = getPrefixCls('layout');

  const indexLayoutCls = getPrefixCls('layout-index');

  const findData = (trees, condition) => {
    for (let i = 0; i < trees.length; i++) {
      const result = findNodeData(trees[i], condition);
      if (result) {
        return result;
      }
    }

    // 未找到满足条件的数据
    return null;
  };

  const findNodeData = (tree, condition) => {
    // 遍历当前节点
    if (condition(tree)) {
      return tree;
    }

    // 遍历子节点
    if (tree.children) {
      for (let i = 0; i < tree.children.length; i++) {
        const result = findNodeData(tree.children[i], condition);
        if (result) {
          return result;
        }
      }
    }

    // 未找到满足条件的数据
    return null;
  };

  const onClick = (v) => {
    const target = findData(items, (item) => item?.key === v?.key);
    if (target?.path) {
      vsf.navigateTo(target.path);
    }
  };

  return (
    <div className={layoutCls}>
      <div className={`${layoutCls}-header`}>
        <Header {...props} />
      </div>
      <div className={`${layoutCls}-sub-header`}>
        <div className={`${layoutCls}-header-menu noscroll`}>
          <Menu
            onClick={onClick}
            // selectedKeys={}
            mode="horizontal"
            items={items}
          />
        </div>
      </div>
      <div className={`${layoutCls}-content`}>
        <Sider className={`${layoutCls}-sider`} theme="light" width={116}>
          <div>
            <LeftSider collapsed={collapsed} />
          </div>
        </Sider>
        <div className={`${layoutCls}-main`}>
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
    </div>
  );
};

export default definePage(Container);
