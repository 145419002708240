import { modKeyText } from './editor/utils/hotkey';

export const html = `
<div class="app app-medical">
<div class="menu" id="medical-menu" editor-component="menu">
  <div class="editor-mode" title="编辑模式(编辑、只读)">编辑模式</div>
  <div class="menu-item">
    <div class="menu-item__undo" title="撤销(${modKeyText}+Z)">
      <i></i>
    </div>
    <div class="menu-item__redo" title="重做(${modKeyText}+Y)">
      <i></i>
    </div>
    <div class="menu-item__painter" title="格式刷(双击可连续使用)">
      <i></i>
    </div>
    <div class="menu-item__format" title="清除格式">
      <i></i>
    </div>
  </div>
  <div class="menu-divider"></div>
  <div class="menu-item">
    <div class="menu-item__font">
      <span class="select" title="字体">微软雅黑</span>
      <div class="options">
        <ul>
          <li data-family="Yahei" style="font-family:'Yahei';">微软雅黑</li>
          <li data-family="宋体" style="font-family:'宋体';">宋体</li>
          <li data-family="黑体" style="font-family:'黑体';">黑体</li>
          <li data-family="仿宋" style="font-family:'仿宋';">仿宋</li>
          <li data-family="楷体" style="font-family:'楷体';">楷体</li>
          <li data-family="等线" style="font-family:'等线';">等线</li>
          <li data-family="华文琥珀" style="font-family:'华文琥珀';">华文琥珀</li>
          <li data-family="华文楷体" style="font-family:'华文楷体';">华文楷体</li>
          <li data-family="华文隶书" style="font-family:'华文隶书';">华文隶书</li>
          <li data-family="华文新魏" style="font-family:'华文新魏';">华文新魏</li>
          <li data-family="华文行楷" style="font-family:'华文行楷';">华文行楷</li>
          <li data-family="华文中宋" style="font-family:'华文中宋';">华文中宋</li>
          <li data-family="华文彩云" style="font-family:'华文彩云';">华文彩云</li>
          <li data-family="Arial" style="font-family:'Arial';">Arial</li>
          <li data-family="Segoe UI" style="font-family:'Segoe UI';">Segoe UI</li>
          <li data-family="Ink Free" style="font-family:'Ink Free';">Ink Free</li>
          <li data-family="Fantasy" style="font-family:'Fantasy';">Fantasy</li>
        </ul>
      </div>
    </div>
    <div class="menu-item__size-add" title="增大字号(${modKeyText}+[)">
      <i></i>
    </div>
    <div class="menu-item__size-minus" title="减小字号(${modKeyText}+])">
      <i></i>
    </div>
    <div class="menu-item__bold" title="加粗(${modKeyText}+B)">
      <i></i>
    </div>
    <div class="menu-item__italic" title="斜体(${modKeyText}+I)">
      <i></i>
    </div>
    <div class="menu-item__underline" title="下划线(${modKeyText}+U)">
      <i></i>
    </div>
    <div class="menu-item__strikeout" title="删除线(Ctrl+Shift+X)">
      <i></i>
    </div>
    <div class="menu-item__superscript" title="上标(${modKeyText}+Shift+.)">
      <i></i>
    </div>
    <div class="menu-item__subscript" title="下标(${modKeyText}+Shift+,)">
      <i></i>
    </div>
    <div class="menu-item__color" title="字体颜色">
      <i></i>
      <span></span>
      <input type="color" id="color" />
    </div>
    <div class="menu-item__highlight" title="高亮">
      <i></i>
      <span></span>
      <input type="color" id="highlight">
    </div>
  </div>
  <div class="menu-divider"></div>
  <div class="menu-item">
    <div class="menu-item__left" title="左对齐(${modKeyText}+Shift+L)">
      <i></i>
    </div>
    <div class="menu-item__center" title="居中对齐(${modKeyText}+Shift+E)">
      <i></i>
    </div>
    <div class="menu-item__right" title="右对齐(${modKeyText}+Shift+R)">
      <i></i>
    </div>
    <div class="menu-item__alignment" title="两端对齐(${modKeyText}+Shift+J)">
      <i></i>
    </div>
    <div class="menu-item__row-margin">
      <i title="行间距"></i>
      <div class="options">
        <ul>
          <li data-rowmargin='1'>1</li>
          <li data-rowmargin="1.25">1.25</li>
          <li data-rowmargin="1.5">1.5</li>
          <li data-rowmargin="1.75">1.75</li>
          <li data-rowmargin="2">2</li>
          <li data-rowmargin="2.5">2.5</li>
          <li data-rowmargin="3">3</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="menu-divider"></div>
  <div class="menu-item">
    <div class="menu-item__table">
      <i title="表格"></i>
    </div>
    <div class="menu-item__table__collapse">
      <div class="table-close">×</div>
      <div class="table-title">
        <span class="table-select">插入</span>
        <span>表格</span>
      </div>
      <div class="table-panel"></div>
    </div>
    <div class="menu-item__image">
      <i title="图片"></i>
      <input type="file" id="image" accept=".png, .jpg, .jpeg">
    </div>
    <div class="menu-item__signature">
      <i title="手写"></i>
    </div>
    <div class="menu-item__hyperlink">
      <i title="超链接"></i>
    </div>
    <div class="menu-item__separator">
      <i title="分割线"></i>
      <div class="options">
        <ul>
          <li data-separator='0,0'>
            <i></i>
          </li>
          <li data-separator="1,1">
            <i></i>
          </li>
          <li data-separator="3,1">
            <i></i>
          </li>
          <li data-separator="4,4">
            <i></i>
          </li>
          <li data-separator="7,3,3,3">
            <i></i>
          </li>
          <li data-separator="6,2,2,2,2,2">
            <i></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="menu-item__watermark">
      <i title="水印(添加、删除)"></i>
      <div class="options">
        <ul>
          <li data-menu="add">添加水印</li>
          <li data-menu="delete">删除水印</li>
        </ul>
      </div>
    </div>
    <div class="menu-item__page-break" title="分页符">
      <i></i>
    </div>
    <div class="menu-item__control" data-menu="control">
      <i title="控件"></i>
      <div class="options">
        <ul>
          <li data-control='text'>文本</li>
          <li data-control="select">列举</li>
          <li data-control="checkbox">复选框</li>
          <li data-control="date">日期</li>
          <li data-control="image">图片</li>
        </ul>
      </div>
    </div>
    <div class="menu-item__checkbox" title="复选框">
      <i></i>
    </div>
    <div class="menu-item__latex" title="LateX">
      <i></i>
    </div>
    <div class="menu-item__block" title="内容块">
      <i></i>
    </div>
  </div>
  <div class="menu-divider"></div>
  <div class="menu-item">
    <div class="menu-item__search" data-menu="search" title="搜索与替换(${modKeyText}+F)">
      <i></i>
    </div>
    <div class="menu-item__search__collapse" data-menu="search">
      <div class="menu-item__search__collapse__search">
        <input type="text" />
        <label class="search-result"></label>
        <div class="arrow-left">
          <i></i>
        </div>
        <div class="arrow-right">
          <i></i>
        </div>
        <span>×</span>
      </div>
      <div class="menu-item__search__collapse__replace">
        <input type="text">
        <button>替换</button>
      </div>
    </div>
    <div class="menu-item__print" data-menu="print" title="打印(${modKeyText}+P)">
      <i></i>
    </div>
    <div class="menu-item__export" data-menu="export" title="导出">
      <i></i>
    </div>
    <div class="menu-item__formdata" data-menu="formdata" title="表单数据">
      <i></i>
    </div>
  </div>
</div>
<div class="editor"></div>
<div class="toast"></div>
<div class="footer" editor-component="footer">
  <div>
    <div class="page-mode">
      <i title="页面模式(分页、连页)"></i>
      <div class="options">
        <ul>
          <li data-page-mode="paging" class="active">分页</li>
          <li data-page-mode="continuity">连页</li>
        </ul>
      </div>
    </div>
    <span>可见页码：<span class="page-no-list">1</span></span>
    <span>页面：<span class="page-no">1</span>/<span class="page-size">1</span></span>
    <span>字数：<span class="word-count">0</span></span>
  </div>
  <div>
    <div class="page-scale-minus" title="缩小(${modKeyText}+-)">
      <i></i>
    </div>
    <span class="page-scale-percentage" title="显示比例(点击可复原${modKeyText}+0)">100%</span>
    <div class="page-scale-add" title="放大(${modKeyText}+=)">
      <i></i>
    </div>
    <div class="paper-size">
      <i title="纸张类型"></i>
      <div class="options">
        <ul>
          <li data-paper-size="794*1123" class="active">A4</li>
          <li data-paper-size="1593*2251">A2</li>
          <li data-paper-size="1125*1593">A3</li>
          <li data-paper-size="565*796">A5</li>
          <li data-paper-size="412*488">5号信封</li>
          <li data-paper-size="450*866">6号信封</li>
          <li data-paper-size="609*862">7号信封</li>
          <li data-paper-size="862*1221">9号信封</li>
          <li data-paper-size="813*1266">法律用纸</li>
          <li data-paper-size="813*1054">信纸</li>
        </ul>
      </div>
    </div>
    <div class="paper-direction">
      <i title="纸张方向"></i>
      <div class="options">
        <ul>
          <li data-paper-direction="vertical" class="active">纵向</li>
          <li data-paper-direction="horizontal">横向</li>
        </ul>
      </div>
    </div>
    <div class="paper-margin" title="页边距">
      <i></i>
    </div>
    <div class="fullscreen" title="全屏显示">
      <i></i>
    </div>
  </div>
</div>
</div>
`;
