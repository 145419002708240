import './assets/css/index.css';

import { Command } from './core/command/Command';
import { CommandAdapt } from './core/command/CommandAdapt';
import { ContextMenu } from './core/contextmenu/ContextMenu';
import { Draw } from './core/draw/Draw';
import { INavigateInfo } from './core/draw/interactive/Search';
import { Listener } from './core/listener/Listener';
import { Register } from './core/register/Register';
import { Shortcut } from './core/shortcut/Shortcut';
import { defaultCheckboxOption } from './dataset/constant/Checkbox';
import { defaultControlOption } from './dataset/constant/Control';
import { defaultCursorOption } from './dataset/constant/Cursor';
import { EDITOR_COMPONENT } from './dataset/constant/Editor';
import { defaultHeaderOption } from './dataset/constant/Header';
import { defaultWatermarkOption } from './dataset/constant/Watermark';
import { BlockType } from './dataset/enum/Block';
import { ControlType, ImageDisplay } from './dataset/enum/Control';
import {
  EditorComponent,
  EditorMode,
  PageMode,
  PaperDirection,
} from './dataset/enum/Editor';
import { ElementType } from './dataset/enum/Element';
import { KeyMap } from './dataset/enum/KeyMap';
import { RowFlex } from './dataset/enum/Row';
import { IBlock } from './interface/Block';
import { ICheckboxOption } from './interface/Checkbox';
import { DeepRequired } from './interface/Common';
import {
  IContextMenuContext,
  IRegisterContextMenu,
} from './interface/contextmenu/ContextMenu';
import { IControlOption } from './interface/Control';
import { ICursorOption } from './interface/Cursor';
import { IEditorOption, IEditorResult } from './interface/Editor';
import { IElement } from './interface/Element';
import { IHeader } from './interface/Header';
import { IWatermark } from './interface/Watermark';
import { deepClone } from './utils';
import { formatElementList } from './utils/element';

export default class Editor {
  public command: Command;
  public listener: Listener;
  public register: Register;
  private draw: Draw;
  public destroy: Function;

  constructor(
    root: HTMLElement,
    container: HTMLDivElement,
    elementList: IElement[],
    options: IEditorOption = {},
  ) {
    const headerOptions: Required<IHeader> = {
      ...defaultHeaderOption,
      ...options.header,
    };
    const waterMarkOptions: Required<IWatermark> = {
      ...defaultWatermarkOption,
      ...options.watermark,
    };
    const controlOptions: Required<IControlOption> = {
      ...defaultControlOption,
      ...options.control,
    };
    const checkboxOptions: Required<ICheckboxOption> = {
      ...defaultCheckboxOption,
      ...options.checkbox,
    };
    const cursorOptions: Required<ICursorOption> = {
      ...defaultCursorOption,
      ...options.cursor,
    };

    const editorOptions: DeepRequired<IEditorOption> = {
      mode: EditorMode.EDIT,
      defaultType: 'TEXT',
      defaultFont: 'Yahei',
      defaultSize: 16,
      defaultRowMargin: 1,
      defaultBasicRowMarginHeight: 8,
      defaultTabWidth: 32,
      width: 1184,
      height: 1123,
      scale: 1,
      pageGap: 0,
      pageNumberBottom: 60,
      pageNumberSize: 12,
      pageNumberFont: 'Yahei',
      underlineColor: '#000000',
      strikeoutColor: '#FF0000',
      rangeAlpha: 0.6,
      rangeColor: '#AECBFA',
      rangeMinWidth: 5,
      searchMatchAlpha: 0.6,
      searchMatchColor: '#FFFF00',
      searchNavigateMatchColor: '#AAD280',
      highlightAlpha: 0.6,
      resizerColor: '#4182D9',
      resizerSize: 5,
      marginIndicatorSize: 35,
      marginIndicatorColor: '#BABABA',
      margins: [100, 120, 100, 120],
      pageMode: PageMode.PAGING,
      tdPadding: 5,
      defaultTdHeight: 40,
      defaultHyperlinkColor: '#0000FF',
      markRequiredColor: '#FF000030',
      headerTop: 50,
      paperDirection: PaperDirection.VERTICAL,
      ...options,
      header: headerOptions,
      watermark: waterMarkOptions,
      control: controlOptions,
      checkbox: checkboxOptions,
      cursor: cursorOptions,
    };
    const elements = deepClone(elementList);
    formatElementList(elements, {
      editorOptions,
    });
    // 监听
    this.listener = new Listener();
    // 启动
    this.draw = new Draw(
      root,
      container,
      editorOptions,
      elements,
      this.listener,
    );
    // 命令
    this.command = new Command(new CommandAdapt(this.draw));
    // 菜单
    const contextMenu = new ContextMenu(this.draw, this.command);
    // 快捷键
    const shortcut = new Shortcut(this.draw, this.command);
    // 注册
    this.register = new Register({
      contextMenu,
      shortcut,
    });
    // 注册销毁方法
    this.destroy = () => {
      this.draw.destroy();
      shortcut.removeEvent();
      contextMenu.removeEvent();
    };
  }

  dispose() {
    this.draw.dispose();
    this.draw.getRoot().remove();
  }

  getValue() {
    return this.draw.getValue();
  }

  getOptions() {
    return this.draw.getOptions();
  }

  getMode() {
    return this.draw.getMode();
  }
}

// 对外对象
export {
  BlockType,
  Command,
  ControlType,
  Editor,
  EDITOR_COMPONENT,
  EditorComponent,
  EditorMode,
  ElementType,
  ImageDisplay,
  KeyMap,
  PageMode,
  PaperDirection,
  RowFlex,
};

// 对外类型
export type {
  IBlock,
  IContextMenuContext,
  IEditorOption,
  IEditorResult,
  IElement,
  INavigateInfo,
  IRegisterContextMenu,
  IWatermark,
};
