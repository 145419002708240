import './index.less';

import { DoubleRightOutlined } from '@ant-design/icons';
import {
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';
const Index = (props) => {
  const { reportDetail } = props;

  const [isShowDetail, setIsShowDetail] = useState(true);
  const [currentReport, setCurrentReport] = useState<number>();
  const [arr, setArr] = useState([
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
  ]);

  const handelShow = (index?) => {
    const tempList = cloneDeep(arr);
    setArr(
      arr?.map((item, _idx) => ({
        show: _idx === index ? !item.show : item.show,
      })),
    );
  };

  return (
    <div className="checked_detail">
      <div style={{ position: 'sticky', top: 0 }}>
        <div className="checked_detail_title">
          {reportDetail?.applyCategory}[{reportDetail?.applyItemName}]
        </div>
        <div className="checked_detail_doctor_name">
          <div>申请医师：{reportDetail?.orderDoctor?.name}</div>
          <div>检查时间：{reportDetail?.collectionTime}</div>
          <div>报告时间：{reportDetail?.reportTime}</div>
        </div>
      </div>
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <div>
          <Block titleBackgroundColor="#DBEDFF" title="申请信息">
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">简要病史:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.patient?.medicalHistory}
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">诊断:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.treat?.mainDiagnose}
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">执行科室:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.executeDepartment}
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">注意事项:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.precaution}
              </div>
            </div>
            {/* <div className="checked_detail_content">
              <div className="checked_detail_content_label">作用:</div>
              <div className="checked_detail_content_content"></div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">适应症:</div>
              <div className="checked_detail_content_content"></div>
            </div> */}
          </Block>
        </div>
        {reportDetail?.examineReportDto && (
          <div>
            <Block
              titleBackgroundColor="#DBEDFF"
              title="报告1"
              extra={
                <div style={{ display: 'flex', paddingRight: '10px' }}>
                  <div className="checked_detail_view_img">查看图像</div>
                  <DoubleRightOutlined
                    className={classnames(
                      isShowDetail
                        ? 'checked_detail_double_up_outlined'
                        : 'checked_detail_double_down_outlined',
                    )}
                    style={{ color: '#3276E8' }}
                    onClick={() => {
                      setIsShowDetail(!isShowDetail);
                    }}
                  />
                </div>
              }
            >
              <div style={{ display: isShowDetail ? 'block' : 'none' }}>
                <div className="checked_detail_content">
                  <div className="checked_detail_content_label">检查所见:</div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.examineSee}
                  </div>
                </div>
                <div className="checked_detail_content">
                  <div className="checked_detail_content_label">印象:</div>
                  <div className="checked_detail_content_content">
                    {/* 两侧胸廓对称，气管居中，两肺纹理增多，两肺多发散在斑片状高密度影，右上为著，可见空洞形成。气管及主要支气管通畅。纵隔淋巴结肿大。胸腔内未见积液征。心景未见异常。左侧第3、4、6肋骨陈旧性骨折。 */}
                    {reportDetail?.examineReportDto?.impression}
                  </div>
                </div>
                <div className="checked_detail_content">
                  <div className="checked_detail_content_label">建议:</div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.suggest}
                  </div>
                </div>
                {/* <div className="checked_detail_content">
                  <div className="checked_detail_content_label">检查参数:</div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.examineParameter}
                  </div>
                </div>
                <div className="checked_detail_content">
                  <div className="checked_detail_content_label">是否阳性:</div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.positive ? '是' : '否'}
                  </div>
                </div>
                <div className="checked_detail_content">
                  <div className="checked_detail_content_label">使用仪器:</div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.usedInstrument}
                  </div>
                </div> */}
                {/* <div className="checked_detail_content">
                  <div className="checked_detail_content_label">
                    报告中图像编号:
                  </div>
                  <div className="checked_detail_content_content">
                    {reportDetail?.examineReportDto?.graphicsNum}
                  </div>
                </div> */}
              </div>
            </Block>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
