import {
  ControlComponent,
  ControlType,
} from '../../../../dataset/enum/Control';
import { KeyMap } from '../../../../dataset/enum/KeyMap';
import { IControlInstance } from '../../../../interface/Control';
import { IElement } from '../../../../interface/Element';
import { Control } from '../Control';

export class ImageControl implements IControlInstance {
  private element: IElement;
  private control: Control;

  constructor(element: IElement, control: Control) {
    this.element = element;
    this.control = control;
  }

  public markRequired(startElement: IElement) {
    const elementList = this.control.getElementList(startElement);
    const startIndex = elementList.indexOf(startElement);
    if (!startElement.control?.required) {
      return;
    }
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (preElement.controlId !== startElement.controlId) {
        break;
      } else if (preElement.controlComponent === ControlComponent.PLACEHOLDER) {
        preElement.markRequired = true;
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (nextElement.controlId !== startElement.controlId) {
        break;
      } else if (
        nextElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        nextElement.markRequired = true;
      }
      nextIndex++;
    }
  }

  public getElement(): IElement {
    return this.element;
  }

  public getValue(): IElement[] {
    const range = this.getImageElementRange();
    if (!range) {
      return [];
    }
    const elementList = this.control.getElementList();
    return elementList.slice(range[0] + 1, range[1] + 1);
  }

  public setValue(): number {
    return -1;
  }

  public keydown(evt: KeyboardEvent): number {
    const elementList = this.control.getElementList();
    const range = this.control.getRange();
    // 收缩边界到Value内
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = range;
    const startElement = elementList[startIndex];
    const endElement = elementList[endIndex];
    // backspace
    if (evt.key === KeyMap.Backspace) {
      // 清空日期
      if (startIndex !== endIndex) {
        return this.clearImage();
      } else {
        if (
          startElement.controlComponent === ControlComponent.PREFIX ||
          endElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 清空日期
          return this.clearImage();
        }
      }
    } else if (evt.key === KeyMap.Delete) {
      // 移除选区元素
      if (startIndex !== endIndex) {
        /// 清空日期
        return this.clearImage();
      } else {
        const endNextElement = elementList[endIndex + 1];
        if (
          (startElement.controlComponent === ControlComponent.PREFIX &&
            endNextElement.controlComponent === ControlComponent.PLACEHOLDER) ||
          endNextElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 清空日期
          return this.clearImage();
        }
      }
    }
    return endIndex;
  }

  public cut(): number {
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = this.control.getRange();
    if (startIndex === endIndex) {
      return startIndex;
    }
    // 清空日期
    return this.clearImage();
  }

  public clearImage(): number {
    const range = this.getImageElementRange();
    if (!range) return -1;
    const [leftIndex, rightIndex] = range;
    const elementList = this.control.getElementList();
    // 删除元素
    elementList.splice(leftIndex + 1, rightIndex - leftIndex);
    // 增加占位符
    this.control.addPlaceholder(leftIndex);
    return leftIndex;
  }

  public getImageElementRange(): [number, number] | null {
    let leftIndex = -1;
    let rightIndex = -1;
    const { startIndex, endIndex } = this.control.getRange();
    if (!~startIndex && !~endIndex) return null;
    const elementList = this.control.getElementList();
    const startElement = elementList[startIndex];
    if (startElement.control?.type !== ControlType.IMAGE) return null;
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (
        preElement.controlId !== startElement.controlId ||
        preElement.controlComponent === ControlComponent.PREFIX ||
        preElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        leftIndex = preIndex;
        break;
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (
        nextElement.controlId !== startElement.controlId ||
        nextElement.controlComponent === ControlComponent.POSTFIX ||
        nextElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        rightIndex = nextIndex - 1;
        break;
      }
      nextIndex++;
    }
    // 控件在最后
    if (nextIndex === elementList.length) {
      rightIndex = nextIndex - 2;
    }
    if (!~leftIndex || !~rightIndex) return null;
    return [leftIndex, rightIndex];
  }
}
