import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';

const labelWidth = 95;
const formItemWidth = 194;

const DiagnoseInfo = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};
  const getValue = (value) => {
    if (typeof value === 'string') {
      try {
        return value && JSON.parse(value || '[]');
      } catch (error) {
        return [];
      }
    } else {
      return value;
    }
  };
  return (
    <VSForm
      labelAlign="left"
      id="diagnoseInfo"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.DiagnosisInfoController_getDiagnosisInfoByPatientId_48e981?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
    >
      <FormBlock>
        <VSFormLayout key="0" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['primarySecondary']}
            label="原发/继发"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.PrimarySecondary}
            fieldProps={{}}
          />

          <VSFormItem
            name={['infertilityYears']}
            label="不育年限"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['obtainingDiagnosis']}
            label="获取诊断"
            valueType="text"
            rules={[
              {
                message: 'obtaining_diagnosis长度不合法',
                type: 'string',
                min: 0,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['mainDiagnosis']}
            label="主要诊断"
            valueType="text"
            rules={[
              {
                message: 'main_diagnosis长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="1" columnCount={3} labelWidth={labelWidth}>
          {/* 无交互 */}
          <VSFormItem
            name={['secondaryDiagnosisOfTheFemaleSide']}
            label="女方次要诊断"
            valueType="select"
            dataSource={
              vsf.stores.dbenums.enums.Secondary_diagnosis_of_the_female_side
            }
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="10" columnCount={3} labelWidth={labelWidth}>
          {/* 无交互 */}
          <VSFormItem
            name={['maleDiagnosis']}
            label="男方诊断"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Male_diagnosis}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={4} labelWidth={labelWidth}>
          {/* 病因诊断 */}
          <div style={{ width: '100%' }}>
            <div>
              <VSFormItem
                name={['etiologicalDiagnosis']}
                label="病因诊断"
                valueType="checkbox"
                dataSource={[
                  { label: '输卵管性不孕', value: 'tubalInfertility' },
                  {
                    label: '卵巢功能减退或低下',
                    value: 'ovarianDysfunctionOrHypofunction',
                  },
                  { label: '染色体异常', value: 'chromosomeAbnormalities' },
                  { label: '基因病', value: 'geneticDisease' },
                  { label: '子宫内膜异位症', value: 'endometriosis' },
                  // {
                  //   label: '排精障碍(N97.000)',
                  //   value: 'endometriosisCondition',
                  // },
                  { label: '免疫性不孕', value: 'immunologicalInfertility' },
                  { label: '不明原因不孕', value: 'unexplainedInfertility' },
                ]}
                transform={(v) => {
                  return { etiologicalDiagnosis: JSON.stringify(v) };
                }}
                convertValue={(v) => {
                  return getValue(v);
                }}
                fieldProps={{}}
              />
            </div>
            <div
              style={{
                marginLeft: '69px',
                marginTop: '-18px',
                display: 'flex',
                width: '1000px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexShrink: 0,
                  marginTop: '5px',
                  marginRight: '5px',
                }}
              >
                <div style={{ marginTop: '5px', marginRight: '5px' }}>
                  男性不育
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                  <VSFormLayout
                    key="29"
                    columnCount={1}
                    labelWidth={labelWidth}
                  >
                    <VSFormItem
                      name={['teratogen']}
                      // label="病因诊断"
                      valueType="checkbox"
                      transform={(v) => {
                        return { teratogen: JSON.stringify(v) };
                      }}
                      convertValue={(v) => {
                        return getValue(v);
                      }}
                      dataSource={[
                        { label: '畸精', value: 'teratogen' },
                        { label: '少精', value: 'oligozoospermia' },
                        { label: '弱精', value: 'asthenozoospermia' },
                        { label: '无精', value: 'azoospermia' },
                      ]}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </div>
              </div>

              {/* <div style={{ flexShrink: 0, marginTop: '15px' }}>)</div> */}
            </div>
          </div>
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={3} labelWidth={labelWidth}>
          {/* 男性不孕 */}
          {/* <VSFormItem
            name={['teratogen']}
            label="畸精"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />
          <VSFormItem
            name={['oligozoospermia']}
            label="少精"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['asthenozoospermia']}
            label="弱精"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['azoospermia']}
            label="无精"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          /> */}
        </VSFormLayout>
        <VSFormLayout key="7" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['mainFactorsOfBothParties']}
            label="双方主要因素"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Main_factors_of_both_parties}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={3}>
          <VSFormItem
            name={['ovarianStimulationPlan']}
            label="卵巢刺激方案"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Ovarian_stimulation_plan}
            fieldProps={{}}
          />

          <VSFormItem
            name={['other']}
            label="其他"
            valueType="text"
            rules={[
              { message: 'other长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={4}>
          <VSFormItem
            name={['recordPhysician']}
            label="记录医师"
            valueType="text"
            rules={[
              {
                message: 'record_physician长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['responsiblePhysician']}
            label="责任医师"
            valueType="text"
            rules={[
              {
                message: 'responsible_physician长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['subjectivePhysician']}
            label="主观医师"
            valueType="text"
            rules={[
              {
                message: 'subjective_physician长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['date']}
            label="日期"
            valueType="date"
            fieldProps={{}}
          />
        </VSFormLayout>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.diagnoseInfo?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.DiagnosisInfoController_createDiagnosisInfo_6e676b?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(DiagnoseInfo);
