import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useState } from 'react';

import TreeMenu from '@/module/TreeMenu';

const Index = (props) => {
  const { onChange, stores } = props;
  const { user } = stores || {};
  const { treatInfo } = user || {};
  const [treeData, setTreeData] = useState([]);

  const getData = useCallback(async () => {
    if (!treatInfo?.id) {
      setTreeData([]);
    } else {
      const res =
        await vsf.services.MedicalRecordController_getMedicalRecordHistoryByTreatId_3d54ca(
          {
            treatId: treatInfo?.id,
          },
        );
      console.log('res', res);
      setTreeData(
        (res?.data || [])?.map((item) => ({
          ...item,
          key: item?.id,
          title: item?.template?.templateName,
        })),
      );
    }
  }, [treatInfo]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TreeMenu
      id="StandardApplicationTree"
      fieldNames={{
        title: 'title',
        key: 'key',
        children: 'medicalRecordTemplateDtoList',
      }}
      treeData={treeData}
      isTree
      search={{
        status: true,
        searchPlaceholder: '可用首字母或名称搜索',
      }}
      row={{
        expand: true,
      }}
      onSelect={(_value) => {
        _value?.id && onChange?.(_value);
      }}
    />
  );
};

export default definePage(Index);
