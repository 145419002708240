import './index.less';

import vsf from '@vs/vsf-boot';
import {
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { divide } from 'lodash';
import React, { useEffect, useState } from 'react';

import Cdss from '@/module/Cdss';

const examineList = [
  {
    label: '申请项目',
    value: 'applyItemName',
  },
  {
    label: '申请医生',
    value: 'orderDoctor',
  },
  {
    label: '申请时间',
    value: 'orderTime',
  },
  {
    label: '检验号',
    value: 'id',
  },
  {
    label: '采样时间',
    value: 'collectionTime',
  },
  {
    label: '标本条码',
    value: 'sampleNum',
  },
  {
    label: '检验者',
    value: 'check',
  },
  {
    label: '报告时间',
    value: 'reportTime',
  },
  {
    label: '状态',
    value: 'applyStatus',
  },
  {
    label: '诊断',
    value: 'treat',
  },
  {
    label: '审核时间',
    value: 'reportTime',
  },
  {
    label: '样本',
    value: 'sample',
  },
  {
    label: '采样',
    value: 'sampling',
  },
  {
    label: '审核',
    value: 'reportDoctor',
  },
];

const Index = (props) => {
  const { reportDetail } = props;

  const renderReportDetails = (item) => {
    if (item.value === 'applyStatus') {
      if (reportDetail?.[item?.value] === 'CONFIRM_REPORT') {
        return '已出报告';
      }
    } else if (item.value === 'orderDoctor') {
      return reportDetail?.orderDoctor?.name;
    } else if (item.value === 'reportDoctor') {
      return reportDetail?.reportDoctor?.name;
    } else if (item.value === 'sampling') {
      return '张华';
    } else if (item.value === 'check') {
      return '丽莎';
    } else if (item.value === 'treat') {
      return reportDetail?.treat?.mainDiagnose;
    } else {
      return reportDetail?.[item?.value];
    }
  };

  return (
    <div className="examine_detail">
      <div className="examine_detail_header">
        {examineList.map((item, index) => {
          return (
            <div key={index} className="examine_detail_header_item">
              <div className="examine_detail_header_item_label">
                {item?.label}：
              </div>
              <div className="examine_detail_header_item_value">
                {renderReportDetails(item)}
              </div>
            </div>
          );
        })}
      </div>
      <div className="examine_detail_tabel">
        <VSPromiseTable
          className="examine_detail_tabel_promise_table"
          pagination={false}
          onFetch={async (params) => {
            return {
              data: reportDetail?.assayReportDtoList,
              total: 0,
            };
          }}
          scroll={{
            y: 465,
          }}
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <VSTableColumn
            valueType="text"
            dataIndex={['id']}
            title=""
            render={(_, __, index) => {
              return index + 1;
            }}
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['assayItemName']}
            title="项目"
            render={(_, data) => {
              return data?.reportItem?.assayItemName;
            }}
          ></VSTableColumn>

          <VSTableColumn
            valueType="text"
            dataIndex={['result']}
            title="结果"
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['range']}
            title="参考范围"
            render={(_, data) => {
              return (
                <div style={{ color: '#3276E8' }}>
                  {data?.reportItem?.isNumber
                    ? `${data?.reportItem?.limitStart}-${data?.reportItem?.limitEnd}`
                    : data?.reportItem?.descr}
                  {/* {data?.isNumber
                    ? data?.reportItem?.limitStart - data?.reportItem?.limitEnd
                    : data?.descr} */}
                </div>
              );
            }}
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['unit']}
            title="单位"
            render={(_, data) => {
              return data?.reportItem?.unit;
            }}
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['abnormalResult']}
            title="异常结果"
            render={(_, data) => {
              return (
                <div style={{ color: '#F54141' }}>
                  {data?.abnormal ? data?.abnormalResult : ''}
                </div>
              );
            }}
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['danger']}
            title="危急值"
          ></VSTableColumn>
          <VSTableColumn
            valueType="text"
            dataIndex={['done']}
            title="操作"
            render={(_, data) => {
              return (
                <Cdss
                  title="智能助手(检测报告)"
                  placement="right"
                  key={data?.id}
                  data={data}
                >
                  <div style={{ cursor: 'pointer', color: '#3276E8' }}>
                    {data?.reportItem?.isNumber ? '分析' : ''}
                  </div>
                </Cdss>
              );
            }}
          ></VSTableColumn>
        </VSPromiseTable>
      </div>
    </div>
  );
};

export default Index;
