import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import classnames from 'classnames';
import { async } from 'q';
import React, { useEffect, useState } from 'react';

import Empty from '@/module/empty';

import Detail from './detail';
import List from './list';
import Search from './search';

const Index = (props) => {
  const { user } = props?.stores || {};

  const { currentPatient } = user || {};

  const [currentRecord, setCurrentRecord] = useState(1);
  const [historyList, setHistoryList] =
    useState<TreatInfoVoMedicalRecordEntranceWebVo[]>();
  const [medicalHistoryDetail, setMedicalHistoryDetail] = useState();
  const [searchList, setSearchList] = useState<any>();

  useEffect(() => {
    medicalHistoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList?.keys, searchList?.departmentNameIs]);

  const medicalHistoryList = async () => {
    const res = await vsf?.services?.TreatInfoController_allTreatInfo_15b3f5?.({
      qto: {
        treatTypeIn: searchList?.keys.length > 0 ? searchList.keys : undefined,
        departmentNameIs: searchList?.departmentNameIs,
        patientIdIs: currentPatient?.patient?.id,
      },
    });
    setHistoryList(res.data);
    if (res.code === 200) {
      if (res?.data?.length > 0) {
        getMedicalHistoryDetail(res?.data?.[0]?.id);
        setCurrentRecord(res?.data?.[0]?.id);
      }
    } else {
      setMedicalHistoryDetail(undefined);
    }
  };

  const getMedicalHistoryDetail = async (id) => {
    if (id) {
      const res =
        await vsf?.services?.DoctorOrderController_getDoctorOrderByTreatId_51f8a8?.(
          { treatId: id },
        );
      if (res.code === 200) {
        setMedicalHistoryDetail(res?.data);
      }
    }
  };

  return (
    <div className="medical-history">
      <Search
        search={(v) => {
          setSearchList(v);
        }}
      />
      <div className="main">
        {!historyList ||
          (historyList?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <List
                value={currentRecord}
                dataSource={historyList}
                onChange={(item) => {
                  setCurrentRecord(item?.id);
                  getMedicalHistoryDetail(item?.id);
                }}
              />
              {historyList && historyList?.length > 0 && (
                <Detail detail={medicalHistoryDetail} />
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default definePage(Index);
