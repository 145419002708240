import { createFromIconfontCN } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import React from 'react';

import { ICON_URL } from '@/config';

const IconFont = createFromIconfontCN({
  scriptUrl: ICON_URL,
});

type IconType = {
  type: string;
  style?: any;
  size?: number;
};

const Index = (props: IconType) => {
  const { type, style = {}, size, ...rest } = props;
  let formatStyle: any = style;
  if (size) {
    formatStyle = {
      ...cloneDeep(style),
      fontSize: size,
    };
  }
  return (
    <IconFont className="vp-icon" type={type} style={formatStyle} {...rest} />
  );
};

export default Index;
