import './index.less';

import classnames from 'classnames';
import React, { useState } from 'react';

type TimelinePropsType = {
  children?: React.ReactNode;
  index?: number;
};

const Index = (props: TimelinePropsType) => {
  const { children, index: activeIndex = -1 } = props;
  const target = Array.isArray(children) ? children : [children];
  const len = target?.length;
  return (
    <div className="aspirin-timeline">
      {target?.map((item, index) => {
        const isActive = index < activeIndex;
        const isHalfActive = index === activeIndex;
        return (
          <div
            className={classnames('item', {
              'active-item': isActive,
              'active-half-item': isHalfActive,
              'only-item': len === 1,
            })}
            key={index}
          >
            <div className="sign"></div>
            <div className="content">{item}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
