import './index.less';

import {
  Checkbox,
  DateRangePicker,
  Radio,
  RadioGroup,
  Select,
  TabPane,
  Tabs,
  Tag,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

const selectOpyion = [
  { label: '全部', value: '全部' },
  { label: '放射', value: '放射' },
  { label: '超声', value: '超声' },
  { label: '功能检查', value: '功能检查' },
  { label: '内镜中心', value: '内镜中心' },
  { label: '核医学', value: '核医学' },
];

const reportTime = [
  { label: '近14天', value: 'week' },
  { label: '近30天', value: 'month' },
  { label: '半年', value: 'year' },
];

const Index = (props) => {
  const { handelConditionQuery } = props;

  const [thisVisit, setThisVisit] = useState<boolean>(false);
  const [isReportValue, setIsReportValue] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string[]>(['全部']);

  // const [checkValue, setCheckValue] = useState(['now', 'finished']);

  useEffect(() => {
    handelConditionQuery({
      isReportValue,
      thisVisit,
      selectValue,
    });
  }, [handelConditionQuery, thisVisit, isReportValue, selectValue]);

  const handleChange = (value) => {
    setSelectValue(value);
  };

  const handleSelect = (e) => {
    if (e?.value === '全部') {
      setSelectValue([e.value]);
    }
    if (selectValue.length === 1 && selectValue?.[0] === '全部') {
      setSelectValue([
        ...cloneDeep(selectValue).filter((item) => item !== '全部'),
        e.value,
      ]);
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="checked_search">
      <div style={{ display: 'flex', flexShrink: 0 }}>
        <Select
          width={106}
          height={32}
          placeholder="报告时间"
          dataSource={reportTime}
          style={{ marginRight: '6px' }}
        />
        <DateRangePicker
          width={224}
          height={32}
          placeholder={['开始时间', '结束时间']}
          style={{ marginRight: '24px' }}
        />
      </div>
      <div style={{ flexShrink: 0, marginRight: '28px' }}>
        <Checkbox
          onChange={(v) => {
            setThisVisit(v as boolean);
          }}
          value={thisVisit}
        >
          本次就诊
        </Checkbox>
        <Checkbox
          onChange={(v) => {
            setIsReportValue(v as boolean);
          }}
          value={isReportValue}
        >
          已出报告
        </Checkbox>
      </div>
      <div
        style={{
          flexShrink: 0,
          marginRight: '20px',
          color: '#343B42',
          fontSize: '18px',
        }}
      >
        检查类型
      </div>
      <Select
        mode="multiple"
        tagRender={tagRender}
        allowClear
        style={{ width: '100%' }}
        placeholder="请选择报告类型"
        value={selectValue}
        onChange={handleChange}
        onSelect={(e) => {
          handleSelect(e);
        }}
        dataSource={selectOpyion}
      />
    </div>
  );
};

export default Index;
