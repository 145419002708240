import vsf from '@vs/vsf-boot';
import React, { useCallback, useEffect, useState } from 'react';

import TreeMenu from '@/module/TreeMenu';

const Index = (props) => {
  const { onChange } = props;
  const [treeData, setTreeData] = useState([]);

  const [expandedKeys, setExpandedKeys] = useState<any[]>();

  const formatData = (data) => {
    const keys: any[] = [];
    const target = data?.map((item) => ({
      ...item,
      key: item?.id,
      medicalRecordTemplateDtoList: (
        item?.medicalRecordTemplateDtoList || []
      )?.map((citem) => {
        keys.push(item?.id);
        return {
          ...citem,
          key: `${item?.id}-${citem.id}`,
        };
      }),
    }));
    setExpandedKeys([...new Set(keys)]);
    return target;
  };

  const getData = useCallback(async () => {
    const res =
      await vsf.services.TemplateCategoryController_allTemplateCategory_5f0fcc(
        {},
      );
    setTreeData(formatData(res?.data || []));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <TreeMenu
      id="StandardApplicationTree"
      fieldNames={{
        title: 'templateName',
        key: 'key',
        children: 'medicalRecordTemplateDtoList',
      }}
      treeData={treeData}
      treeProps={{
        expandedKeys: expandedKeys,
        onExpand: (expandedKeys) => {
          console.log(expandedKeys);
        },
      }}
      isTree
      search={{
        status: true,
        searchPlaceholder: '可用首字母或名称搜索',
      }}
      row={{
        expand: true,
      }}
      onSelect={(_value) => {
        _value?.templateCategoryId && onChange?.(_value);
      }}
    />
  );
};

export default Index;
