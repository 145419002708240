import { EditorMode } from '../../../dataset/enum/Editor';
import { DeepRequired } from '../../../interface/Common';
import { IEditorOption } from '../../../interface/Editor';
import { IRowElement } from '../../../interface/Row';
import { Draw } from '../Draw';

export class CheckboxParticle {
  private options: DeepRequired<IEditorOption>;

  constructor(draw: Draw) {
    this.options = draw.getOptions();
  }

  public render(
    ctx: CanvasRenderingContext2D,
    element: IRowElement,
    x: number,
    y: number,
    mode: EditorMode,
  ) {
    const {
      checkbox: { gap, lineWidth, fillStyle, fontStyle },
      scale,
    } = this.options;
    const { metrics, checkbox, markRequired } = element;
    // left top 四舍五入避免1像素问题
    const left = Math.round(x + gap);
    const top = Math.round(y - metrics.height + lineWidth);
    const width = metrics.width - gap * 2 * scale;
    const height = metrics.height;
    ctx.save();
    ctx.beginPath();
    ctx.translate(0.5, 0.5);
    // 绘制勾选状态
    if (checkbox?.value) {
      // 边框
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = fillStyle;
      ctx.rect(left, top, width, height);
      ctx.stroke();
      // 背景色
      ctx.beginPath();
      ctx.fillStyle = fillStyle;
      ctx.fillRect(left, top, width, height);
      // 勾选对号
      ctx.beginPath();
      ctx.strokeStyle = fontStyle;
      ctx.lineWidth = lineWidth * 2;
      ctx.moveTo(left + 2 * scale, top + 7 * scale);
      ctx.lineTo(left + 7 * scale, top + 11 * scale);
      ctx.moveTo(left + 6.5 * scale, top + 11 * scale);
      ctx.lineTo(left + 12 * scale, top + 3 * scale);
      ctx.stroke();
    } else {
      if (markRequired && mode === EditorMode.EDIT) {
        const oldColor = ctx.fillStyle;
        ctx.fillStyle = this.options.markRequiredColor;
        ctx.fillRect(left, top, width, height);
        ctx.fillStyle = oldColor;
      }
      ctx.lineWidth = lineWidth;
      ctx.rect(left, top, width, height);
      ctx.stroke();
    }
    ctx.closePath();
    ctx.restore();
  }
}
