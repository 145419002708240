import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'
import {  useRef, useState } from "react";
import { debounce } from "lodash";
import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import Icon from '@/module/Icon'

import './index.less'

const IntelligentDiagnosis = props => {

  const { open, intelligentDiagnosisTableValue = [] } = props

  const [selectList, setSelectList] = useState<any>([])

  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">智能助手</div>
      </div>
    )
  }

  const handelSearch = debounce(async v => {
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.DiagnosisDictionaryController_getAllByDiagnosisDictionaryQto_31d06d?.({
      // TODO: 请检查以下这部分传参是否符合需求
      qto: {
        diagnosisNameLike: v
      }
    });
    const data = res?.data?.map(item => ({
      label: item?.diagnosisName,
      value: item?.id,
      diagnosisCode: item?.diagnosisCode,
      diagnosisName: item?.diagnosisName
    })) ?? [];
    setSelectList(data)
    return data
  }, 500)

  const transform = (value) => {
    return value?.map(item => ({
      ...item,
      diagnosisName: selectList?.find(_v => _v.value === item?.diagnosisName)?.diagnosisName,
      diagnosisNameId: item?.diagnosisName
    }))
  }

  const handelSubmit = async () => {
    props?.onOk(await vsf.refs?.IntelligentDiagnosisTable?.getValues())
  }

  const AIIntelligent = [
    {
      diagnosisName: '不良孕产个人史',
      diagnosisType: '门诊诊断',
      diagnosisCode: 'Z87.500x005',
      createName: '管理员',
      createAt: '2023-11-20'
    },
    {
      diagnosisName: '不孕症',
      diagnosisType: '门诊诊断',
      diagnosisCode: 'N97.900',
      createName: '管理员',
      createAt: '2023-11-20'
    },
  ]

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="intelligent_diagnosis_wapper"
      width={1012}
      bodyStyle={{
        height: 559,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.IntelligentDiagnosisTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.IntelligentDiagnosisTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="IntelligentDiagnosisTable"
        defaultValue={[
          ...AIIntelligent,
          ...(intelligentDiagnosisTableValue ?? [])
        ]}
        editable={{
          editType: 'single',
          columnProps: {
            width: 80,
            hideInTable: true
          },
          onCanEdit: () => false,
          deleteText: <Icon type="icon-shanchuwudi" size={18} />,
          onFieldChange: (key, value: any, record, form) => {
            if (value?.diagnosisName) {
              form.setFieldValue(key, {
                ...record,
                diagnosisName: selectList?.find(item => item.value === value?.diagnosisName)?.label,
                diagnosisId: value?.diagnosisName,
                diagnosisCode: selectList?.find(item => item.value === value?.diagnosisName)?.diagnosisCode
              })
            }
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={240}
          title='诊断'
          dataIndex={['diagnosisName']}
          valueType='select'
          dataSource={selectList}
          fieldProps={{
            showSearch: true,
            onSearch: handelSearch
          }}
        />
        <VSTableColumn
          hideInTable
          dataIndex={['diagnosisId']}
          valueType='text'
        />
        <VSTableColumn
          width={130}
          title='诊断类型'
          initialValue={"门诊诊断"}
          dataIndex={['diagnosisType']}
          valueType='select'
          dataSource={[{
            label: '门诊诊断',
            value: '门诊诊断'
          }]}
          fieldProps={{
            showSearch: true
          }}
          preview
        />
        <VSTableColumn
          width={140}
          title='诊断编码'
          dataIndex={['diagnosisCode']}
          valueType='text'
          preview
        />
        <VSTableColumn
          width={120}
          title='创建人'
          dataIndex={['createName']}
          valueType='text'
        />
        <VSTableColumn
          width={210}
          initialValue={Date.now()}
          title='创建时间'
          dataIndex={['createAt']}
          valueType='date'
          preview
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(IntelligentDiagnosis)