import './index.less';

import vsf from '@vs/vsf-boot';
import classnames from 'classnames';
import { async } from 'q';
import React, { useEffect, useState } from 'react';

import List from './list';
import Search from './search';

const mock = [
  {
    label: '三个月',
    value: 3,
  },
  {
    label: '半年',
    value: 6,
  },
  {
    label: '一年',
    value: 12,
  },
  {
    label: '全部',
    value: -1,
  },
];

const Index = (props) => {
  const { onSelect } = props;
  const [searchValue, setSearchValue] = useState('210病区');
  const [typeValue, setTypeValue] = useState('在科');
  const [bedCardList, setBedCardList] = useState();

  useEffect(() => {
    getInpatientBedCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const getInpatientBedCardList = async () => {
    const res =
      await vsf?.services?.HospitalPatientController_allHospitalPatient_9febc7?.(
        {
          qto: {
            wardNameIs: searchValue,
          },
        },
      );
    if (res.code === 200) {
      setBedCardList(res?.data);
    }
  };
  const handelOnchange = (value) => {
    setSearchValue(value.selectValue);
    setTypeValue(value?.typeValue);
  };

  return (
    <div className="Inpatient_bed_card">
      <div className="Inpatient_bed_card_search">
        <Search handelOnchange={handelOnchange} />
      </div>
      <div className="Inpatient_bed_card_list">
        <List typeValue={typeValue} list={bedCardList} onChange={onSelect} />
      </div>
    </div>
  );
};

export default Index;
