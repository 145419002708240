import './signature.css';

import { EDITOR_COMPONENT, EditorComponent } from '../../editor';
import { Canvas } from '../Canvas/Canvas';

export interface ISignatureResult {
  value: string;
  width: number;
  height: number;
}

export interface ISignatureOptions {
  width?: number;
  height?: number;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: (payload: ISignatureResult | null) => void;
}

export class Signature {
  private readonly DEFAULT_WIDTH = 390;
  private readonly DEFAULT_HEIGHT = 180;
  private canvasWidth: number;
  private canvasHeight: number;
  private options: ISignatureOptions;
  private mask: HTMLDivElement;
  private container: HTMLDivElement;
  private canvas: Canvas;
  private root: HTMLElement;

  constructor(options: ISignatureOptions, root: HTMLElement) {
    this.root = root;
    this.options = options;
    this.canvasWidth = options.width || this.DEFAULT_WIDTH;
    this.canvasHeight = options.height || this.DEFAULT_HEIGHT;
    const { mask, container, canvas } = this._render();
    this.mask = mask;
    this.container = container;
    this.canvas = canvas;
  }

  private _render() {
    const { onClose, onCancel, onConfirm } = this.options;
    // 渲染遮罩层
    const mask = document.createElement('div');
    mask.classList.add('signature-mask');
    mask.setAttribute(EDITOR_COMPONENT, EditorComponent.COMPONENT);
    this.root.append(mask);
    // 渲染容器
    const container = document.createElement('div');
    container.classList.add('signature-container');
    container.setAttribute(EDITOR_COMPONENT, EditorComponent.COMPONENT);
    // 弹窗
    const signatureContainer = document.createElement('div');
    signatureContainer.classList.add('signature');
    container.append(signatureContainer);
    // 标题容器
    const titleContainer = document.createElement('div');
    titleContainer.classList.add('signature-title');
    // 标题&关闭按钮
    const titleSpan = document.createElement('span');
    titleSpan.append(document.createTextNode('手写'));
    const titleClose = document.createElement('i');
    titleClose.onclick = () => {
      if (onClose) {
        onClose();
      }
      this._dispose();
    };
    titleContainer.append(titleSpan);
    titleContainer.append(titleClose);
    signatureContainer.append(titleContainer);
    // 画布
    const canvas = new Canvas({
      width: this.canvasWidth,
      height: this.canvasHeight,
    });
    signatureContainer.append(canvas.dom);
    // 按钮容器
    const menuContainer = document.createElement('div');
    menuContainer.classList.add('signature-menu');
    // 取消按钮
    const cancelBtn = document.createElement('button');
    cancelBtn.classList.add('signature-menu__cancel');
    cancelBtn.append(document.createTextNode('取消'));
    cancelBtn.type = 'default';
    cancelBtn.onclick = () => {
      if (onCancel) {
        onCancel();
      }
      this._dispose();
    };
    menuContainer.append(cancelBtn);
    // 确认按钮
    const confirmBtn = document.createElement('button');
    confirmBtn.append(document.createTextNode('确定'));
    confirmBtn.type = 'primary';
    confirmBtn.onclick = () => {
      if (onConfirm) {
        onConfirm(this.canvas.toData());
      }
      this._dispose();
    };
    menuContainer.append(confirmBtn);
    signatureContainer.append(menuContainer);
    // 渲染
    this.root.append(container);
    this.container = container;
    this.mask = mask;
    return {
      mask,
      canvas,
      container,
    };
  }

  private _dispose() {
    this.mask.remove();
    this.container.remove();
  }
}
