import './index.less';

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { definePage } from '@vs/vsf-boot';
import { Dropdown, Image, Input } from '@vs/vsf-kit';
import React, { useEffect } from 'react';

import Avatar from '@/assets/avatar.jpeg';
import Logo from '@/assets/logo.png';
import { getPrefixCls, hospital_name } from '@/config';
import Icon from '@/module/Icon';

const items: any[] = [
  {
    label: '门诊工作站',
    key: 'out',
  },
  {
    label: '住院工作站',
    key: 'in',
  },
];

const Index = (props: any) => {
  const { user } = props?.stores || {};
  const { hospitalType, setHospitalType, setCurrentPatient, setTreatInfo } =
    user || {};
  const header = getPrefixCls('header-main');

  const renderSmallBefore = () => {
    return (
      <div className="small-search-before">
        <Icon type="icon-a-Group203" />
        <span className="label">智能助手 :</span>
      </div>
    );
  };

  const onClick = ({ key }) => {
    setHospitalType(key);
  };

  useEffect(() => {
    setCurrentPatient({});
    setTreatInfo({});
  }, [hospitalType, setCurrentPatient, setTreatInfo]);

  return (
    <div className={header}>
      <div className="left">
        <Image className="logo" src={Logo} width={50} preview={false} />
        <div className="title">{hospital_name}</div>
        <div className="detail">
          ({`生殖科-${hospitalType === 'in' ? '住院' : '门诊'}(本部)-接诊`})
        </div>
      </div>
      <div className="right">
        <div className="small-search">
          <Input
            width={400}
            addonBefore={renderSmallBefore()}
            addonAfter={<Icon type="icon-a-Voiceluyin" />}
          />
        </div>
        <div className="department">科室: 生殖科</div>
        <div className="avatar">
          <Image src={Avatar} preview={false} />
        </div>
        <Dropdown menu={{ items: [] }}>
          <div className="role dropdown-container">
            ADMIN 超级管理员
            <DownOutlined />
          </div>
        </Dropdown>
        <Dropdown menu={{ items, onClick }}>
          <div className="console dropdown-container">
            切换工作站
            <DownOutlined />
          </div>
        </Dropdown>

        <div className="header-icon">
          <Icon type="icon-lingdang" size={26} />
        </div>
        <div className="header-icon">
          <Icon type="icon-bangzhu" size={26} />
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
