import { ZERO } from '../../../dataset/constant/Common';
import { LETTER_REG, NUMBER_LIKE_REG } from '../../../dataset/constant/Regular';
import { ControlType } from '../../../dataset/enum/Control';
import { EditorMode } from '../../../dataset/enum/Editor';
import { CanvasEvent } from '../CanvasEvent';

function dblclick(host: CanvasEvent) {
  const draw = host.getDraw();
  const position = draw.getPosition();
  const cursorPosition = position.getCursorPosition();
  if (!cursorPosition) return;
  const { value, index } = cursorPosition;
  const elementList = draw.getElementList();
  const control = elementList[index]?.control;
  if (control) {
    const listener = draw.getListener();
    const mode = draw.getMode();
    let canEdit = false;
    if (mode === EditorMode.TEMPLATE) {
      canEdit = true;
    } else if (mode === EditorMode.EDIT) {
      canEdit = control.type === ControlType.IMAGE;
    }
    if (canEdit && listener.controlDoubleClick) {
      const activeControl = draw.getControl().getActiveControl();
      listener.controlDoubleClick(
        {
          ...control,
          value: activeControl?.getValue() || null,
        },
        mode,
      );
    }
    return;
  }
  // 判断是否是数字或英文
  let upCount = 0;
  let downCount = 0;
  const isNumber = NUMBER_LIKE_REG.test(value);
  if (isNumber || LETTER_REG.test(value)) {
    // 向上查询
    let upStartIndex = index - 1;
    while (upStartIndex > 0) {
      const value = elementList[upStartIndex].value;
      if (
        (isNumber && NUMBER_LIKE_REG.test(value)) ||
        (!isNumber && LETTER_REG.test(value))
      ) {
        upCount++;
        upStartIndex--;
      } else {
        break;
      }
    }
    // 向下查询
    let downStartIndex = index + 1;
    while (downStartIndex < elementList.length) {
      const value = elementList[downStartIndex].value;
      if (
        (isNumber && NUMBER_LIKE_REG.test(value)) ||
        (!isNumber && LETTER_REG.test(value))
      ) {
        downCount++;
        downStartIndex++;
      } else {
        break;
      }
    }
  }
  // 设置选中区域
  const rangeManager = draw.getRange();
  rangeManager.setRange(index - upCount - 1, index + downCount);
  // 刷新文档
  draw.render({
    isSubmitHistory: false,
    isSetCursor: false,
    isCompute: false,
  });
}

function threeClick(host: CanvasEvent) {
  const draw = host.getDraw();
  const position = draw.getPosition();
  const cursorPosition = position.getCursorPosition();
  if (!cursorPosition) return;
  const { index } = cursorPosition;
  const elementList = draw.getElementList();
  // 判断是否是零宽字符
  let upCount = 0;
  let downCount = 0;
  // 向上查询
  let upStartIndex = index - 1;
  while (upStartIndex > 0) {
    const value = elementList[upStartIndex].value;
    if (value !== ZERO) {
      upCount++;
      upStartIndex--;
    } else {
      break;
    }
  }
  // 向下查询
  let downStartIndex = index + 1;
  while (downStartIndex < elementList.length) {
    const value = elementList[downStartIndex].value;
    if (value !== ZERO) {
      downCount++;
      downStartIndex++;
    } else {
      break;
    }
  }
  // 设置选中区域
  const rangeManager = draw.getRange();
  rangeManager.setRange(index - upCount - 1, index + downCount);
  // 刷新文档
  draw.render({
    isSubmitHistory: false,
    isSetCursor: false,
    isCompute: false,
  });
}

export default {
  dblclick,
  threeClick,
};
