import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';

const labelWidth = 95;

const FemaleRecordTwo = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};
  return (
    <VSForm
      labelAlign="left"
      // vsid="11980"
      id="femaleRecordTwo"
      onFetch={async () => {
        const res =
          await vsf?.services?.FemaleRecordTwoController_getFemaleRecordTwoByPatientId_03bf6e?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
      // onSubmit={async (params) => {
      //   const res =
      //     await vsf?.services?.FemaleRecordTwoController_createFemaleRecordTwo_84d02b?.(
      //       { btoParam: params },
      //     );
      //   return params; // 直接返回提交的数据
      // }}
    >
      <FormBlock title="血型">
        <VSFormLayout key="0" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['bloodType']}
            label="血型 "
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.xx}
            fieldProps={{}}
          />

          <VSFormItem
            name={['phFactor']}
            label=" PH因子"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.rh}
            fieldProps={{}}
          />

          <VSFormItem
            name={['chromosome']}
            label="染色体"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="血常规">
        <VSFormLayout key="1" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['bloodRoutineOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hemoglobin']}
            label="血红蛋白"
            valueType="text"
            addonAfter="g/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['redBloodCell']}
            label="红细胞"
            valueType="text"
            addonAfter="x10^12/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['whiteBloodCell']}
            label="白细胞"
            valueType="text"
            addonAfter="x10^9/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['xueCheng']}
            label="血沉"
            valueType="text"
            addonAfter="mm/H"
            fieldProps={{}}
          />
          <VSFormItem
            name={['hematocrit']}
            label="红细胞压积"
            valueType="text"
            addonAfter="%"
            fieldProps={{}}
          />
          <VSFormItem
            name={['bloodPlatelet']}
            label="血小板"
            addonAfter="x10^9/L"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="凝血">
        <VSFormLayout key="3" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['xueNingOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['pt']}
            label="PT"
            valueType="text"
            addonAfter="秒"
            fieldProps={{}}
          />
          <VSFormItem
            name={['aptt']}
            label="APTT"
            valueType="text"
            addonAfter="秒"
            fieldProps={{}}
          />
          <VSFormItem
            name={['fibrinogen']}
            label="纤维蛋白原"
            valueType="text"
            addonAfter="g/L"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="生化">
        <VSFormLayout key="5" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['biochemistryOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="6" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['alanineTransaminase']}
            label="谷丙转氨酶"
            valueType="text"
            addonAfter="U/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['glutamicOxalaceticTransaminase']}
            label="谷草转氨酶"
            addonAfter="U/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['totalProtein']}
            label="总蛋白"
            addonAfter="g/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['ricim']}
            label="白蛋白"
            addonAfter="g/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['bloodHomocysteine']}
            label="血同型半胱氨酸"
            valueType="text"
            addonAfter="μmol/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['urea']}
            label="尿素"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['creatinine']}
            label="肌酰"
            addonAfter="μmol/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['triglyceride']}
            label="甘油三酯"
            addonAfter="mmol/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['totalCholesterol']}
            label="总胆固醇"
            addonAfter="mmol/L"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['highDensityLipoprotein']}
            label="高密度脂蛋白"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['lowDensityLipoprotein']}
            label="低密度脂蛋白"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="7" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['potassium']}
            label="钾"
            valueType="text"
            fieldProps={{}}
            addonAfter="mmol/L"
          />
          <VSFormItem
            name={['sodium']}
            label="钠"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />

          <VSFormItem
            name={['nitrogen']}
            label="氮"
            addonAfter="mmol/L"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['glucose']}
            label="葡萄糖"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />

          <VSFormItem
            name={['hypersensitiveInsulin']}
            label="超敏胰岛素"
            valueType="text"
            addonAfter="mmol/L"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="甲状腺">
        <VSFormLayout key="8" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['thyroidOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['tsh']}
            label="TSH "
            valueType="text"
            addonAfter="mIU/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['ft3']}
            label="FT3"
            valueType="text"
            addonAfter="pmol/L"
            fieldProps={{}}
          />

          <VSFormItem
            name={['ft4']}
            label="FT4"
            valueType="text"
            addonAfter="pmol/L"
            fieldProps={{}}
          />

          <VSFormItem
            name={['antiTg']}
            label="Anti-tg"
            addonAfter="IU/mL"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['antiTpo']}
            label="Anti-tpo"
            valueType="text"
            addonAfter="IU/mL"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="TORCH">
        <VSFormLayout key="10" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['torchOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['toxotypusLgg']}
            label="弓型虫IgG "
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />
          <VSFormItem
            name={['toxotypusLgm']}
            label="弓型虫IgM  "
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />

          <VSFormItem
            name={['cytomegalovirusLgg']}
            label="巨细胞病毒IgG"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />

          <VSFormItem
            name={['cytomegalovirusLgm']}
            label="巨细胞病毒IgM"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />
          <VSFormItem
            name={['rubellaVirusLgg']}
            label="风疹病毒IgG "
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />

          <VSFormItem
            name={['rubellaVirusLgm']}
            label="风疹病毒IgM "
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.lgc}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="乙肝">
        <VSFormLayout key="12" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hepatitisBOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="12" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hbsag']}
            label="HBSAg"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.HBSAg}
            fieldProps={{}}
          />

          <VSFormItem
            name={['hbsab']}
            label="HBsAb"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.HBSAg}
            fieldProps={{}}
          />

          <VSFormItem
            name={['hbcag']}
            label="HBcAg"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.HBSAg}
            fieldProps={{}}
          />
          <VSFormItem
            name={['hbcab']}
            label="HBcAb"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.HBSAg}
            fieldProps={{}}
          />

          <VSFormItem
            name={['hbeab']}
            label="HBeAb"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.HBSAg}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="肝炎系列">
        <VSFormLayout key="14" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hepatitisSeriesOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="15" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hepatitisALgm']}
            label="甲型肝炎IgM"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.blgm}
            fieldProps={{}}
          />

          <VSFormItem
            name={['hepatitisCLgg']}
            label="丙型肝炎IgG"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.blgm}
            fieldProps={{}}
          />

          <VSFormItem
            name={['hepatitisELgm']}
            label="戊型肝炎IgM"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.blgm}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="STD">
        <VSFormLayout key="14" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['stdOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="15" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['stdHivab']}
            label="STDHIVAb"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.hivab}
            fieldProps={{}}
          />
          <VSFormItem
            name={['syphilisTpab']}
            label="梅毒TPAB"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.hivab}
            fieldProps={{}}
          />
          <VSFormItem
            name={['trust']}
            label="TRUST"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.hivab}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="基础内分泌">
        <VSFormLayout key="16" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['basalEndocrineHivab']}
            label="HIVAb"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['basalEndocrineOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="17" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['fsh']}
            label="FSH"
            valueType="text"
            addonAfter="IU/L"
            fieldProps={{}}
          />
          <VSFormItem
            name={['e2']}
            label="E2"
            valueType="text"
            addonAfter="pmol/L"
            fieldProps={{}}
          />

          <VSFormItem
            addonAfter="nmo/L"
            name={['p']}
            label="P"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['lh']}
            label="LH"
            valueType="text"
            addonAfter="IU/L"
            fieldProps={{}}
          />

          <VSFormItem
            name={['prl']}
            label="PRL"
            valueType="text"
            addonAfter="ng/ml"
            fieldProps={{}}
          />
          <VSFormItem
            name={['t']}
            addonAfter="nmol/L"
            label="T"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['amh']}
            label="AMH"
            valueType="text"
            addonAfter="ng/mL"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="肿瘤标志物">
        <VSFormLayout key="18" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['tumorMarkerOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="19" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['cea']}
            label="CEA"
            valueType="text"
            fieldProps={{}}
            addonAfter="ng/mL"
          />
          <VSFormItem
            name={['ca125']}
            label="CA125"
            valueType="text"
            addonAfter="U/mL"
            fieldProps={{}}
          />
          <VSFormItem
            name={['afp']}
            label="AFP"
            valueType="text"
            addonAfter="ng/mL"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="宫颈分泌物">
        <VSFormLayout key="20" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['cervicalSecretionOutsideHospitalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{
              width: 278,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="21" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['herpesvirusHominis']}
            label="单纯疱疹病毒"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.danbingdu}
            fieldProps={{}}
          />

          <VSFormItem
            name={['gonococcusDna']}
            label="淋球菌DNA"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.danbingdu}
            fieldProps={{}}
          />

          <VSFormItem
            name={['chlamydiaDna']}
            label="衣原体DNA"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.danbingdu}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="宫颈癌筛查">
        <VSFormLayout key="22" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['hpv']}
            label="宫颈癌筛查HPV"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['liquidBasedCytology']}
            label="液基细胞学"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="心电图">
        <VSFormLayout key="23" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['electrocardiograph']}
            label="心电图"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="胸片">
        <VSFormLayout key="24" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['chestRadiography']}
            label="胸片"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="盆腔B超">
        <VSFormLayout key="25" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['pelvicUltrasound']}
            label="盆腔B超"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="乳腺B超">
        <VSFormLayout key="26" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['breastBUltrasound']}
            label="乳腺B超"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="子宫造影">
        <VSFormLayout key="27" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['hysterographic']}
            label="子宫造影HSG"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="活检状况">
        <VSFormLayout key="28" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['endometrialBiopsy']}
            label="子宫内膜活检"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['uteroscope']}
            label="宫腔镜"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['laparoscope']}
            label="腹腔镜"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['ovarianCystPuncture']}
            label="卵巢囊肿穿刺"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="输卵管积水">
        <VSFormLayout key="29" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['hydrosalpinx']}
            label="输卵管积水"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="新冠">
        <VSFormLayout key="30" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['nucleicAcid']}
            label="核酸"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['antibody']}
            label="抗体"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="注射GnRH-a/Gn前超声">
        <VSFormLayout key="31" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['leftAfcNum']}
            label="左侧AFC个数"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['rightAfcNum']}
            label="右侧AFC个数"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['intimalThickness']}
            label="内膜厚度"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="32" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['remark']}
            label="备注"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.femaleRecordTwo?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.FemaleRecordTwoController_createFemaleRecordTwo_84d02b?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(FemaleRecordTwo);
