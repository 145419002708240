import vsf from '@vs/vsf-boot';
import { Button, Dropdown, Input, Popover } from '@vs/vsf-kit';
import React, { useState } from 'react';

import Icon from '@/module/Icon';

import Content from './content';

const Index = (props) => {
  const { onChange } = props;
  const { user } = vsf?.stores || {};
  const { patientList } = user || {};

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = async (v) => {
    // vsf?.stores?.user?.setCurrentPatient(v);
    const res =
      await vsf?.services?.MedicalRecordTemplateController_getMedicalRecordTemplate_dd80b0?.(
        { medicalRecordTemplate: v?.id },
      );
    onChange?.(res?.data);
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      menu={{
        items: [],
      }}
      onOpenChange={(v) => {
        setDropdownOpen(v);
      }}
      dropdownRender={(menu) => <Content onChange={handleChange} />}
      open={dropdownOpen}
    >
      <div className="medical-dropdown">
        <span>病历模板</span>
        {dropdownOpen ? <Icon type="icon-xia" /> : <Icon type="icon-shang" />}
      </div>
    </Dropdown>
  );
};

export default Index;
