import { definePage } from '@vs/vsf-boot';
import {
  Image,
  RadioGroup,
  Tag,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';

import Icon1 from '@/assets/Slice 12@2x.png';
import Icon2 from '@/assets/Slice 13@2x.png';
import Icon3 from '@/assets/Slice 14@2x.png';
import Icon4 from '@/assets/Slice 15@2x.png';
import Block from '@/module/Block';
import Icon from '@/module/Icon';

const mock1 = Array.from({ length: 4 }).fill({
  label: '预约到诊率',
  value: '80%',
});

const mock2 = Array.from({ length: 4 }).fill({
  label: '平均候诊时间',
  value: '6.5min',
});

const mock3 = Array.from({ length: 4 }).fill({
  label: '平均诊疗时间',
  value: '5.5min',
});

const mock4 = [
  {
    label: '下转患者人次数 (门急诊、住院 )',
    value: '132',
  },
  {
    label: '门诊人次数与出院人次数比',
    value: '5/1',
  },
  {
    label: '抗菌药物使用强度 (DDDs)',
    value: '40.8%',
  },
  {
    label: '集中采购中标药品使用比例',
    value: '60%',
  },
];

const mock5 = [
  {
    label: '门诊患者平均预约诊疗率',
    value: '80%',
  },
  {
    label: '出院患者手术占比：',
    value: '75%',
  },
  {
    label: '门诊患者基本药物处方占比',
    value: '70%',
  },
  {
    label: '日间手术占择期手术比例',
    value: '90%',
  },
];
const mock6 = [
  {
    label: '门诊患者预约后平均等待时间',
    value: '5.6min',
  },
  {
    label: '低风险组病例死亡率',
    value: '0.02%',
  },
  {
    label: '住院患者基本药物使用率',
    value: '80%',
  },
  {
    label: '出院患者微创手术占比',
    value: '40%',
  },
];
const mockChart = [
  { value: 1048, name: '预约接诊' },
  { value: 735, name: '预约人数' },
  { value: 484, name: '挂号接诊' },
  { value: 580, name: '挂号人数' },
  { value: 300, name: '云已接诊' },
  { value: 300, name: '云门诊' },
];

const mock = [
  {
    label: '全部',
    value: '全部',
  },
  {
    label: '手术',
    value: '手术',
  },
  {
    label: '会诊',
    value: '会诊',
  },
];

const mock7 = [
  {
    number: '18床',
    name: '任勇',
    gender: '男',
    age: '45岁',
    tag: ['病危', '手术'],
  },
  {
    number: '32床',
    name: '张雪',
    gender: '女',
    age: '38岁',
    tag: ['出院'],
  },
  {
    number: '06床',
    name: '李耀',
    gender: '男',
    age: '24岁',
    tag: ['病重', '手术'],
  },
  {
    number: '11床',
    name: '张也',
    gender: '男',
    age: '42岁',
    tag: ['新入'],
  },
  {
    number: '52床',
    name: '王雪花',
    gender: '女',
    age: '52岁',
    tag: ['手术'],
  },
  {
    number: '39床',
    name: '李一',
    gender: '男',
    age: '27岁',
    tag: ['病危', '手术'],
  },
  {
    number: '30床',
    name: '张亚男',
    gender: '女',
    age: '28岁',
    tag: ['转入'],
  },
  {
    number: '16床',
    name: '刘敏',
    gender: '女',
    age: '35岁',
    tag: ['手术'],
  },
];

const total = mockChart.reduce((total, item) => total + item.value, 0);

const Index = (props) => {
  const { user } = props?.stores || {};
  const { hospitalType } = user || {};
  const [radioValue, setRadioValue] = useState<string>('全部');

  const onChange = (v) => {
    setRadioValue(v);
  };

  useEffect(() => {
    const chartDom = document.getElementById('people-number-chart');
    if (chartDom) {
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        color: [
          '#89D676',
          ' #CFEAC9',
          '#7DAAF8',
          '#BED1F3',
          '#EFB492',
          '#F3D5C4',
        ],
        legend: [
          {
            orient: 'vertical',
            bottom: '65%',
            right: '34%',
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false,
            width: '50%',
            textStyle: {
              color: '#5D6268',
              fontSize: '18px',
              fontWeight: '200',
              width: 200,
            },
            data: ['预约人数', '预约接诊'],
            // data: ['挂号接诊', '预约人数'],
            itemWidth: 20,
            formatter: function (name) {
              //通过name获取到数组对象中的单个对象
              const singleData = mockChart.filter(function (item) {
                return item.name == name;
              });
              return (
                name +
                ' : ' +
                singleData[0].value +
                '(' +
                ((singleData[0].value / total) * 100).toFixed(1) +
                ')'
              );
            },
          },
          {
            orient: 'vertical',
            bottom: '65%',
            right: '5%',
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false,
            width: '50%',
            textStyle: {
              color: '#5D6268',
              fontSize: '18px',
              fontWeight: '200',
              width: 200,
            },
            data: ['挂号人数', '挂号接诊'],
            itemWidth: 20,
            formatter: function (name) {
              //通过name获取到数组对象中的单个对象
              const singleData = mockChart.filter(function (item) {
                return item.name == name;
              });
              return (
                name +
                ' : ' +
                singleData[0].value +
                '(' +
                ((singleData[0].value / total) * 100).toFixed(1) +
                ')'
              );
            },
          },
          {
            orient: 'vertical',
            bottom: '37%',
            right: '36%',
            // doesn't perfectly work with our tricks, disable it
            selectedMode: false,
            width: '50%',
            textStyle: {
              color: '#5D6268',
              fontSize: '18px',
              fontWeight: '200',
              width: 200,
            },
            data: ['云门诊', '云已接诊'],
            itemWidth: 20,
            formatter: function (name) {
              //通过name获取到数组对象中的单个对象
              const singleData = mockChart.filter(function (item) {
                return item.name == name;
              });
              return (
                name +
                ' : ' +
                singleData[0].value +
                '(' +
                ((singleData[0].value / total) * 100).toFixed(1) +
                ')'
              );
            },
          },
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '90%'],
            center: ['20%', '60%'],
            // adjust the start angle
            startAngle: 180,
            label: {
              show: false,
              formatter(param) {
                // correct the percentage
                return param.name + ' (' + param.percent * 2 + '%)';
              },
            },
            data: [
              ...mockChart,
              {
                // make an record to fill the bottom 50%
                value: total,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: 'none',
                  decal: {
                    symbol: 'none',
                  },
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }, [hospitalType]);

  return (
    <div className="left-container">
      <div className="header">
        <div className="left">
          <div className="avatar">
            <Icon type="icon-Frame2" size={69} />
          </div>
          <div className="info">
            <div className="first">下午好，吴自力</div>
            <div className="second">又是准时上班的一天，棒棒哒！</div>
          </div>
        </div>
        <div className="extra">
          <div className="item">
            <Image src={Icon1} preview={false} />
            <div className="word">
              <span className="label">门诊</span>
              <span className="value">80</span>
            </div>
          </div>
          <div className="item">
            <Image src={Icon2} preview={false} />
            <div className="word">
              <span className="label">云门诊</span>
              <span className="value">52</span>
            </div>
          </div>
          <div className="item">
            <Image src={Icon3} preview={false} />
            <div className="word">
              <span className="label">住院</span>
              <span className="value">40</span>
            </div>
          </div>
          <div className="item">
            <Image src={Icon4} preview={false} />
            <div className="word">
              <span className="label">预住院</span>
              <span className="value">32</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="login-ab-bg"></div>
        {hospitalType === 'out' ? (
          <>
            {/* <div className="first"> */}
            <Block
              title="国考指标"
              style={{
                height: 'auto',
              }}
            >
              <div className="first-in">
                <div>
                  {mock4?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
                <div>
                  {mock5?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
                <div>
                  {mock6?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Block>
            {/* </div> */}
            <div className="second" id="people-number-chart"></div>
          </>
        ) : (
          <>
            <Block
              title="国考指标"
              style={{
                height: 'auto',
              }}
            >
              <div className="first-in">
                <div>
                  {mock4?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
                <div>
                  {mock5?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
                <div>
                  {mock6?.map((item: any, index) => (
                    <div key="index" className="item">
                      <span className="label">{item?.label}</span>
                      <span className="value dinpro-medium">{item?.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Block>
            <Block
              title="重点关注"
              style={{
                height: 'auto',
              }}
            >
              <div className="second-in">
                {mock7?.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="bg"></div>
                    <div className="item-left">
                      <div className="avatar">
                        <Icon type="icon-Frame2" size={16} />
                      </div>
                      <div className="number">{item?.number}</div>
                      <div className="name">{item?.name}</div>
                      <div className="gender">{item?.gender}</div>
                      <div className="age">{item?.age}</div>
                    </div>
                    <div className="item-right">
                      <div className="tags">
                        {item?.tag?.map((str, index) => (
                          <Tag
                            key={index}
                            color={
                              str === '手术' ||
                              str === '出院' ||
                              str === '转入' ||
                              str === '新入'
                                ? 'blue'
                                : str === '病重'
                                ? 'error'
                                : 'red'
                            }
                          >
                            {str}
                          </Tag>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Block>
          </>
        )}
      </div>
      <div className="footer">
        <div className="login-ab-bg"></div>
        <Block
          title="重要工作"
          extra={
            <div className="extra-oper">
              <RadioGroup
                optionType="button"
                onChange={onChange}
                value={radioValue}
                dataSource={mock}
                style={{
                  marginLeft: '12px',
                }}
              ></RadioGroup>
            </div>
          }
        >
          <VSPromiseTable
            id="1"
            onFetch={async (params) => {
              return {
                data: [
                  {
                    type: '临时',
                    advice:
                      '拟于 2023-08-23 09:55 行 踝关节骨折切开复位钢板内固定术 其他全身麻醉',
                    time: '08-24  13:56',
                    name: '李华',
                  },
                  {
                    type: '临时',
                    advice:
                      '拟于 2023-08-12 09:55 行 踝关节骨折切开复位钢板内固定术 其他全身麻醉',
                    time: '08-11  12:56',
                    name: '张萌',
                  },
                  {
                    type: '临时',
                    advice:
                      '拟于 2023-08-11 09:55 行 踝关节骨折切开复位钢板内固定术 其他全身麻醉',
                    time: '08-05  14:56',
                    name: '杨利',
                  },
                ],
                total: 0,
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
            className="aspirin-table"
          >
            <VSTableColumn
              dataIndex={['time']}
              title="时间"
              editable={false}
              valueType="digit"
              width={150}
            />

            <VSTableColumn
              dataIndex={['type']}
              title="长/临"
              editable={false}
              valueType="text"
              fieldProps={{}}
              width={50}
            />

            <VSTableColumn
              dataIndex={['advice']}
              title="医嘱"
              editable={false}
              valueType="text"
            />

            <VSTableColumn
              dataIndex={['name']}
              title="名字"
              editable={false}
              valueType="text"
              width={100}
            />
          </VSPromiseTable>
        </Block>
      </div>
    </div>
  );
};

export default definePage(Index);
