import { debounce } from '../../utils';
import { Draw } from '../draw/Draw';

export interface IElementVisibleInfo {
  intersectionHeight: number;
}

export interface IPageVisibleInfo {
  intersectionPageNo: number;
  visiblePageNoList: number[];
}

export class ScrollObserver {
  private draw: Draw;
  private root: HTMLElement;

  constructor(draw: Draw) {
    this.draw = draw;
    this.root = draw.getRoot();
    // 监听滚轮
    setTimeout(() => {
      if (!this.root.scrollTop) {
        this._observer();
      }
    });
    this._addEvent();
  }

  private _addEvent() {
    this.root.addEventListener('scroll', this._observer);
  }

  public removeEvent() {
    this.root.removeEventListener('scroll', this._observer);
  }

  public getElementVisibleInfo(element: Element): IElementVisibleInfo {
    const rect = element.getBoundingClientRect();
    const viewHeight = this.root.clientHeight;
    const visibleHeight =
      Math.min(rect.bottom, viewHeight) - Math.max(rect.top, 0);
    return {
      intersectionHeight: visibleHeight > 0 ? visibleHeight : 0,
    };
  }

  public getPageVisibleInfo(): IPageVisibleInfo {
    const pageList = this.draw.getPageList();
    const visiblePageNoList: number[] = [];
    let intersectionPageNo = 0;
    let intersectionMaxHeight = 0;
    for (let i = 0; i < pageList.length; i++) {
      const curPage = pageList[i];
      const { intersectionHeight } = this.getElementVisibleInfo(curPage);
      // 之前页存在交叉 && 当前页不交叉则后续均不交叉，结束循环
      if (intersectionMaxHeight && !intersectionHeight) break;
      if (intersectionHeight) {
        visiblePageNoList.push(i);
      }
      if (intersectionHeight > intersectionMaxHeight) {
        intersectionMaxHeight = intersectionHeight;
        intersectionPageNo = i;
      }
    }
    return {
      intersectionPageNo,
      visiblePageNoList,
    };
  }

  private _observer = debounce(() => {
    const { intersectionPageNo, visiblePageNoList } = this.getPageVisibleInfo();
    this.draw.setIntersectionPageNo(intersectionPageNo);
    this.draw.setVisiblePageNoList(visiblePageNoList);
  }, 150);
}
