import Center from '@/pages/center';
import Index from '@/pages/index';
import Test from '@/pages/test';
import Operation from '@/pages/operation';

const routes = [
  {
    // 首页
    path: '/center',
    exact: true,
    component: Center,
  },
  {
    // 登录
    path: '/index',
    exact: true,
    component: Index,
  },
  {
    // 登录
    path: '/operation',
    exact: true,
    component: Operation,
  },
];

export default routes;
