import vsf, { definePage } from "@vs/vsf-boot";
import {
  Button,
  Modal,
  VSTable,
  VSTableColumn
} from '@vs/vsf-kit'

import leftradio from '@/assets/imgs/leftradio.png';
import cdssheader from '@/assets/imgs/cdssheader.png';
import robot from '@/assets/imgs/robot.png';

import './index.less'


const ProductRecords = props => {

  const { title, open, produceRecordsTableValue = [] } = props

  const renderTitle = () => {
    return (
      <div className="custom_title">
        <img src={leftradio} className="leftradio" />
        <img src={cdssheader} className="cdssheader" />
        <img src={robot} className="robot" />

        <div className="label">{title}</div>
      </div>
    )
  }

  const handelSubmit = async () => {
    console.log((await vsf.refs?.ProductRecordsTable?.getValues()))
    props?.onOk(
      (await vsf.refs?.ProductRecordsTable?.getValues())
    )
  }

  return (
    <Modal
      destroyOnClose
      open={open}
      cancelText="取消"
      okText="确认"
      wrapClassName="product_records_wapper"
      width={700}
      bodyStyle={{
        height: 350,
        padding: '0 24px'
      }}
      title={renderTitle()}
      onCancel={props?.onCancel}
      onOk={handelSubmit}
    >
      <Button
        onClick={async () => {
          const _id = `create_${Math.random()}`
          await vsf?.refs?.ProductRecordsTable?.add({
            id: _id
          }, 'bottom')
          await vsf?.refs?.ProductRecordsTable?.startEdit(_id)
        }}>新增（测试按钮）</Button>
      <VSTable
        id="ProductRecordsTable"
        scroll={{
          y: 220
        }}
        defaultValue={produceRecordsTableValue}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true
          },
          saveOnClickOutside: true
        }}
      >
        <VSTableColumn
          width={600}
          title='妊娠日期'
          dataIndex={['pregnancyDate']}
          valueType='dateYear'
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择妊娠日期'
              }
            ]
          }}
          fieldProps={{
            width: 630
          }}
        />
      </VSTable>
    </Modal>
  )
}


export default definePage(ProductRecords)