import './index.less';

import { TabPane, Tabs } from '@vs/vsf-kit';
import React from 'react';

import Overview from './overview';

const Index = (props) => {
  const { detail } = props;
  return (
    <div className="medical-detail">
      <Tabs className="aspirin-tab aspirin-history-tab">
        <TabPane tab="概览" key="overview">
          <Overview detail={detail} />
        </TabPane>
        <TabPane tab="医嘱" key="a">
          测试
        </TabPane>
        <TabPane tab="病历文书" key="b">
          测试
        </TabPane>
        <TabPane tab="检查报告" key="c">
          测试
        </TabPane>
        <TabPane tab="检验报告" key="d">
          测试
        </TabPane>
        <TabPane tab="计价信息" key="e">
          测试
        </TabPane>
        <TabPane tab="外来资料" key="f">
          测试
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
