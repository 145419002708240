import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';
import Icon from '@/module/Icon';

const labelWidth = 80;
const formItemWidth = 194;

const PatientInfo = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};
  const suffix = (value = 1) => {
    return (
      <Icon
        style={{ color: '#3276E8', fontWeight: 600 }}
        size={18}
        type={value === 1 ? 'icon-tianjiagongneng' : 'icon-Frame-21'}
      />
    );
  };
  return (
    <VSForm
      id="patientInfo"
      labelAlign="left"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.DiagnosePatientInfoController_getDiagnosePatientInfo_654515?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
    >
      <FormBlock title="女方信息">
        <VSFormLayout key="0" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['femaleRecordNum']}
            label="女病案号"
            valueType="text"
            fieldProps={{
              suffix: suffix(2),
            }}
          />
          <VSFormItem
            name={['femaleName']}
            label="患者姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleBirthday']}
            label="出生日期"
            valueType="date"
            fieldProps={{ width: formItemWidth }}
          />
          <VSFormItem
            name={['femaleCardType']}
            label="证件类型"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CARD_TYPE}
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleNation']}
            label="民族"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.NATION}
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleEducation']}
            label="文化程度"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.EDUCATION}
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleOccupation']}
            label="职业"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.OCCUPATION_ENUM}
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleIdCart']}
            label="证件号码"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleFirstTime']}
            label="初诊日期"
            valueType="date"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['femaleAge']}
            label="年龄"
            valueType="digit"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['femaleNationality']}
            label="国籍"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.COUNTRY}
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleBirthPlace']}
            label="籍贯"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femalePhoneOne']}
            label="电话号码1"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femalePhoneTwo']}
            label="电话号码2"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleWorkPlace']}
            label="工作单位"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleRegisteredAddress']}
            label="户口地址"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="男方信息">
        <VSFormLayout key="1" columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['maleRecordNum']}
            label="男病案号"
            valueType="text"
            fieldProps={{
              suffix: suffix(2),
            }}
          />
          <VSFormItem
            name={['maleName']}
            label="患者姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleBirthday']}
            label="出生日期"
            valueType="date"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['maleCardType']}
            label="证件类型"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CARD_TYPE}
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleNation']}
            label="民族"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.NATION}
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleEducation']}
            label="文化程度"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.EDUCATION}
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleOccupation']}
            label="职业"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.OCCUPATION_ENUM}
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleIdCart']}
            label="证件号码"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['femaleFirstTime']}
            label="初诊日期"
            valueType="date"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['maleAge']}
            label="年龄"
            valueType="digit"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['maleNationality']}
            label="国籍"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.COUNTRY}
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleBirthPlace']}
            label="籍贯"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['malePhoneOne']}
            label="电话号码1"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['malePhoneTwo']}
            label="电话号码2"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleWorkPlace']}
            label="工作单位"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['maleRegisteredAddress']}
            label="户口地址"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="基本信息">
        <VSFormLayout key={2} columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['locationProvince']}
            label="地址-省"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.PROVINCE}
            fieldProps={{}}
          />
          <VSFormItem
            name={['locationCity']}
            label="地址-市"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CITY}
            fieldProps={{}}
          />
          <VSFormItem
            name={['locationDistrict']}
            label="地址-区"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CITY}
            fieldProps={{}}
          />
          <VSFormItem
            name={['locationDetail']}
            label="详细地址"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['otherContact']}
            label="其他联系人"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['contactName']}
            label="姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['contactPhone']}
            label="电话"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['contactAddress']}
            label="地址"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key={4} columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['comment']}
            label="备注"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="周期信息">
        <VSFormLayout key={5} columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['cycleHospital']}
            label="本院周期"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['cycleHospitalOut']}
            label="外院周期"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['cycleNum']}
            label="周期序号"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['treatTime']}
            label="就诊时间"
            valueType="date"
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['ARTMethod']}
            label="ART方式"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.art_methed}
            fieldProps={{}}
          />
          <VSFormItem
            name={['cycleType']}
            label="周期类型"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.zhouqi_type}
            fieldProps={{}}
          />
          <VSFormItem
            name={['technologyMethod']}
            label="技术方式"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.jishufangshi1}
            fieldProps={{}}
          />
          <VSFormItem
            name={['patientOrigin']}
            label="病人来源"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.binrenlaiyuan}
            fieldProps={{}}
          />

          <VSFormItem
            name={['spermOrigin']}
            label="精子来源"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.binrenlaiyuan}
            fieldProps={{}}
          />
          <VSFormItem
            name={['spermMethod']}
            label="取精方式"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.qujinfangshi}
            fieldProps={{}}
          />
          <VSFormItem
            name={['spermProp']}
            label="精子属性"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.jinzishuxin}
            fieldProps={{}}
          />
          <VSFormItem
            name={['ovumOrigin']}
            label="卵子来源"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.luanzilaiyuan}
            fieldProps={{}}
          />
          <VSFormItem
            name={['recordNum']}
            label="病历序号"
            valueType="text"
            fieldProps={{
              suffix: suffix,
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['recordCode']}
            label="病历编号"
            valueType="text"
            fieldProps={{
              suffix: suffix,
              width: formItemWidth,
            }}
          />
          <VSFormItem
            name={['IVF']}
            label="IVF"
            valueType="text"
            fieldProps={{
              suffix: suffix,
            }}
          />
          <VSFormItem
            name={['cardNum']}
            label="证件号"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.patientInfo?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.DiagnosePatientInfoController_createDiagnosePatientInfo_251168?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(PatientInfo);
