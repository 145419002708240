export function formatDate(date: Date, format = 'yyyy-MM-dd hh:mm:ss'): string {
  let dateString = format;
  const dateOption = {
    'y+': date.getFullYear().toString(),
    'M+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'h+': date.getHours().toString(),
    'm+': date.getMinutes().toString(),
    's+': date.getSeconds().toString(),
  };
  for (const k in dateOption) {
    const reg = new RegExp('(' + k + ')').exec(format);
    const key = <keyof typeof dateOption>k;
    if (reg) {
      dateString = dateString.replace(
        reg[1],
        reg[1].length === 1
          ? dateOption[key]
          : dateOption[key].padStart(reg[1].length, '0'),
      );
    }
  }
  return dateString;
}
