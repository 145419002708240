import Root from '@/layouts';
import Login from '@/pages/login';

import indexRouter from './IndexRoute';

const routes = [
  {
    // 登录
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/',
    component: Root,
    routes: indexRouter,
  },
];

export default routes;
