import { ZERO } from '../../dataset/constant/Common';
import { EDITOR_COMPONENT, EDITOR_PREFIX } from '../../dataset/constant/Editor';
import { ControlComponent, ImageDisplay } from '../../dataset/enum/Control';
import {
  EditorComponent,
  EditorMode,
  PageMode,
  PaperDirection,
} from '../../dataset/enum/Editor';
import { ElementType } from '../../dataset/enum/Element';
import { RowFlex } from '../../dataset/enum/Row';
import { DeepRequired } from '../../interface/Common';
import { IControl } from '../../interface/Control';
import {
  IDrawOption,
  IDrawRowPayload,
  IPainterOptions,
} from '../../interface/Draw';
import { IEditorOption, IEditorResult } from '../../interface/Editor';
import {
  IElement,
  IElementFillRect,
  IElementMetrics,
  IElementPosition,
  IElementStyle,
} from '../../interface/Element';
import { IMargin } from '../../interface/Margin';
import { IPositionContext } from '../../interface/Position';
import { IRange } from '../../interface/Range';
import { IRow, IRowElement } from '../../interface/Row';
import { deepClone, getUUID, nextTick } from '../../utils';
import { isImageElement, zipElementList } from '../../utils/element';
import { formatElementList } from '../../utils/element';
import { Cursor } from '../cursor/Cursor';
import { CanvasEvent } from '../event/CanvasEvent';
import { GlobalEvent } from '../event/GlobalEvent';
import { HistoryManager } from '../history/HistoryManager';
import { Listener } from '../listener/Listener';
import { ImageObserver } from '../observer/ImageObserver';
import { ScrollObserver } from '../observer/ScrollObserver';
import { SelectionObserver } from '../observer/SelectionObserver';
import { Position } from '../position/Position';
import { RangeManager } from '../range/RangeManager';
// import { WorkerManager } from '../worker/WorkerManager';
import { Control } from './control/Control';
import { Background } from './frame/Background';
import { Header } from './frame/Header';
import { Margin } from './frame/Margin';
import { PageNumber } from './frame/PageNumber';
import { Watermark } from './frame/Watermark';
import { Search } from './interactive/Search';
import { BlockParticle } from './particle/block/BlockParticle';
import { CheckboxParticle } from './particle/CheckboxParticle';
import { DateParticle } from './particle/DateParticle';
import { HyperlinkParticle } from './particle/HyperlinkParticle';
import { ImageParticle } from './particle/ImageParticle';
import { LaTexParticle } from './particle/latex/LaTexParticle';
import { PageBreakParticle } from './particle/PageBreak';
import { Previewer } from './particle/previewer/Previewer';
import { SeparatorParticle } from './particle/Separator';
import { SubscriptParticle } from './particle/Subscript';
import { SuperscriptParticle } from './particle/Superscript';
import { TableParticle } from './particle/table/TableParticle';
import { TableTool } from './particle/table/TableTool';
import { TextParticle } from './particle/TextParticle';
import { Highlight } from './richtext/Highlight';
import { Strikeout } from './richtext/Strikeout';
import { Underline } from './richtext/Underline';

export class Draw {
  private root: HTMLElement;
  private container: HTMLDivElement;
  private pageContainer: HTMLDivElement;
  private pageList: HTMLCanvasElement[];
  private ctxList: CanvasRenderingContext2D[];
  private pageNo: number;
  private mode: EditorMode;
  private options: DeepRequired<IEditorOption>;
  private position: Position;
  private elementList: IElement[];
  private listener: Listener;

  private canvasEvent: CanvasEvent;
  private globalEvent: GlobalEvent;
  private cursor: Cursor;
  private range: RangeManager;
  private margin: Margin;
  private background: Background;
  private search: Search;
  private underline: Underline;
  private strikeout: Strikeout;
  private highlight: Highlight;
  private historyManager: HistoryManager;
  private previewer: Previewer;
  private imageParticle: ImageParticle;
  private laTexParticle: LaTexParticle;
  private textParticle: TextParticle;
  private tableParticle: TableParticle;
  private tableTool: TableTool;
  private pageNumber: PageNumber;
  private waterMark: Watermark;
  private header: Header;
  private hyperlinkParticle: HyperlinkParticle;
  private dateParticle: DateParticle;
  private separatorParticle: SeparatorParticle;
  private pageBreakParticle: PageBreakParticle;
  private superscriptParticle: SuperscriptParticle;
  private subscriptParticle: SubscriptParticle;
  private checkboxParticle: CheckboxParticle;
  private blockParticle: BlockParticle;
  private control: Control;
  // private workerManager: WorkerManager;
  private scrollObserver: ScrollObserver;
  private selectionObserver: SelectionObserver;
  private imageObserver: ImageObserver;

  private rowList: IRow[];
  private pageRowList: IRow[][];
  private painterStyle: IElementStyle | null;
  private painterOptions: IPainterOptions | null;
  private visiblePageNoList: number[];
  private intersectionPageNo: number;
  private lazyRenderIntersectionObserver: IntersectionObserver | null;

  constructor(
    root: HTMLElement,
    rootContainer: HTMLElement,
    options: DeepRequired<IEditorOption>,
    elementList: IElement[],
    listener: Listener,
  ) {
    this.root = root;
    this.container = this._wrapContainer(rootContainer);
    this.pageList = [];
    this.ctxList = [];
    this.pageNo = 0;
    this.mode = options.mode;
    this.options = options;
    this.elementList = elementList;
    this.listener = listener;

    this._formatContainer();
    this.pageContainer = this._createPageContainer();
    this._createPage(0);

    this.historyManager = new HistoryManager();
    this.position = new Position(this);
    this.range = new RangeManager(this);
    this.margin = new Margin(this);
    this.background = new Background(this);
    this.search = new Search(this);
    this.underline = new Underline(this);
    this.strikeout = new Strikeout(this);
    this.highlight = new Highlight(this);
    this.previewer = new Previewer(this);
    this.imageParticle = new ImageParticle(this);
    this.laTexParticle = new LaTexParticle(this);
    this.textParticle = new TextParticle(this);
    this.tableParticle = new TableParticle(this);
    this.tableTool = new TableTool(this);
    this.pageNumber = new PageNumber(this);
    this.waterMark = new Watermark(this);
    this.header = new Header(this);
    this.hyperlinkParticle = new HyperlinkParticle(this);
    this.dateParticle = new DateParticle();
    this.separatorParticle = new SeparatorParticle();
    this.pageBreakParticle = new PageBreakParticle(this);
    this.superscriptParticle = new SuperscriptParticle();
    this.subscriptParticle = new SubscriptParticle();
    this.checkboxParticle = new CheckboxParticle(this);
    this.blockParticle = new BlockParticle(this);
    this.control = new Control(this);

    this.scrollObserver = new ScrollObserver(this);
    this.selectionObserver = new SelectionObserver(this.container, this.root);
    this.imageObserver = new ImageObserver();

    this.canvasEvent = new CanvasEvent(this);
    this.cursor = new Cursor(this, this.canvasEvent);
    this.canvasEvent.register();
    this.globalEvent = new GlobalEvent(this, this.canvasEvent);
    this.globalEvent.register();

    // this.workerManager = new WorkerManager(this);

    this.rowList = [];
    this.pageRowList = [];
    this.painterStyle = null;
    this.painterOptions = null;
    this.visiblePageNoList = [];
    this.intersectionPageNo = 0;
    this.lazyRenderIntersectionObserver = null;

    this.render({ isSetCursor: false, isSubmitHistory: false });
  }

  dispose() {
    // this.workerManager.dispose();
  }

  public getMode(): EditorMode {
    return this.mode;
  }

  public setMode(payload: EditorMode) {
    this.mode = payload;
  }

  public isReadonly() {
    return this.mode === EditorMode.READONLY;
  }

  public getOriginalWidth(): number {
    const { paperDirection, width, height } = this.options;
    return paperDirection === PaperDirection.VERTICAL ? width : height;
  }

  public getOriginalHeight(): number {
    const { paperDirection, width, height } = this.options;
    return paperDirection === PaperDirection.VERTICAL ? height : width;
  }

  public getWidth(): number {
    return Math.floor(this.getOriginalWidth() * this.options.scale);
  }

  public getHeight(): number {
    return Math.floor(this.getOriginalHeight() * this.options.scale);
  }

  public getPaperDirection(): PaperDirection {
    return this.options.paperDirection;
  }

  public getCanvasWidth(pageNo = -1): number {
    const page = this.getPage(pageNo);
    return page.width;
  }

  public getCanvasHeight(pageNo = -1): number {
    const page = this.getPage(pageNo);
    return page.height;
  }

  public getInnerWidth(): number {
    const width = this.getWidth();
    const margins = this.getMargins();
    return width - margins[1] - margins[3];
  }

  public getOriginalInnerWidth(): number {
    const width = this.getOriginalWidth();
    const margins = this.getOriginalMargins();
    return width - margins[1] - margins[3];
  }

  public getMargins(): IMargin {
    return <IMargin>(
      this.getOriginalMargins().map((m) => m * this.options.scale)
    );
  }

  public getOriginalMargins(): number[] {
    const { margins, paperDirection } = this.options;
    return paperDirection === PaperDirection.VERTICAL
      ? margins
      : [margins[1], margins[2], margins[3], margins[0]];
  }

  public getPageGap(): number {
    return this.options.pageGap * this.options.scale;
  }

  public getPageNumberBottom(): number {
    return this.options.pageNumberBottom * this.options.scale;
  }

  public getHeaderTop(): number {
    return this.options.headerTop * this.options.scale;
  }

  public getMarginIndicatorSize(): number {
    return this.options.marginIndicatorSize * this.options.scale;
  }

  public getDefaultBasicRowMarginHeight(): number {
    return this.options.defaultBasicRowMarginHeight * this.options.scale;
  }

  public getTdPadding(): number {
    return this.options.tdPadding * this.options.scale;
  }

  public getRoot(): HTMLElement {
    return this.root;
  }

  public getContainer(): HTMLDivElement {
    return this.container;
  }

  public getPageContainer(): HTMLDivElement {
    return this.pageContainer;
  }

  public getVisiblePageNoList(): number[] {
    return this.visiblePageNoList;
  }

  public setVisiblePageNoList(payload: number[]) {
    this.visiblePageNoList = payload;
    if (this.listener.visiblePageNoListChange) {
      this.listener.visiblePageNoListChange(this.visiblePageNoList);
    }
  }

  public getIntersectionPageNo(): number {
    return this.intersectionPageNo;
  }

  public setIntersectionPageNo(payload: number) {
    this.intersectionPageNo = payload;
    if (this.listener.intersectionPageNoChange) {
      this.listener.intersectionPageNoChange(this.intersectionPageNo);
    }
  }

  public getPageNo(): number {
    return this.pageNo;
  }

  public setPageNo(payload: number) {
    this.pageNo = payload;
  }

  public getPage(pageNo = -1): HTMLCanvasElement {
    return this.pageList[~pageNo ? pageNo : this.pageNo];
  }

  public getPageList(): HTMLCanvasElement[] {
    return this.pageList;
  }

  public getRowList(): IRow[] {
    return this.rowList;
  }

  public getPageRowList(): IRow[][] {
    return this.pageRowList;
  }

  public getCtx(): CanvasRenderingContext2D {
    return this.ctxList[this.pageNo];
  }

  public getOptions(): DeepRequired<IEditorOption> {
    return this.options;
  }

  public getSearch(): Search {
    return this.search;
  }

  public getHistoryManager(): HistoryManager {
    return this.historyManager;
  }

  public getPosition(): Position {
    return this.position;
  }

  public getRange(): RangeManager {
    return this.range;
  }

  public getElementList(): IElement[] {
    const positionContext = this.position.getPositionContext();
    if (positionContext.isTable) {
      const { index, trIndex, tdIndex } = positionContext;
      return (
        this.elementList[index!].trList![trIndex!].tdList[tdIndex!]?.value || []
      );
    }
    return this.elementList;
  }

  public insertElementList(payload: IElement[]) {
    if (!payload.length) return;
    const isPartRangeInControlOutside =
      this.control.isPartRangeInControlOutside();
    if (isPartRangeInControlOutside) return;
    const { startIndex, endIndex } = this.range.getRange();
    if (!~startIndex && !~endIndex) return;
    formatElementList(payload, {
      isHandleFirstElement: false,
      editorOptions: this.options,
    });
    let curIndex = -1;
    // 判断是否在控件内
    const activeControl = this.control.getActiveControl();
    if (activeControl && !this.control.isRangInPostfix()) {
      curIndex = activeControl.setValue(payload);
    } else {
      const elementList = this.getElementList();
      const isCollapsed = startIndex === endIndex;
      const start = startIndex + 1;
      if (!isCollapsed) {
        elementList.splice(start, endIndex - startIndex);
      }
      const positionContext = this.position.getPositionContext();
      for (let i = 0; i < payload.length; i++) {
        const element = payload[i];
        if (positionContext.isTable) {
          element.tdId = positionContext.tdId;
          element.trId = positionContext.trId;
          element.tableId = positionContext.tableId;
        }
        elementList.splice(start + i, 0, element);
      }
      curIndex = startIndex + payload.length;
    }
    if (~curIndex) {
      this.range.setRange(curIndex, curIndex);
      this.render({
        curIndex,
      });
    }
  }

  public getOriginalElementList() {
    return this.elementList;
  }

  public getCanvasEvent(): CanvasEvent {
    return this.canvasEvent;
  }

  public getListener(): Listener {
    return this.listener;
  }

  public getCursor(): Cursor {
    return this.cursor;
  }

  public getPreviewer(): Previewer {
    return this.previewer;
  }

  public getImageParticle(): ImageParticle {
    return this.imageParticle;
  }

  public getTableTool(): TableTool {
    return this.tableTool;
  }

  public getHyperlinkParticle(): HyperlinkParticle {
    return this.hyperlinkParticle;
  }

  public getControl(): Control {
    return this.control;
  }

  // public getWorkerManager(): WorkerManager {
  //   return this.workerManager;
  // }

  public getImageObserver(): ImageObserver {
    return this.imageObserver;
  }

  public getRowCount(): number {
    return this.rowList.length;
  }

  public async getDataURL(hideControl: boolean): Promise<string[]> {
    const oldElementList = this.getOriginalElementList();
    if (hideControl) {
      // 替换control的前后缀和placeholder为空格
      const elements = deepClone(oldElementList);
      elements.forEach((e) => {
        if (
          e.controlComponent === ControlComponent.PREFIX ||
          e.controlComponent === ControlComponent.PLACEHOLDER ||
          e.controlComponent === ControlComponent.POSTFIX
        ) {
          e.value = ' ';
        }
        if (e.type === ElementType.TABLE) {
          e.trList?.forEach((tr) => {
            tr.tdList.forEach((td) => {
              td.value.forEach((ele) => {
                if (
                  ele.controlComponent === ControlComponent.PREFIX ||
                  ele.controlComponent === ControlComponent.PLACEHOLDER ||
                  ele.controlComponent === ControlComponent.POSTFIX
                ) {
                  ele.value = ' ';
                }
              });
            });
          });
        }
        if (e.type === ElementType.PAGE_BREAK) {
          e.value = '';
        }
      });
      this.elementList = elements;
    }

    this.render({
      isLazy: false,
      isCompute: hideControl,
      isSetCursor: false,
      isSubmitHistory: false,
    });

    // 获取替换后的渲染结果
    await this.imageObserver.allSettled();
    const ret = this.pageList.map((c) => c.toDataURL());

    if (hideControl) {
      // 还原
      this.elementList = oldElementList;
      this.render({
        isLazy: false,
        isSubmitHistory: false,
        isSetCursor: false,
      });
    }
    return ret;
  }

  public getControls() {
    // 记录当前位置
    let cursorPosition = this.position.getCursorPosition();
    const currentPos = cursorPosition
      ? deepClone(this.position.getPositionContext())
      : undefined;
    const currentRange = cursorPosition
      ? deepClone(this.range.getRange())
      : undefined;

    // 遍历control
    let result = this._gotoNextControl(
      { isTable: false, index: 0 },
      false,
      false,
    );
    const controls: IControl[] = [];
    while (result) {
      cursorPosition = this.position.getCursorPosition();
      const elementList = this.getElementList();
      if (cursorPosition) {
        const control = elementList[cursorPosition.index]?.control;
        if (control) {
          const activeControl = this.control.getActiveControl();
          controls.push({
            ...control,
            value: activeControl?.getValue() || null,
          });
        }
      }
      result = this._gotoNextControl(
        this.position.getPositionContext(),
        false,
        false,
      );
    }

    // 恢复当前位置
    if (currentPos && currentRange) {
      this.range.setRange(
        currentRange.startIndex,
        currentRange.endIndex,
        currentRange.tableId,
        currentRange.startTdIndex,
        currentRange.endTdIndex,
        currentRange.startTrIndex,
        currentRange.endTrIndex,
      );
      this.position.setPositionContext(currentPos);
      this.render({
        curIndex: currentRange.startIndex,
        isSubmitHistory: false,
      });
    } else {
      this._gotoNextControl({ isTable: false, index: 0 }, true, false);
    }

    return controls;
  }

  public getPainterStyle(): IElementStyle | null {
    return this.painterStyle && Object.keys(this.painterStyle).length
      ? this.painterStyle
      : null;
  }

  public getPainterOptions(): IPainterOptions | null {
    return this.painterOptions;
  }

  public setPainterStyle(
    payload: IElementStyle | null,
    options?: IPainterOptions,
  ) {
    this.painterStyle = payload;
    this.painterOptions = options || null;
    if (this.getPainterStyle()) {
      this.pageList.forEach((c) => (c.style.cursor = 'copy'));
    }
  }

  public setDefaultRange() {
    if (!this.elementList.length) return;
    setTimeout(() => {
      const curIndex = this.elementList.length - 1;
      this.range.setRange(curIndex, curIndex);
      this.range.setRangeStyle();
    });
  }

  public setPageMode(payload: PageMode) {
    if (!payload || this.options.pageMode === payload) return;
    this.options.pageMode = payload;
    // 纸张大小重置
    if (payload === PageMode.PAGING) {
      const { height } = this.options;
      const dpr = window.devicePixelRatio;
      const canvas = this.pageList[0];
      canvas.style.height = `${height}px`;
      canvas.height = height * dpr;
      // canvas尺寸发生变化，上下文被重置
      this._initPageContext(this.ctxList[0]);
    }
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
    // 回调
    setTimeout(() => {
      if (this.listener.pageModeChange) {
        this.listener.pageModeChange(payload);
      }
    });
  }

  public setPageScale(payload: number) {
    const dpr = window.devicePixelRatio;
    this.options.scale = payload;
    const width = this.getWidth();
    const height = this.getHeight();
    this.container.style.width = `${width}px`;
    this.pageList.forEach((p, i) => {
      p.width = width * dpr;
      p.height = height * dpr;
      p.style.width = `${width}px`;
      p.style.height = `${height}px`;
      p.style.marginBottom = `${this.getPageGap()}px`;
      this._initPageContext(this.ctxList[i]);
    });
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
    if (this.listener.pageScaleChange) {
      this.listener.pageScaleChange(payload);
    }
  }

  public setPageDevicePixel() {
    const dpr = window.devicePixelRatio;
    const width = this.getWidth();
    const height = this.getHeight();
    this.pageList.forEach((p, i) => {
      p.width = width * dpr;
      p.height = height * dpr;
      this._initPageContext(this.ctxList[i]);
    });
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
  }

  public setPaperSize(width: number, height: number) {
    const dpr = window.devicePixelRatio;
    this.options.width = width;
    this.options.height = height;
    this.container.style.width = `${width}px`;
    this.pageList.forEach((p, i) => {
      p.width = width * dpr;
      p.height = height * dpr;
      p.style.width = `${width}px`;
      p.style.height = `${height}px`;
      this._initPageContext(this.ctxList[i]);
    });
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
  }

  public setPaperDirection(payload: PaperDirection) {
    const dpr = window.devicePixelRatio;
    this.options.paperDirection = payload;
    const width = this.getWidth();
    const height = this.getHeight();
    this.container.style.width = `${width}px`;
    this.pageList.forEach((p, i) => {
      p.width = width * dpr;
      p.height = height * dpr;
      p.style.width = `${width}px`;
      p.style.height = `${height}px`;
      this._initPageContext(this.ctxList[i]);
    });
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
  }

  public setPaperMargin(payload: IMargin) {
    this.options.margins = payload;
    this.render({
      isSubmitHistory: false,
      isSetCursor: false,
    });
  }

  public getValue(): IEditorResult {
    // 配置
    const { width, height, margins, watermark, header } = this.options;
    // 数据
    const data = zipElementList(this.elementList);
    return {
      version: '0.1.0',
      width,
      height,
      margins,
      header: header.data ? header : undefined,
      watermark: watermark.data ? watermark : undefined,
      data,
    };
  }

  public gotoNext() {
    const positionContext = this.position.getPositionContext();
    if (!positionContext.index) return;
    if (positionContext.isTable && !positionContext.isControl) {
      if (!this._gotoNextInTable(positionContext)) {
        this._gotoNextControl(positionContext);
      }
    } else {
      this._gotoNextControl(positionContext);
    }
  }

  private _gotoNextInTable(posCtx: IPositionContext) {
    if (!posCtx.index) return false;
    const elements = this.getOriginalElementList();
    const table = elements[posCtx.index];
    let trIndex = posCtx.trIndex;
    let tdIndex = posCtx.tdIndex;
    let tr = (table.trList || [])[trIndex!];
    let td = tr?.tdList[tdIndex!];
    // 跳到下一个td
    tdIndex = tdIndex! + 1;
    // 越界，跳到下一个tr的第一个td
    if (tdIndex >= tr.tdList.length) {
      trIndex = trIndex! + 1;
      tdIndex = 0;
      tr = (table.trList || [])[trIndex];
    }
    td = tr?.tdList[tdIndex];
    if (tr && td) {
      this.position.setPositionContext({
        ...posCtx,
        index: posCtx.index,
        trIndex,
        tdIndex,
        trId: tr.id,
        tdId: td.id,
      });
      const tdValueIndex = td.value.length - 1;
      this.range.setRange(tdValueIndex, tdValueIndex);
      this.render({ isSubmitHistory: false });
      this._scrollToBottomOfPosition(td.positionList![tdValueIndex]);
      return true;
    }
    return false;
  }

  private _gotoNextControl(
    posCtx: IPositionContext,
    scroll = true,
    loop = true,
  ) {
    if (posCtx.index === undefined || posCtx.index === null) return false;
    const elements = this.getOriginalElementList();
    const positions = this.position.getOriginalPositionList();
    let result = this._findControlElement(
      elements,
      positions,
      posCtx,
      posCtx.index,
      elements.length,
    );
    if (!result.nextPosCtx && loop) {
      result = this._findControlElement(
        elements,
        positions,
        posCtx,
        0,
        posCtx.index,
      );
    }
    if (result.nextPosCtx) {
      const { nextPosCtx, position } = result;
      this.setPageNo(position!.pageNo);
      if (nextPosCtx.tableId) {
        const { tdValueIndex, ...rest } = nextPosCtx;
        this.position.setPositionContext(rest);
        this.range.setRange(tdValueIndex!, tdValueIndex!);
        this.render({ curIndex: tdValueIndex, isSubmitHistory: false });
      } else {
        this.position.setPositionContext(nextPosCtx);
        const idx = nextPosCtx.index!;
        this.range.setRange(idx, idx);
        this.render({ curIndex: idx, isSubmitHistory: false });
      }
      if (scroll) {
        this._scrollToBottomOfPosition(position!);
      }
      return true;
    }
    return false;
  }

  private _scrollToBottomOfPosition(position: IElementPosition) {
    // css value in ./style.css
    const editorMarginTop = 80; // .editor: margin: 80px auto;
    const menuHeight = 60; // .menu: height: 60px;
    const footerHeight = 30; // .footer: height: 30px;
    const diff = editorMarginTop - menuHeight;
    const scrollY = this.root.scrollTop - diff;
    const clientHeight = this.root.clientHeight;
    const y =
      position.pageNo * (this.getHeight() + this.getPageGap()) +
      position.coordinate.leftBottom[1];
    if (y < scrollY || y > scrollY + clientHeight - editorMarginTop) {
      this.root.scrollTo({
        top: y + footerHeight + editorMarginTop + menuHeight - clientHeight,
      });
    }
  }

  private _findControlElement(
    elements: IElement[],
    positions: IElementPosition[],
    posCtx: IPositionContext,
    from: number,
    to: number,
  ) {
    let nextPosCtx: (IPositionContext & { tdValueIndex?: number }) | undefined =
      undefined;
    let position: IElementPosition | undefined = undefined;
    for (let index = from; index < to; index++) {
      const element = elements[index];
      if (element.type === ElementType.TABLE) {
        const { info, position: pos } =
          this._findNextControlInTable(
            element,
            posCtx,
            this.range.getRange(),
          ) || {};
        if (info) {
          nextPosCtx = {
            ...info,
            index,
          };
          position = pos;
          break;
        }
      } else if (
        element.type === ElementType.CONTROL &&
        element.controlId !== posCtx.controlId
      ) {
        nextPosCtx = {
          index,
          isTable: false,
          isControl: true,
          controlId: element.controlId,
        };
        position = positions[index];
        break;
      }
    }
    return { nextPosCtx, position };
  }

  private _findNextControlInTable(
    table: IElement,
    posCtx: IPositionContext,
    range: IRange,
  ) {
    const trList = table.trList || [];
    let t =
      (table.tableId && table.tableId === posCtx.tableId) ||
      (table.id && table.id === posCtx.tableId)
        ? posCtx.trIndex!
        : 0;
    for (; t < trList.length; t++) {
      const tr = trList[t];
      let d = tr.id === posCtx.trId ? posCtx.tdIndex! : 0;
      for (; d < tr.tdList.length; d++) {
        const td = tr?.tdList[d];
        let v = td.id === posCtx.tdId ? range.endIndex : 0;
        for (; v < td.value.length; v++) {
          const inTableElement = td.value[v];
          if (
            inTableElement.type === ElementType.CONTROL &&
            inTableElement.controlId !== posCtx.controlId
          ) {
            return {
              info: {
                trId: tr.id,
                trIndex: t,
                tdId: td.id,
                tdIndex: d,
                tdValueIndex: v,
                controlId: inTableElement.controlId,
                tableId: table.id,
                id: table.id,
                isTable: true,
                isControl: true,
              },
              position: td.positionList?.[v],
            };
          }
        }
      }
    }
    return undefined;
  }

  private _wrapContainer(rootContainer: HTMLElement): HTMLDivElement {
    const container = document.createElement('div');
    rootContainer.append(container);
    return container;
  }

  private _formatContainer() {
    // 容器宽度需跟随纸张宽度
    this.container.style.position = 'relative';
    this.container.style.width = `${this.getWidth()}px`;
    this.container.setAttribute(EDITOR_COMPONENT, EditorComponent.MAIN);
  }

  private _createPageContainer(): HTMLDivElement {
    const pageContainer = document.createElement('div');
    pageContainer.classList.add(`${EDITOR_PREFIX}-page-container`);
    this.container.append(pageContainer);
    return pageContainer;
  }

  private _createPage(pageNo: number) {
    const width = this.getWidth();
    const height = this.getHeight();
    const canvas = document.createElement('canvas');
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.style.display = 'block';
    canvas.style.backgroundColor = 'transparent';
    canvas.style.marginBottom = `${this.getPageGap()}px`;
    canvas.setAttribute('data-index', String(pageNo));
    this.pageContainer.append(canvas);
    // 调整分辨率
    const dpr = window.devicePixelRatio;
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    canvas.style.cursor = 'text';
    const ctx = canvas.getContext('2d')!;
    // 初始化上下文配置
    this._initPageContext(ctx);
    // 缓存上下文
    this.pageList.push(canvas);
    this.ctxList.push(ctx);
  }

  private _initPageContext(ctx: CanvasRenderingContext2D) {
    const dpr = window.devicePixelRatio;
    ctx.scale(dpr, dpr);
    // 重置以下属性是因部分浏览器(chrome)会应用css样式
    ctx.letterSpacing = '0px';
    ctx.wordSpacing = '0px';
    ctx.direction = 'ltr';
  }

  private _getFont(el: IElement, scale = 1): string {
    const { defaultSize, defaultFont } = this.options;
    const font = el.font || defaultFont;
    const size = el.actualSize || el.size || defaultSize;
    return `${el.italic ? 'italic ' : ''}${el.bold ? 'bold ' : ''}${
      size * scale
    }px ${font}`;
  }

  private _computeRowList(innerWidth: number, elementList: IElement[]) {
    const { defaultSize, defaultRowMargin, scale, tdPadding, defaultTabWidth } =
      this.options;
    const defaultBasicRowMarginHeight = this.getDefaultBasicRowMarginHeight();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const rowList: IRow[] = [];
    if (elementList.length) {
      rowList.push({
        width: 0,
        height: 0,
        ascent: 0,
        elementList: [],
        startIndex: 0,
        rowFlex: elementList?.[1]?.rowFlex,
      });
    }
    // 先合并同id的表格
    const tableNeedMerge = elementList.reduce<
      { tableId: string; start: number; end: number; replace: IElement }[]
    >((ret, element, index) => {
      if (element.type === ElementType.TABLE) {
        const tableId = element.fromTableId || element.id!;
        const merge = ret[0];
        if (merge?.tableId === tableId) {
          merge.end = index;
          merge.replace = {
            ...merge.replace,
            trList: merge.replace.trList!.concat(element.trList!),
          };
        } else {
          ret.unshift({
            tableId,
            start: index,
            end: index,
            replace: element,
          });
        }
      }
      return ret;
    }, []);
    tableNeedMerge.forEach((merge) => {
      if (merge.start === merge.end) return;
      elementList.splice(
        merge.start,
        merge.end - merge.start + 1,
        merge.replace,
      );
    });
    for (let i = 0; i < elementList.length; i++) {
      const curRow: IRow = rowList[rowList.length - 1];
      const element = elementList[i];
      const rowMargin =
        defaultBasicRowMarginHeight * (element.rowMargin || defaultRowMargin);
      const metrics: IElementMetrics = {
        width: 0,
        height: 0,
        boundingBoxAscent: 0,
        boundingBoxDescent: 0,
      };
      if (isImageElement(element) || element.type === ElementType.LATEX) {
        const elementWidth = element.width! * scale;
        const elementHeight = element.height! * scale;
        // 图片超出尺寸后自适应
        const curRowWidth =
          element.imgDisplay === ImageDisplay.INLINE ? 0 : curRow.width;
        if (curRowWidth + elementWidth > innerWidth) {
          // 计算剩余大小
          let surplusWidth = (innerWidth - curRowWidth) * 0.9;
          if (surplusWidth / scale < 50) {
            surplusWidth = 50 * scale;
          }
          element.width = surplusWidth;
          element.height = (elementHeight * surplusWidth) / elementWidth;
          metrics.width = element.width;
          metrics.height = element.height;
          metrics.boundingBoxDescent = element.height;
        } else {
          metrics.width = elementWidth;
          metrics.height = elementHeight;
          metrics.boundingBoxDescent = elementHeight;
        }
        metrics.boundingBoxAscent = 0;
      } else if (element.type === ElementType.TABLE) {
        const tdGap = tdPadding * 2;
        // 计算表格行列
        this.tableParticle.computeRowColInfo(element);
        // 计算表格内元素信息
        const trList = element.trList!;
        for (let t = 0; t < trList.length; t++) {
          const tr = trList[t];
          let maxTrHeight = 0;
          for (let d = 0; d < tr.tdList.length; d++) {
            const td = tr.tdList[d];
            const rowList = this._computeRowList(
              (td.width! - tdGap) * scale,
              td.value,
            );
            const rowHeight = rowList.reduce((pre, cur) => pre + cur.height, 0);
            td.rowList = rowList;
            // 移除缩放导致的行高变化-渲染时会进行缩放调整
            const curTrHeight = (rowHeight + tdGap) / scale;
            if (maxTrHeight < curTrHeight) {
              maxTrHeight = curTrHeight;
            }
          }
          tr.height = maxTrHeight;
        }
        // 需要重新计算表格内值
        this.tableParticle.computeRowColInfo(element);
        // 计算出表格高度
        const tableHeight = trList.reduce((pre, cur) => pre + cur.height, 0);
        const tableWidth = element.colgroup!.reduce(
          (pre, cur) => pre + cur.width,
          0,
        );
        element.width = tableWidth;
        element.height = tableHeight;
        const elementWidth = tableWidth * scale;
        const elementHeight = tableHeight * scale;
        metrics.width = elementWidth;
        metrics.height = elementHeight;
        metrics.boundingBoxDescent = elementHeight;
        metrics.boundingBoxAscent = 0;
        // 表格分页处理(拆分表格)
        const margins = this.getMargins();
        const height = this.getHeight();
        const marginHeight = margins[0] + margins[2];
        let curPagePreHeight = marginHeight;
        for (let r = 0; r < rowList.length; r++) {
          const row = rowList[r];
          if (
            row.height + curPagePreHeight > height ||
            rowList[r - 1]?.isPageBreak
          ) {
            curPagePreHeight = marginHeight + row.height;
          } else {
            curPagePreHeight += row.height;
          }
        }
        // 表格高度超过页面高度
        const rowMarginHeight = rowMargin * 2;
        if (curPagePreHeight + rowMarginHeight + elementHeight > height) {
          const trList = element.trList!;
          // 计算需要移除的行数
          let deleteStart = 0;
          let deleteCount = 0;
          let preTrHeight = 0;
          if (trList.length > 1) {
            for (let r = 0; r < trList.length; r++) {
              const tr = trList[r];
              if (
                curPagePreHeight + rowMarginHeight + preTrHeight + tr.height >
                height
              ) {
                const width = tr.tdList.reduce((w, td) => {
                  return w + td.width!;
                }, 0);
                // 是否跨列，所有列宽度加起来不等于总款说明存在跨列，否则可能是单元格合并
                if (
                  element.colgroup?.length !== tr.tdList.length &&
                  width != element.width
                ) {
                  deleteCount = 0;
                }
                break;
              } else {
                deleteStart = r + 1;
                deleteCount = trList.length - deleteStart;
                preTrHeight += tr.height;
              }
            }
          }
          if (deleteCount) {
            const cloneTrList = trList.splice(deleteStart, deleteCount);
            const cloneTrHeight = cloneTrList.reduce(
              (pre, cur) => pre + cur.height,
              0,
            );
            element.height -= cloneTrHeight;
            metrics.height -= cloneTrHeight;
            metrics.boundingBoxDescent -= cloneTrHeight;
            // 追加拆分表格
            const cloneElement = deepClone(element);
            cloneElement.trList = cloneTrList;
            cloneElement.fromTableId = cloneElement.id;
            cloneElement.id = getUUID();
            elementList.splice(i + 1, 0, cloneElement);
            // 换页的是当前行则改变上下文
            const positionContext = this.position.getPositionContext();
            if (
              positionContext.isTable &&
              positionContext.trIndex === deleteStart
            ) {
              positionContext.index! += 1;
              positionContext.trIndex = 0;
              this.position.setPositionContext(positionContext);
            }
          }
        }
      } else if (element.type === ElementType.SEPARATOR) {
        element.width = innerWidth;
        metrics.width = innerWidth;
        metrics.height = this.options.defaultSize;
        metrics.boundingBoxAscent = -rowMargin;
        metrics.boundingBoxDescent = -rowMargin;
      } else if (element.type === ElementType.PAGE_BREAK) {
        element.width = innerWidth;
        metrics.width = innerWidth;
        metrics.height = this.options.defaultSize;
      } else if (
        element.type === ElementType.CHECKBOX ||
        element.controlComponent === ControlComponent.CHECKBOX
      ) {
        const { width, height, gap } = this.options.checkbox;
        const elementWidth = (width + gap * 2) * scale;
        element.width = elementWidth;
        metrics.width = elementWidth;
        metrics.height = height * scale;
      } else if (element.type === ElementType.TAB) {
        metrics.width = defaultTabWidth * scale;
        metrics.height = defaultSize * scale;
        metrics.boundingBoxDescent = 0;
        metrics.boundingBoxAscent = metrics.height;
      } else if (element.type === ElementType.BLOCK) {
        const innerWidth = this.getInnerWidth();
        if (!element.width) {
          metrics.width = innerWidth;
        } else {
          const elementWidth = element.width * scale;
          metrics.width = elementWidth > innerWidth ? innerWidth : elementWidth;
        }
        metrics.height = element.height! * scale;
        metrics.boundingBoxDescent = metrics.height;
        metrics.boundingBoxAscent = 0;
      } else {
        // 设置上下标真实字体尺寸
        const size = element.size || this.options.defaultSize;
        if (
          element.type === ElementType.SUPERSCRIPT ||
          element.type === ElementType.SUBSCRIPT
        ) {
          element.actualSize = Math.ceil(size * 0.6);
        }
        metrics.height = (element.actualSize || size) * scale;
        ctx.font = this._getFont(element);
        const fontMetrics = this.textParticle.measureText(ctx, element);
        metrics.width = fontMetrics.width * scale;
        if (element.letterSpacing) {
          metrics.width += element.letterSpacing * scale;
        }
        metrics.boundingBoxAscent =
          (element.value === ZERO
            ? defaultSize
            : fontMetrics.actualBoundingBoxAscent) * scale;
        metrics.boundingBoxDescent =
          fontMetrics.actualBoundingBoxDescent * scale;
        if (element.type === ElementType.SUPERSCRIPT) {
          metrics.boundingBoxAscent += metrics.height / 2;
        } else if (element.type === ElementType.SUBSCRIPT) {
          metrics.boundingBoxDescent += metrics.height / 2;
        }
      }
      const ascent = metrics.boundingBoxAscent + rowMargin;
      const descent = metrics.boundingBoxDescent + rowMargin;
      const height = ascent + descent;
      const rowElement: IRowElement = Object.assign(element, {
        metrics,
        style: this._getFont(element, scale),
      });
      // 超过限定宽度
      const preElement = elementList[i - 1];
      if (
        preElement?.type === ElementType.TABLE ||
        preElement?.type === ElementType.BLOCK ||
        element.type === ElementType.BLOCK ||
        preElement?.imgDisplay === ImageDisplay.INLINE ||
        element.imgDisplay === ImageDisplay.INLINE ||
        curRow.width + metrics.width > innerWidth ||
        (i !== 0 && element.value === ZERO)
      ) {
        // 两端对齐
        if (
          preElement?.rowFlex === RowFlex.ALIGNMENT &&
          curRow.width + metrics.width > innerWidth
        ) {
          const gap = (innerWidth - curRow.width) / curRow.elementList.length;
          for (let e = 0; e < curRow.elementList.length; e++) {
            const el = curRow.elementList[e];
            el.metrics.width += gap;
          }
          curRow.width = innerWidth;
        }
        rowList.push({
          width: metrics.width,
          height,
          startIndex: i,
          elementList: [rowElement],
          ascent,
          rowFlex: elementList[i + 1]?.rowFlex,
          isPageBreak: element.type === ElementType.PAGE_BREAK,
        });
      } else {
        curRow.width += metrics.width;
        if (curRow.height < height) {
          curRow.height = height;
          if (isImageElement(element) || element.type === ElementType.LATEX) {
            curRow.ascent = metrics.height;
          } else {
            curRow.ascent = ascent;
          }
        }
        curRow.elementList.push(rowElement);
      }
    }
    return rowList;
  }

  private _computePageList(): IRow[][] {
    const pageRowList: IRow[][] = [[]];
    const { pageMode } = this.options;
    const height = this.getHeight();
    const margins = this.getMargins();
    const marginHeight = margins[0] + margins[2];
    let pageHeight = marginHeight;
    let pageNo = 0;
    if (pageMode === PageMode.CONTINUITY) {
      pageRowList[0] = this.rowList;
      // 重置高度
      pageHeight += this.rowList.reduce((pre, cur) => pre + cur.height, 0);
      const dpr = window.devicePixelRatio;
      const pageDom = this.pageList[0];
      const pageDomHeight = Number(pageDom.style.height.replace('px', ''));
      if (pageHeight > pageDomHeight) {
        pageDom.style.height = `${pageHeight}px`;
        pageDom.height = pageHeight * dpr;
      } else {
        const reduceHeight = pageHeight < height ? height : pageHeight;
        pageDom.style.height = `${reduceHeight}px`;
        pageDom.height = reduceHeight * dpr;
      }
      this._initPageContext(this.ctxList[0]);
    } else {
      for (let i = 0; i < this.rowList.length; i++) {
        const row = this.rowList[i];
        if (
          row.height + pageHeight > height ||
          this.rowList[i - 1]?.isPageBreak
        ) {
          pageHeight = marginHeight + row.height;
          pageRowList.push([row]);
          pageNo++;
        } else {
          pageHeight += row.height;
          pageRowList[pageNo].push(row);
        }
      }
    }
    return pageRowList;
  }

  private _drawRichText(ctx: CanvasRenderingContext2D) {
    this.underline.render(ctx);
    this.strikeout.render(ctx);
    this.highlight.render(ctx);
    this.textParticle.complete();
  }

  private _drawRow(ctx: CanvasRenderingContext2D, payload: IDrawRowPayload) {
    const { rowList, pageNo, positionList, startIndex } = payload;
    const { scale, tdPadding } = this.options;
    const { isCrossRowCol, tableId } = this.range.getRange();
    const mode = this.getMode();
    let index = startIndex;
    for (let i = 0; i < rowList.length; i++) {
      const curRow = rowList[i];
      // 选区绘制记录
      const rangeRecord: IElementFillRect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      };
      let tableRangeElement: IElement | null = null;
      for (let j = 0; j < curRow.elementList.length; j++) {
        const element = curRow.elementList[j];
        const metrics = element.metrics;
        // 当前元素位置信息
        const {
          ascent: offsetY,
          coordinate: {
            leftTop: [x, y],
          },
        } = positionList[curRow.startIndex + j];
        // 元素绘制
        if (isImageElement(element)) {
          this._drawRichText(ctx);
          this.imageParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.LATEX) {
          this._drawRichText(ctx);
          this.laTexParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.TABLE) {
          if (isCrossRowCol) {
            rangeRecord.x = x;
            rangeRecord.y = y;
            tableRangeElement = element;
          }
          this.tableParticle.render(ctx, element, x, y);
        } else if (element.type === ElementType.HYPERLINK) {
          this._drawRichText(ctx);
          this.hyperlinkParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.DATE) {
          this._drawRichText(ctx);
          this.dateParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.SUPERSCRIPT) {
          this._drawRichText(ctx);
          this.superscriptParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.SUBSCRIPT) {
          this._drawRichText(ctx);
          this.subscriptParticle.render(ctx, element, x, y + offsetY);
        } else if (element.type === ElementType.SEPARATOR) {
          this.separatorParticle.render(ctx, element, x, y);
        } else if (element.type === ElementType.PAGE_BREAK) {
          this.pageBreakParticle.render(ctx, element, x, y);
        } else if (
          element.type === ElementType.CHECKBOX ||
          element.controlComponent === ControlComponent.CHECKBOX
        ) {
          this._drawRichText(ctx);
          this.checkboxParticle.render(ctx, element, x, y + offsetY, mode);
        } else if (element.type === ElementType.TAB) {
          this._drawRichText(ctx);
        } else if (element.rowFlex === RowFlex.ALIGNMENT) {
          // 如果是两端对齐，因canvas目前不支持letterSpacing需单独绘制文本
          this.textParticle.record(ctx, element, x, y + offsetY, mode);
          this._drawRichText(ctx);
        } else if (element.type === ElementType.BLOCK) {
          this._drawRichText(ctx);
          this.blockParticle.render(pageNo, element, x, y);
        } else {
          this.textParticle.record(ctx, element, x, y + offsetY, mode);
        }
        const preElement = curRow.elementList[j - 1];
        // 下划线记录
        if (element.underline) {
          this.underline.recordFillInfo(
            ctx,
            x,
            y + curRow.height,
            metrics.width,
            0,
            element.color,
          );
        } else if (preElement?.underline) {
          this.underline.render(ctx);
        }
        // 删除线记录
        if (element.strikeout) {
          this.strikeout.recordFillInfo(
            ctx,
            x,
            y + curRow.height / 2,
            metrics.width,
          );
        } else if (preElement?.strikeout) {
          this.strikeout.render(ctx);
        }
        // 元素高亮记录
        if (element.highlight) {
          // 高亮元素相连需立即绘制，并记录下一元素坐标
          if (
            preElement &&
            preElement.highlight &&
            preElement.highlight !== element.highlight
          ) {
            this.highlight.render(ctx);
          }
          this.highlight.recordFillInfo(
            ctx,
            x,
            y,
            metrics.width,
            curRow.height,
            element.highlight,
          );
        } else if (preElement?.highlight) {
          this.highlight.render(ctx);
        }
        // 选区记录
        const { startIndex, endIndex } = this.range.getRange();
        if (
          startIndex !== endIndex &&
          startIndex <= index &&
          index <= endIndex
        ) {
          // 从行尾开始-绘制最小宽度
          if (startIndex === index) {
            const nextElement = this.elementList[startIndex + 1];
            if (nextElement && nextElement.value === ZERO) {
              rangeRecord.x = x + metrics.width;
              rangeRecord.y = y;
              rangeRecord.height = curRow.height;
              rangeRecord.width += this.options.rangeMinWidth;
            }
          } else {
            const positionContext = this.position.getPositionContext();
            // 表格需限定上下文
            if (
              (!positionContext.isTable && !element.tdId) ||
              positionContext.tdId === element.tdId
            ) {
              let rangeWidth = metrics.width;
              // 最小选区宽度
              if (rangeWidth === 0 && curRow.elementList.length === 1) {
                rangeWidth = this.options.rangeMinWidth;
              }
              // 记录第一次位置、行高
              if (!rangeRecord.width) {
                rangeRecord.x = x;
                rangeRecord.y = y;
                rangeRecord.height = curRow.height;
              }
              rangeRecord.width += rangeWidth;
            }
          }
        }
        index++;
        // 绘制表格内元素
        if (element.type === ElementType.TABLE) {
          const tdGap = tdPadding * 2;
          for (let t = 0; t < element.trList!.length; t++) {
            const tr = element.trList![t];
            for (let d = 0; d < tr.tdList!.length; d++) {
              const td = tr.tdList[d];
              this._drawRow(ctx, {
                positionList: td.positionList!,
                rowList: td.rowList!,
                pageNo,
                startIndex: 0,
                innerWidth: (td.width! - tdGap) * scale,
              });
            }
          }
        }
      }
      // 绘制富文本及文字
      this._drawRichText(ctx);
      // 绘制选区
      if (rangeRecord.width && rangeRecord.height) {
        const { x, y, width, height } = rangeRecord;
        this.range.render(ctx, x, y, width, height);
      }
      if (
        isCrossRowCol &&
        tableRangeElement &&
        tableRangeElement.id === tableId
      ) {
        const {
          coordinate: {
            leftTop: [x, y],
          },
        } = positionList[curRow.startIndex];
        this.tableParticle.drawRange(ctx, tableRangeElement, x, y);
      }
    }
  }

  private _clearPage(pageNo: number) {
    const ctx = this.ctxList[pageNo];
    const pageDom = this.pageList[pageNo];
    ctx.clearRect(0, 0, pageDom.width, pageDom.height);
    this.blockParticle.clear();
  }

  private _drawPage(
    positionList: IElementPosition[],
    rowList: IRow[],
    pageNo: number,
  ) {
    const { pageMode } = this.options;
    const innerWidth = this.getInnerWidth();
    const ctx = this.ctxList[pageNo];
    this._clearPage(pageNo);
    // 绘制背景
    // this.background.render(ctx);
    // 绘制页边距
    // this.margin.render(ctx, pageNo);
    // 渲染元素
    const index = rowList[0].startIndex;
    this._drawRow(ctx, {
      positionList,
      rowList,
      pageNo,
      startIndex: index,
      innerWidth,
    });
    // 绘制页眉
    // this.header.render(ctx);
    // 绘制页码
    // this.pageNumber.render(ctx, pageNo);
    // 搜索匹配绘制
    if (this.search.getSearchKeyword()) {
      this.search.render(ctx, pageNo);
    }
    // 绘制水印
    // if (pageMode !== PageMode.CONTINUITY && this.options.watermark.data) {
    //   this.waterMark.render(ctx);
    // }
  }

  private _lazyRender() {
    const positionList = this.position.getOriginalPositionList();
    this.lazyRenderIntersectionObserver?.disconnect();
    this.lazyRenderIntersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Number(
              (<HTMLCanvasElement>entry.target).dataset.index,
            );
            this._drawPage(positionList, this.pageRowList[index], index);
          }
        });
      },
    );
    this.pageList.forEach((el) => {
      this.lazyRenderIntersectionObserver!.observe(el);
    });
  }

  private _immediateRender() {
    const positionList = this.position.getOriginalPositionList();
    for (let i = 0; i < this.pageRowList.length; i++) {
      this._drawPage(positionList, this.pageRowList[i], i);
    }
  }

  public render(payload?: IDrawOption) {
    const { pageMode } = this.options;
    const {
      isSubmitHistory = 'action',
      isSetCursor = true,
      isCompute = true,
      isLazy = true,
    } = payload || {};
    let { curIndex } = payload || {};
    const innerWidth = this.getInnerWidth();
    // 计算文档信息
    if (isCompute) {
      // 行信息
      this.rowList = this._computeRowList(innerWidth, this.elementList);
      // 页面信息
      this.pageRowList = this._computePageList();
      // 位置信息
      this.position.computePositionList();
      // 搜索信息
      const searchKeyword = this.search.getSearchKeyword();
      if (searchKeyword) {
        this.search.compute(searchKeyword);
      }
    }
    // 清除光标等副作用
    this.imageObserver.clearAll();
    this.cursor.recoveryCursor();
    // 创建纸张
    const positionList = this.position.getOriginalPositionList();
    for (let i = 0; i < this.pageRowList.length; i++) {
      if (!this.pageList[i]) {
        this._createPage(i);
      }
    }
    // 移除多余页
    const curPageCount = this.pageRowList.length;
    const prePageCount = this.pageList.length;
    if (prePageCount > curPageCount) {
      const deleteCount = prePageCount - curPageCount;
      this.ctxList.splice(curPageCount, deleteCount);
      this.pageList
        .splice(curPageCount, deleteCount)
        .forEach((page) => page.remove());
    }
    // 绘制元素
    // 连续页因为有高度的变化会导致canvas渲染空白，需立即渲染，否则会出现闪动
    if (isLazy && pageMode === PageMode.PAGING) {
      this._lazyRender();
    } else {
      this._immediateRender();
    }
    // 光标重绘
    if (isSetCursor) {
      const positionContext = this.position.getPositionContext();
      if (positionContext.isTable) {
        const { index, trIndex, tdIndex } = positionContext;
        const tablePositionList =
          this.elementList[index!].trList?.[trIndex!].tdList[tdIndex!]
            .positionList;
        if (curIndex === undefined && tablePositionList) {
          curIndex = tablePositionList.length - 1;
        }
        const tablePosition = tablePositionList?.[curIndex!];
        this.position.setCursorPosition(tablePosition || null);
      } else {
        if (curIndex === undefined) {
          curIndex = positionList.length - 1;
        }
        this.position.setCursorPosition(positionList[curIndex!] || null);
      }
      this.cursor.drawCursor();
    }
    // 历史记录用于undo、redo
    if (isSubmitHistory) {
      const self = this;
      const oldElementList = deepClone(this.elementList);
      const { startIndex, endIndex } = this.range.getRange();
      const pageNo = this.pageNo;
      const oldPositionContext = deepClone(this.position.getPositionContext());
      this.historyManager.execute(function () {
        self.setPageNo(pageNo);
        self.position.setPositionContext(oldPositionContext);
        self.elementList = deepClone(oldElementList);
        self.range.setRange(startIndex, endIndex);
        self.render({ curIndex, isSubmitHistory: false });
      }, isSubmitHistory === 'mousedown');
    }
    // 信息变动回调
    nextTick(() => {
      // 页面尺寸改变
      if (this.listener.pageSizeChange) {
        this.listener.pageSizeChange(this.pageRowList.length);
      }
      // 文档内容改变
      if (this.listener.contentChange && isSubmitHistory) {
        this.listener.contentChange();
      }
    });
  }

  public destroy() {
    this.container.remove();
    this.globalEvent.removeEvent();
    this.scrollObserver.removeEvent();
    this.selectionObserver.removeEvent();
  }
}
