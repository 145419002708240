import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Drawer,
  Input,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';
import TabCheck from '@/pages/center/components/TabCheck';

const testItems = [
  { label: '常用项目', key: '常用项目' },
  { label: '急诊检验', key: '急诊检验' },
  { label: '临检', key: '临检' },
  { label: '生化', key: '生化' },
  { label: '免疫', key: '免疫' },
  { label: '微生物', key: '微生物' },
  { label: '分子生物', key: '分子生物' },
  { label: '内分泌系列', key: '内分泌系列' },
  { label: '组合项目', key: '组合项目' },
  { label: '肿瘤类', key: '肿瘤类' },
  { label: '优生优育', key: '优生优育' },
  { label: '药物浓度', key: '药物浓度' },
];

const data = Array.from({ length: 20 })
  .fill({
    label: '24小时尿蛋白定量',
  })
  .map((item: any, index) => ({
    label: `${item?.assayName}${index}`,
    value: index,
  }));

const Content = (props) => {
  const { onSubmit, currentTab, open, onClose, defaultValue } = props;
  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};
  const [currentInfo, setCurrentInfo] = useState<any>();
  const [formValues, setFormValues, getFormValues] = useGetState<any>();
  const [checkList, setCheckList, getCheckList] = useGetState<number[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');

  const [items, setItems] = useState([]);

  const hangleCheckItem = (data) => {
    const target = getCheckList();
    const v = target?.find((item: any) => item?.id === data?.id);
    if (!v) {
      setCheckList([...target, data]);
    } else {
      setCheckList([...target]?.filter((v: any) => v?.id !== data?.id));
    }
  };

  const handleDelItem = (data) => {
    hangleCheckItem(data);
  };

  const handleSetCurrent = (item) => {
    setCurrentInfo(item);
  };

  const handleClearAll = () => {
    setCheckList([]);
  };

  const handleClose = useCallback(() => {
    setCheckList([]);
    setCurrentInfo(null);
    setFormValues(null);
    onClose?.();
  }, [onClose, setCheckList, setCurrentInfo, setFormValues]);

  const getItemList = useCallback(async () => {
    const res =
      await vsf?.services?.AssayCommonController_getByCommonType_d6595d?.({
        commonType: 'DEPARTMENT',
      });
    setItems(res?.data);
  }, []);

  const onValuesChange = (value, record) => {
    setFormValues({
      ...(getFormValues() ?? {}),
      [currentInfo?.id]: record,
    });
  };

  const getOtherItemList = useCallback(async () => {
    const res = await vsf?.services?.AssayController_allAssay_b04dc7?.({
      qto: {
        assayTypeIs: currentTab,
        assayNameLike: searchValue,
      },
    });
    setItems(res?.data);
  }, [currentTab, searchValue]);

  useEffect(() => {
    if (currentTab === '常用项目') {
      getItemList();
    } else {
      getOtherItemList();
    }
  }, [getItemList, getOtherItemList, currentTab]);

  const target = items?.filter(
    (item: any) => item?.assayName?.indexOf(searchValue) >= 0,
  );

  const handleSubmit = useCallback(async () => {
    const formatValues = checkList?.map((item: any) => {
      const values = formValues?.[item?.id] ?? {
        sample: item?.sample,
        executeDepartment: '检验科',
      };
      return {
        id: item?.id,
        orderItemDtoList: {
          orderItemStatus: 'OPEN',
          orderTypeId: item?.id,
          orderType: 'ASSAY',
          enduring: hospitalType === 'in',
          orderName: {
            ...item,
            medicationName: item?.assayName,
            id: item?.id,
          },
          unitPrice: item?.unitPrice,
          executeDepartment: values?.executeDepartment,
          orderApplyBto: values,
        },
      };
    });
    onSubmit?.(formatValues);
    handleClose();
  }, [onSubmit, checkList, hospitalType, handleClose, formValues]);

  const setDefaultValue = useCallback(
    (value) => {
      const target = items?.find((item: any) => item?.id === value?.id);
      if (target) {
        setCheckList([target]);
      }
    },
    [items, setCheckList],
  );

  useEffect(() => {
    setDefaultValue(defaultValue);
  }, [defaultValue, setDefaultValue]);

  useEffect(() => {
    if (!currentInfo) {
      setCurrentInfo(checkList?.[0]);
    }
  }, [checkList, currentInfo]);

  return (
    <div className="cpoe-test">
      <div className="main">
        <div className="left">
          <Input
            width={344}
            placeholder="请输入项目名称或首字母"
            value={searchValue}
            onInput={(e: any) => {
              setSearchValue(e?.target?.value);
            }}
          />
          <div className="list">
            {target?.map((item: any, index) => (
              <div
                className="item"
                key={index}
                onClick={() => hangleCheckItem(item)}
              >
                <div>
                  <div>
                    <Checkbox
                      value={
                        checkList?.findIndex(
                          (citem: any) => citem?.id === item.id,
                        ) >= 0
                      }
                    />
                  </div>
                  <div>{item?.assayName}</div>
                </div>
                <div>
                  <div>
                    <Icon type="icon-Frame1" />
                  </div>
                  <div>
                    <Icon type="icon-Frame-14" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <div className="first">
            <Block
              title="检验项目"
              scroll
              extra={
                <div className="clear-all" onClick={handleClearAll}>
                  <Icon type="icon-shanchuwudi" />
                  <span>清空</span>
                </div>
              }
            >
              <div className="check-list">
                {checkList?.map((v: any, index) => {
                  return (
                    <div
                      className={classnames('item', {
                        'active-item': v?.id === currentInfo?.id,
                      })}
                      key={index}
                      onClick={() => {
                        handleSetCurrent(v);
                      }}
                    >
                      <div className="content">
                        <div className="label">{v?.assayName}</div>
                        <div className="value">
                          {v?.unitPrice ? `¥ ${v?.unitPrice}` : ''}
                        </div>
                      </div>
                      <div
                        className="oper"
                        onClick={() => {
                          handleDelItem(v);
                        }}
                      >
                        <Icon type="icon-shanchuwudi" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Block>
          </div>
          <div className="second">
            <Block title="标本信息">
              <VSControlledForm
                className="info-form"
                key={currentInfo?.id}
                value={formValues?.[currentInfo?.id]}
                labelAlign="left"
                id="assayForm"
                onValuesChange={onValuesChange}
              >
                <VSFormLayout columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['sample']}
                    label="标本"
                    valueType="select"
                    rules={[{ required: true }]}
                    fieldProps={{
                      defaultValue: currentInfo?.sample ?? '',
                    }}
                    dataSource={[currentInfo?.sample ?? '']}
                  />

                  <VSFormItem
                    name={['executeDepartment']}
                    label="科室"
                    valueType="select"
                    rules={[{ required: true }]}
                    fieldProps={{
                      defaultValue: '检验科',
                    }}
                    dataSource={[
                      {
                        label: '检验科',
                        value: '检验科',
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['menstrualCycle']}
                    label="生理周期"
                    valueType="select"
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSFormItem
                    name={['collectionParts']}
                    label="采集部位"
                    valueType="select"
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSFormItem
                    name={['collectionLocation']}
                    label="采集地点"
                    valueType="select"
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSFormItem
                    name={['comment']}
                    label="备注"
                    valueType="textarea"
                    fieldProps={{
                      className: 'textarea',
                    }}
                  />
                </VSFormLayout>
              </VSControlledForm>
            </Block>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="info">
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥ 380.00</span>
        </div>
        <Button className="cancel" onClick={handleClose}>
          取消
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          确定
        </Button>
      </div>
    </div>
  );
};

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [currentTab, setCurrentTab] = useState('常用项目');
  const [open, setOpen] = useState<boolean>(false);
  const [defaultValue, setdefaultValue] = useState<any>();

  const onChange = (v, record) => {
    setCurrentTab(v);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    if (v) {
      setdefaultValue(v);
      setCurrentTab(v?.categoryType);
    }
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer"
      title={
        <TabCheck
          onChange={onChange}
          dataSource={testItems}
          value={currentTab}
        />
      }
      placement="right"
      onClose={onClose}
      open={open}
      width={1360}
    >
      <Content
        defaultValue={defaultValue}
        open={open}
        currentTab={currentTab}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
