import './index.less';

import vsf from '@vs/vsf-boot';
import { DateRangePicker, Radio, RadioGroup, Select } from '@vs/vsf-kit';
import classnames from 'classnames';
import { async } from 'q';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/Icon';

const mock = [
  {
    label: '我的',
    value: 3,
  },
  {
    label: '本组',
    value: 6,
  },
  {
    label: '本科',
    value: 12,
  },
];

const selectData = [
  { label: '210病区', value: '210病区' },
  { label: '211病区', value: '211病区' },
  { label: '212病区', value: '212病区' },
  { label: '213病区', value: '213病区' },
  { label: '214病区', value: '214病区' },
  { label: '215病区', value: '215病区' },
];

const selectTypeData = [
  { label: '在科', value: '在科' },
  { label: '会诊', value: '会诊' },
  { label: '预住院', value: '预住院' },
  { label: '转科', value: '转科' },
  { label: '出院', value: '出院' },
];

const Index = (props) => {
  const { handelOnchange } = props;

  const [radioValue, setRadioValue] = useState(3);
  const [selectValue, setSelectValueValue] = useState('210病区');
  const [typeValue, setTypeValue] = useState('在科');

  const onChange = (v) => {
    setRadioValue(v);
  };

  return (
    <div className="Inpatient_bed_card_search_content">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          optionType="button"
          onChange={onChange}
          value={radioValue}
          dataSource={mock}
        ></RadioGroup>
        <div className="Inpatient_bed_card_search_content_text">病区筛选</div>
        <Select
          allowClear
          width={280}
          dataSource={selectData}
          value={selectValue}
          onChange={(v) => {
            handelOnchange({
              selectValue: v,
              typeValue,
            });
            setSelectValueValue(v);
          }}
        />
        <div className="Inpatient_bed_card_search_content_text">患者类型</div>
        <Select
          value={typeValue}
          allowClear
          width={280}
          dataSource={selectTypeData}
          onChange={(v) => {
            handelOnchange({
              selectValue,
              typeValue: v,
            });
            setTypeValue(v);
          }}
        />
      </div>
      <div className="Inpatient_bed_card_search_content_change">
        <div>切换列表</div>
        <Icon size={15} type="icon-qiehuan" />
      </div>
    </div>
  );
};

export default Index;
