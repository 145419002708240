import './index.less';

import { definePage } from '@vs/vsf-boot';
import {
  Button,
  Input,
  Tabs,
  TabsProps,
  Tooltip,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { getPrefixCls } from '@/config';
import Empty from '@/module/empty';
import Icon from '@/module/Icon';
import Visible from '@/module/Visible';

import Checked from './checked';
import Examine from './examine';
import History from './history';
import Info from './info';
import Medical from './medical';
import PreviousHistory from './previous_history/';
import Reproduction from './reproduction';
import Main from '../main';
import MedicalIframe from './MedicalIframe';

const items = [
  {
    key: '2',
    label: '病历',
    icon: <Icon type="icon-bingli" size={30} />,
    children: <Medical />,
  },
  {
    key: '5',
    label: '生殖病历',
    icon: <Icon type="icon-shengzhike" size={30} />,
    children: <Reproduction />,
  },
  {
    key: '3',
    label: '检验报告',
    icon: <Icon type="icon-jianyan" size={30} />,
    children: <Examine />,
  },
  {
    key: '11',
    label: '检查报告',
    icon: <Icon type="icon-jiancha1" size={30} />,
    children: <Checked />,
  },
  {
    key: '4',
    label: '手术记录',
    icon: <Icon type="icon-Frame-5" size={34} />,
    children: <Empty desc="当前页面没有内容哦~" />,
  },
  {
    key: '8',
    label: '就诊史',
    icon: <Icon type="icon-jiuzhenshi" size={30} />,
    children: <History />,
  },
  {
    key: '9',
    label: '既往史',
    icon: <Icon type="icon-jiwangshi" size={30} />,
    children: <PreviousHistory />,
  },
  {
    key: '10',
    label: '外来资料',
    icon: <Icon type="icon-menzhenzhuyuanwailaiziliao" size={30} />,
    children: <Empty desc="当前页面没有内容哦~" />,
  },
];

const normalItems = [
  {
    key: '2',
    label: '病历',
    icon: <Icon type="icon-bingli1" size={30} />,
    children: <MedicalIframe />,
  },
  {
    key: '5',
    label: '生殖病历',
    icon: <Icon type="icon-shengzhike" size={30} />,
    children: <Reproduction />,
  },
  {
    key: '222',
    label: '医嘱',
    icon: <Icon type="icon-bingli" size={30} />,
    children: <Main prefixCls={getPrefixCls('center')} />,
  },
  {
    key: '3',
    label: '检验报告',
    icon: <Icon type="icon-jianyan" size={30} />,
    children: <Examine />,
  },
  {
    key: '11',
    label: '检查报告',
    icon: <Icon type="icon-jiancha1" size={30} />,
    children: <Checked />,
  },
  {
    key: '4',
    label: '手术记录',
    icon: <Icon type="icon-Frame-5" size={34} />,
    children: <Empty desc="当前页面没有内容哦~" />,
  },
  {
    key: '8',
    label: '就诊史',
    icon: <Icon type="icon-jiuzhenshi" size={30} />,
    children: <History />,
  },
  {
    key: '9',
    label: '既往史',
    icon: <Icon type="icon-jiwangshi" size={30} />,
    children: <PreviousHistory />,
  },
  {
    key: '10',
    label: '外来资料',
    icon: <Icon type="icon-menzhenzhuyuanwailaiziliao" size={30} />,
    children: <Empty desc="当前页面没有内容哦~" />,
  },
];

const inItems = [
  {
    key: '1',
    label: '概览',
    icon: <Icon type="icon-zhuyuangaishu" size={30} />,
    children: <Info />,
  },
];

const Index = (props) => {
  const { prefixCls, size = 'large' } = props;
  const { user } = props?.stores || {};
  const { hospitalType } = user || {};

  const [currentKey, setCurrentKey] = useState<string>(
    hospitalType === 'in' ? '1' : '2',
  );

  useEffect(() => {
    setCurrentKey(hospitalType === 'in' ? '1' : '2');
  }, [hospitalType]);

  const baseItems = size === 'large' ? items : normalItems;
  const target = hospitalType === 'in' ? inItems?.concat(baseItems) : baseItems;

  const targetContent = target?.find((item) => item?.key === currentKey);

  return (
    <>
      <div className={`${prefixCls}-leftContainer-bg`}></div>
      <div className={`${prefixCls}-leftContainer ${size}-leftContainer`}>
        <div className="tab-icon">
          <div
            className={classnames('tab-line', {
              'tab-line-in': hospitalType === 'in',
              'tab-line-out': hospitalType === 'out',
            })}
          ></div>
          <div className="tab-icon-container">
            {target?.map((item, index) => {
              return (
                <Tooltip
                  title={
                    <span
                      style={{
                        color: '#3276E8',
                      }}
                    >
                      {item?.label}
                    </span>
                  }
                  color="#F1F6FF"
                  key={index}
                >
                  <div
                    className={classnames('tab-icon-block', {
                      'tab-icon-block-active': item?.key === currentKey,
                    })}
                    key={index}
                    onClick={() => {
                      setCurrentKey(item?.key);
                    }}
                  >
                    {item?.icon}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
        <div className="main">
          {targetContent?.children}
          {/* {target?.map((item) => {
            return (
              <Visible key={item?.key} value={currentKey === item?.key}>
                {item?.children}
              </Visible>
            );
          })} */}
        </div>
      </div>
    </>
  );
};

export default definePage(Index);
