import { createFromIconfontCN } from '@vs/vsf-icons';
import React from 'react';

export const ICON_URL = '//at.alicdn.com/t/c/font_4190246_hn2zb2s91xf.js';

const IconFont = createFromIconfontCN({
  scriptUrl: ICON_URL,
});

type IconType = {
  type: string;
  style?: any;
  size?: number;
};

const Index = (props: IconType) => {
  const { type, style = {}, size } = props;
  const formatStyle = style;
  if (size) {
    formatStyle['fontSize'] = size;
  }
  return <IconFont className="vp-icon" type={type} style={formatStyle} />;
};

export default Index;
