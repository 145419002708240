import './index.less';

import vsf from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import className from 'classnames';
import React, { useEffect, useState } from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';

// 既往史

const blockTitleStyle = {
  color: '#343B42',
  fontWeight: 600,
};

const Index = () => {
  const renderAdd = () => {
    return <div className="previous_history_add">新增</div>;
  };

  const renderOption = (data) => {
    return (
      <div className="previous_history_option">
        <div className="previous_history_option_item">
          <Icon type="icon-Frame-21" size={18} />
        </div>
        <div>
          <Icon type="icon-shanchuwudi" size={18} />
        </div>
      </div>
    );
  };

  const renderAllergyType = (data) => {
    return (
      <div
        //     className="previous_history_diseas_type
        // previous_history_diseas_type_anxious"
        className={className(
          'previous_history_allergys_type',
          data?.type === '食物'
            ? 'previous_history_allergys_type_food'
            : 'previous_history_allergys_type_medicine',
        )}
      >
        {data?.type}
      </div>
    );
  };

  const renderDiseasType = (data) => {
    return (
      <div
        //     className="previous_history_diseas_type
        // previous_history_diseas_type_anxious"
        className={className(
          'previous_history_diseas_type',
          data?.type === '急症'
            ? 'previous_history_diseas_type_anxious'
            : 'previous_history_diseas_type_slow',
        )}
      >
        {data?.type}
      </div>
    );
  };

  return (
    <div className="previous_history">
      <div className="previous_history_list">
        <Block
          title="过敏史"
          titleStyle={{ ...blockTitleStyle }}
          extra={renderAdd()}
        >
          <VSPromiseTable
            className="previous_history_list_tabel"
            pagination={false}
            onFetch={async (params) => {
              return {
                data: [
                  {
                    type: '食物',
                    allergen: '羊肉',
                    degree: '轻/',
                    time: '2023-08-11',
                    currentState: '治愈',
                    informationSource: '本院发生',
                  },
                  {
                    type: '药物',
                    allergen: '注射用青霉素钠',
                    degree: '轻/',
                    time: '2023-08-11',
                    currentState: '治愈',
                    informationSource: '患者自述',
                  },
                  {
                    type: '药物',
                    allergen: '注射用青霉素钠',
                    degree: '轻/',
                    time: '2023-08-11',
                    currentState: '治愈',
                    informationSource: '患者自述',
                  },
                ],
                total: 0,
              };
            }}
            // scroll={{
            //   y: 300,
            // }}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              valueType="text"
              dataIndex={['type']}
              title="过敏类型"
              render={(_, data) => {
                return <div style={{ color: '#3276E8' }}>{data?.type}</div>;
              }}
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['allergen']}
              title="过敏原"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['degree']}
              title="过敏程度"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['time']}
              title="过敏时间"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['informationSource']}
              title="信息来源"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['currentState']}
              title="当前状态"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['done']}
              title=""
              render={(_, data) => {
                return renderOption(data);
              }}
            ></VSTableColumn>
          </VSPromiseTable>
        </Block>
      </div>
      <div className="previous_history_list">
        <Block
          titleStyle={{ ...blockTitleStyle }}
          title="疾病史"
          extra={renderAdd()}
        >
          <VSPromiseTable
            className="previous_history_list_tabel"
            pagination={false}
            onFetch={async (params) => {
              return {
                data: [
                  {
                    type: '急症',
                    name: '阑尾炎',
                    time: '2023-04-04',
                    continueTime: '1年/治愈',
                    currentState: '治愈',
                    informationSource: '本院发生',
                  },
                  {
                    type: '慢病',
                    name: '高血压',
                    time: '2023-08-02',
                    continueTime: '2年/治愈',
                    currentState: '治愈',
                    informationSource: '本院发生',
                  },
                  {
                    type: '慢病',
                    name: '高血压',
                    time: '2023-08-02',
                    continueTime: '3年/治愈',
                    currentState: '治愈',
                    informationSource: '本院发生',
                  },
                ],
                total: 0,
              };
            }}
            // scroll={{
            //   y: 300,
            // }}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              valueType="text"
              dataIndex={['type']}
              title="疾病类型"
              render={(_, data) => {
                return renderDiseasType(data);
              }}
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['name']}
              title="疾病名称"
              // render={(_, data) => {
              //   return data?.reportItem?.assayItemName;
              // }}
            ></VSTableColumn>

            <VSTableColumn
              valueType="text"
              dataIndex={['continueTime']}
              title="持续时间"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['time']}
              title="发病时间"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['informationSource']}
              title="信息来源"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['currentState']}
              title="当前状态"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['done']}
              title=""
              render={(_, data) => {
                return renderOption(data);
              }}
            ></VSTableColumn>
          </VSPromiseTable>
        </Block>
      </div>
      <div className="previous_history_list">
        <Block
          titleStyle={{ ...blockTitleStyle }}
          title="手术史"
          extra={renderAdd()}
        >
          <VSPromiseTable
            className="previous_history_list_tabel"
            pagination={false}
            onFetch={async (params) => {
              return {
                data: [],
                total: 0,
              };
            }}
            // scroll={{
            //   y: 300,
            // }}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              valueType="text"
              dataIndex={['name']}
              title="手术名称"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['time']}
              title="手术时间"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['informationSource']}
              title="信息来源"
            ></VSTableColumn>
          </VSPromiseTable>
        </Block>
      </div>
      <div style={{ marginBottom: '0px' }} className="previous_history_list">
        <Block
          titleStyle={{ ...blockTitleStyle }}
          title="家族史"
          extra={renderAdd()}
        >
          <VSPromiseTable
            className="previous_history_list_tabel"
            pagination={false}
            onFetch={async (params) => {
              return {
                data: [
                  {
                    type: '父亲',
                    name: '高血压',
                    informationSource: '本院发生',
                    time: '2019-04-02',
                  },
                  {
                    type: '母亲',
                    name: '糖尿病伴并发症，其他特指的',
                    informationSource: '患者自述',
                    time: '2019-04-01',
                  },
                ],
                total: 0,
              };
            }}
            // scroll={{
            //   y: 300,
            // }}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              valueType="text"
              dataIndex={['type']}
              title="家属类型"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['name']}
              title="疾病名称"
            ></VSTableColumn>

            <VSTableColumn
              valueType="text"
              dataIndex={['time']}
              title="发病时间"
            ></VSTableColumn>
            <VSTableColumn
              valueType="text"
              dataIndex={['informationSource']}
              title="信息来源"
            ></VSTableColumn>

            <VSTableColumn
              valueType="text"
              dataIndex={['done']}
              title=""
              render={(_, data) => {
                return renderOption(data);
              }}
            ></VSTableColumn>
          </VSPromiseTable>
        </Block>
      </div>
    </div>
  );
};

export default Index;
