import { DateRangePicker, Radio, RadioGroup } from '@vs/vsf-kit';
import React, { useState } from 'react';

import CheckBlock from './check';

const mock = [
  {
    label: '三个月',
    value: 3,
  },
  {
    label: '半年',
    value: 6,
  },
  {
    label: '一年',
    value: 12,
  },
  {
    label: '全部',
    value: -1,
  },
];

const Index = (props) => {
  const { search } = props;
  const [radioValue, setRadioValue] = useState(3);
  const [checkBlockValue, setCheckBlockValue] = useState(3);

  const onChange = (v) => {
    setRadioValue(v);
  };

  return (
    <div className="history-search">
      <DateRangePicker placeholder={['开始时间', '结束时间']} />
      <RadioGroup
        optionType="button"
        onChange={onChange}
        value={radioValue}
        dataSource={mock}
        style={{
          marginLeft: '12px',
        }}
      ></RadioGroup>
      <CheckBlock
        style={{
          marginLeft: 48,
        }}
        handelConditionQuery={(v) => {
          setCheckBlockValue(v);
          search(v);
        }}
      />
    </div>
  );
};

export default Index;
