import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Dropdown, Input, Popover } from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/Icon';

import Bed from './inpatient_bed_card';
import List from './list';

const Index = (props) => {
  const { user } = props?.stores || {};
  const { patientList, hospitalType, currentPatient } = user || {};

  const [dropdownOpen, setDropdownOpen] = useState(true);

  const onSelect = (v) => {
    vsf?.stores?.user?.setCurrentPatient(v);
    setDropdownOpen(false);
  };

  const onSelectBed = (v) => {
    vsf?.stores?.user?.setCurrentPatient(v?.info);
    setDropdownOpen(false);
  };

  useEffect(() => {
    setDropdownOpen(true);
  }, [hospitalType]);

  return (
    <div
      className={classnames({
        oper: hospitalType === 'out',
        'oper-in': hospitalType === 'in',
      })}
    >
      {hospitalType === 'out' ? (
        <div className="first">
          <Button icon={<Icon type="icon-zhongjiao" />}>重叫</Button>
          <Button icon={<Icon type="icon-guohao" />}>过号</Button>
          <Button icon={<Icon type="icon-xiayige" />}>下一个</Button>
          <Button>结束就诊</Button>
        </div>
      ) : (
        <div className="first">
          <Button>当前床卡:</Button>
          <Button type="primary">{currentPatient?.bedNum}号</Button>
        </div>
      )}

      {hospitalType === 'out' ? (
        <Dropdown
          menu={{
            items: [],
          }}
          onOpenChange={(v) => {
            setDropdownOpen(v);
          }}
          dropdownRender={(menu) => (
            <List data={patientList} onSelect={onSelect} />
          )}
          open={dropdownOpen}
          trigger={['click']}
        >
          <div className="second">
            <div
              className="left"
              onClick={() => {
                setDropdownOpen(true);
              }}
            >
              <Icon type="icon-xia" size={24} />
              <Icon type="icon-a-Useryonghu" size={18} />
              <span className="label">候诊</span>
              <span className="value">{patientList?.length}</span>
            </div>
            <div className="input">
              <Input
                placeholder="请输入患者ID或姓名"
                onFocus={() => {
                  setDropdownOpen(true);
                }}
              />
            </div>
            <div className="scan">
              <Icon type="icon-a-Scan-codesaoma" size={24} />
            </div>
            <div className="list">
              <Icon type="icon-a-List-viewliebiaoshitu" size={24} />
            </div>
          </div>
        </Dropdown>
      ) : (
        <Dropdown
          menu={{
            items: [],
          }}
          onOpenChange={(v) => {
            setDropdownOpen(v);
          }}
          dropdownRender={(menu) => <Bed onSelect={onSelectBed} />}
          open={dropdownOpen}
          trigger={['click']}
        >
          <div className="second-in">
            <Button type="primary">
              <Icon type="icon-a-Scan-codesaoma" />
              <span>切换床卡</span>
              <Icon type="icon-xia" />
            </Button>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default definePage(Index);
