import React, { useCallback } from "react";
import vsf, { definePage } from "@vs/vsf-boot";

const MedicalIframe = props => {
  const { user } = props?.stores || {};
  const { hospitalType } = user || {}

  const getClinicType = () => {
    return hospitalType === 'out' ? 'OUTP' : 'INP'
  }

  const renderIframe = useCallback(() => {
    return (
      <iframe
        style={{ width: '100%', height: '100%', border: 'none' }}
        src={`https://triptorelin.teitui.com/index?encounterId=1&clinicType=${getClinicType()}&deptCode=10500501&doctorCode=1000`}
      />
    )
  }, [hospitalType])

  return renderIframe()
}

export default definePage(MedicalIframe)