import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Drawer,
  Input,
  RadioGroup,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import { flattenDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';
import TabCheck from '@/pages/center/components/TabCheck';

const testItems = [
  {
    label: '常用检查',
    key: '常用检查',
    value: '常用检查',
    dataSource: ['妇产超声科'],
  },
  { label: '放射', key: '放射', value: '放射', dataSource: ['放射科'] },
  { label: '超声', key: '超声', value: '超声', dataSource: ['超声医学科'] },
  {
    label: '功能检查',
    key: '功能检查',
    value: '功能检查',
    dataSource: {
      303: ['心电图室'],
      402: ['脑电图室'],
      403: ['肌电图室'],
    },
  },
  { label: '内镜中心', key: '内镜中心', value: '内镜中心' },

  { label: '核医学', key: '核医学', value: '核医学' },
];

const data = Array.from({ length: 20 })
  .fill({
    label: '24小时尿蛋白定量',
  })
  .map((item: any, index) => ({
    label: `${item?.label}${index}`,
    value: index,
  }));

const mock = [
  { label: '个人常用', value: 'PERSON' },
  { label: '科室常用', value: 'DEPARTMENT' },
];

const Content = (props) => {
  const { onSubmit, currentTab, open, onClose, defaultValue } = props;
  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};

  const [init, setInit] = useState(false);

  const [currentInfo, setCurrentInfo] = useState<any>({
    purpose: '明确诊断',
    reportType: '图文报告',
  });
  const [checkList, setCheckList, getCheckList] = useGetState<any[]>([]);

  const [radioValue, setRadioValue] = useState('PERSON');

  const [firstList, setFirstList] = useState([]);
  const [firstActive, setFirstActive] = useState('');

  const [secondList, setSecondList] = useState([]);
  const [secondActive, setSecondActive] = useState('');

  const [thirdList, setThirdList] = useState([]);
  const [thirdActive, setThirdActive] = useState('');

  const [searchValue, setSearchValue] = useState<string>('');

  const [departmentDataSource, setDepartmentDataSource] = useState<any[]>();

  const handleChangeRadio = (v) => {
    setRadioValue(v);
  };

  const handleCheckItem = (data) => {
    const target = getCheckList();
    const v = target?.find((item) => item?.id === data?.id);
    if (!v) {
      setCheckList([...target, data]);
    } else {
      setCheckList([...target]?.filter((v) => v?.id !== data?.id));
    }
  };

  const handleDelItem = (data) => {
    handleCheckItem(data);
  };

  const handleSetCurrent = (item) => {
    setCurrentInfo(item);
  };

  const handleClearAll = () => {
    setCheckList([]);
  };

  const target = data?.filter((item) => item?.label.indexOf(searchValue) >= 0);

  const handleChooseFirst = (data) => {
    setFirstActive(data?.id);
  };

  const handleChooseSecond = (data) => {
    setSecondActive(data?.partsName);
  };

  const handleCloseTag = (e, v) => {
    e.preventDefault();
    handleDelItem(v);
  };

  const getCategory = useCallback(async () => {
    if (currentTab) {
      const res =
        await vsf?.services?.ExamineCategoryController_getByCategoryType_831347?.(
          { categoryType: currentTab },
        );
      setFirstList(res?.data);
      if (defaultValue?.categoryId && !init) {
        setFirstActive(defaultValue?.categoryId);
      }
    }
  }, [currentTab, defaultValue, init]);

  useEffect(() => {
    console.log('currentTab', currentTab);
    getCategory();
  }, [getCategory, currentTab]);

  useEffect(() => {
    setSecondList([]);
    setThirdList([]);
    handleClearWithFirst();
    setFirstActive('');
  }, [currentTab]);

  const getPart = useCallback(async () => {
    const res =
      await vsf?.services?.ExamineCommonController_allExamineCommonParts_4f83ac?.(
        {
          qto: {
            commonTypeIs: radioValue,
            examineCategoryIdIs: firstActive,
          },
        },
      );
    setSecondList(res?.data);
    if (defaultValue?.parts && !init) {
      setSecondActive(defaultValue?.parts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstActive, radioValue, defaultValue, init]);

  useEffect(() => {
    if (firstActive) {
      getPart();
    }
  }, [getPart, firstActive]);

  useEffect(() => {
    setInit(false);
  }, [defaultValue]);

  const getList = useCallback(async () => {
    if (!firstActive || !secondActive) return;
    const res = await vsf?.services?.ExamineController_allExamine_6a9f09?.({
      qto: {
        commonTypeIs: radioValue,
        examineCategoryIdIs: firstActive,
        examinePartsIs: secondActive,
      },
    });
    setThirdList(res?.data);
    if (defaultValue && !init) {
      const target = res?.data?.find(
        (item: any) => item?.id === defaultValue?.id,
      );
      setCheckList([target]);
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstActive, secondActive, radioValue, secondList, defaultValue, init]);

  const handleClearWithFirst = () => {
    setSecondActive('');
    setThirdActive('');
  };
  const handleClearWithSecond = () => {
    setThirdActive('');
  };

  useEffect(() => {
    if (secondActive) {
      getList();
    }
  }, [getList, secondActive]);

  const handleSubmit = useCallback(async () => {
    const values = await vsf.refs.examForm?.validateFieldsReturnFormatValue();
    const formatValues = checkList?.map((item) => {
      return {
        id: item?.id,
        orderItemDtoList: {
          orderItemStatus: 'OPEN',
          orderTypeId: item?.id,
          orderType: 'EXAMINE',
          enduring: hospitalType === 'in',
          orderName: {
            ...item,
            medicationName: item?.examineName,
            id: item?.id,
          },
          executeDepartment: values?.executeDepartment,
          urgent: values?.extraInfo?.includes('urgent'),
          unitPrice: item?.unitPrice,

          orderApplyBto: {
            urgent: values?.extraInfo?.includes('urgent'),
            anesthesia: values?.extraInfo?.includes('anesthesia'),
            anesthesiaAssessment: values?.extraInfo?.includes(
              'anesthesiaAssessment',
            ),
            reportType: values?.reportType,
            purpose: values?.purpose,
            executeDepartment: values?.executeDepartment,
          },
        },
      };
    });
    onSubmit?.(formatValues);
    onClose();
  }, [checkList, hospitalType, onSubmit, onClose]);

  useEffect(() => {
    const dataSource =
      testItems?.find((item: any) => item?.key === currentTab)?.dataSource ??
      [];
    let currentDataSource: any[];
    if (!Array.isArray(dataSource)) {
      currentDataSource = firstActive ? dataSource?.[firstActive] : [];
    } else {
      currentDataSource = dataSource;
    }
    const target = currentDataSource?.map((item) => ({
      label: item,
      value: item,
    }));
    setDepartmentDataSource(target);
    vsf?.refs?.examForm?.setFieldValue('executeDepartment', target?.[0]?.value);
  }, [currentTab, firstActive]);

  return (
    <div className="cpoe-exam">
      <div className="header"></div>
      <div className="main">
        <Block
          title="项目列表"
          className="left"
          extra={
            <div>
              <Input
                width={344}
                placeholder="请输入项目名称或首字母"
                value={searchValue}
                onInput={(e: any) => {
                  setSearchValue(e?.target?.value);
                }}
              />
              <RadioGroup
                optionType="button"
                onChange={handleChangeRadio}
                value={radioValue}
                dataSource={mock}
                style={{
                  marginLeft: '12px',
                }}
              ></RadioGroup>
            </div>
          }
        >
          <div className="left-container">
            <div className="first">
              {firstList?.map((item: any, index) => (
                <div
                  key={index}
                  className={classnames('item', {
                    'active-item': firstActive === item?.id,
                  })}
                  onClick={() => {
                    handleChooseFirst(item);
                    handleClearWithFirst();
                  }}
                >
                  <span className="label">{item?.categoryName}</span>
                  <Icon type="icon-you" size={22} />
                </div>
              ))}
            </div>
            <div className="second">
              {secondList?.map((item: any, index) => (
                <div
                  key={index}
                  className={classnames('item', {
                    'active-item': secondActive === item?.partsName,
                  })}
                  onClick={() => {
                    handleChooseSecond(item);
                    handleClearWithSecond();
                  }}
                >
                  <span className="label">{item?.partsName}</span>
                  {/* <Icon type="icon-you" size={22} /> */}
                </div>
              ))}
            </div>
            <div className="third">
              {thirdList?.map((item: any, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onClick={() => handleCheckItem(item)}
                  >
                    <div className="left">
                      <Checkbox
                        value={
                          checkList?.findIndex(
                            (citem) => citem?.id === item?.id,
                          ) >= 0
                        }
                      />
                      <span className="label">{item?.examineName}</span>
                    </div>
                    <div className="right">
                      <Icon type="icon-Frame-14" />
                      <Icon type="icon-Frame-14" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Block>
        <div className="right">
          <div className="first">
            <Block title="检查项目">
              <div className="check-list">
                {checkList?.map((v, index) => {
                  return (
                    <Tag
                      key={index}
                      closable
                      color="processing"
                      onClose={(e) => {
                        handleCloseTag(e, v);
                      }}
                    >
                      {v?.examineName}
                    </Tag>
                  );
                })}
              </div>
              <VSControlledForm
                className="info-form"
                value={currentInfo}
                labelAlign="left"
                id="examForm"
              >
                <VSFormLayout columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['extraInfo']}
                    label=""
                    valueType="checkbox"
                    dataSource={[
                      { label: '加急', value: 'urgent' },
                      { label: '麻醉', value: 'anesthesia' },
                      { label: '麻醉评估', value: 'anesthesiaAssessment' },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['reportType']}
                    label="报告类型"
                    valueType="select"
                    rules={[{ required: true }]}
                    fieldProps={{}}
                    dataSource={[
                      {
                        label: '图文报告',
                        value: '图文报告',
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['purpose']}
                    label="检查目的"
                    valueType="select"
                    rules={[{ required: true }]}
                    dataSource={[
                      {
                        label: '明确诊断',
                        value: '明确诊断',
                      },
                      {
                        label: '排除诊断',
                        value: '排除诊断',
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['executeDepartment']}
                    label="执行科室"
                    valueType="select"
                    fieldProps={{
                      defaultValue: departmentDataSource?.[0]?.value,
                    }}
                    rules={[{ required: true }]}
                    dataSource={departmentDataSource}
                  />
                </VSFormLayout>
              </VSControlledForm>
            </Block>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="info">
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥ 380.00</span>
        </div>
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          确定
        </Button>
      </div>
    </div>
  );
};

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [currentTab, setCurrentTab] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [defaultValue, setdefaultValue] = useState<any>();

  const onChange = (v, record) => {
    setCurrentTab(v);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    console.log('v', v);
    if (v) {
      setdefaultValue(v);
      setCurrentTab(v?.categoryType);
    } else {
      setCurrentTab('常用检查');
    }
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer"
      title={
        <TabCheck
          value={currentTab}
          onChange={onChange}
          dataSource={testItems}
        />
      }
      placement="right"
      onClose={onClose}
      open={open}
      width={1360}
      destroyOnClose={true}
    >
      <Content
        defaultValue={defaultValue}
        open={open}
        currentTab={currentTab}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
