import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';

const labelWidth = 96;
const formItemWidth = 959;

const MaleRecordOne = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};

  return (
    <VSForm
      id="maleRecordOne"
      labelAlign="left"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.MaleRecordOneController_getMaleRecordOneByPatientId_e76ed9?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
    >
      <FormBlock title="一般情况">
        <VSFormLayout key="0" columnCount={1} labelWidth={labelWidth}>
          {/* <VSFormItem
            name={['bedNumber']}
            label="床号"
            valueType="text"
            rules={[
              {
                message: 'bed_number长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          /> */}

          <VSFormItem
            name={['chiefComplaint']}
            label="主诉"
            valueType="text"
            rules={[
              {
                message: 'chief_complaint长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['currentMedicalHistory']}
            label="现病史"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="既往病史">
        <VSFormLayout key="1" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['historyOfLiverDisease']}
            label="肝病史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.History_of_liver_disease}
            fieldProps={{}}
          />
          <VSFormItem
            name={['historyOfLiverDiseaseDescription']}
            // label="肝病史描述"
            valueType="text"
            rules={[
              {
                message: 'history_of_liver_disease_description长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{
              width: formItemWidth,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['tuberculosisHistory']}
            label="结核史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.History_of_liver_disease}
            fieldProps={{}}
          />
          <VSFormItem
            name={['descriptionOfTuberculosisHistory']}
            // label="结核史描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_tuberculosis_history长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="3" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['historyOfReproductiveSystemSurgery']}
            label="生殖系统手术"
            valueType="select"
            dataSource={
              vsf.stores.dbenums.enums.History_of_Reproductive_System_Surgery
            }
            fieldProps={{}}
          />

          <VSFormItem
            name={['descriptionOfReproductiveSystemSurgeryHistory']}
            // label="生殖系统手术史描述"
            valueType="text"
            rules={[
              {
                message:
                  'description_of_reproductive_system_surgery_history长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['historyOfMumps']}
            label="腮腺炎史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.History_of_mumps}
            fieldProps={{}}
          />

          <VSFormItem
            name={['descriptionOfHistoryOfMumps']}
            // label="腮腺炎史描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_history_of_mumps长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['cardiovascularDisease']}
            label="心血管疾病"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Cardiovascular_disease}
            fieldProps={{}}
          />
          <VSFormItem
            name={['descriptionOfCardiovascularDiseases']}
            // label="心血管疾病描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_cardiovascular_diseases长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="6" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['historyOfSexualTransmission']}
            label="性传播史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.History_of_Sexual_Transmission}
            fieldProps={{}}
          />

          <VSFormItem
            name={['descriptionOfSexualTransmissionHistory']}
            // label="性传播史描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_sexual_transmission_history长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="7" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['urinaryTractInfection']}
            label="泌尿系统感染"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.urinary_tract_infection}
            fieldProps={{}}
          />

          <VSFormItem
            name={['descriptionOfUrinarySystemInfection']}
            // label="泌尿系统感染描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_urinary_system_infection长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['historyOfEndocrineDiseases']}
            label="内分泌系统"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.History_of_Endocrine_Diseases}
            fieldProps={{}}
          />
          <VSFormItem
            name={['descriptionOfEndocrineDiseaseHistory']}
            // label="内分泌疾病史描述"
            valueType="text"
            rules={[
              {
                message: 'description_of_endocrine_disease_history长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['other']}
            label="其他"
            valueType="text"
            rules={[
              { message: 'other长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="个人史">
        <VSFormLayout key="10" columnCount={3} labelWidth={labelWidth}>
          <VSFormLayout key="11" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['smoke']}
              label="吸烟"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.smoke}
              fieldProps={{}}
            />
            <VSFormItem
              name={['frequency']}
              // label="频次"
              valueType="text"
              addonAfter="支/天"
              rules={[
                {
                  message: 'frequency长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['excessiveDrinking']}
            label="酗酒"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Excessive_drinking}
            fieldProps={{}}
          />
          <VSFormItem
            name={['drugUse']}
            label="吸毒"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Drug_use}
            fieldProps={{}}
          />
          <VSFormItem
            name={['historyOfMajorMentalStimulation']}
            label="重大精神刺激"
            valueType="select"
            dataSource={
              vsf.stores.dbenums.enums.History_of_Major_Mental_Stimulation
            }
            fieldProps={{}}
          />
          <VSFormLayout key="12" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['medicine']}
              label="药物"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.medicine}
              fieldProps={{}}
            />
            <VSFormItem
              name={['drugDescription']}
              // label="药物描述"
              valueType="text"
              rules={[
                {
                  message: 'drug_description长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['medicine']}
            label="药物"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.medicine}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugDescription']}
            // label="药物描述"
            valueType="text"
            rules={[
              {
                message: 'drug_description长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{ width: formItemWidth }}
          />
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['healthCondition']}
            label="健康状况"
            valueType="text"
            rules={[
              {
                message: 'health_condition长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormLayout key="12" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['birthDefect']}
              label="出生缺陷"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.birth_defect}
              fieldProps={{}}
            />

            <VSFormItem
              name={['defectDescription']}
              // label="缺陷描述"
              valueType="text"
              rules={[
                {
                  message: 'defect_description长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['genitalInflammation']}
            label="近6个月生殖器炎症"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Genital_inflammation}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="婚育史">
        <VSFormLayout key="12" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['ageOfMarriage']}
            label="结婚年龄"
            valueType="text"
            rules={[
              {
                message: 'age_of_marriage长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['inbreeding']}
            label="近亲婚配"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Inbreeding}
            fieldProps={{}}
          />
          <VSFormItem
            name={['remarriage']}
            label="再婚"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.remarriage}
            fieldProps={{}}
          />

          <VSFormItem
            name={['sexualLife']}
            label="性生活"
            valueType="text"
            rules={[
              {
                message: 'sexual_life长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="家族史">
        <VSFormLayout key="13" labelWidth={labelWidth}>
          <VSFormItem
            name={['familyHistory']}
            label="家族史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.family_history}
            fieldProps={{}}
          />
          <VSFormItem
            name={['elaborateOn']}
            label="详述"
            valueType="text"
            rules={[
              {
                message: 'elaborate_on长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{
              width: 667,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="14" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['urogenitalHistory']}
            label="泌尿生殖史"
            valueType="text"
            rules={[
              {
                message: 'urogenital_history长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="一般体格检查">
        <VSFormLayout key="15" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['height']}
            label="身高"
            valueType="text"
            addonAfter="cm"
            rules={[
              { message: 'height长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['weight']}
            label="体重"
            valueType="text"
            addonAfter="Kg"
            rules={[
              { message: 'weight长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['tr']}
            label="TR(血压)"
            valueType="text"
            addonAfter="mmHg"
            rules={[
              { message: 'tr 长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['fingerspan']}
            label="指距"
            valueType="text"
            addonAfter="cm"
            rules={[
              {
                message: 'fingerspan长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['upperToLowerBodyRatio']}
            label="上下身比"
            valueType="text"
            rules={[
              {
                message: 'upper_to_lower_body_ratio长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.maleRecordOne?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.MaleRecordOneController_createMaleRecordOne_5a7bc2?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(MaleRecordOne);
