import './canvas.css';

export interface ICanvasOptionResult {
  value: string;
  width: number;
  height: number;
}

export interface ICanvasOption {
  width: number;
  height: number;
  lineWidth?: number;
}

export class Canvas {
  private readonly MAX_RECORD_COUNT = 1000;
  private undoStack: Array<Function> = [];
  private x = 0;
  private y = 0;
  private isDrawing = false;
  private isDrawn = false;
  private linePoints: [number, number][] = [];
  private trashContainer: HTMLDivElement;
  private undoContainer: HTMLDivElement;
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private dpr: number;
  public readonly options: ICanvasOption;
  public dom: HTMLElement;

  constructor(options: ICanvasOption) {
    this.options = options;
    this.dpr = window.devicePixelRatio;
    const { root, trashContainer, undoContainer, canvas } = this._render();
    this.dom = root;
    this.trashContainer = trashContainer;
    this.undoContainer = undoContainer;
    this.canvas = canvas;
    this.ctx = <CanvasRenderingContext2D>canvas.getContext('2d');
    this.ctx.scale(this.dpr, this.dpr);
    this.ctx.lineCap = 'round';
    this._bindEvent();
    this._clearUndoFn();
  }

  private _render() {
    const width = this.options.width * this.dpr;
    const height = this.options.height * this.dpr;
    const root = document.createElement('div');
    root.classList.add('canvas-root');
    // 操作区
    const operationContainer = document.createElement('div');
    operationContainer.classList.add('canvas-operation');
    // 撤销
    const undoContainer = document.createElement('div');
    undoContainer.classList.add('canvas-operation__undo');
    const undoIcon = document.createElement('i');
    const undoLabel = document.createElement('span');
    undoLabel.innerText = '撤销';
    undoContainer.append(undoIcon);
    undoContainer.append(undoLabel);
    operationContainer.append(undoContainer);
    // 清空画布
    const trashContainer = document.createElement('div');
    trashContainer.classList.add('canvas-operation__trash');
    const trashIcon = document.createElement('i');
    const trashLabel = document.createElement('span');
    trashLabel.innerText = '清空';
    trashContainer.append(trashIcon);
    trashContainer.append(trashLabel);
    operationContainer.append(trashContainer);
    root.append(operationContainer);
    // 绘图区
    const canvasContainer = document.createElement('div');
    canvasContainer.classList.add('canvas-canvas');
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = `${width / this.dpr}px`;
    canvas.style.height = `${height / this.dpr}px`;
    canvasContainer.append(canvas);
    root.append(canvasContainer);
    // 渲染
    return {
      canvas,
      root,
      trashContainer,
      undoContainer,
    };
  }

  private _bindEvent() {
    this.trashContainer.onclick = this.clean.bind(this);
    this.undoContainer.onclick = this._undo.bind(this);
    this.canvas.onmousedown = this._startDraw.bind(this);
    this.canvas.onmousemove = this._draw.bind(this);
    this.dom.onmouseup = this._stopDraw.bind(this);
  }

  private _undo() {
    if (this.undoStack.length > 1) {
      this.undoStack.pop();
      if (this.undoStack.length) {
        this.undoStack[this.undoStack.length - 1]();
      }
    }
  }

  private _saveUndoFn(fn: Function) {
    this.undoStack.push(fn);
    while (this.undoStack.length > this.MAX_RECORD_COUNT) {
      this.undoStack.shift();
    }
  }

  private _clearUndoFn() {
    const clearFn = () => {
      this.ctx.clearRect(0, 0, this.options.width, this.options.height);
    };
    this.undoStack = [clearFn];
  }

  private _startDraw(evt: MouseEvent) {
    this.isDrawing = true;
    this.x = evt.offsetX;
    this.y = evt.offsetY;
    this.ctx.lineWidth = this.options.lineWidth || 3;
  }

  private _draw(evt: MouseEvent) {
    if (!this.isDrawing) return;
    const { offsetX, offsetY } = evt;
    this.ctx.beginPath();
    this.ctx.moveTo(this.x, this.y);
    this.ctx.lineTo(offsetX, offsetY);
    this.ctx.stroke();
    this.x = offsetX;
    this.y = offsetY;
    this.linePoints.push([offsetX, offsetY]);
    this.isDrawn = true;
  }

  private _stopDraw() {
    this.isDrawing = false;
    if (this.isDrawn) {
      const imageData = this.ctx.getImageData(
        0,
        0,
        this.options.width,
        this.options.height,
      );
      const self = this;
      this._saveUndoFn(function () {
        self.ctx.clearRect(0, 0, self.options.width, self.options.height);
        self.ctx.putImageData(imageData, 0, 0);
      });
      this.isDrawn = false;
    }
  }

  public clean() {
    this._clearUndoFn();
    this.ctx.clearRect(0, 0, this.options.width, this.options.height);
  }

  public toData(): ICanvasOptionResult | null {
    if (!this.linePoints.length) return null;
    // 查找矩形四角坐标
    const startX = this.linePoints[0][0];
    const startY = this.linePoints[0][1];
    let minX = startX;
    let minY = startY;
    let maxX = startX;
    let maxY = startY;
    for (let p = 0; p < this.linePoints.length; p++) {
      const point = this.linePoints[p];
      if (minX > point[0]) {
        minX = point[0];
      }
      if (maxX < point[0]) {
        maxX = point[0];
      }
      if (minY > point[1]) {
        minY = point[1];
      }
      if (maxY < point[1]) {
        maxY = point[1];
      }
    }
    // 增加边框宽度
    const lineWidth = this.ctx.lineWidth;
    minX = minX < lineWidth ? 0 : minX - lineWidth;
    minY = minY < lineWidth ? 0 : minY - lineWidth;
    maxX = maxX + lineWidth;
    maxY = maxY + lineWidth;
    const sw = maxX - minX;
    const sh = maxY - minY;
    // 裁剪图像
    const imageData = this.ctx.getImageData(
      minX * this.dpr,
      minY * this.dpr,
      sw * this.dpr,
      sh * this.dpr,
    );
    const canvas = document.createElement('canvas');
    canvas.style.width = `${sw}px`;
    canvas.style.height = `${sh}px`;
    canvas.width = sw * this.dpr;
    canvas.height = sh * this.dpr;
    const ctx = <CanvasRenderingContext2D>canvas.getContext('2d')!;
    ctx.putImageData(imageData, 0, 0);
    const value = canvas.toDataURL();
    return {
      value,
      width: sw,
      height: sh,
    };
  }

  public dispose() {
    this.dom.remove();
  }
}
