export const toast = (root: HTMLElement, text: string) => {
  const toast = root.querySelector<HTMLDivElement>('.toast');
  if (!toast) {
    return;
  }
  toast.classList.toggle('visible');
  toast.innerText = text;
  setTimeout(() => {
    toast.classList.toggle('visible');
  }, 2000);
};
