export const CLASS_PREFIX = 'aspirin-cpoe';
export const ICON_URL = '//at.alicdn.com/t/c/font_4222800_hkj85knktkm.js';
export const getPrefixCls = (
  suffixCls?: string,
  customizePrefixCls?: string,
) => {
  if (customizePrefixCls) return customizePrefixCls;
  return suffixCls ? `${CLASS_PREFIX}-${suffixCls}` : CLASS_PREFIX;
};

export const STORAGE_KEY_PREFIX = 'aspirin-cpoe';

export const hospital_name = '浙江大学医学院附属医院';

export default {};
