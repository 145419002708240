import './index.less';

import { definePage } from '@vs/vsf-boot';
import { Button, Input, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useSize } from 'ahooks';

import { getPrefixCls } from '@/config';
import Block from '@/module/Block';
import FlexBlock from '@/module/FlexBlock';
import Icon from '@/module/Icon';

import Info from './header/info';
import IpatientBedCard from './header/inpatient_bed_card';
import Left from './left';
import Main from './main';
import Right from './right';

const normal_size = 3440;
const large_size = 3440;

const Index = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient, hospitalType } = user || {};
  const centerCls = getPrefixCls('center');

  const [mediaSize, setMediaSize] = useState<string>();

  const size = useSize(document.body);

  const handleSetMediaSize = useCallback(() => {
    if (size) {
      if (size?.width < large_size) {
        setMediaSize('normal');
      } else {
        setMediaSize('large');
      }
    }
  }, [size]);

  useEffect(() => {
    handleSetMediaSize();
  }, [handleSetMediaSize]);

  console.log('mediaSize', mediaSize);
  return (
    <div className={centerCls}>
      <Block height={100}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Info />
          {currentPatient?.patient?.id && (
            <div
              style={{
                width: '112px',
                height: '48px',
                lineHeight: '48px',
                fontSize: '20px',
                background: '#e3e4ee',
                borderRadius: '4px',
                textAlign: 'center',
                // opacity: 0.1,
              }}
            >
              <Icon
                style={{ color: '#ffa57e', marginRight: '10px' }}
                size={20}
                type="icon-shoucang1"
              />
              收藏
            </div>
          )}
        </div>
      </Block>
      <div className={`main-container main-container-${mediaSize}`}>
        {mediaSize === 'normal' ?
          <FlexBlock
            style={{
              justifyContent: 'flex-start',
            }}
            gap={['16px']}
            colSpan={[1, '608px']}
          >
            <Left prefixCls={centerCls} size={mediaSize}/>
            <Right prefixCls={centerCls} />
          </FlexBlock>
          :
          <FlexBlock
            style={{
              justifyContent: 'flex-start',
            }}
            gap={['16px', '16px']}
            colSpan={['1240px', '1412px', '608px']}
          >
            <Left prefixCls={centerCls} />
            <Main prefixCls={centerCls} />
            <Right prefixCls={centerCls} />
          </FlexBlock>
        }
      </div>
    </div>
  );
};

export default definePage(Index);
