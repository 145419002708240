import CSS from 'csstype';
import React from 'react';

type FlexBlock = {
  direction?: 'column' | 'row';
  colSpan?: (number | string)[];
  gap?: string[];
  children?: React.ReactNode;
  style?: CSS.Properties;
  className?: string;
};

const Index = (props: FlexBlock) => {
  const {
    direction = 'row',
    gap,
    colSpan,
    style,
    className = '',
    children,
  } = props;

  const target = children
    ? Array.isArray(children)
      ? children
      : [children]
    : [];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        ...style,
      }}
      className={className}
    >
      {target?.map((item, index) => {
        const colItem = colSpan?.[index] ?? 1;
        const gapItem = gap?.[index] ?? 0;
        const style =
          typeof colItem === 'number'
            ? {
                flex: colItem,
                // width: 0,
                marginRight: gapItem,
              }
            : {
                width: colItem,
                flexBasic: colItem,
                marginRight: gapItem,
              };
        const formatStyle = {
          ...style,
          ...(direction === 'row'
            ? {
                height: '100%',
              }
            : {
                width: '100%',
              }),
        };
        return (
          <div
            key={index}
            style={{
              position: 'relative',
              ...formatStyle,
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default Index;
