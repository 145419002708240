import { MoveDirection } from '../../dataset/enum/Observer';

export class SelectionObserver {
  // 每次滚动长度
  private readonly step: number = 10;
  // 触发滚动阀值
  private readonly thresholdPoints: [
    top: number,
    down: number,
    left: number,
    right: number,
  ] = [70, 40, 10, 20];

  private requestAnimationFrameId: number | null;
  private isMousedown: boolean;
  private isMoving: boolean;
  private root: HTMLElement;
  private container: HTMLElement;

  constructor(container: HTMLElement, root: HTMLElement) {
    this.root = root;
    this.container = container;
    this.requestAnimationFrameId = null;
    this.isMousedown = false;
    this.isMoving = false;

    this._addEvent();
  }

  private _addEvent() {
    this.container.addEventListener('mousedown', this._mousedown);
    this.container.addEventListener('mousemove', this._mousemove);
    this.container.addEventListener('mouseup', this._mouseup);
  }

  public removeEvent() {
    this.container.removeEventListener('mousedown', this._mousedown);
    this.container.removeEventListener('mousemove', this._mousemove);
    this.container.removeEventListener('mouseup', this._mouseup);
  }

  private _mousedown = (evt: MouseEvent) => {
    const clientWidth = this.root.clientWidth;
    const clientHeight = this.root.clientHeight;
    const { x, y } = evt;
    if (
      y < this.thresholdPoints[0] ||
      clientHeight - y <= this.thresholdPoints[1] ||
      x < this.thresholdPoints[2] ||
      clientWidth - x < this.thresholdPoints[3]
    ) {
      return;
    }
    this.isMousedown = true;
  };

  private _mouseup = () => {
    this.isMousedown = false;
    this._stopMove();
  };

  private _mousemove = (evt: MouseEvent) => {
    if (!this.isMousedown) return;
    const { x, y } = evt;
    const clientWidth = this.root.clientWidth;
    const clientHeight = this.root.clientHeight;
    if (y < this.thresholdPoints[0]) {
      this._startMove(MoveDirection.UP);
    } else if (clientHeight - y <= this.thresholdPoints[1]) {
      this._startMove(MoveDirection.DOWN);
    } else if (x < this.thresholdPoints[2]) {
      this._startMove(MoveDirection.LEFT);
    } else if (clientWidth - x < this.thresholdPoints[3]) {
      this._startMove(MoveDirection.RIGHT);
    } else {
      this._stopMove();
    }
  };

  private _move(direction: MoveDirection) {
    const x = this.root.scrollLeft;
    const y = this.root.scrollTop;
    if (direction === MoveDirection.DOWN) {
      this.root.scrollBy(x, this.step);
    } else if (direction === MoveDirection.UP) {
      this.root.scrollBy(x, -this.step);
    } else if (direction === MoveDirection.LEFT) {
      this.root.scrollBy(-this.step, y);
    } else {
      this.root.scrollBy(this.step, y);
    }
    this.requestAnimationFrameId = window.requestAnimationFrame(
      this._move.bind(this, direction),
    );
  }

  private _startMove(direction: MoveDirection) {
    if (this.isMoving) return;
    this.isMoving = true;
    this._move(direction);
  }

  private _stopMove() {
    if (this.requestAnimationFrameId) {
      window.cancelAnimationFrame(this.requestAnimationFrameId);
      this.requestAnimationFrameId = null;
      this.isMoving = false;
    }
  }
}
