import './index.less';

import { Button, Checkbox, Image, Popover, TabPane, Tabs } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import PathImage from '@/assets/demo-path.png';
import Block from '@/module/Block';

const mock = [
  {
    key: 1,
    label: '不孕症',
    value: '不孕症',
  },
];

const mock1 = [
  {
    id: 1049,
    dosageUnit: 'mg',
    usedUnit: '片',
    medicationName: '地屈孕酮片（薄膜衣）',
    specification: '10mg*20',
    insuranceType: null,
    stock: null,
    drugstore: null,
    unitPrice: 108,
    createdAt: '2023-09-08 15:46:50',
    updatedAt: '2023-09-08 15:46:50',
    deletedAt: null,
    company: '荷兰Abbott B',
    pingyin: 'DQYTPBMY',
    dosage: 10,
    usage: '餐前',
    dayAmount: 3,
    way: '口服',
    packagingUnit: '盒',
    packaging: 20,
    checkInfo: null,
    checkLevel: null,
    zhinengAmount: 10,
    frequency: 'qd',
    zhineng:
      '用于先兆流产或习惯性流产，口服，一次4片，随后每8小时1片，至症状消失；或一次1片，每日2次，至怀孕20周。注意事项：孕妇禁房事，感冒发热勿服，忌食萝卜、薏苡仁、绿豆芽。',
  },
  {
    id: 537,
    dosageUnit: 'mg',
    usedUnit: '粒',
    medicationName: '黄体酮胶囊(益玛欣)',
    specification: '50mg*28',
    insuranceType: null,
    stock: null,
    drugstore: null,
    unitPrice: 36.83,
    createdAt: '2023-09-08 15:46:43',
    updatedAt: '2023-09-08 15:46:43',
    deletedAt: null,
    company: '浙江仙琚制药股份有限',
    pingyin: 'HTTJNYMX',
    dosage: 50,
    usage: '餐前',
    dayAmount: 3,
    way: '口服',
    packagingUnit: '盒',
    packaging: 28,
    checkInfo: null,
    checkLevel: null,
    zhinengAmount: 50,
    frequency: 'qd',
    zhineng:
      '用于无排卵性不孕症或黄体功能不足，口服，每日2次，每次1粒；或用于催月经，一次性口服2粒，连续5天。注意事项：孕妇禁房事，感冒发热勿服，忌食萝卜、薏苡仁、绿豆芽。',
  },
  {
    id: 2070,
    dosageUnit: 'iu',
    usedUnit: '支',
    medicationName: '注射用绒促性素',
    specification: '2000iu',
    insuranceType: null,
    stock: null,
    drugstore: null,
    unitPrice: 47.7,
    createdAt: '2023-09-08 15:47:07',
    updatedAt: '2023-09-08 15:47:07',
    deletedAt: null,
    company: '丽珠集团丽珠制药厂',
    pingyin: 'ZSYRCXS',
    dosage: 2000,
    usage: '餐前',
    dayAmount: 3,
    way: '口服',
    packagingUnit: '支',
    packaging: 1,
    checkInfo: null,
    checkLevel: null,
    zhinengAmount: 2000,
    frequency: 'qd',
    zhineng:
      '用于男性性腺功能低下或女性无排卵性不孕症，肌内注射，每周2-3次，每次1000-4000单位；或用于黄体功能不足，每日1次，每次500-1000单位。注意事项：严重肝肾功能不全者禁用，应定期检查血压、血糖、肝肾功能等。',
  },
  {
    id: 1062,
    dosageUnit: 'mg',
    usedUnit: '片',
    medicationName: '戊酸雌二醇片(补佳乐)',
    specification: '1mg*21',
    insuranceType: null,
    stock: null,
    drugstore: null,
    unitPrice: 27.85,
    createdAt: '2023-09-08 15:46:51',
    updatedAt: '2023-09-08 15:46:51',
    deletedAt: null,
    company: '法国杜芬药厂DELP',
    pingyin: 'WSCECPBJL',
    dosage: 1,
    usage: '餐前',
    dayAmount: 3,
    way: '口服',
    packagingUnit: '盒',
    packaging: 21,
    checkInfo: null,
    checkLevel: null,
    zhinengAmount: 1,
    frequency: 'qd',
    zhineng:
      '用于更年期综合征或卵巢功能衰退综合征，口服，每日2片，饭后用水吞服；或按周期序贯疗法，每经过21天的治疗后停药至少一周。注意事项：开始治疗前应进行全面彻底的内科和妇科检查，并每隔约6个月进行对照检查。',
  },
  {
    id: 1837,
    dosageUnit: 'g',
    usedUnit: '丸',
    medicationName: '滋肾育胎丸（浓缩水蜜丸）',
    specification: '5g*6',
    insuranceType: null,
    stock: null,
    drugstore: null,
    unitPrice: 83.5,
    createdAt: '2023-09-08 15:47:04',
    updatedAt: '2023-09-08 15:47:04',
    deletedAt: null,
    company: '广州白云山中一药业有',
    pingyin: 'ZSYTWNSSMW',
    dosage: 5,
    usage: '餐前',
    dayAmount: 3,
    way: '口服',
    packagingUnit: '盒',
    packaging: 6,
    checkInfo: null,
    checkLevel: null,
    zhinengAmount: 5,
    frequency: 'qd',
    zhineng:
      '用于脾肾两虚所致的滑胎（防治习惯性流产和先兆性流产），口服，一次5g，一日3次，淡盐水或蜂蜜水送服。注意事项：孕妇禁房事，感冒发热勿服，忌食萝卜、薏苡仁、绿豆芽。',
  },
];

const mock2 = [
  {
    id: 415,
    categoryType: '专科检查',
    examineCategoryId: 304,
    examineParts: '血管',
    examineName: '腹部大血管彩色多普勒超声(子宫动脉)',
    createdAt: '2023-09-05 19:53:03',
    updatedAt: '2023-09-05 19:58:43',
    deletedAt: null,
    examineChargeDtoList: [],
    unitPrice: 58,
    pingyin: null,
    zhineng:
      '用于评估子宫动脉阻力指数（RI），预测子痫前期和胎盘功能不全的风险。检查方法：在孕22-24周时进行经腹超声检查，将多普勒样本体积放置在子宫动脉的近端和远端处，并测量RI值。注意事项：多普勒超声应在有临床指征的情况下使用，并遵循ALARA原则。',
  },
  {
    id: 412,
    categoryType: '专科检查',
    examineCategoryId: 304,
    examineParts: '腹部',
    examineName: '床旁B超检查、脐带血流图分析(S/D)',
    createdAt: '2023-09-05 19:49:36',
    updatedAt: '2023-09-05 19:49:36',
    deletedAt: null,
    examineChargeDtoList: [],
    unitPrice: 58,
    pingyin: null,
    zhineng:
      '用于监测胎儿生长情况和胎盘功能状态。检查方法：在孕28周后定期进行床旁B超检查，并测量胎儿头围、腹围、股骨长等参数；同时进行脐带血流图分析，测量脐动脉的收缩期血流速度（S）和舒张期血流速度（D），计算S/D比值。注意事项：床旁B超检查应在专业人员的指导下进行，脐带血流图分析应在胎儿安静状态下进行。',
  },
  {
    id: 410,
    categoryType: '专科检查',
    examineCategoryId: 304,
    examineParts: '胎儿',
    examineName: '早孕期超声筛查',
    createdAt: '2023-09-05 19:45:34',
    updatedAt: '2023-09-05 19:45:34',
    deletedAt: null,
    examineChargeDtoList: [],
    unitPrice: 203,
    pingyin: null,
    zhineng:
      '用于筛查胎儿结构异常和染色体异常的风险。检查方法：在孕11-13+6周时进行经腹或经阴道超声检查，测量胎儿头臀长和颈项透明层厚度（NT），重点筛查早孕期胎儿结构严重畸形，评估染色体异常风险，早期预测双胎输血综合征的发生。注意事项：早孕期超声检查应在医生的指导下进行，并在检查前接受咨询，了解超声检查的可能益处和局限性。',
  },
];

const mock3 = [
  {
    id: 1001,
    assayType: '临检',
    assayName: '优生十项',
    createdAt: '2023-09-07 10:22:48',
    updatedAt: '2023-09-08 17:03:22',
    deletedAt: null,
    assayChargeDtoList: [],
    unitPrice: 120,
    pingyin: 'yssx',
    sample: '分泌物',
    zhineng:
      '用于筛查胎儿染色体异常和神经管缺陷的风险。检查方法：在孕16-20周时进行血清学检测，测量甲胎蛋白（AFP）、人绒毛膜促性腺激素（hCG）、雌三醇（E3）、抑制素A（Inhibin-A）和孕酮（P）等5种指标，并结合母亲的年龄、体重、糖尿病史等因素，计算出胎儿患唐氏综合征、爱德华综合征、神经管缺陷等疾病的概率。注意事项：优生十项是一种筛查方法，不是诊断方法，不能确诊胎儿是否存在异常，只能提示高危或低危。',
  },
  {
    id: 704,
    assayType: '生化',
    assayName: '凝血七项',
    createdAt: '2023-09-06 15:13:33',
    updatedAt: '2023-09-08 14:24:42',
    deletedAt: null,
    assayChargeDtoList: [],
    unitPrice: 97,
    pingyin: null,
    sample: '抗凝血',
    zhineng:
      '用于评估凝血功能和出血倾向的检测。检查方法：采集静脉血液样本，测量凝血酶原时间（PT）、国际标准化比值（INR）、活化部分凝血活酶时间（APTT）、纤维蛋白原（FIB）、凝血酶时间（TT）、D-二聚体（D-Dimer）和抗凝血酶III（AT-III）等7种指标，并与正常参考值进行比较。注意事项：凝血七项应在空腹状态下进行，避免服用影响凝血功能的药物，如阿司匹林、华法林等 。',
  },
  {
    id: 601,
    assayType: '临检',
    assayName: '全自动阴道分泌物联合检测',
    createdAt: '2023-09-06 13:58:40',
    updatedAt: '2023-09-08 14:24:42',
    deletedAt: null,
    assayChargeDtoList: [],
    unitPrice: 57.2,
    pingyin: null,
    sample: '分泌物',
    zhineng:
      '用于诊断阴道炎、宫颈炎等妇科炎症。检查方法：采集阴道分泌物样本，使用全自动阴道分泌物联合检测仪器，通过荧光原位杂交技术，同时检测阴道内10种微生物，包括解脲支原体、沙眼衣原体、念珠菌属、芽生菌属、厌氧菌属、乳杆菌属、淋球菌、人乳头瘤病毒（HPV）、单纯疱疹病毒1型和2型。注意事项：检查前应避免房事、冲洗阴道、使用药物或避孕套等，以免影响检测结果 。',
  },
];

const Cdss = (props) => {
  const { isOpen, children, onClose, onSubmit } = props;

  const [current, setCurrent] = useState<any>(mock?.[0]?.value);
  const [checkList, setCheckList, getCheckList] = useGetState<any[]>([]);

  const [activeKey, setActiveKey] = useState<string>('mine');

  const handleCheckItems = (data) => {
    const target: any[] = getCheckList();
    const v = target?.find((item) => item?.id === data?.id);
    if (!v) {
      setCheckList([...target, data]);
    } else {
      setCheckList([...target]?.filter((v) => v?.id !== data?.id));
    }
  };

  const handleChange = (v) => {
    setActiveKey(v);
  };

  const renerTitle = () => (
    <div className="title">
      <Tabs
        className="aspirin-tab"
        onChange={handleChange}
        activeKey={activeKey}
      >
        <TabPane tab="智能推荐" key="mine"></TabPane>
        <TabPane tab="门诊路径" key="path"></TabPane>
        <TabPane tab="医嘱模板" key="department"></TabPane>
        <TabPane tab="历史医嘱" key="collect"></TabPane>
      </Tabs>
    </div>
  );

  const renderContent = () => {
    if (activeKey === 'path') {
      return <Image src={PathImage} preview={false} />;
    }
    return (
      <div className="main">
        <div className="left">
          {mock?.map((item, index) => (
            <div
              className={classnames('item', {
                'active-item': item?.value === current,
              })}
              key={index}
              onClick={() => {
                setCurrent(item?.value);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="right">
          <div className="info">
            <Block title="推荐药品">
              {mock1?.map((item: any, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => {
                    handleCheckItems(item);
                  }}
                >
                  <div>
                    <Checkbox
                      value={
                        checkList?.findIndex(
                          (citem) => item?.id === citem?.id,
                        ) >= 0
                      }
                    />
                    <div className="type">药疗</div>
                    <div className="label">
                      {item?.medicationName}({item?.specification})
                    </div>
                  </div>
                  <div className="desc" title={item?.zhineng}>
                    {item?.zhineng}
                  </div>
                </div>
              ))}
            </Block>
            <Block title="推荐检查">
              {mock2?.map((item: any, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => {
                    handleCheckItems(item);
                  }}
                >
                  <div>
                    <Checkbox
                      value={
                        checkList?.findIndex(
                          (citem) => item?.id === citem?.id,
                        ) >= 0
                      }
                    />
                    <div className="type">检查</div>
                    <div className="label">
                      {item?.examineName}({item?.examineParts})
                    </div>
                  </div>
                  <div className="desc" title={item?.zhineng}>
                    {item?.zhineng}
                  </div>
                </div>
              ))}
            </Block>
            <Block title="推荐检验">
              {mock3?.map((item: any, index) => (
                <div
                  key={index}
                  className="item"
                  onClick={() => {
                    handleCheckItems(item);
                  }}
                >
                  <div>
                    <Checkbox
                      value={
                        checkList?.findIndex(
                          (citem) => item?.id === citem?.id,
                        ) >= 0
                      }
                    />
                    <div className="type">检验</div>
                    <div className="label">
                      {item?.assayName}({item?.assayType})
                    </div>
                  </div>
                  <div className="desc" title={item?.zhineng}>
                    {item?.zhineng}
                  </div>
                </div>
              ))}
            </Block>
          </div>
          <div className="footer">
            <Button
              onClick={() => {
                onSubmit?.(checkList);
              }}
            >
              导入
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setOpen(isOpen);
      if (!isOpen) {
        setCheckList([]);
      }
    }
  }, [isOpen, setCheckList]);

  const extra = typeof isOpen === 'boolean' ? { open } : {};

  return (
    <Popover
      overlayClassName="cdss-order-container"
      content={
        <div className="cdss-order-content-container">{renderContent()}</div>
      }
      {...extra}
      onOpenChange={(v) => {
        if (!v) {
          setCheckList([]);
          onClose?.();
        }
      }}
      title={renerTitle()}
      showArrow={false}
      trigger="click"
      placement="bottomLeft"
    >
      {children}
    </Popover>
  );
};

export default Cdss;
