// @ts-nocheck
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Image, message, Switch } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import CdssImage from '@/assets/cdss.png';
import Cdss from '@/module/Cdss';
import Icon from '@/module/Icon';
import { addEditor } from '@/module/medical';
import { portalize } from '@/module/Portal';

import History from './history';
import { EmptyXMLDOC, templateIn, templates } from './mock';
import Template from './template';
import IntelligentDiagnosis from './intelligent_diagnosis';
import AllergyHistory from './allergy_history';
import AbortionRecords from './abortion_records';
import EctopicPregnancyRecords from './ectopic_pregnancy_records';
import ProduceRecords from './produce_records';
import AuxiliaryExamination from './auxiliary_examination';

const mockData = {
  historyChronicDiseases: '无高血压、糖尿病、心脏病等慢性病史',
  'COVID-19InfectionHistory': '无新冠肺炎感染史',
  contactHistory: '无',
  overseasTravelHistory: '无',
  allergyHistory: '无',
  dustContactHistory: '无',
  LMP: '2023年9月1日',
  menstrualCycle: '中等',
  menstrualColor: '鲜红',
  dysmenorrhea: '无痛经',
  menstrualCyclePattern: '周期28天，经期5天',
  bloodPressure: '120/80',
  temperature: '36.8',
  pulse: '85',
  breathe: 20,
  consciousness: '清醒',
  skinColor: '正常',
  rash: '无',
  bleedingCondition: '无',
  edema: '无',
  chestShape: '对称',
  chestTenderness: '无',
  breathing: '清晰',
  rale: '无',
  fricative: '无',
  chestOther: '无其他',
  heartRhythm: '齐',
  noise: '无',
  pericardialFrictionSound: '无',
  vascularsign: '无',
  orther: '无',
  Otherinspections: '妇科检查:外阴、阴道、宫颈均未见异常。',
  keepHospital: 'no',
  handlingOpinions:
    '查染色体、血常规、血凝、生化、甲状腺、TOCRH、乙肝、肝炎系列、STD、基础内分泌、肿瘤标志物、宫颈分泌物、宫颈癌筛查、心电图、胸片、盆腔B超、乳腺B超、子宫造影',
};

declare const window: any

const XMLID = 6

const Index = (props) => {
  const { user } = props?.stores || {};
  const { submitMedicalDiagnose, treatInfo, hospitalType } = user || {};

  const [currentTpl, setCurrentTpl, getCurrentTpl] = useGetState<any>();
  const currentTplRef = useRef()
  const [instance, setInstance] = useState<any>();
  const [xmlIsLoad, setXmlIsLoad] = useState(false)

  const [allergyHistoryVisible, setAllergyHistoryVisible] = useState(false)
  const [intelligentDiagnosisVisible, setIntelligentDiagnosisVisible] = useState(false)
  const [abortionRecordsVisible, setAbortionRecordsVisible] = useState(false)
  const [ectopicPregnancyRecordsVisible, setEctopicPregnancyRecordsVisible] = useState(false)
  const [produceRecordsVisible, setProduceRecordsVisible] = useState(false)
  const [auxiliaryExaminationVisible, setAuxiliaryExaminationVisible] = useState(false)
  const [produceRecordsConfig, setProduceRecordsConfig] = useState<any>()
  const [allergyHistoryTableValue, setAllergyHistoryTableValue]: any = useState()
  const [intelligentDiagnosisTableValue, setIntelligentDiagnosisTableValue]: any = useState()
  const [abortionRecordsTableValue, setAbortionRecordsTableValue]: any = useState()
  const [ectopicPregnancyRecordsTableValue, setEctopicPregnancyRecordsTableValue]: any = useState()
  const [produceRecordsTableValue, setProduceRecordsTableValue]: any = useState()
  const [auxiliaryExaminationTableValue, setAuxiliaryExaminationTableValue]: any = useState()
  const pointRef = useRef<any>({
    x: 0,
    y: 0,
  });
  const portalRef = useRef<any>();
  const portalRef2 = useRef<any>();

  const getValue = (data, key) => {
    if (data?.[key] !== undefined) {
      return Array.from(`${data?.[key]}`)?.map((item) => ({
        value: item,
        size: 16,
      }));
    } else {
      return [];
    }
  };

  const setMedicalValues = (data, formData) => {
    return data?.map((item) => {
      if (item?.type === 'control') {
        return {
          ...item,
          control: {
            ...item.control,
            value: getValue(formData, item?.control?.key),
          },
        };
      } else {
        return item;
      }
    });
  };

  const setMedicalJSON = (jsonValue) => {
    console.log(treatInfo?.medicalRecordDto, 'treatInfo?.medicalRecordDto')
    if (treatInfo?.medicalRecordDto?.id) {
      if (treatInfo?.medicalRecordDto?.templateId === XMLID) {
        setCurrentTpl(treatInfo?.medicalRecordDto)
        currentTplRef.current = treatInfo?.medicalRecordDto
        // loadScripts()
        handleChangeTemplate({
          ...treatInfo?.medicalRecordDto,
          id: treatInfo?.medicalRecordDto?.templateId
        })
      }
    } else {
      handleChangeTemplate({
        id: 6,
        medicalRecordContent: EmptyXMLDOC
      })
    }
  };

  useEffect(() => {
    console.log(treatInfo?.medicalRecordDto, '=====treatInfo,treatInfo,treatInfo')
    setMedicalJSON(treatInfo?.medicalRecordDto?.medicalRecordContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatInfo?.medicalRecordDto?.medicalRecordContent, hospitalType]);

  const handleClose = (v) => {
    if (!v) {
      portalRef?.current?.close();
    }
  };

  const setCdssTips = (v) => {
    setTimeout(() => {
      portalRef.current = portalize({
        component: (
          <Cdss title="智能诊断" isOpen placement="top" onClose={handleClose}>
            <div></div>
          </Cdss>
        ),
        event: {
          clientX: pointRef?.current?.x + 150,
          clientY: pointRef?.current?.y + 350,
        },
        key: 'cdss-tips',
        destoryClickOutside: false,
      });
    }, 50);
  };

  const handleClose2 = (v) => {
    if (!v) {
      portalRef2?.current?.close();
    }
  };

  const setKnowledge = (v) => {
    setTimeout(() => {
      portalRef2.current = portalize({
        component: (
          <Cdss
            title="智能助手(医学知识)"
            isOpen
            placement="right"
            onClose={handleClose2}
          >
            <div></div>
          </Cdss>
        ),
        event: {
          clientX: pointRef?.current?.x + 150,
          clientY: pointRef?.current?.y + 350,
        },
        key: 'cdss-knowledge',
        destoryClickOutside: false,
      });
    }, 50);
  };

  const handleChangeTemplate = (data) => {
    setCurrentTpl(data);
    currentTplRef.current = data
    if (data?.id === XMLID) {
      if (xmlIsLoad) {
        loadXMLDocument(data)
      } else {
        loadScripts()
      }
    } else {
      const json = JSON.parse(data?.medicalRecordContent ?? '[]')?.data;
      const root = document.querySelector<HTMLElement>('.medical-main-old')!;

      const formatData = setMedicalValues(json, {
        name: treatInfo?.patient?.name,
        gender: treatInfo?.patient?.gender ? '男' : '女',
        age: treatInfo?.patient?.age,
        department: '生殖科',
        patientId: treatInfo?.patient?.id,
        startTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm'),
        ...mockData,
      });

      addEditor({
        root: root,
        elements: formatData,
        options: templates?.[0].options,
        extra: {
          showCdss: setCdssTips,
          showKnowledge: setKnowledge,
        },
      } as any);
    }
  };

  const handleChangeRecord = (data) => {
    setCurrentTpl(data?.template);
    currentTplRef.current = data?.template

    const json = JSON.parse(data?.medicalRecord ?? '[]')?.data;
    const root = document.querySelector<HTMLElement>('.medical-main-old')!;

    addEditor({
      root: root,
      elements: json,
      options: templates?.[0].options,
      extra: {
        showCdss: setCdssTips,
        showKnowledge: setKnowledge,
      },
    } as any);
  };

  const onSubmitMedical = () => {
    const rootElement: any = document.getElementById("medical-root")

    const formData = instance?.command?.getFormData();
    const JSON =
      getCurrentTpl()?.templateId === XMLID
        ? rootElement?.SaveDocumentToString("xml")
        : instance?.command?.getMedicalJSON();

    submitMedicalDiagnose({
      formData,
      JSON,
      tpl: getCurrentTpl(),
    });
    message.success('保存成功');
  };

  const [revise, setRevise, getRevise] = useGetState<boolean>(false);

  const handleRevise = () => {
    const target = document.getElementById('medical-menu');
    const value = getRevise();
    if (target) {
      if (!value) {
        target.style.display = 'flex';
      } else {
        target.style.display = 'none';
      }
    }

    setRevise(!value);
  };

  const loadScripts = () => {
    const script1 = document.createElement('script')
    const script2 = document.createElement('script')
    script1.src = 'https://cdn.bootcdn.net/ajax/libs/jquery/3.7.1/jquery.js'
    script2.src = 'https://dcwriter.teitui.com/MyWriter/MoreHandleDCWriterServicePage?wasmres=dcwriter5.js'
    document.body?.appendChild(script1)
    document.body?.appendChild(script2)
  }

  const loadXMLDocument = (data) => {
    const rootElement: any = document.getElementById('medical-root');
    const { WriterControl_Task, WriterControl_Event } = window
    const result = rootElement.__DCWriterReference.invokeMethod("LoadDocumentFromString", data?.medicalRecordContent, null, null);
    if (WriterControl_Task.__Tasks && WriterControl_Task.__Tasks.length > 0) {
      WriterControl_Task.AddCallbackForCompletedAllTasks(function () {
        WriterControl_Event.RaiseControlEvent(rootElement, "EventAfterDocumentDraw");
      })
      return;
    } else {
      WriterControl_Event.RaiseControlEvent(rootElement, "EventAfterDocumentDraw");
    }
    //表示为文档正常加载
    if (result === true) {
      rootElement.RefreshDocument();
    }
  }

  const mockOper = [
    {
      label: '保存',
      icon: 'icon-baocun',
      callback: onSubmitMedical,
    },
    {
      label: '取消修订',
      icon: 'icon-quxiaoxiuding',
      callback: handleRevise,
      render: (v) => {
        return v ? '取消修订' : '修订';
      },
    },
    {
      label: '签名',
      icon: 'icon-Frame3',
    },
    {
      label: '打印',
      icon: 'icon-Frame-16',
    },
    {
      label: '历史病历',
      icon: 'icon-Frame-22',
    },
    {
      label: '撤销',
      icon: 'icon-Frame-31',
    },
    {
      label: '重做',
      icon: 'icon-Frame-41',
    },
    {
      label: '刷新处置',
      icon: 'icon-Frame-51',
    },
    {
      label: '检查',
      icon: 'icon-Frame-61',
    },
    {
      label: '检验',
      icon: 'icon-Frame-71',
    },
    {
      label: '处方',
      icon: 'icon-Frame-81',
    },
    {
      label: '医嘱',
      icon: 'icon-Frame-91',
    },
    {
      label: '体征',
      icon: 'icon-Frame-101',
    },
    {
      label: '过敏',
      icon: 'icon-Frame-111',
    },
  ];

  useEffect(() => {
    // loadScripts()
  }, [])

  useEffect(() => {
    const canvas = document.getElementById('medical-root');
    if (canvas) {
      canvas.addEventListener('click', function (event) {
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        pointRef.current = {
          x,
          y,
        };
        console.log('点击坐标：' + x + ', ' + y);
      });
    }
  }, []);

  useEffect(() => {
    // divRef?.current?.set
    let ctl = document.getElementById("medical-root");

    function WriterControl_OnLoad(rootElement) {
      console.log('1111控件加载完毕')
      setXmlIsLoad(true)

      let timer;
      let i = 0;
      const getCanvas = () => {
        timer = null;
        if (i <= 500) {
          timer = setTimeout(() => {
            i++;
            const canvas = document.getElementsByTagName('canvas')?.[0];
            if (canvas) {
              const context = canvas.getContext('2d');
              if (context) {
                // 设置边框颜色为白色
                context.fillStyle = 'white';
                // 绘制矩形边框
                context.fillRect(0, 0, canvas.width, 80);
                getCanvas();
              }
            } else {
              getCanvas();
            }
          }, 10)
        }
      };

      getCanvas();

      if (!xmlIsLoad) {
        loadXMLDocument(currentTplRef.current)
        return
      }
      console.log('1111控件加载完毕')

      rootElement.EventChangeSearchInputSpellCode = function (eventObj) {
        console.log("EventChangeSearchInputSpellCode获取的用户输入信息：" + eventObj);
        for (var i = 0; i < 10; i++) {
          eventObj.AddResultItemByTextValue(eventObj.value + i, i);
        }
        eventObj.ChangeSpellCode();//不使用该接口，将始终在QueryListItems已添加的数据中赛选文本；添加后将在EventChangeSearchInputSpellCode中动态添加
      }
      rootElement.ondocumentclick = function (e) {
        console.log("点击事件");
      }
      rootElement.ondocumentdblclick = function (e) {
        console.log("双击事件");
      }

    };

    window.WriterControl_EventElementMouseDblClick = (rootElement, args) => {
      try {
        console.log(rootElement.id + " 元素双击事件EventElementMouseDblClick " + args.ElementID)

        if (args.ElementID && args.ElementID === "field113") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field113');
            setIntelligentDiagnosisTableValue(JSON.parse(values))
            setIntelligentDiagnosisVisible(true)
          }
        }

        if (args.ElementID && args.ElementID === "field114") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field114');
            setAllergyHistoryTableValue(JSON.parse(values))
            setAllergyHistoryVisible(true)
          }
        }

        if (args.ElementID && args.ElementID === "field115") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field115');
            setAbortionRecordsTableValue(JSON.parse(values))
            setAbortionRecordsVisible(true)
          }
        }

        if (args.ElementID && args.ElementID === "field116") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field116');
            setEctopicPregnancyRecordsTableValue(JSON.parse(values))
            setEctopicPregnancyRecordsVisible(true)
          }
        }

        if (args.ElementID && args.ElementID === "field117") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field117');
            setProduceRecordsConfig({
              title: '顺产记录',
              fieldId: 'field117'
            })
            setProduceRecordsTableValue(JSON.parse(values))
            setProduceRecordsVisible(true)
          }
        }


        if (args.ElementID && args.ElementID === "field118") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field118');
            setAuxiliaryExaminationTableValue(JSON.parse(values))
            setAuxiliaryExaminationVisible(true)
          }
        }

        if (args.ElementID && args.ElementID === "field119") {
          if (rootElement) {
            const values = rootElement?.GetElementInnerValueStringByID('field119');
            setProduceRecordsConfig({
              title: '刨宫产记录',
              fieldId: 'field119'
            })
            setProduceRecordsTableValue(JSON.parse(values))
            setProduceRecordsVisible(true)
          }
        }

      } catch (error) {
        console.error(error)
      }
    }

    window.WriterControl_OnLoad = WriterControl_OnLoad;
  }, []);

  const fileOpen = () => {
    const rootElement: any = document.getElementById('medical-root');
    const { WriterControl_Task, WriterControl_Event } = window
    let result: any
    if (rootElement) {
      var file = rootElement.ownerDocument.createElement('input');
      file.setAttribute('id', 'dcInputFile');
      file.setAttribute('type', 'file');
      file.setAttribute('accept', '.xml,.json,.rtf,.html,.htm,.odt');
      file.style.cssText = 'position: relative;left: -2000px; ';
      rootElement.appendChild(file);
      file.click();
      //file文件选中事件
      file.onchange = function () {
        var fileList = this.files;
        if (fileList.length > 0) {
          // console.log(fileList[0]);
          var reader = new FileReader();
          //wyc20230613改变写法
          var isodt = fileList[0].name.endsWith("odt") === true;
          if (isodt) {
            reader.readAsDataURL(fileList[0]);
          } else {
            reader.readAsText(fileList[0]);
          }
          //reader.readAsDataURL(fileList[0]);
          reader.onload = function (e) {
            if (!e) throw new Error("reader.onload error");

            //获取到文件内容
            if (isodt === true) {
              result = rootElement.__DCWriterReference.invokeMethod("LoadDocumentFromFile", e.target?.result);
            } else {
              result = rootElement.__DCWriterReference.invokeMethod("LoadDocumentFromString", e.target?.result, null, null);
            }
            if (WriterControl_Task.__Tasks && WriterControl_Task.__Tasks.length > 0) {
              WriterControl_Task.AddCallbackForCompletedAllTasks(function () {
                WriterControl_Event.RaiseControlEvent(rootElement, "EventAfterDocumentDraw");
              })
              return;
            } else {
              WriterControl_Event.RaiseControlEvent(rootElement, "EventAfterDocumentDraw");
            }
            //表示为文档正常加载
            if (result === true) {
              rootElement.RefreshDocument();
            }
            //rootElement.LoadDocumentFromString(e.target.result, 'xml');

          }
        }
      }
      //在编辑器的window重新获取焦点时,确保点击取消或X时能正确删除file
      window.addEventListener('focus', function () {
        setTimeout(function () {
          file.remove();
        }, 100);
      }, { once: true });
      return result;
    }
  }

  const handelIntelligentDiagnosisSubmit = async (tableValues) => {
    if (!tableValues?.length) return message.error('请选择诊断')
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、[${item?.diagnosisName}]`
      })
      rootElement.SetElementInnerValueStringByID("field113", JSON.stringify(tableValues), `\n${showValue}\n`)
      setIntelligentDiagnosisVisible(false)
    }
  }

  const handelAllergyHistorySubmit = async (tableValues) => {
    if (!tableValues?.length) return message.error('请选择过敏原')
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、[${item?.allergyName}]`
      })
      rootElement.SetElementInnerValueStringByID("field114", JSON.stringify(tableValues), `\n${showValue}\n`)
      setAllergyHistoryVisible(false)
    }
  }

  const handelAbortionRecordsSubmit = async (tableValues) => {
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、妊娠日期：${new Date(item?.pregnancyDate)?.getFullYear()}年，孕周：${item?.gestationalWeek?.join('-')}，流产方式：${item?.allergyReaction}`
      })
      rootElement.SetElementInnerValueStringByID("field115", JSON.stringify(tableValues), `${showValue}\n`)
      setAbortionRecordsVisible(false)
    }
  }

  const handelEctopicPregnancyRecordsSubmit = async (tableValues) => {
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、妊娠日期：${new Date(item?.pregnancyDate)?.getFullYear()}年，妊娠部位：${item?.pregnancySite?.left} ${item?.pregnancySite?.right}，治疗方式：${item?.treatmentMethods}`
      })
      rootElement.SetElementInnerValueStringByID("field116", JSON.stringify(tableValues), `${showValue}\n`)
      setEctopicPregnancyRecordsVisible(false)
    }
  }

  const handelProduceRecordsSubmit = async (tableValues) => {
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、妊娠日期：${new Date(item?.pregnancyDate)?.getFullYear()}年`
      })

      rootElement.SetElementInnerValueStringByID(produceRecordsConfig.fieldId, JSON.stringify(tableValues), `${showValue}\n`)
      setProduceRecordsVisible(false)
    }
  }

  const handelAuxiliaryExaminationSubmit = async (tableValues) => {
    const rootElement: any = document.getElementById("medical-root")
    if (rootElement) {
      let showValue = ''
      tableValues?.forEach((item, _idx) => {
        showValue += `\n${_idx + 1}、精液检查：${item?.semenExamination}，其他：${item?.other}`
      })

      rootElement.SetElementInnerValueStringByID('field118', JSON.stringify(tableValues), `${showValue}\n`)
      setAuxiliaryExaminationVisible(false)
    }
  }

  return (
    <div className="medical">
      <div className="medical-extra">
        <div>
          {mockOper?.map((item, index) => {
            return (
              <div
                className="item"
                key={index}
                onClick={() => {
                  item?.callback?.();
                }}
              >
                <Icon type={item?.icon ?? ''} />
                <span className="label">
                  {item?.render ? item?.render(revise) : item?.label}
                </span>
              </div>
            );
          })}
        </div>
        <div>
          <div className="item active-item">
            <Icon type="icon-Frame-19" />
            <span className="label">另存为模板</span>
          </div>
        </div>
      </div>
      {/* <div className="medical-toolbar"></div> */}
      <div className="medical-block">
        <div className="header">
          <div className="left">
            <Template onChange={handleChangeTemplate} />
            <History onChange={handleChangeRecord} />
          </div>
          <div className="right">
            <div
              style={{
                marginRight: 24,
              }}
            >
              <Cdss title="智能助手">
                <Image
                  src={CdssImage}
                  preview={false}
                  className="cdss-image-robot"
                />
              </Cdss>
            </div>
            <Icon type="icon-zhuyuangaishu" />
            <span className="title">医学知识库</span>
            <Switch size="small" defaultValue={true} />
          </div>
        </div>
        <div
          id="medical-root"
          className="medical-main"
          dctype="WriterControlForWASM"
          // RuleVisible="true" 
          AllowDrop="true"
          AllowDragContent="true"
          RuleBackColor="rgb(155, 187, 227)"
          CaretCss="5,Blue"
          PageCssString="box-shadow:0px 0px 0px grey; border-width: 0;"
        ></div>
      </div>

      <IntelligentDiagnosis
        intelligentDiagnosisTableValue={intelligentDiagnosisTableValue}
        open={intelligentDiagnosisVisible}
        onCancel={() => setIntelligentDiagnosisVisible(false)}
        onOk={handelIntelligentDiagnosisSubmit}
      />

      <AllergyHistory
        allergyHistoryTableValue={allergyHistoryTableValue}
        open={allergyHistoryVisible}
        onCancel={() => setAllergyHistoryVisible(false)}
        onOk={handelAllergyHistorySubmit}
      />


      <AbortionRecords
        abortionRecordsTableValue={abortionRecordsTableValue}
        open={abortionRecordsVisible}
        onCancel={() => setAbortionRecordsVisible(false)}
        onOk={handelAbortionRecordsSubmit}
      />

      <EctopicPregnancyRecords
        ectopicPregnancyRecordsTableValue={ectopicPregnancyRecordsTableValue}
        open={ectopicPregnancyRecordsVisible}
        onCancel={() => setEctopicPregnancyRecordsVisible(false)}
        onOk={handelEctopicPregnancyRecordsSubmit}
      />

      <ProduceRecords
        title={produceRecordsConfig?.title}
        produceRecordsTableValue={produceRecordsTableValue}
        open={produceRecordsVisible}
        onCancel={() => setProduceRecordsVisible(false)}
        onOk={handelProduceRecordsSubmit}
      />

      <AuxiliaryExamination
        auxiliaryExaminationTableValue={auxiliaryExaminationTableValue}
        open={auxiliaryExaminationVisible}
        onCancel={() => setAuxiliaryExaminationVisible(false)}
        onOk={handelAuxiliaryExaminationSubmit}
      />
    </div>
  );
};

export default definePage(Index);
