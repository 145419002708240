import { Dialog } from '../components/dialog/Dialog';
import { ControlType, EditorMode, ElementType } from '../editor';
import { IControl, IImageData, IValueSet } from '../editor/interface/Control';
import { getUUID } from '../editor/utils';
import { formatDate } from '../editor/utils/date';

export function getControlValue(control: Partial<IControl>) {
  if (
    control.type === ControlType.CHECKBOX ||
    control.type === ControlType.SELECT
  ) {
    return control.code || undefined;
  }
  let value: string | undefined = undefined;
  if (control.value?.length) {
    value = '';
    control.value.forEach((v) => {
      value = value + v.value;
    });
  }
  return value;
}

function normalizeCode(code = '', valueSets: IValueSet[], multiple?: boolean) {
  let codes: string[] = [];
  code.split(',').forEach((c) => {
    const index = valueSets.findIndex((v) => v.code === c);
    if (index > -1) {
      codes.push(c);
    }
  });
  if (!multiple && codes.length > 1) {
    codes = codes.slice(0, 1);
  }
  return codes.join(',');
}

export function openControlDialog(
  root: HTMLElement,
  control: Partial<IControl>,
  mode: EditorMode,
  callback: (control: IControl) => void,
) {
  const value = getControlValue(control);
  const type = control.type;
  switch (type) {
    case ControlType.TEXT:
      new Dialog(
        {
          title: '文本控件',
          data: [
            {
              type: 'text',
              label: '字段名',
              name: 'key',
              value: control.key,
            },
            {
              type: 'text',
              label: '占位符',
              name: 'placeholder',
              placeholder: '请输入占位符',
              value: control.placeholder,
              required: true,
            },
            {
              type: 'text',
              label: '值',
              name: 'value',
              placeholder: '请输入值',
              value,
              core: true,
            },
            {
              type: 'checkbox',
              label: '必填',
              name: 'required',
              checked: control.required ?? false,
            },
          ],
          onConfirm: (payload) => {
            const placeholder = payload.find((p) => p.name === 'placeholder')
              ?.value;
            if (!placeholder) return;
            const value = payload.find((p) => p.name === 'value')?.value || '';
            const required = payload.find((p) => p.name === 'required')
              ?.checked;
            const key =
              payload.find((p) => p.name === 'key')?.value ||
              `${type}_${getUUID()}`;
            callback({
              key,
              type,
              value: value
                ? [
                    {
                      value,
                    },
                  ]
                : null,
              placeholder,
              required,
            });
          },
        },
        root,
      );
      break;
    case ControlType.SELECT:
      new Dialog(
        {
          title: '列举控件',
          data: [
            {
              type: 'text',
              label: '字段名',
              name: 'key',
              value: control.key,
            },
            {
              type: 'text',
              label: '占位符',
              name: 'placeholder',
              placeholder: '请输入占位符',
              value: control.placeholder,
              required: true,
            },
            {
              type: 'text',
              label: '值',
              name: 'code',
              placeholder: '请输入值',
              value,
              core: true,
            },
            {
              type: 'value-set',
              label: '值集',
              name: 'valueSets',
              value: control.valueSets
                ? JSON.stringify(control.valueSets)
                : undefined,
              required: true,
            },
            {
              type: 'checkbox',
              label: '必填',
              name: 'required',
              checked: control.required ?? false,
            },
          ],
          onConfirm: (payload) => {
            const valueSets = payload.find((p) => p.name === 'valueSets')
              ?.value;
            if (!valueSets) return;
            const placeholder = payload.find((p) => p.name === 'placeholder')
              ?.value;
            if (!placeholder) return;
            const required = payload.find((p) => p.name === 'required')
              ?.checked;
            const key =
              payload.find((p) => p.name === 'key')?.value ||
              `${type}_${getUUID()}`;
            const code = payload.find((p) => p.name === 'code')?.value;
            const sets = JSON.parse(valueSets);
            callback({
              key,
              type,
              code: normalizeCode(code, sets, false),
              value: null,
              placeholder,
              valueSets: sets,
              required,
            });
          },
        },
        root,
      );
      break;
    case ControlType.CHECKBOX:
      new Dialog(
        {
          title: '复选框控件',
          data: [
            {
              type: 'text',
              label: '字段名',
              name: 'key',
              value: control.key,
            },
            {
              type: 'text',
              label: '值',
              name: 'code',
              placeholder: '请输入值，多个值以英文逗号分割',
              value,
              core: true,
            },
            {
              type: 'value-set',
              label: '值集',
              name: 'valueSets',
              value: control.valueSets
                ? JSON.stringify(control.valueSets)
                : undefined,
              required: true,
            },
            {
              type: 'checkbox',
              label: '多选',
              name: 'multiple',
              checked: control.multiple ?? true,
            },
            {
              type: 'checkbox',
              label: '必填',
              name: 'required',
              checked: control.required ?? false,
            },
          ],
          onConfirm: (payload) => {
            const valueSets = payload.find((p) => p.name === 'valueSets')
              ?.value;
            if (!valueSets) return;
            const code = payload.find((p) => p.name === 'code')?.value;
            const key =
              payload.find((p) => p.name === 'key')?.value ||
              `${type}_${getUUID()}`;
            const multiple = payload.find((p) => p.name === 'multiple')
              ?.checked;
            const required = payload.find((p) => p.name === 'required')
              ?.checked;
            const sets = JSON.parse(valueSets);
            callback({
              key,
              type,
              code: normalizeCode(code, sets, multiple),
              value: null,
              valueSets: sets,
              multiple,
              required,
            });
          },
        },
        root,
      );
      break;
    case ControlType.DATE:
      new Dialog(
        {
          title: '日期控件',
          data: [
            {
              type: 'text',
              label: '字段名',
              name: 'key',
              value: control.key,
            },
            {
              type: 'text',
              label: '占位符',
              name: 'placeholder',
              placeholder: '请输入占位符',
              value: control.placeholder,
              required: true,
            },
            {
              type: 'text',
              label: '格式',
              name: 'dateFormat',
              placeholder: '请输入日期格式，如yyyy-MM-dd hh:mm:ss',
              value: control.dateFormat || 'yyyy-MM-dd hh:mm:ss',
            },
            {
              type: 'datetime-local',
              label: '值',
              name: 'value',
              placeholder: '请输入值，多个值以英文逗号分割',
              value: formatDate(new Date(value || ''), 'yyyy-MM-dd hh:mm:ss'),
              core: true,
            },
            {
              type: 'checkbox',
              label: '必填',
              name: 'required',
              checked: control.required ?? false,
            },
          ],
          onConfirm: (payload) => {
            const placeholder = payload.find((p) => p.name === 'placeholder')
              ?.value;
            if (!placeholder) return;
            const dateFormat =
              payload.find((p) => p.name === 'dateFormat')?.value || '';
            const value = formatDate(
              new Date(payload.find((p) => p.name === 'value')?.value || ''),
              dateFormat,
            );
            const key =
              payload.find((p) => p.name === 'key')?.value ||
              `${type}_${getUUID()}`;
            const required = payload.find((p) => p.name === 'required')
              ?.checked;
            callback({
              key,
              type,
              value: value
                ? [
                    {
                      value,
                    },
                  ]
                : null,
              placeholder,
              required,
              dateFormat,
            });
          },
        },
        root,
      );
      break;
    case ControlType.IMAGE:
      new Dialog(
        {
          title: '图片控件',
          coreOnly: mode === EditorMode.EDIT,
          data: [
            {
              type: 'text',
              label: '字段名',
              name: 'key',
              value: control.key,
            },
            {
              type: 'text',
              label: '占位符',
              name: 'placeholder',
              placeholder: '请输入占位符',
              value: control.placeholder,
              required: true,
            },
            {
              type: 'image',
              label: '图片',
              name: 'value',
              value: value
                ? JSON.stringify({
                    ...control.image,
                    data: value,
                  })
                : undefined,
              core: true,
            },
            {
              type: 'checkbox',
              label: '必填',
              name: 'required',
              checked: control.required ?? false,
            },
          ],
          onConfirm: (payload) => {
            const placeholder = payload.find((p) => p.name === 'placeholder')
              ?.value;
            if (!placeholder) return;
            const value = payload.find((p) => p.name === 'value')?.value;
            const key =
              payload.find((p) => p.name === 'key')?.value ||
              `${type}_${getUUID()}`;
            const required = payload.find((p) => p.name === 'required')
              ?.checked;
            const image: IImageData | undefined = value
              ? JSON.parse(value)
              : undefined;
            callback({
              key,
              type,
              value: image
                ? [
                    {
                      ...image.size,
                      value: image.data,
                      type: ElementType.IMAGE,
                    },
                  ]
                : null,
              placeholder,
              required,
              image: image
                ? {
                    size: image.size,
                    source: image.source,
                  }
                : undefined,
            });
          },
        },
        root,
      );
      break;
    default:
      break;
  }
}
