import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect } from 'react';

import FormBlock from '@/module/FormBlock';
const labelWidth = 96;

const FemaleRecordOne = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};

  return (
    <VSForm
      id="examForms"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.FemaleRecordOneController_getFemaleRecordOneByPatientId_48edb4?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
      onSubmit={async (params) => {
        const res =
          await vsf?.services?.FemaleRecordOneController_createFemaleRecordOne_8633ef?.(
            { btoParam: params },
          );
        return params; // 直接返回提交的数据
      }}
      labelAlign="left"
    >
      <FormBlock title="男方信息">
        <VSFormLayout columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['mainSuit']}
            label="主诉"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 64 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['historyPresentIllness']}
            label="现病史"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="既往病史">
        <VSFormLayout key="0" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['isHighBloodPressure']}
            label="高血压"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormItem
            name={['highBloodPressureInfo']}
            // label="高血压信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 64 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['isCoronaryHeartDisease']}
            label="冠心病"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormItem
            name={['coronaryHeartDiseaseInfo']}
            // label="冠心病信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 64 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['isDiabetes']}
            label="糖尿病"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['diabetesInfo']}
            // label="糖尿病信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['isHepatitis']}
            label="肝炎"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormItem
            name={['hepatitisInfo']}
            // label="肝炎信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['isTuberculosis']}
            label="结核"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['tuberculosisInfo']}
            // label="结核信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['isPelvicInflammation']}
            label="盆腔炎"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['pelvicInflammationInfo']}
            // label="盆腔炎信息"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="1" columnCount={3} labelWidth={labelWidth}>
          <VSFormLayout key="2" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['isAppendicitis']}
              label="阑尾炎"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.gaoxueya}
              fieldProps={{}}
            />
            <VSFormItem
              name={['appendicitisInfo']}
              // label="阑尾炎信息"
              valueType="text"
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['heart']}
            label="心"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['liver']}
            label="肝"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="3" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['lung']}
            label="肺"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['breath']}
            label="脑"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['kidney']}
            label="肾"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['isOtherEpidemics']}
            label="其他传染病"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['otherEpidemicsInfo']}
            // label="其他传染病"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['isOperationHistory']}
            label="手术史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormItem
            name={['operationHistoryInfo']}
            // label="手术史信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['isTransfusionHistory']}
            label="输血史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['transfusionHistoryInfo']}
            // label="输血史信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['isPoisoningHistory']}
            label="是否中毒史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['poisoningHistoryInfo']}
            // label="中毒史信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['isHypersensitivityHistory']}
            label="高敏体质史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormItem
            name={['hypersensitivityHistoryInfo']}
            // label="高敏体质史信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['isExposurePoisonsAndRadioactiveDustHistory']}
            label="毒物、放射粉尘接触史"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['exposurePoisonsAndRadioactiveDustHistoryInfo']}
            // label="毒物、放射粉尘接触史信息"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['allergicHistoryInfo']}
            label="过敏史"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ marginTop: '5px', width: labelWidth, flexShrink: 0 }}>
            既往ART史
          </div>
          <div style={{ flex: 1 }}>
            <VSFormLayout key="6" columnCount={12}>
              <VSFormItem
                name={['isAih']}
                // label="HCG"
                valueType="checkbox"
                dataSource={[{ label: 'AIH', value: '1' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isAih: true };
                  } else {
                    return { isAih: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '1' : '';
                }}
                fieldProps={{}}
              />
              <VSFormItem
                name={['aihInfo']}
                // label="AIH"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
              <VSFormItem
                name={['isAid']}
                // label="AID"
                dataSource={[{ label: 'AID', value: '2' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isAid: true };
                  } else {
                    return { isAid: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '2' : '';
                }}
                valueType="checkbox"
                fieldProps={{}}
              />
              <VSFormItem
                name={['aidInfo']}
                // label="AID"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
              <VSFormItem
                name={['isIvf']}
                // label="IVF"
                fieldProps={{}}
                valueType="checkbox"
                dataSource={[{ label: 'IVF', value: '3' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isIvf: true };
                  } else {
                    return { isIvf: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '3' : '';
                }}
              />
              <VSFormItem
                name={['ivfInfo']}
                // label="IVF"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
              <VSFormItem
                name={['isIcsi']}
                // label="ICSI"
                valueType="checkbox"
                dataSource={[{ label: 'ICSI', value: '4' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isIcsi: true };
                  } else {
                    return { isIcsi: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '4' : '';
                }}
              />
              <VSFormItem
                name={['icsiInfo']}
                // label="ICSI"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
              <VSFormItem
                name={['isPgt']}
                // label="PGT"
                valueType="checkbox"
                dataSource={[{ label: 'PGT', value: '5' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isPgt: true };
                  } else {
                    return { isPgt: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '5' : '';
                }}
              />
              <VSFormItem
                name={['pgtInfo']}
                // label="PGT"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
              <VSFormItem
                name={['isOther']}
                // label="其他"
                valueType="checkbox"
                dataSource={[{ label: '其他', value: '6' }]}
                transform={(v) => {
                  if (v.length > 0) {
                    return { isOther: true };
                  } else {
                    return { isOther: false };
                  }
                }}
                convertValue={(v) => {
                  const _v =
                    v instanceof Array ? (v.length > 0 ? true : false) : v;

                  return _v ? '6' : '';
                }}
              />
              <VSFormItem
                name={['otherInfo']}
                // label="其他"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.gaoxueya}
                fieldProps={{}}
              />
            </VSFormLayout>
          </div>
        </div>
      </FormBlock>
      <FormBlock title="个人史">
        <VSFormLayout key="7" columnCount={3} labelWidth={labelWidth}>
          <VSFormLayout key="8" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['isSmoking']}
              label="吸烟"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.gaoxueya}
              fieldProps={{}}
            />

            <VSFormItem
              name={['smokingFrequency']}
              // label="吸烟频率"
              valueType="text"
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['intemperanceInfo']}
            label="酗酒"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />

          <VSFormItem
            name={['takeDrugsInfo']}
            label="吸毒"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['majorMentalStimulation']}
            label="重大精神刺激"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gaoxueya}
            fieldProps={{}}
          />
          <VSFormLayout key="8" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['isDrugAddiction']}
              label="成瘾药物"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.gaoxueya}
              fieldProps={{}}
            />
            <VSFormItem
              name={['drugAddictionInfo']}
              // label="成瘾药物"
              valueType="text"
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['birthplaceInfo']}
            label="出生地"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['foreignResidenceHistory']}
            label="外地居住史"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['dirtySexHistory']}
            label="不洁性生活史"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormLayout key="12" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['isBirthDefect']}
              label="出生缺陷"
              valueType="text"
              fieldProps={{}}
            />
            <VSFormItem
              name={['birthDefectInfo']}
              // label="出生缺陷"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.gaoxueya}
              fieldProps={{}}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="13" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['epidemicResidenceHistory']}
            label="疫病居住史"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['infectiousDiseaseExposureHistory']}
            label="传染病接触史"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="月经史">
        <VSFormLayout key="14" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['menophaniaInfo']}
            label="初潮"
            addonAfter="岁"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['menstrualCycleInfo']}
            label="月经周期"
            valueType="text"
            addonAfter="天"
            fieldProps={{}}
          />

          <VSFormItem
            name={['periodDays']}
            label="经期天数"
            addonAfter="天"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['lmpInfo']}
            label="LMP"
            valueType="date"
            fieldProps={{}}
          />

          <VSFormItem
            name={['menstrualBloodVolume']}
            label="经量"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.xiyan}
            fieldProps={{}}
          />

          <VSFormItem
            name={['dysmenorrheaInfo']}
            label="痛经"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.xiyan}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="婚育史">
        <VSFormLayout key="14" columnCount={3} labelWidth={labelWidth}>
          <VSFormLayout key="15" labelWidth={25} columnCount={4}>
            <VSFormItem
              name={['zuChanInfo']}
              label="足"
              valueType="text"
              fieldProps={{
                width: 40,
              }}
            />

            <VSFormItem
              name={['prematureDeliveryInfo']}
              label="早"
              valueType="text"
              fieldProps={{
                width: 40,
              }}
            />

            <VSFormItem
              name={['abortionInfo']}
              label="流"
              valueType="text"
              fieldProps={{
                width: 40,
              }}
            />
            <VSFormItem
              name={['cunInfo']}
              label="存"
              valueType="text"
              fieldProps={{
                width: 40,
              }}
            />
          </VSFormLayout>
          <VSFormItem
            name={['lastAbortion']}
            label="末次流产"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormLayout key="16" labelWidth={labelWidth} columnCount={2}>
            <VSFormItem
              name={['lastGestationYear']}
              label="末次妊娠时间"
              valueType="text"
              addonAfter="年"
              fieldProps={{ width: 96 }}
            />
            <VSFormItem
              name={['lastGestationMonth']}
              // label="末次妊娠时间月"
              valueType="text"
              addonAfter="月"
              fieldProps={{ width: 96 }}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="17" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['marriageableAge']}
            label="结婚年龄"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['contraceptiveDevice']}
            label="避孕工具"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['contraceptiveTime']}
            label="避孕时间"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="18" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem
            name={['adverseBirthHistory']}
            label="不良生育史"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="家族史">
        <VSFormLayout key="19" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['familHistoryFatherInfo']}
            label="父亲"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['familHistoryMotherInfo']}
            label="母亲"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['familHistoryBotherInfo']}
            label="兄弟"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['familHistorySisterInfo']}
            label="姐妹"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="20" labelWidth={labelWidth}>
          <VSFormItem
            name={['geneticHistory']}
            label="遗传病史"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['fgeneticHistoryDetail']}
            label="详述"
            valueType="text"
            fieldProps={{
              width: 665,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="21" labelWidth={labelWidth}>
          <VSFormItem
            name={['familyMedicalHistory']}
            label="家族病史"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['familyMedicalHistoryDetail']}
            label="详述"
            valueType="text"
            fieldProps={{ width: 665 }}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="体格检查">
        <VSFormLayout columnCount={4} labelWidth={labelWidth}>
          <VSFormItem
            name={['temperature']}
            label="T"
            valueType="text"
            fieldProps={{}}
            addonAfter="℃"
          />
          <VSFormItem
            name={['pulse']}
            label="P"
            valueType="text"
            addonAfter="次/分钟"
            fieldProps={{}}
          />
          <VSFormItem
            name={['rate']}
            label="R"
            addonAfter="次/分钟"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['tr']}
            label="TR"
            addonAfter="mmHg"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['height']}
            label="身高"
            valueType="text"
            fieldProps={{}}
            addonAfter="cm"
          />

          <VSFormItem
            name={['weight']}
            label="体重"
            valueType="text"
            fieldProps={{}}
            addonAfter="kg"
          />

          <VSFormItem
            name={['bim']}
            label="BIM"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['waistline']}
            label="腰围"
            addonAfter="cm"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['hipline']}
            label="臀围"
            valueType="text"
            fieldProps={{}}
            addonAfter="cm"
          />

          <VSFormItem
            name={['waistHipRatio']}
            label="腰臀比"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['nutritionCondition']}
            label="营养"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.yinyang}
            fieldProps={{}}
          />
          <VSFormItem
            name={['nutritionInfo']}
            // label="营养信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['developmentalCondition']}
            label="发育"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.yinyang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['developmentalInfo']}
            // label="发育信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['mindCondition']}
            label="精神"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.yinyang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['mindInfo']}
            // label="精神信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['cutaneousMucousMembraneCondition']}
            label="皮肤粘膜"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />
          <VSFormItem
            name={['cutaneousMucousMembraneInfo']}
            // label="皮肤粘膜信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['superficiaLymphNodeCondition']}
            label="浅表淋巴结"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['superficiaLymphNodeInfo']}
            // label="浅表淋巴结信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['headAndOrgansCondition']}
            label="头部及器官"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['headAndOrgansInfo']}
            // label="头部及器官信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['thyroidCondition']}
            label="甲状腺"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['thyroidInfo']}
            // label="甲状腺信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['lungsCondition']}
            label="肺脏"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['lungsInfo']}
            // label="肺脏信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['heartCondition']}
            label="心脏"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['heartInfo']}
            // label="心脏信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['pulmonaryRespirationCondition']}
            label="腹部呼吸"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['pulmonaryRespirationInfo']}
            // label="腹部信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['liverCondition']}
            label="肝脏"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['liverInfo']}
            // label="肝脏信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['spleenCondition']}
            label="脾脏"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.pizang}
            fieldProps={{}}
          />
          <VSFormItem
            name={['spleenInfo']}
            // label="脾脏信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['kidneyCondition']}
            label="肾脏"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.shenzang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['kidneyInfo']}
            // label="肾脏信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['spinalLimbsCondition']}
            label="脊柱四肢"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.rufang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['spinalLimbsInfo']}
            // label="脊柱四肢信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['breastCondition']}
            label="乳房"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.rufang}
            fieldProps={{}}
          />
          <VSFormItem
            name={['breastInfo']}
            // label="乳房信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['muscularTensionCondition']}
            label="肌张力"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.rufang}
            fieldProps={{}}
          />

          <VSFormItem
            name={['muscularTensionInfo']}
            // label="肌张力信息"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['myodynamiaCondition']}
            label="肌力"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.jl}
            fieldProps={{}}
          />

          <VSFormItem
            name={['myodynamiaInfo']}
            // label="肌力信息"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['patellarTendonReflexCondition']}
            label="膝腱反射"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.fanshe}
            fieldProps={{}}
          />
          <VSFormItem
            name={['patellarTendonReflexInfo']}
            // label="膝腱反射信息"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['babinskiCondition']}
            label="Babinski症"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.fanshe}
            fieldProps={{}}
          />
          <VSFormItem
            name={['babinskiInfo']}
            // label="Babinski症信息"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout labelWidth={labelWidth} columnCount={1}>
          <VSFormItem
            name={['physicaExaminationOther']}
            label="其他"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="体格检查">
        <VSFormLayout columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['vulvaCondition']}
            label="外阴"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.waiyin}
            fieldProps={{}}
          />

          <VSFormItem
            name={['vulvaInfo']}
            // label="外阴信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['vaginaCondition']}
            label="阴道状"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.yindao}
            fieldProps={{}}
          />

          <VSFormItem
            name={['vaginaInfo']}
            // label="阴道信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['cervixUteriCondition']}
            label="宫颈"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.gongjing}
            fieldProps={{}}
          />
          <VSFormItem
            name={['cervixUteriInfo']}
            // label="宫颈信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['leftSideAttachmentCondition']}
            label="附件 左侧"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.fl}
            fieldProps={{}}
          />

          <VSFormItem
            name={['leftSideAttachmentInfo']}
            // label="附件左侧信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['rightSideAttachmentCondition']}
            label="附件 右侧"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.fr}
            fieldProps={{}}
          />

          <VSFormItem
            name={['rightSideAttachmentInfo']}
            // label="附件右侧信息"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['uterus']}
            label="子宫"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.zigong}
            fieldProps={{}}
          />
          <VSFormItem
            name={['uterusSize']}
            label="大小"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.uterine_size}
            fieldProps={{}}
          />

          <VSFormItem
            name={['uterusTexture']}
            label="质地"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.zhidi}
            fieldProps={{}}
          />

          <VSFormItem
            name={['pressingPain']}
            label="压痛"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.yatong}
            fieldProps={{}}
          />
          <VSFormItem
            name={['activityDegree']}
            label="活动度"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.hdd}
            fieldProps={{}}
          />

          <VSFormItem
            name={['uterusOtherInfo']}
            label="子宫其他"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 32 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.examForms?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.FemaleRecordOneController_createFemaleRecordOne_8633ef?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(FemaleRecordOne);
