import classnames from 'classnames';
import React from 'react';

const mock = [
  {
    label: '病历诊疗',
    value: '病历诊疗',
  },
  {
    label: '临床治疗',
    value: '临床治疗',
  },
  {
    label: '胚胎室记录',
    value: '胚胎室记录',
  },
  {
    label: '并发症记录',
    value: '并发症记录',
  },
  {
    label: '周期总结',
    value: '周期总结',
  },
  {
    label: '随访记录',
    value: '随访记录',
  },
];

const Index = (props) => {
  const { value, onChange } = props;
  return (
    <div className="cpoe-reproduction-template">
      {mock?.map((item, index) => (
        <div
          className={classnames('item', {
            'active-item': item?.value === value,
          })}
          key={index}
          onClick={() => {
            onChange?.(item?.value);
          }}
        >
          {/* <span className="sign"></span> */}
          <span className="word">{item?.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Index;
