import * as echarts from 'echarts';
import React, { useEffect, useRef } from 'react';

const Index = () => {
  const chartRef = useRef<any>();
  const init = () => {
    const chartDom = document.getElementById('center-chart');
    chartRef.current = echarts.init(chartDom);
    const option = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['脉搏', '呼吸', '体温'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      toolbox: {
        // feature: {
        //   saveAsImage: {},
        // },
      },
      textStyle: {
        fontFamily: 'DINPro',
        fontWeight: 'bold',
        color: '#343B42',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [
          '07-22 00:00',
          '07-22 04:00',
          '07-22 08:00',
          '07-22 12:00',
          '07-22 16:00',
          '07-22 20:00',
          '07-22 00:00',
        ],
      },
      yAxis: [
        {
          type: 'value',
          name: '{block|}  呼吸',
          nameTextStyle: {
            color: '#50BC35',
            padding: [0, 40, 0, 0],
            rich: {
              block: {
                backgroundColor: '#50BC35',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  脉搏',
          nameTextStyle: {
            color: '#3276E8',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#3276E8',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  体温',
          offset: 60,
          min: 35,
          max: 42,
          interval: true,
          nameTextStyle: {
            color: '#FF7E7E',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#FF7E7E',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
      ],
      series: [
        {
          name: '脉搏',
          type: 'line',
          stack: 'Total',
          data: [60, 68, 61, 70, 72, 60, 80],
          yAxisIndex: 0, // 使用第一个Y轴
        },
        {
          name: '呼吸',
          type: 'line',
          stack: 'Total',
          data: [90, 70, 110, 99, 100, 110, 105],
          yAxisIndex: 1, // 使用第一个Y轴
        },
        {
          name: '体温',
          type: 'line',
          stack: 'Total',
          data: [36, 37, 38, 39, 40, 37, 37],
          yAxisIndex: 1, // 使用第一个Y轴
        },
      ],
    };
    option && chartRef.current.setOption(option);
  };

  const resize = () => {
    if (chartRef.current) {
      chartRef.current?.resize();
    }
  };
  useEffect(() => {
    init();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
  return (
    <div
      id="center-chart"
      style={{
        width: '100%',
        height: '100%',
      }}
    ></div>
  );
};

export default Index;
