import { createServices } from '@vs/vsf-boot';
export default createServices({
  HospitalPatientController_allHospitalPatient_9febc7: {
    method: 'post',
    url: '/api/patient/all-hospital-patient',
    parameterFomatter: (data?: {
      qto: AllHospitalPatientQtoPatientPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: HospitalPatientDetailVoPatientEntranceWebVo[],
    ) => data,
  },
  RegisterPatientController_allRegisterPatient_315489: {
    method: 'post',
    url: '/api/patient/all-register-patient',
    parameterFomatter: (data?: {
      qto: AllRegisterPatientQtoPatientPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegisterPatientDetailVoPatientEntranceWebVo[],
    ) => data,
  },
  RegisterPatientController_getRegisterPatientDetail_9eb147: {
    method: 'post',
    url: '/api/patient/get-register-patient-detail',
    parameterFomatter: (data?: { registerId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegisterPatientDetailVoPatientEntranceWebVo,
    ) => data,
  },
  TreatInfoController_allTreatInfo_15b3f5: {
    method: 'post',
    url: '/api/medical-record/all-treat-info',
    parameterFomatter: (data?: { qto: AllTreatQtoMedicalRecordPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: TreatInfoVoMedicalRecordEntranceWebVo[],
    ) => data,
  },
  TreatInfoController_createTreatInfo_18fdc5: {
    method: 'post',
    url: '/api/medical-record/create-treat-info',
    parameterFomatter: (data?: {
      btoParam: CreateTreatInfoBtoMedicalRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TreatInfoController_getTreatInfoDetail_9204c8: {
    method: 'post',
    url: '/api/medical-record/get-treat-info-detail',
    parameterFomatter: (data?: {
      id: number;
      registerId: number;
      hospitalId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatInfoDetailVoMedicalRecordEntranceWebVo,
    ) => data,
  },
  TreatInfoController_updateTreatInfo_7eba18: {
    method: 'post',
    url: '/api/medical-record/update-treat-info',
    parameterFomatter: (data?: {
      btoParam: UpdateTreatInfoBtoMedicalRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicalRecordController_createMedicalRecord_a984f3: {
    method: 'post',
    url: '/api/medical-record/create-medical-record',
    parameterFomatter: (data?: {
      btoParam: CreateMedicalRecordBtoMedicalRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicalRecordController_getMedicalRecordByTreatId_99fc77: {
    method: 'post',
    url: '/api/medical-record/get-medical-record-by-treat-id',
    parameterFomatter: (data?: { treatId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoMedicalRecordEntranceWebVo,
    ) => data,
  },
  TreatInfoController_createHospitalTreatInfo_af1168: {
    method: 'post',
    url: '/api/medical-record/create-hospital-treat-info',
    parameterFomatter: (data?: {
      btoParam: CreateHospitalTreatInfoBtoMedicalRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicalRecordController_getMedicalRecordHistoryByPatientId_103a89: {
    method: 'post',
    url: '/api/medical-record/get-medical-record-history-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordHistoryDtoMedicalRecordManagerDto[],
    ) => data,
  },
  MedicalRecordController_getMedicalRecordHistoryByTreatId_3d54ca: {
    method: 'post',
    url: '/api/medical-record/get-medical-record-history-by-treat-id',
    parameterFomatter: (data?: { treatId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordHistoryDtoMedicalRecordManagerDto[],
    ) => data,
  },
  TreatInfoController_getTreatFlag_771723: {
    method: 'post',
    url: '/api/medical-record/get-treat-flag',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TemplateCategoryController_allTemplateCategory_5f0fcc: {
    method: 'post',
    url: '/api/medical-record-template/all-template-category',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: TemplateCategoryVoMedicalRecordTemplateEntranceWebVo[],
    ) => data,
  },
  MedicalRecordTemplateController_getMedicalRecordTemplate_dd80b0: {
    method: 'post',
    url: '/api/medical-record-template/get-medical-record-template',
    parameterFomatter: (data?: { medicalRecordTemplate: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordTemplateVoMedicalRecordTemplateEntranceWebVo,
    ) => data,
  },
  MedicalRecordTemplateController_getMedicalRecordTemplateName_5df923: {
    method: 'post',
    url: '/api/medical-record-template/get-medical-record-template-name',
    parameterFomatter: (data?: { templateCategoryId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordTemplateNameVoMedicalRecordTemplateEntranceWebVo[],
    ) => data,
  },
  OrderApplyController_allOrderApply_ce2c69: {
    method: 'post',
    url: '/api/doctor-order/all-order-apply',
    parameterFomatter: (data?: {
      qto: AllOrderApplyQtoDoctorOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderApplyVoDoctorOrderEntranceWebVoDoctorordervo[],
    ) => data,
  },
  OrderApplyController_getOrderApplyDetailById_9708b7: {
    method: 'post',
    url: '/api/doctor-order/get-order-apply-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderApplyDetailVoDoctorOrderEntranceWebVo,
    ) => data,
  },
  OrderItemController_allOrderItem_f38b92: {
    method: 'post',
    url: '/api/doctor-order/all-order-item',
    parameterFomatter: (data?: { qto: AllOrderItemQtoDoctorOrderPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrderItemVoDoctorOrderEntranceWebVoDoctorordervo[],
    ) => data,
  },
  DoctorOrderController_createDoctorOrder_c7940c: {
    method: 'post',
    url: '/api/doctor-order/create-doctor-order',
    parameterFomatter: (data?: {
      btoParam: CreateDoctorOrderBtoDoctorOrderServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DoctorOrderController_getDoctorOrderByTreatId_51f8a8: {
    method: 'post',
    url: '/api/doctor-order/get-doctor-order-by-treat-id',
    parameterFomatter: (data?: { treatId: number }) => data,
    responseFormatter: (_, __, data?: DoctorOrderVoDoctorOrderEntranceWebVo) =>
      data,
  },
  DoctorOrderController_orderEnd_7f1077: {
    method: 'post',
    url: '/api/doctor-order/order-end',
    parameterFomatter: (data?: { treatId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  HandleMedicationController_allHandleMedication_70cd0f: {
    method: 'post',
    url: '/api/medication/all-handle-medication',
    parameterFomatter: (data?: {
      qto: AllHandleMedicationQtoMedicationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: HandleMedicationVoMedicationEntranceWebVoHandlemedicationcommonvo[],
    ) => data,
  },
  HandleMedicationController_getCommonHandleMedication_0467c3: {
    method: 'post',
    url: '/api/medication/get-common-handle-medication',
    parameterFomatter: (data?: { commonType: CommonTypeEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: HandleMedicationVoMedicationEntranceWebVoHandlemedicationcommonvo[],
    ) => data,
  },
  MedicationController_allCommonMedication_ff98e3: {
    method: 'post',
    url: '/api/medication/all-common-medication',
    parameterFomatter: (data?: { commonType: CommonTypeEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicationVoMedicationEntranceWebVoMedicationcommonvo[],
    ) => data,
  },
  MedicationController_allMedication_183d90: {
    method: 'post',
    url: '/api/medication/all-medication',
    parameterFomatter: (data?: { qto: AllMedicationQtoMedicationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: MedicationVoMedicationEntranceWebVoMedicationcommonvo[],
    ) => data,
  },
  MedicationController_createMedication_47c148: {
    method: 'post',
    url: '/api/medication/create-medication',
    parameterFomatter: (data?: {
      bto: CreateMedicationBtoMedicationServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamineCommonController_allCommonExamine_bee2ed: {
    method: 'post',
    url: '/api/examine/all-common-examine',
    parameterFomatter: (data?: { qto: AllExamineCommonQtoExaminePersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ExamineVoExamineEntranceWebVoExaminecommonvo[],
    ) => data,
  },
  ExamineCommonController_allExamineCommonParts_4f83ac: {
    method: 'post',
    url: '/api/examine/all-examine-common-parts',
    parameterFomatter: (data?: {
      qto: AllExamineCommonPartsQtoExaminePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamineCommonPartsVoExamineEntranceWebVo[],
    ) => data,
  },
  ExamineController_allExamine_6a9f09: {
    method: 'post',
    url: '/api/examine/all-examine',
    parameterFomatter: (data?: { qto: AllExamineQtoExaminePersistQto }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamineVoExamineEntranceWebVoExaminecommonvo[],
    ) => data,
  },
  ExamineCategoryController_getByCategoryType_831347: {
    method: 'post',
    url: '/api/examine/get-by-category-type',
    parameterFomatter: (data?: { categoryType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamineCategoryVoExamineEntranceWebVo[],
    ) => data,
  },
  ExamineCategoryController_allExamineCategory_2d81a0: {
    method: 'post',
    url: '/api/examine/all-examine-category',
    parameterFomatter: (data?: {
      qto: AllExamineCategoryQtoExaminePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamineCategoryVoExamineEntranceWebVo[],
    ) => data,
  },
  ExamineController_createExamine_813e8f: {
    method: 'post',
    url: '/api/examine/create-examine',
    parameterFomatter: (data?: {
      createExamineBvo: CreateExamineBvoExamineEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamineCategoryController_allExamineCategoryAndAssay_2ea4ae: {
    method: 'post',
    url: '/api/examine/all-examine-category-and-assay',
    parameterFomatter: (data?: {
      qto: AllExamineCategoryQtoExaminePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamineCategoryVoExamineEntranceWebVo[],
    ) => data,
  },
  AssayController_allAssay_b04dc7: {
    method: 'post',
    url: '/api/assay/all-assay',
    parameterFomatter: (data?: { qto: AllAssayQtoAssayPersistQto }) => data,
    responseFormatter: (
      _,
      __,
      data?: AssayVoAssayEntranceWebVoAssaycommonvo[],
    ) => data,
  },
  AssayCommonController_getByCommonType_d6595d: {
    method: 'post',
    url: '/api/assay/get-by-common-type',
    parameterFomatter: (data?: { commonType: CommonTypeEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: AssayVoAssayEntranceWebVoAssaycommonvo[],
    ) => data,
  },
  AssayController_createAssay_e7f1bf: {
    method: 'post',
    url: '/api/assay/create-assay',
    parameterFomatter: (data?: { bto: CreateAssayVoAssayEntranceWebVo }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  AssayController_createAssayItem_0f1ac6: {
    method: 'post',
    url: '/api/assay/create-assay-item',
    parameterFomatter: (data?: { bto: CreateReportItemBtoAssayServiceBto }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TreatmentRecordBOController_createTreatmentRecord_17cffd: {
    method: 'post',
    url: '/api/treatment-record/create-treatment-record',
    parameterFomatter: (data?: {
      btoParam: CreateTreatmentRecordBtoTreatmentRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TreatmentRecordBOController_updateTreatmentRecord_990a4c: {
    method: 'post',
    url: '/api/treatment-record/update-treatment-record',
    parameterFomatter: (data?: {
      btoParam: UpdateTreatmentRecordBtoTreatmentRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TreatmentRecordDtoController_getById_13902a: {
    method: 'post',
    url: '/api/treatment-record/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatmentRecordDtoTreatmentRecordManagerDto,
    ) => data,
  },
  TreatmentRecordDtoController_getByUserId_2a69de: {
    method: 'post',
    url: '/api/treatment-record/get-by-user-id',
    parameterFomatter: (data?: { userId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatmentRecordDtoTreatmentRecordManagerDto[],
    ) => data,
  },
  FemaleIllnessHistoryBOController_createFemaleIllnessHistory_194041: {
    method: 'post',
    url: '/api/medical-history/create-female-illness-history',
    parameterFomatter: (data?: {
      btoParam: CreateFemaleIllnessHistoryBtoMedicalHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FemaleIllnessHistoryBOController_updateFemaleIllnessHistory_054b83: {
    method: 'post',
    url: '/api/medical-history/update-female-illness-history',
    parameterFomatter: (data?: {
      btoParam: UpdateFemaleIllnessHistoryBtoMedicalHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FemaleIllnessHistoryDtoController_getById_7b9aba: {
    method: 'post',
    url: '/api/medical-history/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FemaleIllnessHistoryDtoMedicalHistoryManagerDto,
    ) => data,
  },
  FemaleIllnessHistoryDtoController_getByTreatmentRecordId_61aaec: {
    method: 'post',
    url: '/api/medical-history/get-by-treatment-record-id',
    parameterFomatter: (data?: { treatmentRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FemaleIllnessHistoryDtoMedicalHistoryManagerDto,
    ) => data,
  },
  MaleIllnessHistoryBOController_createMaleIllnessHistory_c24fb9: {
    method: 'post',
    url: '/api/male-medical-history/create-male-illness-history',
    parameterFomatter: (data?: {
      btoParam: CreateMaleIllnessHistoryBtoMaleMedicalHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MaleIllnessHistoryBOController_updateMaleIllnessHistory_c8c2c2: {
    method: 'post',
    url: '/api/male-medical-history/update-male-illness-history',
    parameterFomatter: (data?: {
      btoParam: UpdateMaleIllnessHistoryBtoMaleMedicalHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MaleIllnessHistoryDtoController_getById_6e4785: {
    method: 'post',
    url: '/api/male-medical-history/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MaleIllnessHistoryDtoMaleMedicalHistoryManagerDto,
    ) => data,
  },
  MaleIllnessHistoryDtoController_getByTreatmentRecordId_b56480: {
    method: 'post',
    url: '/api/male-medical-history/get-by-treatment-record-id',
    parameterFomatter: (data?: { treatmentRecordId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MaleIllnessHistoryDtoMaleMedicalHistoryManagerDto,
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeDtoComAspirinBaseDictionaryManagerDto[],
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeTypeAndParentCode_27dfb1: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeTypeAndParentCode',
    parameterFomatter: (data?: { var: CodeTypeAndParentCode }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeDtoComAspirinBaseDictionaryManagerDto[],
    ) => data,
  },
  BaseDictionaryController_getAll_6ecb98: {
    method: 'post',
    url: '/api/dbenum/codetype/all/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeDtoComAspirinBaseDictionaryManagerDto[],
    ) => data,
  },
  DoctorInfoController_doctorLogin_5542f9: {
    method: 'post',
    url: '/api/doctor/doctor-login',
    parameterFomatter: (data?: { code: string; password: string }) => data,
    responseFormatter: (_, __, data?: DoctorInfoVoDoctorEntranceWebVo) => data,
  },
  DoctorInfoController_getDoctorInfoByCode_678cdf: {
    method: 'post',
    url: '/api/doctor/get-doctor-info-by-code',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: DoctorInfoVoDoctorEntranceWebVo) => data,
  },
  DoctorInfoController_getDoctorInfoById_1d26d9: {
    method: 'post',
    url: '/api/doctor/get-doctor-info-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DoctorInfoVoDoctorEntranceWebVo) => data,
  },
  DiagnosePatientInfoController_createDiagnosePatientInfo_251168: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-diagnose-patient-info',
    parameterFomatter: (data?: {
      btoParam: CreateDiagnosePatientInfoBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DiagnosePatientInfoController_getDiagnosePatientInfo_654515: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-diagnose-patient-info',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosePatientInfoVoMedicalRecordReproductionEntranceWebVo,
    ) => data,
  },
  DiagnosisInfoController_createDiagnosisInfo_6e676b: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-diagnosis-info',
    parameterFomatter: (data?: {
      btoParam: CreateDiagnosisInfoBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DiagnosisInfoController_getDiagnosisInfoByPatientId_48e981: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-diagnosis-info-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosisInfoDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  FemaleRecordOneController_createFemaleRecordOne_8633ef: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-female-record-one',
    parameterFomatter: (data?: {
      btoParam: CreateFemaleRecordOneBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FemaleRecordOneController_getFemaleRecordOneByPatientId_48edb4: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-female-record-one-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FemaleRecordOneDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  FemaleRecordTwoController_createFemaleRecordTwo_84d02b: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-female-record-two',
    parameterFomatter: (data?: {
      btoParam: CreateFemaleRecordTwoBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FemaleRecordTwoController_getFemaleRecordTwoByPatientId_03bf6e: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-female-record-two-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FemaleRecordTwoDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  FirstDiseaseController_createFirstDisease_175f56: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-first-disease',
    parameterFomatter: (data?: {
      btoParam: CreateFirstDiseaseBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FirstDiseaseController_getFirstDiseaseByPatientId_fa0716: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-first-disease-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FirstDiseaseDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  MaleRecordOneController_createMaleRecordOne_5a7bc2: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-male-record-one',
    parameterFomatter: (data?: {
      btoParam: CreateMaleRecordOneBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MaleRecordOneController_getMaleRecordOneByPatientId_e76ed9: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-male-record-one-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MaleRecordOneDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  MaleRecordTwoController_createMaleRecordTwo_3ff148: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-male-record-two',
    parameterFomatter: (data?: {
      btoParam: CreateMaleRecordTwoBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MaleRecordTwoController_getMaleRecordTwoByPatientId_29a56d: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-male-record-two-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MaleRecordTwoDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  MedicatedFolliclesController_createMedicatedFollicles_30fa6c: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-medicated-follicles',
    parameterFomatter: (data?: {
      btoParam: CreateMedicatedFolliclesBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicatedFolliclesController_getMedicatedFolliclesByPatientId_a462d6: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-medicated-follicles-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicatedFolliclesDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  MedicatedFolliclesHormoneController_createMedicatedFolliclesHormone_d0504b: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-medicated-follicles-hormone',
    parameterFomatter: (data?: {
      btoParam: CreateMedicatedFolliclesHormoneBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicatedFolliclesHormoneController_getMedicatedFolliclesHormoneByPatientId_82e216:
    {
      method: 'post',
      url: '/api/medical-record-reproduction/get-medicated-follicles-hormone-by-patient-id',
      parameterFomatter: (data?: { patientId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicatedFolliclesHormoneDtoMedicalRecordReproductionManagerDto[],
      ) => data,
    },
  MedicatedFolliclesMedicationController_createMedicatedFolliclesMedication_f664f0:
    {
      method: 'post',
      url: '/api/medical-record-reproduction/create-medicated-follicles-medication',
      parameterFomatter: (data?: {
        btoParam: CreateMedicatedFolliclesMedicationBtoMedicalRecordReproductionServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  MedicatedFolliclesMedicationController_getMedicatedFolliclesMedicationByPatientId_5f4bf4:
    {
      method: 'post',
      url: '/api/medical-record-reproduction/get-medicated-follicles-medication-by-patient-id',
      parameterFomatter: (data?: { patientId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicatedFolliclesMedicationDtoMedicalRecordReproductionManagerDto[],
      ) => data,
    },
  TransplantRecordController_createTransplantRecord_7c879c: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-transplant-record',
    parameterFomatter: (data?: {
      btoParam: CreateTransplantRecordBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TransplantRecordController_getTransplantRecordByPatientId_5220d0: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-transplant-record-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TransplantRecordDtoMedicalRecordReproductionManagerDto,
    ) => data,
  },
  MemorandumController_createMemorandum_2333a0: {
    method: 'post',
    url: '/api/medical-record-reproduction/create-memorandum',
    parameterFomatter: (data?: {
      btoParam: CreateMemorandumBtoMedicalRecordReproductionServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MemorandumController_getMemorandumByPatientId_425b48: {
    method: 'post',
    url: '/api/medical-record-reproduction/get-memorandum-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MemorandumVoMedicalRecordReproductionEntranceWebVo,
    ) => data,
  },
  DoctorOrderOperationController_createOrderOperation_72729a: {
    method: 'post',
    url: '/api/doctor-order-operation/create-order-operation',
    parameterFomatter: (data?: {
      btoParam: CreateOrderOperationBtoDoctorOrderOperationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DoctorOrderOperationController_getById_ea493a: {
    method: 'post',
    url: '/api/doctor-order-operation/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderOperationDetailVoDoctorOrderOperationEntranceWebVo,
    ) => data,
  },
});
