import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Image,
  message,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSize } from 'ahooks';

import CdssImage from '@/assets/cdss.png';
import Block from '@/module/Block';
import Cdss from '@/module/Cdss';
import Icon from '@/module/Icon';

import CdssOrder from './cdss';
import Exam from './exam';
import Operation from './operation';
import Test from './test';

type ValueType = { id: number } & { [key: string]: any };
const style = {
  borderRadius: '2px',
  opacity: 1,
  border: '1px solid #3276E8',
  backgroundColor: 'transparent',
  color: '#3276E8',
  fontSize: '14px',
};

const normal_size = 3440;
const large_size = 3440;

const Index = (props) => {
  const { user } = props?.stores ?? {};
  const {
    treatInfo,
    currentPatient,
    medicalType,
    getDoctorOrder,
    doctorOrder,
    hospitalType,
    setInitKey,
  } = user ?? {};
  const testRef = useRef<any>();
  const examRef = useRef<any>();
  const operationRef = useRef<any>();
  const tableRef = useRef<any>();
  const [init, setInit] = useState<boolean>(false);

  const [value, setValue, getValue] = useGetState<any[]>([]);
  const { prefixCls } = props;

  const [mediaSize, setMediaSize] = useState<string>();

  const size = useSize(document.body);

  const handleSetMediaSize = useCallback(() => {
    if (size) {
      if (size?.width < large_size) {
        setMediaSize('normal');
      } else {
        setMediaSize('large');
      }
    }
  }, [size]);

  useEffect(() => {
    handleSetMediaSize();
  }, [handleSetMediaSize]);

  const handleClickOperation = async () => {
    const res = await vsf?.services?.TreatInfoController_getTreatFlag_771723?.({
      // TODO: 请检查以下这部分传参是否符合需求
    }); // TODO: 你可能需要整理返回数据格式
    if (res?.data > 0) {
      operationRef?.current?.onOpen();
    }
  };

  const renderExtraOperation = () => {
    return (
      <div className="extra-operation">
        <div onClick={handleAddRecord}>
          <Icon size={20} type="icon-yaoliao" />
          <span>药疗[1]</span>
        </div>
        <div>
          <Icon size={20} type="icon-zhongcaoyao" />
          <span>中草药[2]</span>
        </div>
        <div
          onClick={() => {
            examRef?.current?.onOpen();
          }}
        >
          <Icon size={20} type="icon-jiancha" />
          <span>检查[3]</span>
        </div>
        <div
          onClick={() => {
            testRef?.current?.onOpen();
          }}
        >
          <Icon size={20} type="icon-huayan" />
          <span>化验[4]</span>
        </div>
        <div onClick={handleClickOperation}>
          <Icon size={20} type="icon-Frame-11" />
          <span>手术[5]</span>
        </div>
        <div>
          <Icon size={20} type="icon-a-Germsbingjun" />
          <span>病理[6]</span>
        </div>
        <div>
          <Cdss title="智能校验">
            <Image
              src={CdssImage}
              preview={false}
              className="cdss-image-robot"
            />
          </Cdss>
        </div>
      </div>
    );
  };

  const handleSave = async () => {
    await tableRef?.current?.saveEdit();
    const values = (tableRef?.current?.getValues() ?? []).filter(
      (item) => item?.orderItemDtoList,
    );
    const res =
      await vsf?.services?.DoctorOrderController_createDoctorOrder_c7940c?.({
        btoParam: {
          treatId: treatInfo?.id,
          patientId: currentPatient?.patient?.id,
          orderItemBtoList: values?.map((item) => {
            const {
              orderName,
              orderTypeId,
              dosageAmount,
              usedAmount,
              allAmount,
              ...other
            } = item?.orderItemDtoList || {};
            const { id, ...rest } = orderName || {};
            return {
              ...other,
              ...rest,
              orderTypeId: id ?? orderTypeId,
              enduring: hospitalType === 'in',
              orderType: item?.orderItemDtoList?.orderType,
              doctorId: 102,
              orderName: rest?.medicationName,
              ...(typeof dosageAmount === 'object'
                ? {
                    dosageAmount: dosageAmount?.value,
                    dosageUnit: dosageAmount?.unit,
                  }
                : {
                    dosageAmount,
                  }),
              ...(typeof usedAmount === 'object'
                ? {
                    usedAmount: usedAmount?.value,
                    usedUnit: usedAmount?.unit,
                  }
                : {
                    usedAmount,
                  }),
              ...(typeof allAmount === 'object'
                ? {
                    allAmount: allAmount?.value,
                    packagingUnit: allAmount?.unit,
                  }
                : {
                    allAmount,
                  }),
            };
          }),
        },
      });
    if (res?.code === 200) {
      message.success('操作成功');
      getDoctorOrder();
      setInitKey();
    }
  };

  const handleFinish = async () => {
    await tableRef?.current?.saveEdit();
    const values = (tableRef?.current?.getValues() ?? []).filter(
      (item) => item?.orderItemDtoList,
    );
    const res = await vsf?.services?.DoctorOrderController_orderEnd_7f1077?.({
      treatId: treatInfo?.id,
    });
    if (res?.code === 200) {
      getDoctorOrder();
      message.success('完成就诊');
      setInitKey();
    }
  };

  const renderFooter = () => {
    return (
      <div className="footer">
        <div className="first">
          <div className="desc" style={{ color: '#babcbd' }}>
            <Icon
              style={{ color: '#babcbd', marginRight: '5px' }}
              type="icon-bangzhu"
              size={14}
            />
            图标说明
          </div>
          <div className="oper">
            <Button>成组</Button>
            <Button onClick={handleSave}>保存</Button>
            <Button onClick={handleSave} type="primary">
              发送
            </Button>
            <Button onClick={handleFinish} type="primary">
              诊结
            </Button>
            <Button>其他操作</Button>
          </div>
        </div>
        <div
          className="second"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {/* <div> */}
          <div style={{ marginRight: '10px', color: '#3276E8' }}>
            <Icon type="icon-Frame-19" style={{ marginRight: '5px' }} />
            另存为模板
          </div>
          {/* </div> */}
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '15px',
              }}
            >
              <div className="label">
                <Icon
                  type="icon-jine"
                  size={14}
                  style={{ marginRight: '5px' }}
                />
                全部待交明细
              </div>
              <div className="value dinpro">¥ 124.66</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="label">本次收费明细</div>
              <div className="value dinpro">¥ 0.00</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderFooter1 = () => {
    return (
      <div className="footer">
        <div
          className="first"
          style={{ justifyContent: 'flex-end', display: 'flex' }}
        >
          <div className="oper" style={{ color: '#3276E8' }}>
            {/* <Button type="primary" ghost>
              成组
            </Button>
            <Button type="dashed">取消发送</Button>
            <Button type="dashed">删除</Button>
            <Button type="dashed">作废</Button>
            <Button type="dashed">停止</Button>
            <Button type="dashed">ST</Button>
            <Button type="dashed">复制</Button> */}
            <Button
              style={style}
              // icon={<Icon type="" />}
            >
              成组
            </Button>
            <Button style={style}>取消发送</Button>
            <Button style={style}>删除</Button>
            <Button style={style}>作废</Button>
            <Button style={style}>停止</Button>
            <Button style={style}>ST</Button>
            <Button style={style}>复制</Button>
          </div>
        </div>
        <div
          className="second"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <div style={{ marginRight: '10px', color: '#3276E8' }}>
              <Icon type="icon-Frame-19" style={{ marginRight: '5px' }} />
              另存为模板
            </div>
            <div className="desc" style={{ color: '#babcbd' }}>
              <Icon
                type="icon-bangzhu"
                style={{ color: '#babcbd', marginRight: '5px' }}
                size={14}
              />
              图标说明
            </div>
          </div>
          <div>
            <Button
              onClick={handleSave}
              style={{ marginRight: '10px' }}
              type="primary"
            >
              保存
            </Button>
            <Button
              onClick={handleSave}
              style={{ marginRight: '10px' }}
              type="primary"
            >
              发送
            </Button>
            <Button type="primary">
              其他操作
              <Icon type="icon-xia" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleAddRecord = () => {
    const id = Math.random();
    const target = (getValue() ?? [])?.find(
      (item: any) => !item?.orderItemDtoList,
    );
    if (target) {
      setTimeout(() => {
        tableRef?.current?.startEdit(target?.id);
      }, 100);
    } else {
      const target = [...(getValue() ?? []), { id }];
      setValue(target);
      setTimeout(() => {
        tableRef?.current?.startEdit(id);
      }, 100);
    }
  };

  const onFieldFinish = async (key, value, record, form) => {
    if (value?.orderItemDtoList?.orderName) {
      // id 要去除, 不然会顶掉 form 的 rowKey
      const { id, ...rest } = value?.orderItemDtoList?.orderName || {};
      if (rest?.orderType === 'EXAMINE') {
        setTimeout(() => {
          examRef?.current?.onOpen(value?.orderItemDtoList?.orderName);
        }, 200);
      } else if (rest?.orderType === 'ASSAY') {
        setTimeout(() => {
          testRef?.current?.onOpen(value?.orderItemDtoList?.orderName);
        }, 200);
      } else {
        form.setFieldsValue({
          [key]: {
            orderItemDtoList: {
              ...rest,
              orderItemStatus: 'OPEN',
              orderType: rest?.orderType,
              dosageAmount: {
                value: rest?.dosage,
                unit: rest?.dosageUnit,
              },
              usedAmount: {
                value: 1,
                unit: rest?.usedUnit,
              },
              allAmount: {
                value: 1,
                unit: rest?.packagingUnit,
              },
            },
          },
        });
      }
    }
  };

  const handleDel = (v) => {};

  const handleExtraSubmit = (values) => {
    // todo 检查数据返回 填入表格
    const target = (getValue() ?? [])?.filter(
      (item: any) => item?.orderItemDtoList,
    );
    const id = Math.random();
    setValue([...target, ...values, { id }]);
    setTimeout(() => {
      tableRef?.current?.startEdit(id);
    }, 100);
  };

  const initData = useCallback(() => {
    getDoctorOrder(treatInfo?.id);
  }, [getDoctorOrder, treatInfo]);

  useEffect(() => {
    console.log('doctorOrder', doctorOrder);
    const formatValue = (doctorOrder?.orderItemDtoList || [])?.map((item) => {
      return {
        id: item?.id,
        orderItemDtoList: {
          ...item,
          orderName: {
            medicationName: item?.orderName,
            id: item?.orderTypeId,
          },
          dosageAmount: {
            value: item?.dosageAmount,
            unit: item?.dosageUnit,
          },
          usedAmount: {
            value: item?.usedAmount,
            unit: item?.usedUnit,
          },
          allAmount: {
            value: item?.allAmount,
            unit: item?.packagingUnit,
          },
        },
      };
    });
    setValue(formatValue);
  }, [doctorOrder, setValue]);

  useEffect(() => {
    initData();
  }, [initData]);

  const [orderCdssOpen, setOrderCdssOpen] = useState(false);

  const formatValues = (v) => {
    return v?.map((item: any) => {
      if (item?.medicationName) {
        return {
          id: item?.id,
          orderItemDtoList: {
            ...item,
            orderType: 'MEDICATION',
            orderName: {
              ...item,
              medicationName: item?.medicationName,
              id: item?.id,
            },
            unitPrice: item?.unitPrice,
            dosageAmount: {
              value: item?.dosage,
              unit: item?.dosageUnit,
            },
            usedAmount: {
              value: 1,
              unit: item?.usedUnit,
            },
            allAmount: {
              value: 1,
              unit: item?.packagingUnit,
            },
          },
        };
      }
      if (item?.examineName) {
        return {
          id: item?.id,
          orderItemDtoList: {
            ...item,
            orderTypeId: item?.id,
            orderType: 'EXAMINE',
            enduring: hospitalType === 'in',
            orderName: {
              ...item,
              medicationName: item?.examineName,
              id: item?.id,
            },
            orderApplyBto: {},
            unitPrice: item?.unitPrice,
          },
        };
      }
      if (item?.assayName) {
        return {
          id: item?.id,
          orderItemDtoList: {
            ...item,
            orderTypeId: item?.id,
            orderType: 'ASSAY',
            enduring: hospitalType === 'in',
            orderName: {
              ...item,
              medicationName: item?.assayName,
              id: item?.id,
            },
            orderApplyBto: {},
            unitPrice: item?.unitPrice,
          },
        };
      }
    });
  };

  const onSubmit = (values) => {
    setOrderCdssOpen(false);
    const target = (getValue() ?? [])?.filter(
      (item: any) => item?.orderItemDtoList,
    );
    const id = Math.random();
    setValue([...target, ...formatValues(values), { id }]);
    setTimeout(() => {
      tableRef?.current?.startEdit(id);
    }, 100);
  };

  const handleDoubleClickOrder = (data) => {
    const { orderItemDtoList } = data || {};
    const { orderType, orderName } = orderItemDtoList;
    console.log('orderName', orderName);

    if (orderType) {
      if (orderType === 'EXAMINE') {
        examRef?.current?.onOpen(orderName);
      }
      if (orderType === 'ASSAY') {
        testRef?.current?.onOpen(orderName);
      }
      if (orderType === 'OPERATION') {
        operationRef?.current?.onOpen(orderName);
      }
    }
  };

  const handleDelItem = (v) => {
    tableRef?.current?.remove(v);
  };

  const handleCancelItem = () => {
    tableRef?.current?.saveEdit();
  };

  return (
    <div className={`${prefixCls}-mainContainer ${mediaSize}-mainContainer`}>
      <Block
        title="医嘱"
        desc={
          <CdssOrder
            isOpen={orderCdssOpen}
            onClose={() => {
              setOrderCdssOpen(false);
            }}
            onSubmit={onSubmit}
          >
            <div
              onClick={() => {
                setOrderCdssOpen(true);
              }}
            >
              <Icon
                type="icon-zhinengyizhu"
                size={20}
                style={{
                  position: 'relative',
                  top: '2px',
                  color: '#3276E8',
                  cursor: 'pointer',
                }}
              />
            </div>
          </CdssOrder>
        }
        extra={renderExtraOperation()}
        footer={hospitalType === 'out' ? renderFooter() : renderFooter1()}
        className="aspirin-block-nomargin"
      >
        <Test ref={testRef} onSubmit={handleExtraSubmit} />
        <Exam ref={examRef} onSubmit={handleExtraSubmit} />
        <Operation ref={operationRef} onSubmit={handleExtraSubmit} />
        <VSTable
          ref={tableRef}
          scroll={{
            y: 780,
            x: hospitalType === 'in' ? 2100 : 1800,
          }}
          className="aspirin-table aspirin-table-nopadding aspirin-table-advice"
          editable={{
            editType: 'single',
            onCanEdit: (value) => {
              const { orderItemDtoList } = value || {};
              const { orderType, orderName } = orderItemDtoList || {};
              return (
                orderType !== 'EXAMINE' &&
                orderType !== 'ASSAY' &&
                orderType !== 'OPERATION'
              );
            },
            columnProps: {
              fixed: 'right',
              width: 50,
              hideInTable: true,
            },
            switchMode: {
              onRowFinish: async (value) => {
                return true;
              },
              onColumnFinish: async (value) => {
                // await onFieldFinish(value);
                return true;
              },
            },
            onFieldChange: onFieldFinish,
            onPreventEdit: () => {},
          }}
          bordered
          onRecord={async () => ({ id: Math.random() })}
          value={value}
          onChange={(_value) => {
            setValue(_value);
          }}
          pagination={false}
          rowClick={{
            rowClickType: {
              type: 'edit-save',
              action: 'double-click',
            },
          }}
          onRow={(record) => {
            return {
              onDoubleClick: (event) => {
                handleDoubleClickOrder(record);
                return false;
              },
            };
          }}
        >
          <VSTableColumn
            dataIndex={['orderItemDtoList', 'orderItemStatus']}
            title="状态"
            valueType="select"
            fieldProps={{
              defaultValue: '',
            }}
            dataSource={[
              { label: '已开立', value: 'OPEN' },
              { label: '已发送', value: 'SEND' },
            ]}
            width={60}
            fixed="left"
            preview
            render={(dom: any) => {
              return <div className="order-status-color">{dom}</div>;
            }}
          />

          {hospitalType === 'in' && (
            <VSTableColumn
              dataIndex={['orderItemDtoList', 'startTime']}
              title="开始时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              preview
              width={100}
            />
          )}

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'orderType']}
            title="类别"
            valueType="select"
            width={55}
            dataSource={[
              { label: '药疗', value: 'MEDICATION' },
              { label: '处置', value: 'HANDLE' },
              { label: '中草药', value: 'HERBS' },
              { label: '检查', value: 'EXAMINE' },
              { label: '化验', value: 'ASSAY' },
              { label: '手术', value: 'OPERATION' },
              { label: '病理', value: 'PATHOLOGY' },
            ]}
            fixed="left"
            className="order-main-color"
            preview
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'orderName']}
            title="医嘱"
            valueType="dictionary"
            width={250}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'dosageAmount']}
            title="剂量"
            valueType="inputUnit"
            width={100}
            className="order-main-color"
            fieldProps={{
              allowClear: false,
            }}
          />

          {/* <VSTableColumn
            dataIndex={['orderItemDtoList', 'dosageUnit']}
            title=""
            valueType="text"
            width={40}
            fieldProps={{}}
            preview
          /> */}

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'usedAmount']}
            title="用量"
            width={100}
            valueType="inputUnit"
            fieldProps={{
              allowClear: false,
            }}
            preview={(v, data) => {
              return data?.orderItemDtoList?.orderType !== 'MEDICATION';
            }}
          />

          {/* <VSTableColumn
            dataIndex={['orderItemDtoList', 'usedUnit']}
            title=""
            width={40}
            valueType="text"
            fieldProps={{}}
            preview
          /> */}

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'way']}
            title="途径"
            width={100}
            valueType="select"
            className="order-main-color"
            fieldProps={{}}
            dataSource={[
              '口服',
              '静脉注射',
              '皮下注射',
              '门诊静注',
              '雾化吸入',
              '皮试',
              '造影',
              '滴左眼',
              '滴右眼',
              '滴双眼',
              '喷鼻',
              '喷患处',
              '冲洗',
              '吸痰用',
              '喷口腔',
              '阴道灌洗',
              '阴道冲洗',
              '深静脉穿刺',
              '涂患处',
              '适量外用',
              '必要时服用',
              '适量滴鼻',
              '适量滴耳',
              '适量滴双眼',
              '适量滴左眼',
              '适量滴右眼',
              '静脉穿刺',
              '适量喷鼻',
              '检查用',
              '适量涂眼',
              '适量涂患处',
              '关节腔内注射',
              '穴位注射',
              '麻醉用',
              '雾化吸入（门诊）',
              '贴患处',
              '深静脉切开',
              '贴胸',
              '贴背',
              '贴用',
              '大关节腔内穿刺注射',
              '皮下注射（腹透）',
              '皮下注射（自助）',
              '冲管用',
              '基础率',
              '皮内注射',
              '小儿雾化吸入',
              '局麻（拨牙）',
              '术中用',
              '皮下泵持续注射',
              'IH（自助）',
              '皮下注射（血透）',
              '皮试用',
              '气道湿化',
              '塞入阴道',
              '深静脉营养液',
              '腹腔灌注',
              'IM',
              '膀胱冲洗',
              '血液透析',
              '膀胱灌注',
              '腹膜透析',
              '持续冲洗',
              '肾盂灌洗',
              '坐浴',
              '鞘内注射',
              '滴鼻',
              '漱口',
              'IV',
              '加入GTT液',
              '羊膜腔注射',
              '涂眼',
              '球结膜注射',
              '涂鼻',
              '舌下含服',
              '塞肛',
              '纤支镜用药',
              '鼻饲',
              '胸腔灌注',
              '肺泡灌洗',
              '胸腔注射',
              'GTT交替',
              '混服',
              '保留灌肠',
              '髂总动脉灌注',
              '肠上动脉灌注',
              '肠下动脉灌注',
              '肝动脉灌注',
              '支气管动脉灌注',
              '外用',
              '烧尿糖',
              '含化',
              '腰穿用药',
              '肠镜用药',
              '腹腔注入',
              '血肿腔注入',
              '局部注射',
              '颈动脉灌注',
              '腹壁泵内注射',
              '滴耳',
              '会阴擦洗',
              '加入输血中',
              'V交替',
              'M交替',
              '双侧合谷封闭',
              '足三里封闭',
              '更换水封瓶',
              '针孔消毒',
              '气管内滴入',
              '灌肠',
              '输血用',
              'GTT维持',
              '血气分析用',
              '胃管注入',
              '微量泵维持',
              '封管',
              '大泵注射',
              '小泵注射',
              '泵内注射',
              '封闭',
              '湿敷',
              '预防接种',
              '宫颈封闭',
              '宫颈注射',
              '宫体注射',
              '局麻用',
              '介入用',
              '备用',
              '煎服',
              '冲服',
              '先煎',
              '后下',
              '浸酒',
              '包煎',
              '煎汁外洗',
              '烊化',
              '吸入',
              '咀嚼',
              '肌注',
            ]?.map((item) => ({
              label: item,
              value: item,
            }))}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'dropRate']}
            title="滴速"
            valueType="text"
            fieldProps={{
              allowClear: false,
            }}
            width={100}
            preview={(v, data) => {
              return data?.orderItemDtoList?.orderType !== 'MEDICATION';
            }}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'frequency']}
            title="频次"
            valueType="select"
            width={100}
            fieldProps={{}}
            dataSource={[
              'qd',
              'q12h',
              'qod',
              'biw',
              'once',
              'prn',
              'qn',
              'q3h',
              '维持',
              'qw',
              'bid',
              'q2w',
              'q4w',
              'q5h',
              'q3d',
              '5/日',
              '7/日',
              '早',
              '中',
              '晚',
              'tid',
              'q72h',
              '一、三、五',
              '二、四、六',
              '一、三',
              'qid',
              '二、四',
              '周一',
              '周二',
              '周三',
              '周四',
              '周五',
              '周六',
              '周日',
              '1D',
              '1k',
              'q1h',
              'qidy',
              'q2h',
              'q4h',
              'q6h',
              'q8h',
            ]?.map((item) => ({
              label: item,
              value: item,
            }))}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'usage']}
            title="用法"
            width={100}
            valueType="select"
            dataSource={[
              { label: '餐前', value: '餐前' },
              { label: '餐后', value: '餐后' },
              { label: '餐时', value: '餐时' },
              { label: '晨间', value: '晨间' },
              { label: '睡前', value: '睡前' },
              { label: '必要时', value: '必要时' },
              { label: '术中用', value: '术中用' },
              { label: '检查用', value: '检查用' },
              { label: '领用', value: '领用' },
              { label: '血透用', value: '血透用' },
            ]}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'dayAmount']}
            title="天数"
            valueType="text"
            fieldProps={{
              allowClear: false,
            }}
            width={70}
          />

          {hospitalType === 'in' && (
            <VSTableColumn
              dataIndex={['orderItemDtoList', 'allAmount']}
              title="摆药"
              valueType="inputUnit"
              fieldProps={{
                allowClear: false,
              }}
              width={100}
            />
          )}
          {hospitalType === 'in' && (
            <VSTableColumn
              dataIndex={['orderItemDtoList', 'injectionAmount']}
              title="配置"
              valueType="text"
              fieldProps={{
                allowClear: false,
              }}
              width={100}
            />
          )}
          {hospitalType === 'out' && (
            <VSTableColumn
              dataIndex={['orderItemDtoList', 'allAmount']}
              title="数量"
              valueType="inputUnit"
              fieldProps={{
                allowClear: false,
              }}
              width={100}
            />
          )}

          {hospitalType === 'out' && (
            <VSTableColumn
              dataIndex={['orderItemDtoList', 'injectionAmount']}
              title="注射"
              valueType="text"
              fieldProps={{
                allowClear: false,
              }}
              width={100}
            />
          )}

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'executeDepartment']}
            title="执行科室"
            valueType="text"
            fieldProps={{
              defaultValue: '门诊药房',
            }}
            width={100}
            preview
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'unitPrice']}
            title="单价"
            valueType="text"
            fieldProps={{
              allowClear: false,
            }}
            width={70}
            preview
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'comment']}
            title="备注"
            valueType="text"
            width={100}
          />

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'doctorId']}
            title="开单医师"
            valueType="text"
            fieldProps={{
              allowClear: false,
            }}
            preview
            width={100}
          />

          {hospitalType === 'in' && (
            <>
              <VSTableColumn
                dataIndex={['orderItemDtoList', 'orderTime']}
                title="预约时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                preview
                width={100}
              />
              <VSTableColumn
                dataIndex={['orderItemDtoList', 'endTime']}
                title="停止时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                preview
                width={100}
              />
            </>
          )}

          <VSTableColumn
            dataIndex={['orderItemDtoList', 'orderTime']}
            title="开单时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            preview
            width={100}
          />

          <VSTableColumn
            title="操作"
            renderFormItem={(dom, config: any) => {
              return (
                <Button
                  type="text"
                  onClick={() => {
                    handleCancelItem();
                  }}
                >
                  取消
                </Button>
              );
            }}
            render={(dom, record) => {
              const forbidDel =
                record?.orderItemDtoList?.orderItemStatus === 'SEND';
              return (
                <Button
                  type="text"
                  onClick={() => {
                    handleDelItem(record);
                  }}
                  disabled={forbidDel}
                >
                  删除
                </Button>
              );
            }}
            fixed="right"
            width={50}
          />
        </VSTable>
      </Block>
    </div>
  );
};

export default definePage(Index);
