import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import Block from '@/module/Block';
import FlexBlock from '@/module/FlexBlock';

import Chart from './chart';

const mockDaySign = [
  {
    label: '身高',
    value: '175cm',
  },
  {
    label: '体重',
    value: '75kg',
  },
  {
    label: '血压',
    value: '100mmHg',
  },
  {
    label: '血糖',
    value: '5.1mmol/L',
  },
  {
    label: 'BMI',
    value: '/',
  },
  {
    label: '呼吸',
    value: '/',
  },
  {
    label: '脉搏',
    value: '/',
  },
  {
    label: '体温',
    value: '/',
  },
];
enum OrderTypeEnum {
  MEDICATION = 'MEDICATION',
  HANDLE = 'HANDLE',
  HERBS = 'HERBS',
  EXAMINE = 'EXAMINE',
  ASSAY = 'ASSAY',
  OPERATION = 'OPERATION',
  PATHOLOGY = 'PATHOLOGY',
}
const Index = (props) => {
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient, treatInfo } = user ?? {};
  console.log(treatInfo);

  const [list, setList] = useState<any[]>([]);
  const [allOrderItem, setAllOrderItem] = useState<any[]>([]);

  const handelType = (type) => {
    return allOrderItem.filter((item) => {
      return item?.orderType === type;
    });
  };

  const getList = useCallback(async () => {
    if (!treatInfo?.id) {
      setList([]);
      setAllOrderItem([]);
    } else {
      const res =
        await vsf?.services?.TreatInfoController_getTreatInfoDetail_9204c8?.({
          id: treatInfo.id,
        });
      const res1 =
        await vsf?.services?.OrderItemController_allOrderItem_f38b92?.({
          qto: { treatIdIs: treatInfo.id },
        });
      if (res.code === 200) {
        setList([res?.data]);
      }
      if (res1.code === 200) {
        setAllOrderItem(res1?.data);
      }
    }
  }, [treatInfo]);
  useEffect(() => {
    getList();
  }, [getList, initKey]);

  return (
    <div className="info">
      <Block
        title="主诉"
        desc={treatInfo?.id ? '未避孕未孕1年，要求AIH' : ''}
      ></Block>
      <FlexBlock
        style={{
          alignItems: 'flex-start',
        }}
        gap={['48px']}
      >
        <Block title="诊断" height={240}>
          <VSPromiseTable
            scroll={{
              y: 195,
            }}
            className="aspirin-table-transparent"
            id="1"
            onFetch={async (params) => {
              return list;
            }}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['mainDiagnose']}
              title="药物性肝损害"
              editable={false}
              valueType="digit"
            />

            <VSTableColumn
              dataIndex={['quantity']}
              title="主要诊断"
              editable={false}
              valueType="text"
              render={() => {
                return '主要诊断';
              }}
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Block>
        <Block title="用药记录" height={240}>
          <VSPromiseTable
            className="aspirin-table-transparent"
            id="1"
            scroll={{
              y: 195,
            }}
            onFetch={async (params) => {
              return { data: handelType(OrderTypeEnum.MEDICATION) };
            }}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderName']}
              title="名称"
              width={110}
              editable={false}
              valueType="digit"
            />
            <VSTableColumn
              dataIndex={['dosageAmount']}
              title="剂量"
              editable={false}
              valueType="text"
              render={(_, data) => {
                return (
                  <div style={{ color: '#3276e8' }}>
                    {data?.dosageAmount} {data?.dosageUnit}
                  </div>
                );
              }}
            />
            <VSTableColumn
              dataIndex={['dosageAmount']}
              title="用量"
              editable={false}
              valueType="text"
              render={(_, data) => {
                return (
                  <div>
                    {data?.usedAmount} {data?.usedUnit}
                  </div>
                );
              }}
            />
            <VSTableColumn
              dataIndex={['way']}
              title="途径"
              editable={false}
              valueType="text"
              render={(_, data) => {
                return <div style={{ color: '#3276e8' }}>{data?.way}</div>;
              }}
            />
            <VSTableColumn
              dataIndex={['dropRate']}
              title="滴速"
              editable={false}
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['orderTime']}
              title="时间"
              editable={false}
              valueType="date"
              fieldProps={{}}
              width={110}
              render={(_, data) => {
                return (
                  <div style={{ color: '#AEB0B3' }}>
                    {dayjs(data?.orderTime).format('YYYY-MM-DD')}
                  </div>
                );
              }}
            />
          </VSPromiseTable>
        </Block>
      </FlexBlock>
      <Block title="体征" height={456}>
        <FlexBlock colSpan={[1, '200px']} gap={['16px']}>
          <Chart />
          <Block className="other" title="今日特征">
            <div className="day-sign">
              {mockDaySign?.map((item, index) => (
                <div className="item" key={index}>
                  <span>{item?.label}</span>
                  <span>{item?.value}</span>
                </div>
              ))}
            </div>
          </Block>
        </FlexBlock>
      </Block>
      <FlexBlock
        style={{
          alignItems: 'flex-start',
        }}
        gap={['48px']}
      >
        <Block title="检查" height={240}>
          <VSPromiseTable
            scroll={{
              y: 195,
            }}
            className="aspirin-table-transparent"
            id="1"
            onFetch={async (params) => {
              console.log(handelType(OrderTypeEnum.EXAMINE), 'fffffp0oa9asu');
              return { data: handelType(OrderTypeEnum.EXAMINE) };
            }}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderName']}
              title="组件"
              editable={false}
              valueType="text"
            />

            <VSTableColumn
              dataIndex={['orderTime']}
              title="是否被锁定"
              editable={false}
              valueType="date"
              render={(_, data) => {
                return (
                  <div style={{ color: '#AEB0B3' }}>
                    {dayjs(data?.orderTime).format('YYYY-MM-DD')}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['orderTime']}
              title="锁定时间"
              editable={false}
              valueType="date"
              render={() => {
                return '确认报告';
              }}
            />
          </VSPromiseTable>
        </Block>
        <Block title="检验" height={240}>
          <VSPromiseTable
            className="aspirin-table-transparent"
            id="1"
            scroll={{
              y: 195,
            }}
            onFetch={async (params) => {
              return { data: handelType(OrderTypeEnum.ASSAY) };
            }}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderName']}
              title="组件"
              editable={false}
              valueType="text"
            />

            <VSTableColumn
              dataIndex={['orderTime']}
              title="是否被锁定"
              editable={false}
              valueType="date"
              render={(_, data) => {
                return (
                  <div style={{ color: '#AEB0B3' }}>
                    {' '}
                    {dayjs(data?.orderTime).format('YYYY-MM-DD')}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['1']}
              title="锁定时间"
              editable={false}
              valueType="date"
              render={() => {
                return '确认报告';
              }}
            />
          </VSPromiseTable>
        </Block>
      </FlexBlock>
    </div>
  );
};

export default definePage(Index);
