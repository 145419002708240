import {
  ControlComponent,
  ControlType,
} from '../../../../dataset/enum/Control';
import { KeyMap } from '../../../../dataset/enum/KeyMap';
import { IControlInstance } from '../../../../interface/Control';
import { IElement } from '../../../../interface/Element';
import { splitText } from '../../../../utils';
import { Control } from '../Control';
import { DatePicker } from './DatePicker';

export class DateControl implements IControlInstance {
  private element: IElement;
  private control: Control;
  private isPopup: boolean;
  private datePicker: DatePicker;

  constructor(element: IElement, control: Control) {
    this.element = element;
    this.control = control;
    this.isPopup = false;
    this.datePicker = new DatePicker(
      {
        mountDom: this.control.getContainer(),
        onSubmit: this.setDate.bind(this),
      },
      this.control.getRoot(),
    );
  }

  public markRequired(startElement: IElement) {
    const elementList = this.control.getElementList(startElement);
    const startIndex = elementList.indexOf(startElement);
    if (!startElement.control?.required) {
      return;
    }
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (preElement.controlId !== startElement.controlId) {
        break;
      } else if (preElement.controlComponent === ControlComponent.PLACEHOLDER) {
        preElement.markRequired = true;
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (nextElement.controlId !== startElement.controlId) {
        break;
      } else if (
        nextElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        nextElement.markRequired = true;
      }
      nextIndex++;
    }
  }

  public getElement(): IElement {
    return this.element;
  }

  public getValue(): IElement[] {
    const range = this.getDateElementRange();
    if (!range) {
      return [];
    }
    const elementList = this.control.getElementList();
    return elementList.slice(range[0] + 1, range[1] + 1);
  }

  public setValue(): number {
    return -1;
  }

  public keydown(evt: KeyboardEvent): number {
    const elementList = this.control.getElementList();
    const range = this.control.getRange();
    // 收缩边界到Value内
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = range;
    const startElement = elementList[startIndex];
    const endElement = elementList[endIndex];
    // backspace
    if (evt.key === KeyMap.Backspace) {
      // 清空日期
      if (startIndex !== endIndex) {
        return this.clearDate();
      } else {
        if (
          startElement.controlComponent === ControlComponent.PREFIX ||
          endElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 清空日期
          return this.clearDate();
        }
      }
    } else if (evt.key === KeyMap.Delete) {
      // 移除选区元素
      if (startIndex !== endIndex) {
        /// 清空日期
        return this.clearDate();
      } else {
        const endNextElement = elementList[endIndex + 1];
        if (
          (startElement.controlComponent === ControlComponent.PREFIX &&
            endNextElement.controlComponent === ControlComponent.PLACEHOLDER) ||
          endNextElement.controlComponent === ControlComponent.POSTFIX ||
          startElement.controlComponent === ControlComponent.PLACEHOLDER
        ) {
          // 前缀、后缀、占位符
          return this.control.removeControl(startIndex);
        } else {
          // 清空日期
          return this.clearDate();
        }
      }
    }
    return endIndex;
  }

  public cut(): number {
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = this.control.getRange();
    if (startIndex === endIndex) {
      return startIndex;
    }
    // 清空日期
    return this.clearDate();
  }

  public clearDate(): number {
    const range = this.getDateElementRange();
    if (!range) return -1;
    const [leftIndex, rightIndex] = range;
    const elementList = this.control.getElementList();
    // 删除元素
    elementList.splice(leftIndex + 1, rightIndex - leftIndex);
    // 增加占位符
    this.control.addPlaceholder(leftIndex);
    return leftIndex;
  }

  public setDate(date?: string) {
    if (!date) return;
    const elementList = this.control.getElementList();
    // 清空日期
    const startIndex = this.clearDate();
    const startElement = elementList[startIndex];
    this.control.removePlaceholder(startIndex);
    // 插入
    const start = startIndex + 1;
    const data = splitText(date);
    for (let i = 0; i < data.length; i++) {
      elementList.splice(start + i, 0, {
        ...startElement,
        value: data[i],
        controlComponent: ControlComponent.VALUE,
      });
    }
    // render
    const newIndex = start + data.length - 1;
    this.control.repaintControl(newIndex);
    this.destroy();
  }

  private _createDatePopupDom() {
    const range = this.getDateElementRange();
    if (!range) return false;
    const position = this.control.getPosition(range[0]);
    if (!position) return false;
    const startTop = this.control.getPreY();
    const value = this.getValue()
      .map((el) => el.value)
      .join('');
    this.datePicker.render({
      value,
      dateFormat: this.element.control?.dateFormat,
      position,
      startTop,
    });
    return true;
  }

  public awake() {
    if (this.isPopup) return;
    const elementList = this.control.getElementList();
    const { startIndex } = this.control.getRange();
    if (elementList[startIndex + 1]?.controlId !== this.element.controlId)
      return;
    if (this._createDatePopupDom()) {
      this.isPopup = true;
    }
  }

  public destroy() {
    if (!this.isPopup) return;
    this.datePicker.dispose();
    this.isPopup = false;
  }

  public getDateElementRange(): [number, number] | null {
    let leftIndex = -1;
    let rightIndex = -1;
    const { startIndex, endIndex } = this.control.getRange();
    if (!~startIndex && !~endIndex) return null;
    const elementList = this.control.getElementList();
    const startElement = elementList[startIndex];
    if (startElement.control?.type !== ControlType.DATE) return null;
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (
        preElement.controlId !== startElement.controlId ||
        preElement.controlComponent === ControlComponent.PREFIX ||
        preElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        leftIndex = preIndex;
        break;
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (
        nextElement.controlId !== startElement.controlId ||
        nextElement.controlComponent === ControlComponent.POSTFIX ||
        nextElement.controlComponent === ControlComponent.PLACEHOLDER
      ) {
        rightIndex = nextIndex - 1;
        break;
      }
      nextIndex++;
    }
    // 控件在最后
    if (nextIndex === elementList.length) {
      rightIndex = nextIndex - 2;
    }
    if (!~leftIndex || !~rightIndex) return null;
    return [leftIndex, rightIndex];
  }
}
