import {
  Button,
  RadioGroup,
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import Icon from '@/module/Icon';

const mock1 = [
  {
    label: '未诊',
    value: 'NOT_TREAT',
  },
  {
    label: '暂挂',
    value: 'HANG_UP',
  },
  {
    label: '已诊',
    value: 'HAS_TREAT',
  },
];

const mock2 = [
  {
    label: '简表',
    value: '简表',
  },
  {
    label: '详表',
    value: '详表',
  },
];
const Index = (props) => {
  const { data, onSelect } = props;
  const [status, setStatus] = useState('NOT_TREAT');
  const [type, setType] = useState('简表');

  const handleSelect = useCallback(
    (data) => {
      onSelect?.(data);
    },
    [onSelect],
  );

  const onChangeStatus = (v) => {
    setStatus(v);
  };
  const onChangeType = (v) => {
    setType(v);
  };

  const renderChild = () => {
    return (
      <div className="content">
        <div className="header">
          <RadioGroup
            optionType="button"
            onChange={onChangeStatus}
            value={status}
            dataSource={mock1}
          ></RadioGroup>
          <RadioGroup
            optionType="button"
            onChange={onChangeType}
            value={type}
            dataSource={mock2}
            style={{
              marginLeft: '24px',
            }}
          ></RadioGroup>
        </div>
        <div className="main">
          <VSTable
            id="1"
            value={data?.filter((item) => item?.registerStatus === status)}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
            rowSelection={{
              selectType: 'single',
              // selectedRows: selectedRows,
              hideColumn: true,
              onSelect: (node, key, selected) => {
                handleSelect(node);
              },
              onSelectedRowsChange: (data, keys) => {
                handleSelect(data?.[0]);
              },
            }}
            rowClick={{
              rowClickType: 'select',
            }}
            className="aspirin-table"
          >
            <VSTableColumn
              dataIndex={['time']}
              title="序号"
              editable={false}
              valueType="digit"
              render={(dom, record: any) => {
                return (
                  <div>
                    {record?.registerDateType}
                    {record?.registerNumber}
                  </div>
                );
              }}
              width={70}
            />

            <VSTableColumn
              dataIndex={['patient', 'name']}
              title="姓名"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['patient', 'gender']}
              title="性别"
              editable={false}
              valueType="text"
              render={(dom, record: any) => {
                return <div>{record?.patient?.gender ? '男' : '女'}</div>;
              }}
              width={50}
            />

            <VSTableColumn
              dataIndex={['patient', 'age']}
              title="年龄"
              editable={false}
              valueType="text"
              width={50}
            />

            <VSTableColumn
              dataIndex={['patient', 'id']}
              title="患者ID"
              editable={false}
              valueType="text"
              render={(dom, record: any) => {
                return (
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {record?.patient?.id}
                  </div>
                );
              }}
            />
          </VSTable>
        </div>
        <div className="footer">
          <Button>退诊</Button>
          <Button>诊结</Button>
          <Button type="primary" icon={<Icon type="icon-Frame-51" />}>
            刷新
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="cpoe-patient-list">
      <Tabs className="aspirin-tab">
        <TabPane tab="我的患者" key="mine">
          {renderChild()}
        </TabPane>
        <TabPane tab="本科患者" key="department">
          {renderChild()}
        </TabPane>
        <TabPane tab="我的收藏" key="collect">
          {renderChild()}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
