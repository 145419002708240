import { ICheckboxOption } from '../../interface/Checkbox';

export const defaultCheckboxOption: Readonly<Required<ICheckboxOption>> = {
  width: 14,
  height: 14,
  gap: 5,
  lineWidth: 1,
  fillStyle: '#5175f4',
  fontStyle: '#ffffff',
};
