export enum EditorComponent {
  COMPONENT = 'component',
  MENU = 'menu',
  MAIN = 'main',
  FOOTER = 'footer',
  CONTEXTMENU = 'contextmenu',
  POPUP = 'popup',
}

export enum EditorContext {
  PAGE = 'page',
  TABLE = 'table',
}

export enum EditorMode {
  EDIT = 'edit',
  TEMPLATE = 'template',
  READONLY = 'readonly',
}

export enum PageMode {
  PAGING = 'paging',
  CONTINUITY = 'continuity',
}

export enum PaperDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
