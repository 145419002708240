import { IValueSet } from '../../editor/interface/Control';
import { IDialogData } from './Dialog';

const addValueSet = (
  valueSetsDiv: HTMLDivElement,
  valueSet: Partial<IValueSet>,
) => {
  const valueSetDiv = document.createElement('div');
  valueSetDiv.classList.add('value-set');

  const addDiv = valueSetsDiv.getElementsByClassName('add-container')[0];
  valueSetsDiv.insertBefore(valueSetDiv, addDiv);

  const codeInput = document.createElement('input');
  codeInput.name = 'code';
  codeInput.value = valueSet.code || '';
  codeInput.placeholder = '枚举值';
  valueSetDiv.append(codeInput);

  const valueInput = document.createElement('input');
  valueInput.name = 'value';
  valueInput.value = valueSet.value || '';
  valueInput.placeholder = '文本';
  valueSetDiv.append(valueInput);

  const removeDiv = document.createElement('span');
  removeDiv.classList.add('remove');
  removeDiv.onclick = () => {
    valueSetDiv.remove();
  };
  valueSetDiv.append(removeDiv);
};

export const createValueSetInput = (option: IDialogData) => {
  // 数据
  const valueSets: IValueSet[] = JSON.parse(option.value || '[]');

  // 容器
  const valueSetsDiv = document.createElement('div');
  valueSetsDiv.classList.add('value-sets');
  valueSetsDiv.id = option.name;

  // 添加条目
  const addContainerDiv = document.createElement('div');
  addContainerDiv.classList.add('add-container');
  const addDiv = document.createElement('div');
  addDiv.classList.add('add');
  addDiv.onclick = () => {
    addValueSet(valueSetsDiv, {});
  };
  addContainerDiv.append(addDiv);
  valueSetsDiv.append(addContainerDiv);

  // 条目
  valueSets.forEach((valueSet) => {
    addValueSet(valueSetsDiv, valueSet);
  });

  return valueSetsDiv;
};

export const getValueSetValue = (valueSetsDiv: HTMLDivElement) => {
  const valueSets: any[] = [];
  const children = valueSetsDiv.children;
  for (let i = 0; i < children.length; i++) {
    const element = children[i];
    if (!element.classList.contains('value-set')) {
      continue;
    }
    const subChildren = element.children;
    const valueSet: Record<string, string> = {};
    for (let j = 0; j < subChildren.length; j++) {
      const input = subChildren[j];
      if (input instanceof HTMLInputElement) {
        valueSet[input.name] = input.value;
      }
    }
    if (Object.keys(valueSet).every((key) => !!valueSet[key])) {
      valueSets.push(valueSet);
    }
  }
  return {
    name: valueSetsDiv.id,
    value: JSON.stringify(valueSets),
  };
};
