import { ImageSource } from '../../editor/dataset/enum/Control';
import { IImageData, ISize } from '../../editor/interface/Control';
import { Canvas, ICanvasOptionResult } from '../Canvas/Canvas';
import { IDialogData } from './Dialog';

type CanvasElement = HTMLDivElement & {
  getImageData: () => ICanvasOptionResult | null;
};

type ImageElement = HTMLImageElement & {
  imageSize: ISize;
};

const setImageSource = (imageDiv: HTMLDivElement, source: ImageSource) => {
  const dataDiv = imageDiv.querySelector<HTMLDivElement>('.data');
  const sources = [ImageSource.FILE, ImageSource.HANDWRITE];
  sources.forEach((s) => {
    const div = dataDiv?.querySelector<HTMLDivElement>(`.${s}`);
    if (div) {
      div.style.display = source === s ? 'flex' : 'none';
    }
  });
};

const getImageSource = (imageDiv: HTMLDivElement) => {
  const sourceDiv = imageDiv.querySelector('.source');
  if (sourceDiv) {
    const options = sourceDiv.children;
    for (let j = 0; j < options.length; j++) {
      const option = options[j];
      const radio = option.children[0] as HTMLInputElement;
      if (radio.checked) {
        return radio.value as ImageSource;
      }
    }
  }
  return ImageSource.FILE;
};

const setImage = (
  imageDiv: HTMLDivElement,
  data?: string,
  size: ISize = { width: 0, height: 0 },
) => {
  const source = getImageSource(imageDiv);
  const dataDiv = imageDiv.querySelector<HTMLDivElement>('.data');
  if (source === ImageSource.FILE) {
    const fileDiv = dataDiv?.querySelector(`.${ImageSource.FILE}`);
    const preview = fileDiv?.querySelector<ImageElement>('.preview');
    if (preview) {
      let drawSize = size;
      if (drawSize.width > 0 && drawSize.height > 0) {
        const maxSize = [280, 200];
        const wRatio = maxSize[0] / drawSize.width;
        const hRatio = maxSize[1] / drawSize.height;
        const ratio = Math.min(wRatio, hRatio);
        if (ratio < 1) {
          drawSize = {
            width: drawSize.width * ratio,
            height: drawSize.height * ratio,
          };
        }
        preview.style.display = '';
      } else {
        preview.style.display = 'none';
      }
      preview.src = data ?? '';
      preview.style.width = `${drawSize.width}px`;
      preview.style.height = `${drawSize.height}px`;
      preview.imageSize = size;
    }
  }
};

const createImageFileInput = (imageDiv: HTMLDivElement) => {
  // 容器
  const fileDiv = document.createElement('div');
  fileDiv.classList.add(ImageSource.FILE);

  // 文件选择（隐藏）
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.png, .jpg, .jpeg';
  fileInput.onchange = function () {
    const file = fileInput.files![0]!;
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      const image = new Image();
      const value = fileReader.result as string;
      image.src = value;
      image.onload = function () {
        setImage(imageDiv, value, { width: image.width, height: image.height });
      };
    };
  };
  fileInput.style.display = 'none';
  fileDiv.append(fileInput);

  // 触发按钮
  const button = document.createElement('button');
  button.classList.add('file');
  button.innerText = '请选择图片';
  button.onclick = () => {
    fileInput.click();
  };
  fileDiv.append(button);

  // 预览
  const imageDom = document.createElement('img');
  imageDom.classList.add('preview');
  fileDiv.append(imageDom);

  return fileDiv;
};

export const createImageInput = (option: IDialogData) => {
  const image: IImageData = option.value ? JSON.parse(option.value) : {};
  if (!image.source) {
    image.source = ImageSource.FILE;
  }
  // 容器
  const imageDiv = document.createElement('div');
  imageDiv.classList.add('image');
  imageDiv.id = option.name;

  // source选项
  const sourceDiv = document.createElement('div');
  sourceDiv.classList.add('source');
  const sources = [
    { value: ImageSource.FILE, label: '文件' },
    { value: ImageSource.HANDWRITE, label: '手写' },
  ];
  sources.forEach((source, index) => {
    const optionDiv = document.createElement('div');
    optionDiv.classList.add('option');
    const radio = document.createElement('input');
    radio.type = 'radio';
    radio.id = `source${index}`;
    radio.value = source.value;
    radio.name = 'source';
    radio.checked = image.source === source.value;
    radio.onchange = (e) => {
      const dom = e.target as HTMLInputElement | null;
      if (dom) {
        setImageSource(imageDiv, dom.value as ImageSource);
      }
    };
    optionDiv.append(radio);

    const label = document.createElement('label');
    label.htmlFor = radio.id;
    label.innerText = source.label;
    optionDiv.append(label);
    sourceDiv.append(optionDiv);
  });
  imageDiv.append(sourceDiv);

  // 图片选择
  const dataDiv = document.createElement('div');
  dataDiv.classList.add('data');
  const fileDiv = createImageFileInput(imageDiv);
  dataDiv.appendChild(fileDiv);

  const canvas = new Canvas({ width: 280, height: 200 });
  const handwriteDiv: CanvasElement = canvas.dom as CanvasElement;
  handwriteDiv.classList.add(ImageSource.HANDWRITE);
  handwriteDiv.getImageData = () => {
    return canvas.toData();
  };
  dataDiv.appendChild(handwriteDiv);

  imageDiv.append(dataDiv);

  setImageSource(imageDiv, image.source);
  setImage(imageDiv, image.data, image.size);
  return imageDiv;
};

export const getImageValue = (imageDiv: HTMLDivElement) => {
  const source = getImageSource(imageDiv);
  const dataDiv = imageDiv.querySelector<HTMLDivElement>('.data');
  let data = '';
  let size: ISize | undefined = undefined;
  if (source === ImageSource.FILE) {
    const fileDiv = dataDiv?.querySelector(`.${ImageSource.FILE}`);
    const preview = fileDiv?.querySelector<ImageElement>('.preview');
    data = preview?.src || '';
    size = preview?.imageSize;
  } else if (source === ImageSource.HANDWRITE) {
    const handwriteDiv = dataDiv?.querySelector<CanvasElement>(
      `.${ImageSource.HANDWRITE}`,
    );
    const result = handwriteDiv?.getImageData();
    data = result?.value || '';
    size =
      result?.width && result?.height
        ? { width: result.width, height: result.height }
        : undefined;
  }
  let value: IImageData | undefined = undefined;
  if (size && size.width && size.height) {
    value = {
      data,
      source,
      size,
    };
  }
  return {
    name: imageDiv.id,
    value: value ? JSON.stringify(value) : '',
  };
};
