import './index.less';

import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Popover,
  Row,
  Space,
  Steps,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { divide, set } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
const { RangePicker } = DatePicker;

const Index = (props) => {
  const { data } = props;
  const chartTime = data?.createdAt;
  const chartTitle = data?.reportItem?.assayItemName;
  const min = data?.reportItem?.limitStart;
  const max = data?.reportItem?.limitEnd;
  const info = JSON.parse(data?.reportItem?.cdss || '[]');

  const info1 = info?.filter(
    (item) => item?.title !== '临床意义' && item?.title !== '治疗建议',
  );
  const info2 = info?.filter(
    (item) => item?.title === '临床意义' || item?.title === '治疗建议',
  );

  const chartData = Array.from({ length: 4 })?.map((item, index) => {
    if (index === 0) {
      return {
        value: min - 1,
        satisfyCondition: false,
      };
    } else if (index === 3) {
      return {
        value: data?.reportItem?.result,
        satisfyCondition: false,
      };
    } else
      return {
        value: Math.floor(Math.random() * (max - min + 1)) + min,
        satisfyCondition: true,
      };
  });
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const target = document.getElementById('echarts-demo');
    if (target) {
      if (!chartRef.current) {
        chartRef.current = echarts.init(target);
      }
      const option = {
        xAxis: {
          type: 'category',
          axisLine: {
            show: false, // 隐藏 x 轴线
          },
          // show: false,
          axisLabel: {
            show: true, // 显示 x 轴刻度标签
          },
          splitLine: {
            // type: 'dashed',
            show: false, // 隐藏 x 轴分隔线
          },
          axisTick: {
            show: false, // 隐藏底部的刻度线
          },
          data: [
            dayjs(chartTime).subtract(21, 'day').format('YYYY-MM-DD'),
            dayjs(chartTime).subtract(14, 'day').format('YYYY-MM-DD'),
            dayjs(chartTime).subtract(7, 'day').format('YYYY-MM-DD'),
            dayjs(chartTime).format('YYYY-MM-DD'),
          ],
          // itemStyle: {
          //   color: function (params) {
          //     console.log('params', params);
          //     // 根据 satisfyCondition 属性的值来设置颜色
          //     if (params.data.satisfyCondition) {
          //       return 'blue'; // 满足条件的点为蓝色
          //     } else {
          //       return 'red'; // 不满足条件的点为红色
          //     }
          //   },
          // },
        },
        yAxis: {
          type: 'value',
          // axisLine: { onZero: false },
          axisLine: {
            show: true,
            lineStyle: {
              // color: '#5D6268',
              width: 1,
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        tooltip: {
          trigger: 'axis', // 使用 axis 触发器
          axisPointer: {
            type: 'line', // 设置为 'line' 类型
          },
        },
        grid: {
          bottom: 40, // 调整底部留白，确保 x 轴标签和刻度线完整显示
        },
        series: [
          {
            data: chartData,
            type: 'line',
          },
        ],
      };
      chartRef.current && chartRef.current.setOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: '24px', paddingTop: '12px', width: 850 }}>
      <div className="box">检验结果趋势图({chartTitle})</div>
      <div style={{ marginTop: '24px' }}>
        <Form layout={'inline'}>
          <Form.Item label="检验日期">
            <RangePicker
              style={{ width: '236px' }}
              placeholder={['开始时间', '结束时间']}
            />
          </Form.Item>
          <Form.Item>
            <Space size={4}>
              <Button type="primary">查询</Button>
              <Button>三个月</Button>
              <Button>半年</Button>
              <Button>一年</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div className="echars" id="echarts-demo"></div>
      <div className="box">指标分析</div>
      {info1?.map((item, index) => (
        <div className="text-item" key={index}>
          <div className="text-item-title">{item?.title}</div>
          <div className="text-item-value">{item?.content}</div>
        </div>
      ))}
      {info2?.map((item, index) => (
        <div className="text-item-2" key={index}>
          <div className="text-item-title-2">{item?.title}</div>
          <div className="text-item-value-2">{item?.content}</div>
        </div>
      ))}
      {/* <div className="result">结果分析</div>
      <div className="ltfv">低于正常值:</div>
      <div className="ltfv">(1)病理性减少:</div>
      <div className="choose">
        <ul>
          <li>A.骨髓造血功能衰竭，如再生障碍性贫血、</li>
          <li>
            B.因造血物质缺乏或利用障碍所致的贫血，如缺铁性贫血、叶酸及维生素B12
            缺乏所致的巨幼细胞性贫血;
          </li>
          <li>
            C.因红细胞膜、酶溃传性的缺陷或外来因素所致红细胞破坏过多而导致的贫血，如遗传性球形红细胞增多症、海洋性贫血、阵发性睡眠性血红蛋白尿、异常血红蛋白病、免疫性溶血性贫血、心脏体外循环的大手术或某些生物性和化学性等因素所致的溶血性贫血以及某些急性或慢性失血所致的贫血。
          </li>
        </ul>
      </div>
      <div className="suggestion">治疗建议</div>
      <div className="suggestion-text">
        重度贫血。如无输血禁忌，应予输血治疗。
      </div> */}
    </div>
  );
};

export default Index;
