import './index.less';

import {
  Checkbox,
  DateRangePicker,
  Radio,
  RadioGroup,
  Select,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const Index = (props) => {
  const { value, dataSource, onChange } = props;

  const [filterValue, setFilterValue] = useState(1);
  const [checkValue, setcheckValue] = useState(['finished']);

  const change = (e) => {
    setFilterValue(e?.target?.value);
  };

  return (
    <div className="checked_list">
      <div className="checked_list_content hideScroll">
        {dataSource?.map((item, index) => {
          return (
            <div
              key={index}
              className={classnames(
                'checked_list_content_item',
                item?.id === value ? 'checked_list_content_item_active' : '',
              )}
              onClick={() => {
                onChange?.(item);
              }}
            >
              <div className="checked_list_content_item_time dinpro-medium">
                <span>
                  {dayjs(item?.collectionTime).format('YYYY-MM-DD HH:ss')}
                </span>
                <span className="checked_list_content_item_time_line"></span>
                <span>
                  {dayjs(item?.reportTime).format('YYYY-MM-DD HH:ss')}
                </span>
              </div>
              <div className="checked_list_content_item_classify_name">
                {item?.applyItemName}
              </div>
              <div className="checked_list_content_item_doctor_name">
                <div>
                  <span className="checked_list_content_item_doctor_name_label">
                    申请医师：
                  </span>
                  <span>{item?.orderDoctor?.name}</span>
                </div>
                <div className="checked_list_content_item_doctor_name_text">
                  确认报告
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
