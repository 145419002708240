import { createServices } from '@vs/vsf-boot';
export default createServices({
  AllergyDictionaryController_getAllByAllergyDictionaryQto_6bf106: {
    method: 'post',
    url: '/api/base-common/get-all-by-allergy-dictionary-qto',
    parameterFomatter: (data?: {
      qto: AllergyDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AllergyDictionaryDtoBaseCommonManagerDto[],
    ) => data,
  },
  DiagnosisDictionaryController_getAllByDiagnosisDictionaryQto_31d06d: {
    method: 'post',
    url: '/api/base-common/get-all-by-diagnosis-dictionary-qto',
    parameterFomatter: (data?: {
      qto: DiagnosisDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosisDictionaryDtoBaseCommonManagerDto[],
    ) => data,
  },
});
