import { Drawer } from '@vs/vsf-kit';
import React, { useImperativeHandle, useState } from 'react';

import Content from './content';

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    console.log('v', v);
    if (v) {
      setCurrent(v);
    }
    setOpen(true);
  };

  const handleSubmit = async (values) => {
    const { id, ...rest } = values;
    const formatValues = [
      {
        id: id,
        orderItemDtoList: {
          orderItemStatus: 'OPEN',
          orderTypeId: id,
          orderType: 'OPERATION',
          enduring: true,
          orderName: {
            medicationName: rest?.operation,
            id: id,
          },

          orderApplyBto: {
            ...rest,
          },
        },
      },
    ];
    onSubmit?.(formatValues);
    onClose();
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer"
      title="手术医嘱"
      placement="right"
      onClose={onClose}
      open={open}
      width={1360}
      destroyOnClose={true}
    >
      <Content data={current} onSubmit={handleSubmit} onClose={onClose} />
    </Drawer>
  );
};

export default React.forwardRef(Index);
