import vsf from '@vs/vsf-boot';
import { Button, Dropdown, Input, Popover } from '@vs/vsf-kit';
import React, { useState } from 'react';

import Icon from '@/module/Icon';

import Content from './content';

const Index = (props) => {
  const { onChange } = props;
  const { user } = vsf?.stores || {};
  const { patientList } = user || {};

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = async (v) => {
    onChange?.(v);
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      menu={{
        items: [],
      }}
      onOpenChange={(v) => {
        setDropdownOpen(v);
      }}
      dropdownRender={(menu) => (
        <Content value={props?.value} onChange={handleChange} />
      )}
      open={dropdownOpen}
    >
      <div className="medical-dropdown">
        <span>患者信息</span>
        {dropdownOpen ? <Icon type="icon-xia" /> : <Icon type="icon-shang" />}
      </div>
    </Dropdown>
  );
};

export default Index;
