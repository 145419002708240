import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Switch } from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import PeopleImage from '@/assets/people.png';
import Block from '@/module/Block';
import Empty from '@/module/empty';
import Visible from '@/module/Visible';

import Timeline from '../../components/Timeline';
import Item from './item';

enum OrderTypeEnum {
  // icon-Frame-9
  MEDICATION = 'MEDICATION',
  // icon-Frame-6
  HANDLE = 'HANDLE',
  HERBS = 'HERBS',
  // icon-Frame-3
  EXAMINE = 'EXAMINE',
  // icon-huayan
  ASSAY = 'ASSAY',
  OPERATION = 'OPERATION',
  PATHOLOGY = 'PATHOLOGY',
}
const timelineType = [
  {
    label: '全部',
    value: '全部',
  },
  {
    label: '药疗',
    value: OrderTypeEnum.MEDICATION,
  },
  {
    label: '检查',
    value: OrderTypeEnum.EXAMINE,
  },
  {
    label: '检验',
    value: OrderTypeEnum.ASSAY,
  },
  {
    label: '病理',
    value: OrderTypeEnum.HERBS,
  },
  {
    label: '手术',
    value: OrderTypeEnum.OPERATION,
  },
];

const Index = (props) => {
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};

  const [currentType, setcurrentType] = useState('全部');
  const [list, setList] = useState<any[]>([]);
  const [switchValue, setSwitchValue] = useState<boolean>(false);

  const getList = useCallback(async () => {
    if (!currentPatient?.patient?.id) {
      setList([]);
    } else {
      const res =
        await vsf?.services?.OrderItemController_allOrderItem_f38b92?.({
          qto: {
            orderTypeIs: currentType === '全部' ? undefined : currentType,
            patientIdIs: currentPatient?.patient?.id,
          },
        });
      if (res.code === 200) {
        setList(res?.data);
      }
    }
  }, [currentPatient, currentType]);

  useEffect(() => {
    getList();
  }, [getList, initKey]);

  return (
    <div className="timeline">
      <Block
        title={!switchValue ? '患者旅程' : '患者画像'}
        extra={
          <div className="toggle">
            <span className="label">
              {!switchValue ? '切换画像' : '切换旅程'}
            </span>
            <Switch
              size="small"
              value={switchValue}
              onChange={(v) => {
                setSwitchValue(v);
              }}
            />
          </div>
        }
        mainStyle={{
          paddingRight: `var(--cpoe-padding)`,
        }}
      >
        <Visible value={!switchValue}>
          <div className="timeline-type">
            {timelineType?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classnames('item', {
                    'active-item': currentType === item?.value,
                  })}
                  onClick={() => {
                    setcurrentType(item?.value);
                  }}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          {!list || list?.length === 0 ? (
            <Empty />
          ) : (
            <Timeline index={0}>
              {list?.map((item, index) => {
                return (
                  <Item currentType={currentType} data={item} key={index} />
                );
              })}
            </Timeline>
          )}
        </Visible>
        <Visible value={switchValue}>
          <div className="people-image-container">
            <div
              className="people-image"
              style={{
                backgroundImage: `url(${PeopleImage})`,
              }}
            ></div>
          </div>
        </Visible>
      </Block>
    </div>
  );
};

export default definePage(Index);
