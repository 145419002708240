import './style.css';

import { Dialog } from './components/dialog/Dialog';
import { Signature } from './components/signature/Signature';
import Editor, {
  BlockType,
  Command,
  ControlType,
  EditorMode,
  ElementType,
  IBlock,
  IEditorOption,
  IElement,
  KeyMap,
  PageMode,
  PaperDirection,
} from './editor';
import { html } from './html';
import { getControlValue, openControlDialog } from './utils/control';
import { toast } from './utils/toast';

export * from './editor';

let instance: Editor;
export function addEditor({ root, elements, options, onSave, extra }) {
  instance?.dispose();
  // 1. 初始化编辑器
  const div = document.createElement('div');
  div.innerHTML = html.trim();
  const app = div.firstChild as HTMLElement;
  root.appendChild(app);
  if (!app) {
    return;
  }
  const container = app.querySelector<HTMLDivElement>('.editor')!;
  instance = new Editor(app, container, elements, options);
  // @ts-ignore
  instance.command.showCdss = () => {
    // @ts-ignore
    const v = instance.command.getFormData();
    extra?.showCdss?.(v);
  };

  // @ts-ignore
  instance.command.showKnowledge = () => {
    // @ts-ignore
    extra?.showKnowledge?.();
  };

  // 2. | 撤销 | 重做 | 格式刷 | 清除格式 |
  const undoDom = app.querySelector<HTMLDivElement>('.menu-item__undo')!;
  undoDom.onclick = function () {
    instance.command.executeUndo();
  };

  const redoDom = app.querySelector<HTMLDivElement>('.menu-item__redo')!;
  redoDom.onclick = function () {
    instance.command.executeRedo();
  };

  const painterDom = app.querySelector<HTMLDivElement>('.menu-item__painter')!;
  painterDom.onclick = function () {
    instance.command.executePainter({
      isDblclick: false,
    });
  };
  painterDom.ondblclick = function () {
    instance.command.executePainter({
      isDblclick: true,
    });
  };

  app.querySelector<HTMLDivElement>('.menu-item__format')!.onclick =
    function () {
      instance.command.executeFormat();
    };

  // 3. | 字体 | 字体变大 | 字体变小 | 加粗 | 斜体 | 下划线 | 删除线 | 上标 | 下标 | 字体颜色 | 背景色 |
  const fontDom = app.querySelector<HTMLDivElement>('.menu-item__font')!;
  const fontSelectDom = fontDom.querySelector<HTMLDivElement>('.select')!;
  const fontOptionDom = fontDom.querySelector<HTMLDivElement>('.options')!;
  fontDom.onclick = function () {
    fontOptionDom.classList.toggle('visible');
  };
  fontOptionDom.onclick = function (evt) {
    const li = evt.target as HTMLLIElement;
    instance.command.executeFont(li.dataset.family!);
  };

  app.querySelector<HTMLDivElement>('.menu-item__size-add')!.onclick =
    function () {
      instance.command.executeSizeAdd();
    };

  app.querySelector<HTMLDivElement>('.menu-item__size-minus')!.onclick =
    function () {
      instance.command.executeSizeMinus();
    };

  const boldDom = app.querySelector<HTMLDivElement>('.menu-item__bold')!;
  boldDom.onclick = function () {
    instance.command.executeBold();
  };

  const italicDom = app.querySelector<HTMLDivElement>('.menu-item__italic')!;
  italicDom.onclick = function () {
    instance.command.executeItalic();
  };

  const underlineDom = app.querySelector<HTMLDivElement>(
    '.menu-item__underline',
  )!;
  underlineDom.onclick = function () {
    instance.command.executeUnderline();
  };

  const strikeoutDom = app.querySelector<HTMLDivElement>(
    '.menu-item__strikeout',
  )!;
  strikeoutDom.onclick = function () {
    instance.command.executeStrikeout();
  };

  const superscriptDom = app.querySelector<HTMLDivElement>(
    '.menu-item__superscript',
  )!;
  superscriptDom.onclick = function () {
    instance.command.executeSuperscript();
  };

  const subscriptDom = app.querySelector<HTMLDivElement>(
    '.menu-item__subscript',
  )!;
  subscriptDom.onclick = function () {
    instance.command.executeSubscript();
  };

  const colorControlDom = app.querySelector<HTMLInputElement>('#color')!;
  colorControlDom.oninput = function () {
    instance.command.executeColor(colorControlDom.value);
  };
  const colorDom = app.querySelector<HTMLDivElement>('.menu-item__color')!;
  const colorSpanDom = colorDom.querySelector('span')!;
  colorDom.onclick = function () {
    colorControlDom.click();
  };

  const highlightControlDom =
    app.querySelector<HTMLInputElement>('#highlight')!;
  highlightControlDom.oninput = function () {
    instance.command.executeHighlight(highlightControlDom.value);
  };
  const highlightDom = app.querySelector<HTMLDivElement>(
    '.menu-item__highlight',
  )!;
  const highlightSpanDom = highlightDom.querySelector('span')!;
  highlightDom.onclick = function () {
    highlightControlDom?.click();
  };

  const leftDom = app.querySelector<HTMLDivElement>('.menu-item__left')!;
  leftDom.onclick = function () {
    instance.command.executeLeft();
  };

  const centerDom = app.querySelector<HTMLDivElement>('.menu-item__center')!;
  centerDom.onclick = function () {
    instance.command.executeCenter();
  };

  const rightDom = app.querySelector<HTMLDivElement>('.menu-item__right')!;
  rightDom.onclick = function () {
    instance.command.executeRight();
  };

  const alignmentDom = app.querySelector<HTMLDivElement>(
    '.menu-item__alignment',
  )!;
  alignmentDom.onclick = function () {
    instance.command.executeAlignment();
  };

  const rowMarginDom = app.querySelector<HTMLDivElement>(
    '.menu-item__row-margin',
  )!;
  const rowOptionDom = rowMarginDom.querySelector<HTMLDivElement>('.options')!;
  rowMarginDom.onclick = function () {
    rowOptionDom.classList.toggle('visible');
  };
  rowOptionDom.onclick = function (evt) {
    const li = evt.target as HTMLLIElement;
    instance.command.executeRowMargin(Number(li.dataset.rowmargin!));
  };

  // 4. | 表格 | 图片 | 超链接 | 分割线 | 水印 | 代码块 | 分隔符 | 控件 | 复选框 | LaTeX | 日期选择器
  const tableDom = app.querySelector<HTMLDivElement>('.menu-item__table')!;
  const tablePanelContainer = app.querySelector<HTMLDivElement>(
    '.menu-item__table__collapse',
  )!;
  const tableClose = app.querySelector<HTMLDivElement>('.table-close')!;
  const tableTitle = app.querySelector<HTMLDivElement>('.table-select')!;
  const tablePanel = app.querySelector<HTMLDivElement>('.table-panel')!;
  // 绘制行列
  const tableCellList: HTMLDivElement[][] = [];
  for (let i = 0; i < 10; i++) {
    const tr = document.createElement('tr');
    tr.classList.add('table-row');
    const trCellList: HTMLDivElement[] = [];
    for (let j = 0; j < 10; j++) {
      const td = document.createElement('td');
      td.classList.add('table-cel');
      tr.append(td);
      trCellList.push(td);
    }
    tablePanel.append(tr);
    tableCellList.push(trCellList);
  }
  let colIndex = 0;
  let rowIndex = 0;
  // 移除所有格选择
  function removeAllTableCellSelect() {
    tableCellList.forEach((tr) => {
      tr.forEach((td) => td.classList.remove('active'));
    });
  }
  // 设置标题内容
  function setTableTitle(payload: string) {
    tableTitle.innerText = payload;
  }
  // 恢复初始状态
  function recoveryTable() {
    // 还原选择样式、标题、选择行列
    removeAllTableCellSelect();
    setTableTitle('插入');
    colIndex = 0;
    rowIndex = 0;
    // 隐藏panel
    tablePanelContainer.style.display = 'none';
  }
  tableDom.onclick = function () {
    tablePanelContainer!.style.display = 'block';
  };
  tablePanel.onmousemove = function (evt) {
    const celSize = 16;
    const rowMarginTop = 10;
    const celMarginRight = 6;
    const { offsetX, offsetY } = evt;
    // 移除所有选择
    removeAllTableCellSelect();
    colIndex = Math.ceil(offsetX / (celSize + celMarginRight)) || 1;
    rowIndex = Math.ceil(offsetY / (celSize + rowMarginTop)) || 1;
    // 改变选择样式
    tableCellList.forEach((tr, trIndex) => {
      tr.forEach((td, tdIndex) => {
        if (tdIndex < colIndex && trIndex < rowIndex) {
          td.classList.add('active');
        }
      });
    });
    // 改变表格标题
    setTableTitle(`${rowIndex}×${colIndex}`);
  };
  tableClose.onclick = function () {
    recoveryTable();
  };
  tablePanel.onclick = function () {
    // 应用选择
    instance.command.executeInsertTable(rowIndex, colIndex);
    recoveryTable();
  };

  const imageDom = app.querySelector<HTMLDivElement>('.menu-item__image')!;
  const imageFileDom = app.querySelector<HTMLInputElement>('#image')!;
  imageDom.onclick = function () {
    imageFileDom.click();
  };
  imageFileDom.onchange = function () {
    const file = imageFileDom.files![0]!;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      // 计算宽高
      const image = new Image();
      const value = fileReader.result as string;
      image.src = value;
      image.onload = function () {
        instance.command.executeImage({
          value,
          width: image.width,
          height: image.height,
        });
        imageFileDom.value = '';
      };
    };
  };

  const signDom = app.querySelector<HTMLDivElement>('.menu-item__signature')!;
  signDom.onclick = function () {
    new Signature(
      {
        onConfirm(payload) {
          if (!payload) return;
          const { value, width, height } = payload;
          if (!value || !width || !height) return;
          instance.command.executeInsertElementList([
            {
              value,
              width,
              height,
              type: ElementType.IMAGE,
            },
          ]);
        },
      },
      app,
    );
  };

  const hyperlinkDom = app.querySelector<HTMLDivElement>(
    '.menu-item__hyperlink',
  )!;
  hyperlinkDom.onclick = function () {
    new Dialog(
      {
        title: '超链接',
        data: [
          {
            type: 'text',
            label: '文本',
            name: 'name',
            required: true,
            placeholder: '请输入文本',
          },
          {
            type: 'text',
            label: '链接',
            name: 'url',
            required: true,
            placeholder: '请输入链接',
          },
          {
            type: 'text',
            label: '回调',
            name: 'callback',
            required: false,
            placeholder: '请输入回调',
          },
        ],
        onConfirm: (payload) => {
          const name = payload.find((p) => p.name === 'name')?.value;
          if (!name) return;
          const url = payload.find((p) => p.name === 'url')?.value;
          const callback = payload.find((p) => p.name === 'callback')?.value;
          if (!url || !callback) return;
          instance.command.executeHyperlink({
            type: ElementType.HYPERLINK,
            value: '',
            url,
            callback,
            valueList: name.split('').map((n) => ({
              value: n,
              size: 16,
            })),
          });
        },
      },
      app,
    );
  };

  const separatorDom = app.querySelector<HTMLDivElement>(
    '.menu-item__separator',
  )!;
  const separatorOptionDom =
    separatorDom.querySelector<HTMLDivElement>('.options')!;
  separatorDom.onclick = function () {
    separatorOptionDom.classList.toggle('visible');
  };
  separatorOptionDom.onmousedown = function (evt) {
    let payload: number[] = [];
    const li = evt.target as HTMLLIElement;
    const separatorDash = li.dataset.separator?.split(',').map(Number);
    if (separatorDash) {
      const isSingleLine = separatorDash.every((d) => d === 0);
      if (!isSingleLine) {
        payload = separatorDash;
      }
    }
    instance.command.executeSeparator(payload);
  };

  const pageBreakDom = app.querySelector<HTMLDivElement>(
    '.menu-item__page-break',
  )!;
  pageBreakDom.onclick = function () {
    instance.command.executePageBreak();
  };

  const watermarkDom = app.querySelector<HTMLDivElement>(
    '.menu-item__watermark',
  )!;
  const watermarkOptionDom =
    watermarkDom.querySelector<HTMLDivElement>('.options')!;
  watermarkDom.onclick = function () {
    watermarkOptionDom.classList.toggle('visible');
  };
  watermarkOptionDom.onmousedown = function (evt) {
    const li = evt.target as HTMLLIElement;
    const menu = li.dataset.menu!;
    watermarkOptionDom.classList.toggle('visible');
    if (menu === 'add') {
      new Dialog(
        {
          title: '水印',
          data: [
            {
              type: 'text',
              label: '内容',
              name: 'data',
              required: true,
              placeholder: '请输入内容',
            },
            {
              type: 'color',
              label: '颜色',
              name: 'color',
              required: true,
              value: '#AEB5C0',
            },
            {
              type: 'number',
              label: '字体大小',
              name: 'size',
              required: true,
              value: '120',
            },
          ],
          onConfirm: (payload) => {
            const nullableIndex = payload.findIndex((p) => !p.value);
            if (~nullableIndex) return;
            const watermark = payload.reduce(
              (pre, cur) => {
                pre[cur.name] = cur.value;
                return pre;
              },
              <any>{},
            );
            instance.command.executeAddWatermark({
              data: watermark.data,
              color: watermark.color,
              size: Number(watermark.size),
            });
          },
        },
        app,
      );
    } else {
      instance.command.executeDeleteWatermark();
    }
  };

  const controlDom = app.querySelector<HTMLDivElement>('.menu-item__control')!;
  const controlOptionDom =
    controlDom.querySelector<HTMLDivElement>('.options')!;
  controlDom.onclick = function () {
    controlOptionDom.classList.toggle('visible');
  };
  controlOptionDom.onmousedown = function (evt) {
    controlOptionDom.classList.toggle('visible');
    const li = evt.target as HTMLLIElement;
    const type = <ControlType>li.dataset.control;
    openControlDialog(app, { type }, EditorMode.TEMPLATE, (control) => {
      instance.command.executeInsertElementList([
        {
          type: ElementType.CONTROL,
          value: '',
          control,
        },
      ]);
    });
  };

  const checkboxDom = app.querySelector<HTMLDivElement>(
    '.menu-item__checkbox',
  )!;
  checkboxDom.onclick = function () {
    instance.command.executeInsertElementList([
      {
        type: ElementType.CHECKBOX,
        value: '',
      },
    ]);
  };

  const latexDom = app.querySelector<HTMLDivElement>('.menu-item__latex')!;
  latexDom.onclick = function () {
    new Dialog(
      {
        title: 'LaTeX',
        data: [
          {
            type: 'textarea',
            height: 100,
            name: 'value',
            placeholder: '请输入LaTeX文本',
          },
        ],
        onConfirm: (payload) => {
          const value = payload.find((p) => p.name === 'value')?.value;
          if (!value) return;
          instance.command.executeInsertElementList([
            {
              type: ElementType.LATEX,
              value,
            },
          ]);
        },
      },
      app,
    );
  };

  const blockDom = document.querySelector<HTMLDivElement>('.menu-item__block')!;
  blockDom.onclick = function () {
    new Dialog(
      {
        title: '内容块',
        data: [
          {
            type: 'select',
            label: '类型',
            name: 'type',
            value: 'iframe',
            required: true,
            options: [
              {
                label: '网址',
                value: 'iframe',
              },
              {
                label: '视频',
                value: 'video',
              },
            ],
          },
          {
            type: 'number',
            label: '宽度',
            name: 'width',
            placeholder: '请输入宽度（默认页面内宽度）',
          },
          {
            type: 'number',
            label: '高度',
            name: 'height',
            required: true,
            placeholder: '请输入高度',
          },
          {
            type: 'textarea',
            label: '地址',
            height: 100,
            name: 'value',
            required: true,
            placeholder: '请输入地址',
          },
        ],
        onConfirm: (payload) => {
          const type = payload.find((p) => p.name === 'type')?.value;
          if (!type) return;
          const value = payload.find((p) => p.name === 'value')?.value;
          if (!value) return;
          const width = payload.find((p) => p.name === 'width')?.value;
          const height = payload.find((p) => p.name === 'height')?.value;
          if (!height) return;
          const block: IBlock = {
            type: <BlockType>type,
          };
          if (block.type === BlockType.IFRAME) {
            block.iframeBlock = {
              src: value,
            };
          } else if (block.type === BlockType.VIDEO) {
            block.videoBlock = {
              src: value,
            };
          }
          const blockElement: IElement = {
            type: ElementType.BLOCK,
            value: '',
            height: Number(height),
            block,
          };
          if (width) {
            blockElement.width = Number(width);
          }
          instance.command.executeInsertElementList([blockElement]);
        },
      },
      app,
    );
  };

  // 5. | 搜索&替换 | 打印 |
  const searchCollapseDom = app.querySelector<HTMLDivElement>(
    '.menu-item__search__collapse',
  )!;
  const searchInputDom = app.querySelector<HTMLInputElement>(
    '.menu-item__search__collapse__search input',
  )!;
  const replaceInputDom = app.querySelector<HTMLInputElement>(
    '.menu-item__search__collapse__replace input',
  )!;
  const searchDom = app.querySelector<HTMLDivElement>('.menu-item__search')!;
  const searchResultDom =
    searchCollapseDom.querySelector<HTMLLabelElement>('.search-result')!;
  function setSearchResult() {
    const result = instance.command.getSearchNavigateInfo();
    if (result) {
      const { index, count } = result;
      searchResultDom.innerText = `${index}/${count}`;
    } else {
      searchResultDom.innerText = '';
    }
  }
  searchDom.onclick = function () {
    searchCollapseDom.style.display = 'block';
    const bodyRect = app.getBoundingClientRect();
    const searchRect = searchDom.getBoundingClientRect();
    const searchCollapseRect = searchCollapseDom.getBoundingClientRect();
    if (searchRect.left + searchCollapseRect.width > bodyRect.width) {
      searchCollapseDom.style.right = '0px';
      searchCollapseDom.style.left = 'unset';
    } else {
      searchCollapseDom.style.right = 'unset';
    }
    searchInputDom.focus();
  };
  searchCollapseDom.querySelector<HTMLSpanElement>('span')!.onclick =
    function () {
      searchCollapseDom.style.display = 'none';
      searchInputDom.value = '';
      replaceInputDom.value = '';
      instance.command.executeSearch(null);
      setSearchResult();
    };
  searchInputDom.oninput = function () {
    instance.command.executeSearch(searchInputDom.value || null);
    setSearchResult();
  };
  searchInputDom.onkeydown = function (evt) {
    if (evt.key === 'Enter') {
      instance.command.executeSearch(searchInputDom.value || null);
      setSearchResult();
    }
  };
  searchCollapseDom.querySelector<HTMLButtonElement>('button')!.onclick =
    function () {
      const searchValue = searchInputDom.value;
      const replaceValue = replaceInputDom.value;
      if (searchValue && replaceValue && searchValue !== replaceValue) {
        instance.command.executeReplace(replaceValue);
      }
    };
  searchCollapseDom.querySelector<HTMLDivElement>('.arrow-left')!.onclick =
    function () {
      instance.command.executeSearchNavigatePre();
      setSearchResult();
    };
  searchCollapseDom.querySelector<HTMLDivElement>('.arrow-right')!.onclick =
    function () {
      instance.command.executeSearchNavigateNext();
      setSearchResult();
    };

  app.querySelector<HTMLDivElement>('.menu-item__print')!.onclick =
    function () {
      instance.command.executePrint();
    };

  const exportDom = app.querySelector<HTMLDivElement>('.menu-item__export')!;
  exportDom.onclick = function () {
    const value = instance.getValue();
    if (!value) return;
    const text = JSON.stringify(value);
    const plainText = new Blob([text], { type: 'text/plain' });
    // @ts-ignore
    const item = new ClipboardItem({
      [plainText.type]: plainText,
    });
    window.navigator.clipboard.write([item]).then(() => {
      toast(app, '已复制');
    });
  };

  const formdataDom = app.querySelector<HTMLDivElement>(
    '.menu-item__formdata',
  )!;
  formdataDom.onclick = function () {
    const controls = instance.command.getControls();
    const formData = controls.reduce<Record<string, string | undefined>>(
      (acc, control) => {
        acc[control.key] = getControlValue(control);
        return acc;
      },
      {},
    );
    alert(JSON.stringify(formData));
  };

  // 6. 页面模式 | 纸张缩放 | 纸张大小 | 纸张方向 | 页边距 | 全屏
  const pageModeDom = app.querySelector<HTMLDivElement>('.page-mode')!;
  const pageModeOptionsDom =
    pageModeDom.querySelector<HTMLDivElement>('.options')!;
  pageModeDom.onclick = function () {
    pageModeOptionsDom.classList.toggle('visible');
  };
  pageModeOptionsDom.onclick = function (evt) {
    const li = evt.target as HTMLLIElement;
    instance.command.executePageMode(<PageMode>li.dataset.pageMode!);
  };

  app.querySelector<HTMLDivElement>('.page-scale-percentage')!.onclick =
    function () {
      instance.command.executePageScaleRecovery();
    };

  app.querySelector<HTMLDivElement>('.page-scale-minus')!.onclick =
    function () {
      instance.command.executePageScaleMinus();
    };

  app.querySelector<HTMLDivElement>('.page-scale-add')!.onclick = function () {
    instance.command.executePageScaleAdd();
  };

  // 纸张大小
  const paperSizeDom = app.querySelector<HTMLDivElement>('.paper-size')!;
  const paperSizeDomOptionsDom =
    paperSizeDom.querySelector<HTMLDivElement>('.options')!;
  paperSizeDom.onclick = function () {
    paperSizeDomOptionsDom.classList.toggle('visible');
  };
  paperSizeDomOptionsDom.onclick = function (evt) {
    const li = evt.target as HTMLLIElement;
    const paperType = li.dataset.paperSize!;
    const [width, height] = paperType.split('*').map(Number);
    instance.command.executePaperSize(width, height);
    // 纸张状态回显
    paperSizeDomOptionsDom
      .querySelectorAll('li')
      .forEach((child) => child.classList.remove('active'));
    li.classList.add('active');
  };

  // 纸张方向
  const paperDirectionDom =
    document.querySelector<HTMLDivElement>('.paper-direction')!;
  const paperDirectionDomOptionsDom =
    paperDirectionDom.querySelector<HTMLDivElement>('.options')!;
  paperDirectionDom.onclick = function () {
    paperDirectionDomOptionsDom.classList.toggle('visible');
  };
  paperDirectionDomOptionsDom.onclick = function (evt) {
    const li = evt.target as HTMLLIElement;
    const paperDirection = li.dataset.paperDirection!;
    instance.command.executePaperDirection(<PaperDirection>paperDirection);
    // 纸张方向状态回显
    paperDirectionDomOptionsDom
      .querySelectorAll('li')
      .forEach((child) => child.classList.remove('active'));
    li.classList.add('active');
  };

  // 页面边距
  const paperMarginDom = app.querySelector<HTMLDivElement>('.paper-margin')!;
  paperMarginDom.onclick = function () {
    const [topMargin, rightMargin, bottomMargin, leftMargin] =
      instance.command.getPaperMargin();
    new Dialog(
      {
        title: '页边距',
        data: [
          {
            type: 'text',
            label: '上边距',
            name: 'top',
            required: true,
            value: `${topMargin}`,
            placeholder: '请输入上边距',
          },
          {
            type: 'text',
            label: '下边距',
            name: 'bottom',
            required: true,
            value: `${bottomMargin}`,
            placeholder: '请输入下边距',
          },
          {
            type: 'text',
            label: '左边距',
            name: 'left',
            required: true,
            value: `${leftMargin}`,
            placeholder: '请输入左边距',
          },
          {
            type: 'text',
            label: '右边距',
            name: 'right',
            required: true,
            value: `${rightMargin}`,
            placeholder: '请输入右边距',
          },
        ],
        onConfirm: (payload) => {
          const top = payload.find((p) => p.name === 'top')?.value;
          if (!top) return;
          const bottom = payload.find((p) => p.name === 'bottom')?.value;
          if (!bottom) return;
          const left = payload.find((p) => p.name === 'left')?.value;
          if (!left) return;
          const right = payload.find((p) => p.name === 'right')?.value;
          if (!right) return;
          instance.command.executeSetPaperMargin([
            Number(top),
            Number(right),
            Number(bottom),
            Number(left),
          ]);
        },
      },
      app,
    );
  };

  // 全屏
  const fullscreenDom = app.querySelector<HTMLDivElement>('.fullscreen')!;
  fullscreenDom.onclick = toggleFullscreen;
  app.addEventListener('keydown', (evt) => {
    if (evt.key === 'F11') {
      toggleFullscreen();
      evt.preventDefault();
    }
  });
  app.addEventListener('fullscreenchange', () => {
    fullscreenDom.classList.toggle('exist');
  });
  function toggleFullscreen() {
    if (!document.fullscreenElement) {
      root.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  // 7. 编辑器使用模式
  const setMode = (mode: EditorMode) => {
    // 设置菜单栏权限视觉反馈
    const isReadonly = mode === EditorMode.READONLY;
    const enableMenuList = ['search', 'print', 'formdata'];
    const isTemplate = mode === EditorMode.TEMPLATE;
    const templateDisableList = ['control', 'export'];
    app.querySelectorAll<HTMLDivElement>('.menu-item>div').forEach((dom) => {
      const menu = dom.dataset.menu;
      isReadonly && (!menu || !enableMenuList.includes(menu))
        ? dom.classList.add('disable')
        : dom.classList.remove('disable');
      if (!isTemplate && menu && templateDisableList.includes(menu)) {
        dom.classList.add('disable');
      }
    });
  };

  let modeIndex = 0;
  const modeList = [
    {
      mode: EditorMode.EDIT,
      name: '编辑模式',
    },
    {
      mode: EditorMode.TEMPLATE,
      name: '模板编辑',
    },
    {
      mode: EditorMode.READONLY,
      name: '只读模式',
    },
  ];
  setMode(instance.getMode());
  const modeElement = app.querySelector<HTMLDivElement>('.editor-mode')!;
  modeElement.onclick = function () {
    // 模式选择循环
    modeIndex === 2 ? (modeIndex = 0) : modeIndex++;
    // 设置模式
    const { name, mode } = modeList[modeIndex];
    modeElement.innerText = name;
    instance.command.executeMode(mode);
    setMode(mode);
  };

  // 8. 内部事件监听
  instance.listener.rangeStyleChange = function (payload) {
    if (payload?.type === 'hyperlink') {
      // @ts-ignore
      instance.command.showKnowledge();
    }
    if (payload?.type === 'image') {
      // @ts-ignore
      instance.command.showCdss();
    }
    // 控件类型
    payload.type === ElementType.SUBSCRIPT
      ? subscriptDom.classList.add('active')
      : subscriptDom.classList.remove('active');
    payload.type === ElementType.SUPERSCRIPT
      ? superscriptDom.classList.add('active')
      : superscriptDom.classList.remove('active');
    payload.type === ElementType.SEPARATOR
      ? separatorDom.classList.add('active')
      : separatorDom.classList.remove('active');
    separatorOptionDom
      .querySelectorAll('li')
      .forEach((li) => li.classList.remove('active'));
    if (payload.type === ElementType.SEPARATOR) {
      const separator = payload.dashArray.join(',') || '0,0';
      const curSeparatorDom = separatorOptionDom.querySelector<HTMLLIElement>(
        `[data-separator='${separator}']`,
      )!;
      if (curSeparatorDom) {
        curSeparatorDom.classList.add('active');
      }
    }

    // 富文本
    fontOptionDom
      .querySelectorAll<HTMLLIElement>('li')
      .forEach((li) => li.classList.remove('active'));
    const curFontDom = fontOptionDom.querySelector<HTMLLIElement>(
      `[data-family='${payload.font}']`,
    );
    if (curFontDom) {
      fontSelectDom.innerText = curFontDom.innerText;
      fontSelectDom.style.fontFamily = payload.font;
      curFontDom.classList.add('active');
    }
    payload.bold
      ? boldDom.classList.add('active')
      : boldDom.classList.remove('active');
    payload.italic
      ? italicDom.classList.add('active')
      : italicDom.classList.remove('active');
    payload.underline
      ? underlineDom.classList.add('active')
      : underlineDom.classList.remove('active');
    payload.strikeout
      ? strikeoutDom.classList.add('active')
      : strikeoutDom.classList.remove('active');
    if (payload.color) {
      colorDom.classList.add('active');
      colorControlDom.value = payload.color;
      colorSpanDom.style.backgroundColor = payload.color;
    } else {
      colorDom.classList.remove('active');
      colorControlDom.value = '#000000';
      colorSpanDom.style.backgroundColor = '#000000';
    }
    if (payload.highlight) {
      highlightDom.classList.add('active');
      highlightControlDom.value = payload.highlight;
      highlightSpanDom.style.backgroundColor = payload.highlight;
    } else {
      highlightDom.classList.remove('active');
      highlightControlDom.value = '#ffff00';
      highlightSpanDom.style.backgroundColor = '#ffff00';
    }

    // 行布局
    leftDom.classList.remove('active');
    centerDom.classList.remove('active');
    rightDom.classList.remove('active');
    alignmentDom.classList.remove('active');
    if (payload.rowFlex && payload.rowFlex === 'right') {
      rightDom.classList.add('active');
    } else if (payload.rowFlex && payload.rowFlex === 'center') {
      centerDom.classList.add('active');
    } else if (payload.rowFlex && payload.rowFlex === 'alignment') {
      alignmentDom.classList.add('active');
    } else {
      leftDom.classList.add('active');
    }

    // 行间距
    rowOptionDom
      .querySelectorAll<HTMLLIElement>('li')
      .forEach((li) => li.classList.remove('active'));
    const curRowMarginDom = rowOptionDom.querySelector<HTMLLIElement>(
      `[data-rowmargin='${payload.rowMargin}']`,
    )!;
    curRowMarginDom.classList.add('active');

    // 功能
    payload.undo
      ? undoDom.classList.remove('no-allow')
      : undoDom.classList.add('no-allow');
    payload.redo
      ? redoDom.classList.remove('no-allow')
      : redoDom.classList.add('no-allow');
    payload.painter
      ? painterDom.classList.add('active')
      : painterDom.classList.remove('active');
  };

  instance.listener.visiblePageNoListChange = function (payload) {
    const text = payload.map((i) => i + 1).join('、');
    app.querySelector<HTMLSpanElement>('.page-no-list')!.innerText = text;
  };

  instance.listener.pageSizeChange = function (payload) {
    app.querySelector<HTMLSpanElement>('.page-size')!.innerText = `${payload}`;
  };

  instance.listener.intersectionPageNoChange = function (payload) {
    app.querySelector<HTMLSpanElement>('.page-no')!.innerText = `${
      payload + 1
    }`;
  };

  instance.listener.pageScaleChange = function (payload) {
    app.querySelector<HTMLSpanElement>(
      '.page-scale-percentage',
    )!.innerText = `${Math.floor(payload * 10 * 10)}%`;
  };

  instance.listener.controlChange = function (payload) {
    const disableMenusInControlContext = [
      'superscript',
      'subscript',
      'table',
      'image',
      'signature',
      'hyperlink',
      'separator',
      'page-break',
      'checkbox',
      'latex',
    ];
    // 菜单操作权限
    disableMenusInControlContext.forEach((menu) => {
      const menuDom = app.querySelector<HTMLDivElement>(`.menu-item__${menu}`)!;
      payload
        ? menuDom.classList.add('disable')
        : menuDom.classList.remove('disable');
    });
    const menuDom = app.querySelector<HTMLDivElement>('.menu-item__control')!;
    if (!payload && instance.getMode() === EditorMode.TEMPLATE) {
      menuDom.classList.remove('disable');
    } else {
      menuDom.classList.add('disable');
    }
  };

  instance.listener.pageModeChange = function (payload) {
    const activeMode = pageModeOptionsDom.querySelector<HTMLLIElement>(
      `[data-page-mode='${payload}']`,
    )!;
    pageModeOptionsDom
      .querySelectorAll('li')
      .forEach((li) => li.classList.remove('active'));
    activeMode.classList.add('active');
  };

  instance.listener.contentChange = async function () {
    const wordCount = await instance.command.getWordCount();
    app.querySelector<HTMLSpanElement>('.word-count')!.innerText = `${
      wordCount || 0
    }`;
  };

  instance.listener.saved = function (payload, option) {
    onSave?.(payload.data, option);
  };

  instance.listener.controlDoubleClick = function (payload, mode) {
    openControlDialog(app, payload, mode, (control) => {
      instance.command.executeRemoveControl();
      instance.command.executeInsertElementList([
        {
          type: ElementType.CONTROL,
          value: '',
          control,
        },
      ]);
    });
  };

  // @ts-ignore
  instance.command.getFormData = () => {
    const controls = instance.command.getControls();
    const formData = controls.reduce<Record<string, string | undefined>>(
      (acc, control) => {
        acc[control.key] = getControlValue(control);
        return acc;
      },
      {},
    );
    return formData;
  };

  // @ts-ignore
  instance.command.getMedicalJSON = () => {
    const value = instance.getValue();
    if (!value) return;
    const text = JSON.stringify(value);
    return text;
  };

  // 9. 右键菜单注册
  instance.register.contextMenuList([
    {
      name: '获取表单数据',
      icon: 'formdata',
      when: () => true,
      callback: (command: Command) => {
        const controls = command.getControls();
        const formData = controls.reduce<Record<string, string | undefined>>(
          (acc, control) => {
            acc[control.key] = getControlValue(control);
            return acc;
          },
          {},
        );
        // alert(JSON.stringify(formData));
      },
    },
  ]);

  // 10. 快捷键注册
  instance.register.shortcutList([
    {
      key: KeyMap.P,
      mod: true,
      isGlobal: true,
      callback: (command: Command) => {
        command.executePrint();
      },
    },
    {
      key: KeyMap.F,
      mod: true,
      isGlobal: true,
      callback: (command: Command) => {
        const text = command.getRangeText();
        searchDom.click();
        if (text) {
          searchInputDom.value = text;
          instance.command.executeSearch(text);
          setSearchResult();
        }
      },
    },
    {
      key: KeyMap.MINUS,
      mod: true,
      isGlobal: true,
      callback: (command: Command) => {
        command.executePageScaleMinus();
      },
    },
    {
      key: KeyMap.EQUAL,
      mod: true,
      isGlobal: true,
      callback: (command: Command) => {
        command.executePageScaleAdd();
      },
    },
    {
      key: KeyMap.ZERO,
      mod: true,
      isGlobal: true,
      callback: (command: Command) => {
        command.executePageScaleRecovery();
      },
    },
  ]);

  return instance;
}
