import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React from 'react';

import FormBlock from '@/module/FormBlock';

const labelWidth = 96;
const formItemWidth = 763;

const MaleRecordTwo = (props) => {
  const { user } = props?.stores || {};
  const { currentPatient } = user || {};

  return (
    <VSForm
      labelAlign="left"
      id="maleRecordTwo"
      onFetch={async (params) => {
        const res =
          await vsf?.services?.MaleRecordTwoController_getMaleRecordTwoByPatientId_29a56d?.(
            { patientId: currentPatient?.patient?.id },
          );
        return res?.data; // todo 整理返回数据格式
      }}
    >
      <FormBlock title="第二性特征">
        <VSFormLayout key="0" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['beard']}
            label="胡须"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Laryngeal_nodule}
            fieldProps={{}}
          />
          <VSFormLayout key="1" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['laryngealnodule']}
              label="喉结"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Laryngeal_nodule}
              fieldProps={{}}
            />
            <VSFormItem
              name={['laryngealnodestatus']}
              // label="喉结状态"
              valueType="text"
              rules={[
                {
                  message: 'laryngealnodestatus长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="2" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['pubichair']}
              label="阴毛"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Pubic_hair}
              fieldProps={{}}
            />

            <VSFormItem
              name={['pubichairstate']}
              // label="阴毛状态"
              valueType="text"
              rules={[
                {
                  message: 'pubichairstate长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="3" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['breasts']}
              label="乳房"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Breasts}
              fieldProps={{}}
            />
            <VSFormItem
              name={['breaststatus']}
              // label="乳房状态"
              valueType="text"
              rules={[
                {
                  message: 'breaststatus长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="生殖系统检查">
        <VSFormLayout key="4" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['penile']}
            label="阴茎"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Laryngeal_nodule}
            fieldProps={{}}
          />
          <VSFormItem
            name={['penilelength']}
            label="阴茎长度"
            valueType="text"
            rules={[
              {
                message: 'penilelength长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['growth']}
            dataSource={vsf.stores.dbenums.enums.Laryngeal_nodule}
            label="发育"
            valueType="select"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['lefttesticularvolume']}
            label="睾丸左体积"
            valueType="text"
            rules={[
              {
                message: 'lefttesticularvolume长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormLayout key="6" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['leftHandedTexture']}
              label="质地"
              valueType="select"
              dataSource={
                vsf.stores.dbenums.enums.Left_handed_testicular_texture
              }
              fieldProps={{}}
            />
            <VSFormItem
              name={['descriptionoflefttesticulartexture']}
              // label="睾丸左质地描述"
              valueType="text"
              rules={[
                {
                  message: 'descriptionoflefttesticulartexture长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />

            {/* <VSFormItem
            name={['rightHandedTexture']}
            label="睾丸右质地"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Right_testicular_texture}
            fieldProps={{}}
          /> */}
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="7" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['righttesticularvolume']}
            label="睾丸右体积"
            valueType="text"
            rules={[
              {
                message: 'righttesticularvolume长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormLayout key="8" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['rightHandedTexture']}
              label="质地"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Right_testicular_texture}
              fieldProps={{}}
            />
            <VSFormItem
              name={['descriptionofrighttesticulartexture']}
              // label="睾丸右质地描述"
              valueType="text"
              rules={[
                {
                  message: 'descriptionofrighttesticulartexture长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['leftepididymis']}
            label="附睾左"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />

          <VSFormItem
            name={['leftdescriptionofepididymis']}
            // label="附睾左描述"
            valueType="text"
            rules={[
              {
                message: 'leftdescriptionofepididymis长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['rightepididymis']}
            label="附睾右"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />
          <VSFormItem
            name={['rightdescriptionofepididymis']}
            // label="附睾右描述"
            valueType="text"
            rules={[
              {
                message: 'rightdescriptionofepididymis长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="10" columnCount={6} labelWidth={labelWidth}>
          <VSFormItem
            name={['leftvasdeferens']}
            label="输精管左"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />

          <VSFormItem
            name={['leftdescriptionofvasdeferens']}
            // label="输精管左描述"
            valueType="text"
            rules={[
              {
                message: 'leftdescriptionofvasdeferens长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['rightvasdeferens']}
            label="输精管右"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />

          <VSFormItem
            name={['rightdescriptionofvasdeferens']}
            // label="输精管右描述"
            valueType="text"
            rules={[
              {
                message: 'rightdescriptionofvasdeferens长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['prostate']}
            label="前列腺"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />
          <VSFormItem
            name={['prostatesize']}
            label="大小"
            valueType="text"
            rules={[
              {
                message: 'prostatesize长度不合法',
                type: 'string',
                min: 0,
                max: 255,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['prostatetexture']}
            label="质地"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
            fieldProps={{}}
          />

          <VSFormLayout key="12" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['leftspermaticvein']}
              label="精索静脉左"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
              fieldProps={{}}
            />
            <VSFormItem
              name={['leftdescriptionofspermaticvein']}
              // label="精索静脉左描述"
              valueType="text"
              rules={[
                {
                  message: 'leftdescriptionofspermaticvein长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="13" columnCount={2} labelWidth={labelWidth}>
            <VSFormItem
              name={['rightspermaticvein']}
              label="精索静脉右"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.Lefte_pididymis}
              fieldProps={{}}
            />
            <VSFormItem
              name={['rightdescriptionofspermaticvein']}
              // label="精索静脉右描述"
              valueType="text"
              rules={[
                {
                  message: 'rightdescriptionofspermaticvein长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormItem
            name={['scrotum']}
            label="阴囊"
            valueType="select"
            // dataSource={vsf.stores.dbenums.enums.Scrotal_mass}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="13" labelWidth={labelWidth}>
          <VSFormItem
            name={['scrotalmass']}
            label="阴囊肿物"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.Scrotal_mass}
            fieldProps={{}}
          />

          <VSFormItem
            name={['descriptionofthetumor']}
            // label="肿物描述"
            valueType="text"
            fieldProps={{
              width: formItemWidth,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="14" columnCount={1} labelWidth={labelWidth}>
          <VSFormItem name={['other']} label="其他" valueType="text" />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="精液常规分析(第一次)">
        <VSFormLayout key="16" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['oneDate']}
            label="日期"
            valueType="date"
            fieldProps={{}}
          />
          <VSFormItem
            name={['abstinenceDays']}
            label="禁欲天数"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="min"
            name={['liquefactionTime']}
            label="液化时间"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['se']}
            label="色"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="ml"
            name={['liang']}
            label="量"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['viscosity']}
            label="粘稠度"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['ph']}
            label="PH"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="X10^6/ml"
            name={['concentration']}
            label="浓度"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['ningji']}
            label="凝集"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="/HPF"
            name={['yuanxibao']}
            label="圆细胞"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="%"
            name={['pr']}
            label="PR前向精子运动率"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="%"
            name={['np']}
            label="NP非前向精子运动率"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="%"
            name={['prAndNp']}
            label="PR+NP活动精子比率"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="%"
            name={['normalForm']}
            label="正常形态值"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['dfi']}
            label="DFI"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="17" labelWidth={labelWidth}>
          <VSFormItem
            addonAfter="%"
            name={['acrosinActivity']}
            label="顶体酶活性"
            valueType="text"
            dataSource={vsf.stores.dbenums.enums.Scrotal_mass}
            fieldProps={{}}
          />

          <VSFormItem
            addonAfter="%"
            name={['mark']}
            label="备注"
            valueType="text"
            fieldProps={{
              width: 666,
            }}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock title="生殖激素测定">
        <VSFormLayout key="15" columnCount={3} labelWidth={labelWidth}>
          <VSFormItem
            name={['externalDate']}
            label="外院日期"
            valueType="date"
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="IU/L"
            name={['fsh']}
            label="FSH"
            valueType="text"
            rules={[
              { message: 'fsh长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="IU/L"
            name={['lh']}
            label="LH"
            valueType="text"
            rules={[
              { message: 'lh长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            addonAfter="nmol/L"
            name={['t']}
            label="T"
            valueType="text"
            rules={[
              { message: 't长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            addonAfter="ng/ml"
            name={['prl']}
            label="PRL"
            valueType="text"
            rules={[
              { message: 'prl长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            addonAfter="pmol/L"
            name={['e2']}
            label="E2"
            valueType="text"
            rules={[
              { message: 'e2长度不合法', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </FormBlock>
      <FormBlock>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={async () => {
              const values =
                await vsf.refs.maleRecordTwo?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.MaleRecordTwoController_createMaleRecordTwo_3ff148?.(
                  {
                    btoParam: {
                      ...values,
                      patientId: currentPatient?.patient?.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.showToast('保存成功', 'success');
              }
              return values; // 直接返回提交的数据
            }}
          >
            保存
          </Button>
        </div>
      </FormBlock>
    </VSForm>
  );
};
export default definePage(MaleRecordTwo);
