type History = { fn: Function; isCursor: boolean };
export class HistoryManager {
  private readonly MAX_RECORD_COUNT = 1000;
  private undoStack: Array<History> = [];
  private redoStack: Array<History> = [];

  public undo() {
    if (this.undoStack.length > 1) {
      let pop = this.undoStack.pop()!;
      if (pop.isCursor && this.undoStack.length) {
        pop = this.undoStack.pop()!;
      }
      this.redoStack.push(pop);
      if (this.undoStack.length) {
        this.undoStack[this.undoStack.length - 1].fn();
      }
    }
  }

  public redo() {
    if (this.redoStack.length) {
      const pop = this.redoStack.pop()!;
      this.undoStack.push(pop);
      pop.fn();
    }
  }

  public execute(fn: Function, isCursor = false) {
    if (
      isCursor &&
      this.undoStack.length &&
      this.undoStack[this.undoStack.length - 1].isCursor
    ) {
      // 连续的因点击引起的光标移动，只保留最后一次
      this.undoStack.pop();
    }
    this.undoStack.push({ fn, isCursor });
    if (this.redoStack.length) {
      this.redoStack = [];
    }
    while (this.undoStack.length > this.MAX_RECORD_COUNT) {
      this.undoStack.shift();
    }
  }

  public isCanUndo(): boolean {
    return this.undoStack.length > 1;
  }

  public isCanRedo(): boolean {
    return !!this.redoStack.length;
  }
}
