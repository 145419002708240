import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const user = defineStore({
  medicalType: storage.get('medicalType') || [],
  patientList: storage.get('patientList') || [],
  currentPatient: storage.get('currentPatient') || {},
  treatInfo: storage.get('treatInfo') || {},
  doctorOrder: storage.get('doctorOrder') || {},

  hospitalType: storage.get('hospitalType') || 'out',

  initKey: 0,
  // 更新
  setInitKey: () => {
    user.initKey = user.initKey + 1;
  },

  // 住院 (in) or 门诊 (out)
  setHospitalType: (v) => {
    user.hospitalType = v;
    storage.set('hospitalType', v);
  },

  // 出诊号别
  setMedicalType: (v) => {
    user.medicalType = v;
    storage.set('medicalType', v);
  },

  // 获取就诊患者列表
  getPatientList: async () => {
    const res =
      await vsf?.services?.RegisterPatientController_allRegisterPatient_315489?.(
        {
          qto: {
            registerTypeIs: '生殖普通门诊',
            //  || user.medicalType?.[0]?.label || '生殖普通门诊',
          },
        },
      );
    user.setPatientList(res?.data);
    return res?.data;
  },

  // 设置就诊患者列表
  setPatientList: (v) => {
    user.patientList = v;
    storage.set('patientList', v);
  },

  // 设置当前就诊患者
  setCurrentPatient: (v) => {
    user.currentPatient = v;
    storage.set('currentPatient', v);
  },

  // 获取当前就诊信息 (包含患者信息, 病历信息)
  getTreatInfo: async () => {
    const res =
      await vsf?.services?.TreatInfoController_getTreatInfoDetail_9204c8?.({
        [user.hospitalType === 'out' ? 'registerId' : 'hospitalId']:
          user?.currentPatient?.id,
      });
    user.setTreatInfo(res?.data);
    return res?.data;
  },

  // 设置当前就诊信息 (包含患者信息, 病历信息)
  setTreatInfo: (v) => {
    user.treatInfo = v;
    storage.set('treatInfo', v);
  },

  // 提交病历
  submitMedicalDiagnose: async (params) => {
    const res =
      user?.hospitalType === 'out'
        ? await vsf?.services?.TreatInfoController_createTreatInfo_18fdc5?.({
            btoParam: {
              registerId: user?.currentPatient?.id,
              mainDiagnose: params?.formData?.diagnosis,
              patientId: user?.currentPatient?.patient?.id,
              doctorId: 102,
              departmentName: '生殖专科',
            },
          })
        : await vsf?.services?.TreatInfoController_createHospitalTreatInfo_af1168?.(
            {
              btoParam: {
                hospitalId: user?.currentPatient?.id,
                mainDiagnose: params?.formData?.diagnosis,
                patientId: user?.currentPatient?.patient?.id,
                doctorId: 102,
                departmentName: '生殖专科',
              },
            },
          );
    console.log('params', params);
    const res2 =
      await vsf?.services?.MedicalRecordController_createMedicalRecord_a984f3?.(
        {
          btoParam: {
            treatId: res?.data,
            medicalRecordContent: params?.JSON,
            templateId: params?.tpl?.templateId ?? null,
          },
        },
      );
    user.getTreatInfo(res?.data);
    user.setInitKey();
  },

  // 获取当前就诊信息 (包含患者信息, 病历信息)
  getDoctorOrder: async (id) => {
    if (!id && !user?.treatInfo?.id) {
      user.setDoctorOrder({});
      return {};
    }
    const res =
      await vsf?.services?.DoctorOrderController_getDoctorOrderByTreatId_51f8a8?.(
        { treatId: id ?? user?.treatInfo?.id },
      );
    user.setDoctorOrder(res?.data);
    return res?.data;
  },

  // 设置当前就诊信息 (包含患者信息, 病历信息)
  setDoctorOrder: (v) => {
    user.doctorOrder = v;
    storage.set('doctorOrder', v);
  },
});

export default user;
