import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox, message, Select } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import React, { useState } from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';

const mock = [
  {
    id: 1,
    label: '泌尿普通门诊',
    type: '普通号',
    time: '上午',
  },
  {
    id: 3,
    label: '生殖普通门诊',
    type: '普通号',
    time: '上午',
  },
];

const mock1 = [
  {
    id: 2,
    label: '泌尿专家门诊',
    type: '普通号',
    time: '上午',
  },
  {
    id: 4,
    label: '生殖专家门诊',
    type: '普通号',
    time: '上午',
  },
];

const mock2 = [
  {
    id: 1,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#FF7E7E',
  },
  {
    id: 2,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#5494FF',
  },
  {
    id: 3,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#FF7E7E',
  },
  {
    id: 4,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#5494FF',
  },
];

const Index = (props) => {
  const { user } = props?.stores || {};
  const { hospitalType } = user || {};
  const [checkList, setCheckList, getCheckList] = useGetState<any[]>([]);

  const [isAll, setIsAll] = useState<boolean>(false);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);

  const handleCheck = (data) => {
    const target = getCheckList();
    const v = target?.find((item) => item === data?.id);
    if (!v) {
      setCheckList([...target, data?.id]);
    } else {
      setCheckList([...target]?.filter((v) => v !== data?.id));
    }
  };

  const target = isShowAll ? [...mock, ...mock1] : mock;

  const handleToDetail = () => {
    if (checkList?.length > 0 || hospitalType === 'in') {
      vsf?.stores?.user?.setMedicalType(
        checkList?.map((item) => {
          const value = target?.find((citem) => citem?.id === item);
          return value ?? {};
        }),
      );

      vsf?.navigateTo('/center');
    } else {
      message.info('请选择需要出诊的号别');
    }
  };

  return (
    <div className="right-container">
      <div className="login-ab-bg"></div>
      {hospitalType === 'out' ? (
        <Block
          title="今日出诊"
          footer={
            <div className="submit-oper">
              <div className="choose">
                <Checkbox
                  value={isAll}
                  onChange={(v: any) => {
                    setIsAll(v);
                  }}
                >
                  全部
                </Checkbox>
                <Checkbox
                  value={isShowAll}
                  onChange={(v: any) => {
                    setIsShowAll(v);
                  }}
                >
                  显示本科全部号别
                </Checkbox>
              </div>
              <div className="btn" onClick={handleToDetail}>
                进入我的诊室
              </div>
            </div>
          }
        >
          <div className="title">请选择今日要出诊的号别</div>
          <div className="main-list">
            {target?.map((item, index) => (
              <div
                key={index}
                className={classnames('item', {
                  'active-item': checkList?.includes(item?.id),
                })}
                onClick={() => {
                  handleCheck(item);
                }}
              >
                <div className="left">
                  <Checkbox value={checkList?.includes(item?.id)} />
                  <div className="info">
                    <div className="label">{item?.label}</div>
                    <div className="type">{item?.type}</div>
                  </div>
                </div>
                <div className="right">{item?.time}</div>
              </div>
            ))}
          </div>
        </Block>
      ) : (
        <Block
          title="待办任务"
          extra={
            <div className="extra-order-type">
              <span className="label">任务类型</span>
              <Select width={300} />
            </div>
          }
          footer={
            <div className="submit-oper">
              <div className="btn" onClick={handleToDetail}>
                进入病房
              </div>
            </div>
          }
        >
          <div className="main-list-in">
            {mock2?.map((item, index) => (
              <div
                key={index}
                className={classnames('item', {
                  'active-item': checkList?.includes(item?.id),
                })}
                style={{
                  borderColor: item?.color ?? 'transparent',
                }}
                onClick={() => {
                  handleCheck(item);
                }}
              >
                <div className="left">
                  <div className="type">{item?.type}</div>
                  <div className="label">{item?.label}</div>
                  <div className="extra">
                    <div className="name">{item.operator}</div>
                    <div className="time">{item.time}</div>
                  </div>
                </div>
                <div className="right">
                  <div className="more">查看更多</div>
                  <div className="oper">
                    <span className="status">{item?.status}</span>
                    <span className="icon">
                      <Icon type="icon-you" />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Block>
      )}
    </div>
  );
};

export default definePage(Index);
