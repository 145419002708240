import { ControlComponent } from '../../../../dataset/enum/Control';
import { KeyMap } from '../../../../dataset/enum/KeyMap';
import { IControlInstance } from '../../../../interface/Control';
import { IElement } from '../../../../interface/Element';
import { Control } from '../Control';

export class CheckboxControl implements IControlInstance {
  private element: IElement;
  private control: Control;

  constructor(element: IElement, control: Control) {
    this.element = element;
    this.control = control;
  }

  public markRequired(startElement: IElement) {
    const elementList = this.control.getElementList(startElement);
    const startIndex = elementList.indexOf(startElement);
    if (!startElement.control?.required) {
      return;
    }
    // 向左查找
    const elements: IElement[] = [];
    let valueCount = 0;
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (preElement.controlId !== startElement.controlId) {
        break;
      } else if (
        preElement.controlComponent !== ControlComponent.PREFIX &&
        preElement.controlComponent !== ControlComponent.POSTFIX
      ) {
        elements.push(preElement);
        if (
          preElement.controlComponent === ControlComponent.CHECKBOX &&
          preElement.checkbox?.value
        ) {
          valueCount++;
        }
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (nextElement.controlId !== startElement.controlId) {
        break;
      } else if (
        nextElement.controlComponent !== ControlComponent.PREFIX &&
        nextElement.controlComponent !== ControlComponent.POSTFIX
      ) {
        elements.push(nextElement);
        if (
          nextElement.controlComponent === ControlComponent.CHECKBOX &&
          nextElement.checkbox?.value
        ) {
          valueCount++;
        }
      }
      nextIndex++;
    }

    elements.forEach((element) => (element.markRequired = valueCount === 0));
  }

  public getElement(): IElement {
    return this.element;
  }

  public getCode(): string | null {
    return this.element.control?.code || null;
  }

  public getValue(): IElement[] {
    const elementList = this.control.getElementList();
    const { startIndex } = this.control.getRange();
    const startElement = elementList[startIndex];
    if (!startElement) {
      return [];
    }
    const data: IElement[] = [];
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (
        preElement.controlId !== startElement.controlId ||
        preElement.controlComponent === ControlComponent.PREFIX
      ) {
        break;
      }
      if (preElement.controlComponent === ControlComponent.VALUE) {
        data.unshift(preElement);
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (
        nextElement.controlId !== startElement.controlId ||
        nextElement.controlComponent === ControlComponent.POSTFIX
      ) {
        break;
      }
      if (nextElement.controlComponent === ControlComponent.VALUE) {
        data.push(nextElement);
      }
      nextIndex++;
    }
    return data;
  }

  public setValue(): number {
    return -1;
  }

  public setSelect(code?: string) {
    const elementList = this.control.getElementList();
    const { control } = this.element;
    const { startIndex } = this.control.getRange();
    const startElement = elementList[startIndex];
    if (!startElement) {
      return;
    }
    const data: string[] = [];
    // 向左查找
    let preIndex = startIndex;
    while (preIndex > 0) {
      const preElement = elementList[preIndex];
      if (
        preElement.controlId !== startElement.controlId ||
        preElement.controlComponent === ControlComponent.PREFIX
      ) {
        break;
      }
      if (preElement.controlComponent === ControlComponent.CHECKBOX) {
        const checkbox = preElement.checkbox;
        if (checkbox && checkbox.value && checkbox.code) {
          if (!checkbox.multiple) {
            if (checkbox.code === code) {
              data.unshift(checkbox.code);
            } else {
              checkbox.value = false;
            }
          } else {
            data.unshift(checkbox.code);
          }
        }
      }
      preIndex--;
    }
    // 向右查找
    let nextIndex = startIndex + 1;
    while (nextIndex < elementList.length) {
      const nextElement = elementList[nextIndex];
      if (
        nextElement.controlId !== startElement.controlId ||
        nextElement.controlComponent === ControlComponent.POSTFIX
      ) {
        break;
      }
      if (nextElement.controlComponent === ControlComponent.CHECKBOX) {
        const checkbox = nextElement.checkbox;
        if (checkbox && checkbox.value && checkbox.code) {
          if (!checkbox.multiple) {
            if (checkbox.code === code) {
              data.unshift(checkbox.code);
            } else {
              checkbox.value = false;
            }
          } else {
            data.unshift(checkbox.code);
          }
        }
      }
      nextIndex++;
    }
    this.markRequired(startElement);
    control!.code = data.join(',');
  }

  public keydown(evt: KeyboardEvent): number {
    const range = this.control.getRange();
    // 收缩边界到Value内
    this.control.shrinkBoundary();
    const { startIndex, endIndex } = range;
    // 删除
    if (evt.key === KeyMap.Backspace || evt.key === KeyMap.Delete) {
      return this.control.removeControl(startIndex);
    }
    return endIndex;
  }

  public cut(): number {
    return -1;
  }
}
