import classnames from 'classnames';
import React from 'react';

const mock = [
  {
    label: '*患者信息',
    value: '患者信息',
  },
  {
    label: '*女方病历一',
    value: '女方病历一',
  },
  {
    label: '*女方病历二',
    value: '女方病历二',
  },
  {
    label: '*男方病历一',
    value: '男方病历一',
  },
  {
    label: '*男方病历二',
    value: '男方病历二',
  },
  {
    label: '*诊断情况',
    value: '诊断情况',
  },
  {
    label: '*移植记录',
    value: '移植记录',
  },
  {
    label: '证件采集',
    value: '证件采集',
  },
  {
    label: '指纹采集',
    value: '指纹采集',
  },
  {
    label: '备忘录',
    value: '备忘录',
  },

  {
    label: '新冠疫苗接种',
    value: '新冠疫苗接种',
  },
  {
    label: '化验审核单',
    value: '化验审核单',
  },
  {
    label: '检验报告查看',
    value: '检验报告查看',
  },
  {
    label: '检查报告查看',
    value: '检查报告查看',
  },
];

const Index = (props) => {
  const { value, onChange } = props;
  return (
    <div className="cpoe-reproduction-template">
      {mock?.map((item, index) => (
        <div
          className={classnames('item', {
            'active-item': item?.value === value,
          })}
          key={index}
          onClick={() => {
            onChange?.(item?.value);
          }}
        >
          {/* <span className="sign"></span> */}
          <span className="word">{item?.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Index;
